import React from 'react';
import Main from './views/Main.js'
import api from './Api.js';
import $ from 'jquery';

//import LogRocket from 'logrocket';
//LogRocket.init('ejs9so/csplus-center');
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class App extends React.Component {

  constructor(props){

    super(props);
    
    var get_session = cookies.get('session');
    var get_user = cookies.get('userId'); 
    var session = null;
    var userId = null;

    if(get_session !== null && get_user !== null){

      session = get_session;
      userId = get_user;

      sessionStorage.setItem("userId", userId);
      sessionStorage.setItem("session", session);

    }
    
    //var session = sessionStorage.getItem('session');
    //var userId = sessionStorage.getItem('userId');

    //caso não tenha, o session é vazio, isso faz o cara ir para página de login
    if(session === null || userId === null){ session = ""; userId = ""; }
 
    this.state = {systemOK:false,userId:userId,session:session,carregouIp:false,darkMode:false,menuMin:false,lessonsMinified:true,languageSelected:"pt-br",languages:[],languageTexts:{}}; 

    this.verificationInitial = this.verificationInitial.bind(this);
    this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
    this.language = this.language.bind(this);
    this.removeSession = this.removeSession.bind(this);
    this.loadConfiguration = this.loadConfiguration.bind(this);
    
    window.app = this;

  }
  
  //retorna os testos da linguagem atual
  language(term){ 

    if(term === undefined){ return this.state.languageTexts; }else{ return this.state.languageTexts[term]; }

  }
  
  async verificationInitial(){
    
      var url = window.location.origin;
      var psa = url.replaceAll('https://', '');
      var splitc = psa.split('.');
      console.log('Empresa: '+splitc);

      var urlCheck = 'https://erp-center-all.s3.sa-east-1.amazonaws.com/serverlist/'+splitc[0]+'.json';
      console.log(urlCheck);

      var ip = await $.ajax({url:urlCheck,async: true}).done(function(data){return data.responseJSON;}).fail(function(){ return false; }).catch(function(){ return false; });

      if(ip === false && window.location.hostname !== "localhost"){ console.log('Erro:01'); return false; }

      if(window.location.hostname === "localhost"){

        console.log('é dev dds');
        splitc[0] = 'supergeeks';
        ip = {url:"http://localhost:3021"};
        sessionStorage.backend = 'http://localhost:3021'; 

      }

      console.log('sa1');
      if(ip.url === undefined){ console.log('Erro:02'); return false; } 

      console.log('sa2');
      console.log('url checada: '+ip.url+'/'+splitc[0]+'/sucesso');
      var backend = await $.ajax({url:ip.url+'/'+splitc[0]+'/sucesso',async: true}).done(function(response){return response.data;}).fail(function(){ console.log('falha ao acessar:'+ip.url); return false;}).catch(function(){console.log('falha ao acessar: '+ip.url);return false;});

      console.log('sa3');
      if(backend){

        console.log('PASSOU ASM0');

        var urlBackend = ip.url+'/'+splitc[0]

        sessionStorage.setItem('backend',urlBackend);
        
        this.setState({carregouIp:true}); 
        this.verify(1);

        return true;

      }else{ console.log('O backend retornou false.'); return false; }/**/

  }

  async loadConfiguration(){

    var a = await api.configuration().then((res) =>{

      this.setState({confs:res.data});
      return true;

    }).catch((res) =>{

      return false;

    });

    return a;
    
  }

  //Para exibir o preload
  loading(n){}

  //Parar deslogar o usuario do sistema
  removeSession(){

    console.log('removeu tudo');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('userId');

    cookies.remove('session',{path:'/'});
    cookies.remove('userId',{path:'/'});

    delete window.app.state.dataUser;
    delete window.app.state.user;
    this.setState({session:'',userId:''});

  }

  //para verificar no servidor se ele esta logado.
  verify(pass){

    console.log('chamando verify');
    if(this.state.session !== "" && this.state.carregouIp === true){ pass = true; }

    console.log('pass:',pass);
    
    if(pass === true){
      api.verifyAcess({}).then((res)=>{

        if(this.state.user === undefined){ 

          this.setState({user:res.data}); 
          //LogRocket.identify('csplus_center_'+res.data.id,{name: res.data.name+' '+res.data.lastName,email:res.data.email,subscriptionType:'pro'});
        
        }
        
      }).catch(error=>{
          console.log(error.data);
          console.log(error);
          console.log(error.response);
          if(error.response !== undefined){if(error.response.status !== undefined){if(error.response.status === 401){  this.removeSession();  }}}
      });

    }else{ console.log('NÃO CHAMOU VERIFY'); }

    //setTimeout(function(){ this.verify(); }.bind(this), 3000);

  }


  //Para carregas as linguagens disponíveis no sistema.
  async getAllLanguage(){
    var language = await api.listLanguages().then((res) => {
      this.setState({languages:res.data})
      return true;
    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });
    return language;
  }


  //para retornar os dados da linguagem atual selecionaand
  getCurrentLanguage(){
    var language;
    for(let i = 0; i < this.state.languages.length; i++){ if(this.state.languages[i].id === this.state.languageSelected){ language = this.state.languages[i]; }}
    return language;
  }

  //para buscar o arquivo da linguagem no sistema
  async getLanguageTexts(nameFile,id){
    console.log(nameFile);
    
    this.setState({languageSelected:id});

    var a = await api.languageText(nameFile).then((res) => {
      this.setState({languageTexts:res.data});
      return true;
    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });
    return a;
  }


  async componentDidMount(){

    //para verificar o backend
    var a = await this.verificationInitial();

    //para pegar as linguagens disponíveis no sistema 
    var b = await this.getAllLanguage();

    var e = await this.loadConfiguration();

    //para carregar a linguagem padrão
    var c = await this.getLanguageTexts('pt-br.json','pt-br');

    //caso esteja tudo correto, exibe a página
    if(a === true && b === true && c === true && e === true){

      this.setState({systemOK:true});

    }else{

      console.log("Verificação do brancked: "+a);
      console.log("Verificação do languages: "+b);
      console.log("Baixou arquivo de linguagem: "+c);
      console.log("Baixou configuração: "+c);

    }

  }

  render(){

    return(
      <React.Fragment>
        {
          
          (this.state.systemOK === true) &&
          <Main loading={ (n) => this.loading(n) }  setAppState={ (n) => this.setState(n) }  getAppState={ () => this.state } />
        
        }
      </React.Fragment>);
    
  }


}

export default App;