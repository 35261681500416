import React from 'react';
import{ BrowserRouter as Router, Route } from 'react-router-dom';
import AuthNavbar from "./../components/Navbars/AuthNavbar.jsx";
import Login from "./pages/examples/Login.jsx";
import AdminLayout from "./../layouts/Admin.jsx";
import Cookies from 'universal-cookie';
import Socket from "./../socket/index.js";
import { ToastProvider, useToasts } from 'react-toast-notifications'

const cookies = new Cookies();
const mainContext = React.createContext({loading:false,message:null,showLoading:message=>{},hideLoading:() => {} })

class Main extends React.Component {

  	constructor(props){

    	super(props);
		this.state = {open:false};
		this.loginSuccess = this.loginSuccess.bind(this);
		window.main = this;

  	}

  	handleClick(){}

  	loginSuccess(session,user){ 

  		sessionStorage.setItem('session',session);
      	sessionStorage.setItem('userId',user.id);
		this.props.setAppState({session:session,userId:user.id,user:user});
		 
		cookies.set('session',session,{path:'/'});
		cookies.set('userId',user.id,{path:'/'});
       
  	}

 	componentDidMount(){}

	render() {
  		const loginSuccess = this.loginSuccess;
  		const value = { ...this.state, loginSuccess};
  		return (<mainContext.Provider value={value}>
  				<Router>
					<ToastProvider>
					{
						//Caso tenha session o storage
						(this.props.getAppState().session !== "" && window.app.state.user !== undefined) &&
						<Route path="*" render={props => <AdminLayout {...props} />} />

					}
					</ToastProvider>
					{
						//Caso o session no app esteja em branco, exibe a tela de login
						((this.props.getAppState().session === "" || this.props.getAppState().session === undefined) && window.app.state.user === undefined) &&
						<React.Fragment>
							<div className="main-content" ref="mainContent"> 
								<AuthNavbar/>  
								<Login {...{ loginSuccess }} /> 
							</div> 
						</React.Fragment>
					}
				</Router>
    	</mainContext.Provider>);

	}

}
// 
export default Main;