import React from "react";
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,Col,Row} from "reactstrap";
//import NotificationAlert from "react-notification-alert";
import classnames from "classnames";

import api from "./../../../Api.js";
import NotificationAlert from "react-notification-alert";

class EditUser extends React.Component {

	constructor(props){

    	super(props);
        this.setState = this.props.setState;

        this.postGroup = this.postGroup.bind(this);
        this.putGroup = this.putGroup.bind(this);
        this.save = this.save.bind(this);

        this.state = {};

    }
      
    async postGroup(){

        this.props.state.dialogGroupField.groupID = this.props.groupID;
        
        await api.postClientGroupFields(this.props.state.dialogGroupField).then(async (res) => {

            this.props.getGroups();
            this.props.setState({openModalGroup:false});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O grupo foi cadastrado.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
    
        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response); 
            this.props.setState({errorGroup:error.response.data.msg});
            return false; 
    
        });

    }

    async putGroup(){

        await api.putClientGroupFields(this.props.state.editGroup,this.props.state.dialogGroupField).then(async (res) => {

            this.props.getGroups();
            this.props.setState({openModalGroup:false});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
    
        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response); 
            this.props.setState({errorGroup:error.response.data.msg});
            return false; 
    
        });

    }

    save(event){

        event.preventDefault();
        if(this.props.state.editGroup === ""){ this.postGroup(); }else{ this.putGroup(); }

    }

  	render(){

        const handleChange = name => event => {
	        var dialogGroupField = this.props.state.dialogGroupField;
	        dialogGroupField[name] = event.target.value;
	        this.setState({dialogGroupField:dialogGroupField});
	    }
     
  		return(<React.Fragment>
              
            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        
            <Modal className="modal-dialog-centered" isOpen={this.props.state.openModalGroup} >
            
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">{this.props.state.textDialog}</h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openDialogUserCreate:false})}><span aria-hidden={true}>×</span> </button>
                </div>
                <CardBody className="px-lg-5">

                    {
                        (this.props.state.errorGroup !== "") &&
                        <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.props.state.errorGroup}</Alert>
                    }

                    <Form role="form" type="post" onSubmit={ (event) => this.save(event) }>

                    <Row>
                        <Col md={12}>
                            <h5>Nome:</h5>
                            <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                <Input type="text" onChange={handleChange('name')} value={ this.props.state.dialogGroupField.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                            </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button className="my-4" color="default" type="button" onClick={ () => this.props.setState({openModalGroup:false}) } >Cancelar</Button>
                        <Button className="my-4" color="primary" type="submit">{this.props.state.textDialogButton}</Button>
                    </div>
                    </Form>
                </CardBody>
                </Card>
            </div>
            </Modal>
        
        </React.Fragment>);

  	}

}

export default EditUser;