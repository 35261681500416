import React from "react";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import ReactQuill from 'react-quill';
import NotificationAlert from "react-notification-alert";
import moment from "moment";
class ListLesson extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {contracts:[],products:[],showRest:false,plan_of_accounts:[]};
        
        this.state.dialog = {name:"",description:"",html:"",reference:""};
        this.state.groupsClient = [];
        this.state.groupsStudent = [];

        this.saveContract = this.saveContract.bind(this);
        this.getGroups = this.getGroups.bind(this);

        this.postContract = this.postContract.bind(this);
        this.putContract = this.putContract.bind(this);
        this.getContract = this.getContract.bind(this);
        this.getProducts = this.getProducts.bind(this);
        
        this.addCampoDoc = this.addCampoDoc.bind(this);
        this.removeCampoDoc = this.removeCampoDoc.bind(this);
        this.listCampoDoc = this.listCampoDoc.bind(this);

		window.newcontract = this;

    }
    
    addCampoDoc(name){

        if(this.state.dialog[name] === null){ this.state.dialog[name] = []; }

        this.state.dialog[name].push({element:"",x:"100.00",y:"20.00",z:"1"});

        this.setState({t:Math.random()});

    }


    removeCampoDoc(name,index){

        this.state.dialog[name].splice(index, 1);
        this.setState({dialog:this.state.dialog,t:Math.random()});

    }


    listCampoDoc(name){

        var vl = [];
        console.log('DS1');

        console.log(this.state.dialog[name]);
        if(Array.isArray(this.state.dialog[name]) === false){

            console.log('DS2');
            if(this.state.dialog[name] !== undefined && this.state.dialog[name] !== null){ 
                
                try{
                    
                    vl = JSON.parse(this.state.dialog[name]);
                    console.log('DS3');

                }catch(error){
                    
                    vl = [];
                    console.log('DS4');

                }
                
            } 

        }else{

            vl = this.state.dialog[name];
            console.log('DS5');

        }

        console.log('array campos');
        console.log(vl);
        
  
        return vl;

    }

    genCampDoc(name){

        return <React.Fragment>
            <table className="table">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Largura (%)</th>
                        <th>Altura</th>
                        <th>Página</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {

                        this.listCampoDoc(name).map((value,index) => {

                            return <tr>
                                        <td>
                                            <select style={{padding:'2px',height:'25px'}} className="form-control" value={this.state.dialog[name][index].element} onChange={(e) => { this.state.dialog[name][index].element = e.target.value; this.setState({a:1}); }} >
                                                <option value=""></option>
                                                <option value="SIGNATURE">Assinatura</option>
                                                <option value="NAME">Nome do signatário</option>
                                                <option value="INITIALS">Rúbrica</option>
                                                <option value="DATE">Data de assinatura</option>
                                                <option value="CPF">CPF do signatário</option>
                                            </select>
                                        </td>
                                        <td><input style={{padding:'7px',height:'25px'}} className="form-control" value={this.state.dialog[name][index].x} onChange={(e) => { this.state.dialog[name][index].x = e.target.value; this.setState({a:1}); }} /></td>
                                        <td><input style={{padding:'7px',height:'25px'}} className="form-control" value={this.state.dialog[name][index].y} onChange={(e) => { this.state.dialog[name][index].y = e.target.value; this.setState({a:1}); }} /></td>
                                        <td><input style={{padding:'7px',height:'25px'}} className="form-control" value={this.state.dialog[name][index].z} onChange={(e) => { this.state.dialog[name][index].z = e.target.value; this.setState({a:1}); }} /></td>
                                        <td><i style={{cursor:'pointer'}} onClick={() => { this.removeCampoDoc(name,index); }} class="far fa-trash-alt"></i></td>
                                    </tr>

                        })

                    }
                </tbody>
            </table>

            <div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
                <button type="button" className="btn btn-sm btn-primary" onClick={() => { this.addCampoDoc(name); }}>Adicionar campo</button>
            </div>
        </React.Fragment>

    }

    getPlanOfplansofaccount(){
        
        api.getPlanOfplansofaccount().then((res) => {

            console.log(res.data);
            this.setState({plan_of_accounts:res.data});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);

        });
          
    }

    async getProducts(){

		await api.getProducts().then((res) => {
            
            this.setState({products:res.data});
   
  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

    async getGroups(group,name){

        await api.getClientGroupFields(group).then(async (res) => {
            
            if(parseInt(group) === 0){ this.setState({groupsClient:res.data}); }

            if(parseInt(group) === 1){ this.setState({groupsStudent:res.data}); }
               
        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  
            return false; 
    
        });

    }

    componentDidMount(){

        this.getGroups(0,'client');
        this.getGroups(1,'student');
        this.getProducts();
        this.getPlanOfplansofaccount();

        if(this.props.match.params !== "new"){

            this.getContract(this.props.match.params.id);

        }

        setTimeout(function(){ 

            var ops = {
                toolbar: [
                  ['style', ['bold', 'italic', 'underline', 'clear']],
                  ['font', ['strikethrough', 'superscript', 'subscript','fontsize']],
                  ['color', ['color']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['height', ['height']],
                  ['Misc',['fullscreen','codeview']],
                  ['Insert',['picture','link']]
                ]
              };

            window.$('#summer').summernote(ops); 
        
        }, 300);

    }

    async getContract(id){

        await api.getContract(id).then(async (res) => {
            
            this.setState({dialog:res.data});
               
        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  
            return false; 
    
        });

    }

    async postContract(){

        var obj = JSON.parse(JSON.stringify(this.state.dialog));
        obj.html = window.$('#summer').summernote('code');
  
        await api.postContract(obj).then(async (res) => {
        
            console.log(res.data);

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">O contrato foi cadastrado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            return false; 
            
    
        });

    }


    async putContract(){

        var obj = JSON.parse(JSON.stringify(this.state.dialog));
        obj.html = window.$('#summer').summernote('code');

        await api.putContract(this.state.dialog.id,obj).then(async (res) => {
            
            console.log(res.data);

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
            return false; 
    
        });

    }

 
    saveContract(event){

        event.preventDefault();

        if(this.state.dialog.id === undefined){

            this.postContract();

        }else{

            this.putContract();

        }

    }


    geraOps(array,sum,ops){

       // console.log(array);
        //return <React.Fragment></React.Fragment>
        if(sum === undefined){ sum = ''; }

        return array.map((value,index) => {
 
            return(
            <React.Fragment key={value}>
                {

                    (value.id !== this.state.editPlan && value.subplan !== value.id) &&
                    <React.Fragment>
                        <br/>{value.name}:
                        <font class="variavel_contrato">[[product_plc_{value.id}_value]]</font>
                        <font class="variavel_contrato">[[product_plc_{value.id}_discount]]</font>
                        <font class="variavel_contrato">[[product_plc_{value.id}_discount_percentage]]</font>
                        <font class="variavel_contrato">[[product_plc_{value.id}_total_value]]</font>
                        <font class="variavel_contrato">[[product_plc_{value.id}_installment]]</font>
                        <font class="variavel_contrato">[[product_plc_{value.id}_method_tiny]]</font>
                        <font class="variavel_contrato">[[product_plc_{value.id}_method_capital]]</font>
                        <font class="variavel_contrato">[[product_plc_{value.id}_installment_total_value]]</font>
                        <br/>
                    </React.Fragment>
                    
                
                }
                {
                    /*this.geraOps(value.subs,(sum+(index+1)),'SUBS')*/
                }
            </React.Fragment>)
            
        })

    }
    
     
 
	render(){
        
        const handleChange = name => event => {
            var dialog = this.state.dialog;
            dialog[name] = event.target.value;
            this.setState({dialog:dialog});
        }
        
        var t1,t2;
        if(this.state.dialog.id !== undefined){

            t1 = "Editar contrato";
            t2 = "Salvar alterações";
            
        }else{

            t1 = "Cadastrar contrato";
            t2 = "Cadastrar contrato";

        }

    	return(
		<React.Fragment> 

            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

            <SimpleHeader name="Contratos" way={[{name:"Contratos",url:"/contract"}]} buttons={<React.Fragment />} />
            <div class="mt--6 container-fluid">
                <div className="justify-content-center row">

                    <div className="card-wrapper col-lg-8">

                        <div className="card">

                            <div class="card-header">
                                <div class="row"><div class="col-6"><h3 class="mb-0">{t1}</h3></div></div>
                            </div>

                            <div className="card-body">

                                <form type="post" role="form" type="post" onSubmit={ (event) => this.saveContract(event) }>

                                    <h6 class="heading-small text-muted mb-4">Informações gerais</h6>
                                    
                                    <div class="pl-lg-4">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-username">Nome do contrato:</label>
                                                    <input onChange={ handleChange('name')} name="name" type="text" class="form-control" value={this.state.dialog.name} />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-username">Descrição do contrato:</label>
                                                    <textarea onChange={ handleChange('description')} name="description" rows="4" class="form-control" value={this.state.dialog.description}></textarea>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-username">Referencia interna:</label>
                                                    <input onChange={ handleChange('reference')} name="reference" type="text" class="form-control" value={this.state.dialog.reference} />
                                                    <div style={{marginTop:"5px"}}>Exemplo de referência: <font style={{color:'red'}}>termo_de_compromisso</font></div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                        <hr/>

                                            <label class="form-control-label" for="input-username">Monte o contrato:</label>
                                        
                                            <br/><br/>

                                            Variáveis disponíveis para <strong style={{color:'red'}}>cliente</strong>:
                                            <br/>
                                            
                                            <font className="variavel_contrato">[[client_name]]</font>
                                            <font className="variavel_contrato">[[client_lastName]]</font>
                                            <font className="variavel_contrato">[[client_email]]</font>
                                            <font className="variavel_contrato">[[client_birthDate]]</font>

                                            <font className="variavel_contrato">[[client_zipCode]]</font>
                                            <font className="variavel_contrato">[[client_address]]</font>
                                            <font className="variavel_contrato">[[client_state]]</font>

                                            <font className="variavel_contrato">[[client_city]]</font>
                                            <font className="variavel_contrato">[[client_number]]</font>
                                            <font className="variavel_contrato">[[client_neighborhood]]</font>

                                            <font className="variavel_contrato">[[client_phone]]</font>
                                            <font className="variavel_contrato">[[client_cell]]</font>
                                            <font className="variavel_contrato">[[client_cpf]]</font>
                                            <font className="variavel_contrato">[[client_rg]]</font>
                                        
                                        {

                                            this.state.groupsClient.map((value,index) =>{

                                                return(<React.Fragment key={"gp_a_"+index}>

                                                    <br/><br/>
                                                    <font>{value.name}:</font>
                                                    <br/>
                                                    
                                                    {

                                                        value.fields.map((value2,index2) => {

                                                            if(parseInt(value2.type) === 11){

                                                                return (<React.Fragment key={"gp_b_"+index2}></React.Fragment>)

                                                            }

                                                            return(<React.Fragment key={"gp_b_"+index2}>

                                                                    <font className="variavel_contrato">[[{'client_exf_'+value2.fieldID}]]</font>

                                                                </React.Fragment>)

                                                        })

                                                    }

                                                </React.Fragment>)

                                            })

                                        }
 
                                        <br/>
                                        <br/>

                                        Variáveis disponíveis para <strong style={{color:'red'}}>estudante</strong>:
                                            <br/>
                                            
                                            <font className="variavel_contrato">[[student_name]]</font>
                                            <font className="variavel_contrato">[[student_lastName]]</font>
                                            <font className="variavel_contrato">[[student_birthDate]]</font>
                                        
                                        Variáveis disponíveis para <strong style={{color:'red'}}>cliente</strong>:
                                        {

                                            this.state.groupsStudent.map((value,index) =>{

                                                return(<React.Fragment key={"gp_a_"+index}>

                                                    <br/><br/>
                                                    <font>{value.name}:</font>
                                                    <br/>
                                                    
                                                    {

                                                        value.fields.map((value2,index2) => {

                                                            if(parseInt(value2.type) === 11){

                                                                return (<React.Fragment key={"gp_bb_"+index2}></React.Fragment>)

                                                            }

                                                            return(<React.Fragment key={"gp_bb_"+index2}>

                                                                    <font className="variavel_contrato">[[{'student_exf_'+value2.fieldID}]]</font>

                                                                </React.Fragment>)

                                                        })

                                                    }

                                                </React.Fragment>)

                                            })

                                        }

                                        <br/><br/>

                                        Variáveis disponíveis para <strong style={{color:'red'}}>turma</strong>:
                                        <br/>
                                        
                                        <font className="variavel_contrato">[[classRoom_name]]</font>
                                        <font className="variavel_contrato">[[classRoom_openingDate]]</font>
                                        <font className="variavel_contrato">[[classRoom_closingDate]]</font>
                                        <br/><br/>

                                        Variáveis disponíveis para <strong style={{color:'red'}}>curso</strong>:
                                        <br/> 
                                        
                                        <font className="variavel_contrato">[[course_name]]</font>
                                        <br/><br/>

                                        Variáveis disponíveis para <strong style={{color:'red'}}>matrícula</strong>:
                                        <br/> 
                                        
                                        <font className="variavel_contrato">[[registration_createDate]]</font>
                                        <font className="variavel_contrato">[[registration_totalvalue]]</font>
                                        <font className="variavel_contrato">[[registration_paymentlaname]]</font>
                                        <font className="variavel_contrato">[[registration_totalvaluediscount]]</font>
                                         
 
                                        <br/><br/>

                                        Variáveis disponíveis para <strong style={{color:'red'}}>data</strong>:
                                        <br/> 
                                        
                                        <font className="variavel_contrato">[[date_mm]]</font>
                                        <font className="variavel_contrato">[[date_dd]]</font>
                                        <font className="variavel_contrato">[[date_yyyy]]</font>
                                        <font className="variavel_contrato">[[date_mm_extensive]]</font>
 
                                        <br/><br/>

                                        Variáveis disponíveis para <strong style={{color:'red'}}>unidade</strong>:
                                        <br/> 
                                        
                                        <font className="variavel_contrato">[[license_cnpj]]</font>
                                        <font className="variavel_contrato">[[license_reason]]</font>
                                        <font className="variavel_contrato">[[license_name]]</font>
                                        <font className="variavel_contrato">[[license_email]]</font>
                                        <font className="variavel_contrato">[[license_operatoremail]]</font>
                                        <font className="variavel_contrato">[[license_zipcode]]</font>
                                        <font className="variavel_contrato">[[license_city]]</font>
                                        <font className="variavel_contrato">[[license_state]]</font>
                                        <font className="variavel_contrato">[[license_zipCode]]</font>
                                        <br/><br/> 

                                        Variáveis disponíveis para <strong style={{color:'red'}}>produtos</strong>:
                                        
                                        {

                                            (this.state.showRest === false) &&
                                            <button onClick={() => this.setState({showRest:true}) } className="btn-round btn-icon btn btn-secondary btn-sm">Mostrar variáveis</button>

                                        }

                                        {

                                            (this.state.showRest === true) &&
                                            <button onClick={() => this.setState({showRest:false}) } className="btn-round btn-icon btn btn-secondary btn-sm">Ocultar variáveis</button>

                                        }
                                        
                                        <br/><br/>
                                            <font className="variavel_contrato">[[Identificador no contrato_value_fix]]</font>
                                            <font className="variavel_contrato">[[Identificador no contrato_discount_fix]]</font>
                                            <font className="variavel_contrato">[[Identificador no contrato_total_fix]]</font>
                                            <font className="variavel_contrato">[[Identificador no contrato_installment_total_value]]</font>
                                        <br/><br/>
                                        
                                        {
                                            
                                            (this.state.showRest === true) &&
                                            <React.Fragment>
                                                
                                                {
                                                    
                                                    this.state.products.map((value,index) => {
        
                                                        return(<React.Fragment>
                                                            <font className="variavel_contrato">[[{value.id_name}_value]]</font>
                                                            <font className="variavel_contrato">[[{value.id_name}_discount]]</font>
                                                            <font className="variavel_contrato">[[{value.id_name}_percentage]]</font>
                                                            <font className="variavel_contrato">[[{value.id_name}_total_value]]</font>
                                                            <font className="variavel_contrato">[[{value.id_name}_installment]]</font>
                                                            <font className="variavel_contrato">[[{value.id_name}_tiny]]</font>
                                                            <font className="variavel_contrato">[[{value.id_name}_method_capital]]</font>
                                                            <font className="variavel_contrato">[[{value.id_name}_installment_value]]</font>
                                                            <font className="variavel_contrato">[[{value.id_name}_installment_discount]]</font>
                                                            <font className="variavel_contrato">[[{value.id_name}_installment_total_value]]</font>
                                                            <br/><br/>
                                                        </React.Fragment>)

                                                    })

                                                }
                                                <br/><br/>    
                                            </React.Fragment>

                                        }
                                       

                                        Variáveis de protudos a partir do <strong style={{color:'red'}}>plano de contas</strong>:
                                        {
                                        
                                            (this.state.plan_of_accounts.map(function(x){ return x.id; }).indexOf(2) !== -1) &&
                                            this.geraOps(this.state.plan_of_accounts[this.state.plan_of_accounts.map(function(x){ return x.id; }).indexOf(2)].subs)

                                        }

                                        <br/>
                                        <br/>

                                        {/*<ReactQuill 	
                                        
                                            modules={{
                                                    
                                                    toolbar: [
                                                                [{font:[]},{size:[]}],
                                                                ['bold','italic','underline','strike'],
                                                                [{color:[]},{'background':[]}],
                                                                [{script:'super'},{'script':'sub'}],
                                                                [{header:'1'},{header:'2'},'blockquote','code-block'],
                                                                [{list:'ordered'},{list:'bullet'},{indent:'-1'},{indent:'+1'}],
                                                                ['direction',{align:[]}],
                                                                ['link','image','video','formula'],
                                                                ['clean']
                                                            ]
                                                            
                                                    }}  
                                                            
                                            value= onChange={(value) => { var dialog = this.state.dialog; dialog.html = value; this.setState({dialog:dialog}); } }  theme="snow"  
                                            
                                        />*/}

                                        <textarea onChange={ () => console.log('mudou') } id="summer" value={this.state.dialog.html}></textarea>


                                        Variáveis de assinatura do cliente (autentique):

                                        {this.genCampDoc('doc_autentique_client')}
                                        <br/><br/>
                                        Variáveis de assinatura da licença (autentique):

                                        {this.genCampDoc('doc_autentique_license')}
                                         

                                    </div>

                                    <br/>
                                    
                                     

                                    <button type="submit" class="btn btn-primary btn-lg btn-block">{t2}</button>

                                </form>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
         

		</React.Fragment>)
    }
}
export default ListLesson;