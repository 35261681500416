import React from "react";
import { connect } from 'react-redux';
import './style_show_info_invoice.css';
import Draggable from 'react-draggable';
import { ResponsivePie } from '@nivo/pie';

class ShowInfos extends React.Component {
    
    constructor(props){

        super(props);
        this.getDatas = this.getDatas.bind(this);
        this.state = {showInfos:false,expanded:false};

        this.state.data = [
            {
                id: "A receber",
                label: "A receber",
                value: 0,
                color: "hsl(118, 70%, 50%)",
                id2:'pending',
                value2:0,
                discount:0
            },
            {
                id: "Vencidas",
                label: "Vencidas",
                value: 0,
                color: "hsl(185, 70%, 50%)",
                id2:'pending_vencid',
                value2:0,
                discount:0
            },
            {
                id: "Pagas",
                label: "Pagas",
                value: 0,
                color: "hsl(342, 70%, 50%)",
                id2:'paid',
                value2:0,
                discount:0
            },
            {
                id: "Parc. paga",
                label: "Parc. paga",
                value: 0,
                color: "hsl(185, 70%, 50%)",
                id2:'partially_paid',
                value2:0,
                discount:0
            },
            {
                id: "Reembolsadas",
                label: "Reembolsadas",
                value: 0,
                color: "hsl(359, 70%, 50%)",
                id2:'refunded',
                value2:0,
                discount:0
            },
            {
                id: "Parc. reembolsada",
                label: "Parc. reembolsada",
                value: 0,
                color: "hsl(185, 70%, 50%)",
                id2:'partially_refunded',
                value2:0,
                discount:0
            },
            {
                id: "Canceladas",
                label: "Canceladas",
                value: 0,
                color: "hsl(180, 70%, 50%)",
                id2:'canceled',
                value2:0,
                discount:0
            },
            {
                id: "Não faturadas",
                label: "Não faturadas",
                value: 0,
                color: "hsl(185, 70%, 50%)",
                id2:null,
                value2:0,
                discount:0
            },
            {
                id: "Em análise",
                label: "Em análise",
                value: 0,
                color: "hsl(185, 70%, 50%)",
                id2:'in_analysis',
                value2:0,
                discount:0
            },
            {
                id: "Contestada",
                label: "Contestada",
                value: 0,
                color: "hsl(185, 70%, 50%)",
                id2:'chargeback',
                value2:0,
                discount:0
            },
            {
                id: "Em protesto",
                label: "Em protesto",
                value: 0,
                color: "hsl(185, 70%, 50%)",
                id2:'in_protest',
                value2:0,
                discount:0
            },
            {
                id: "Expirada",
                label: "Expirada",
                value: 0,
                color: "hsl(185, 70%, 50%)",
                id2:'expired',
                value2:0,
                discount:0
            }
          ];

  
             
             
             
 
             
             

          
    }
  
    getDatas(){

        var grafico = JSON.parse(JSON.stringify(this.state.data));
        var datas = {desconto_total:0,total_com_desconto:0,total_sem_desconto:0,multa:0};

        for (let index = 0; index < this.props.invoices.length; index++) {
             
            var invoice = this.props.invoices[index];
            
            if(invoice.status === "pending"){ 

                if(invoice.financialOK === false){

                    var gg = grafico.find(x => x.id2 === 'pending');
                    if(gg !== undefined){

                        gg.value++;
                        gg.value2 = gg.value2 + invoice.value;
                        gg.discount = gg.discount + invoice.discount;

                    }

                }

                if(invoice.financialOK === true){

                    var gg = grafico.find(x => x.id2 === 'pending_vencid');
                    if(gg !== undefined){

                        gg.value++;
                        gg.value2 = gg.value2 + invoice.value;
                        gg.discount = gg.discount + invoice.discount;

                    }

                }
            
            }else{

                var gg = grafico.find(x => x.id2 === invoice.status);
                if(gg !== undefined){
    
                    gg.value++;
                    gg.value2 = gg.value2 + invoice.value;
                    gg.discount = gg.discount + invoice.discount;
    
                }

            }
 
            datas.desconto_total += invoice.discount;
            datas.total_com_desconto += invoice.value - invoice.discount;
            datas.total_sem_desconto += invoice.value;
            datas.multa += invoice.fine;

        }

        return {grafico:grafico,valores:datas};

    }

    render(){

        var dados = this.getDatas();

        var datas = dados.valores;
        var grafico = dados.grafico;

        return(<React.Fragment>

            <div className="b123" style={{position:'fixed',top:'0px',zIindex:'999'}} >
                <Draggable defaultPosition={{x:100,y:30}} >

                    <div>

                        {

                            (this.state.expanded === true) &&
                            <style>{' .expanded_infoinvoice{ width: 430px; height: 370px; } '}</style>

                        }

                        <div className="infoi_invoice_div">
                            <div className="expanded_infoinvoice">

                                <div onClick={ () => this.setState({expanded:!this.state.expanded}) } className="expand_left_info_invoice "> 
                                    {

                                        (this.state.expanded === false) &&
                                        <i className="far fa-caret-square-left"></i>

                                    }
                                    {

                                        (this.state.expanded === true) &&
                                        <i className="far fa-caret-square-right"></i>

                                    }
                                    </div>


                                    <ResponsivePie
                        refs="dddd"
                        data={grafico}
                        margin={{ top: 40, right: 0, bottom: 80, left: 0 }}
                        innerRadius={0.5}
                        padAngle={this.state.angle}
                        colors={['#ff952a','#fc2858','#2dce89','#972193','#000000','#919191','#fcfd15','#0b92a6','#ff136a','#f013ff','#1000ff','#00ffff']}

                        startAngle={0}
                        endAngle={180}

                        cornerRadius={3}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextXOffset={6}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkOffset={-6}
                        radialLabelsLinkDiagonalLength={8}
                        radialLabelsLinkHorizontalLength={4}
                        radialLabelsLinkStrokeWidth={1}
                        radialLabelsLinkColor={{ from: 'color' }}
                        slicesLabelsSkipAngle={10}
                        slicesLabelsTextColor="#333333"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'Vencidas'
                                },
                                id: 'lines'
                            } 
                        ]}
                        legends={[
                        {
                            anchor: 'bottom-left',
                            direction: 'column',
                            justify: false,
                            translateX: 10,
                            translateY: -0,
                            itemsSpacing: 4,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 1,
                            itemTextColor: '#777',
                            symbolSize: 10,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000',
                                        itemBackground: '#f7fafb'
                                    }
                                }
                            ]
                        }
                    ]}

                    animate={true}
                    motionStiffness={130}
                    motionDamping={15}

                />


                            </div>
                            <div className="div_miniinfos_invoice animated">
                                <div>
                                    <h3>Soma dos status:</h3>
                                    {

                                        grafico.map((value,index) => {
                                            
                                            console.log(value);
                                            return(<div className="swinf_1" style={{color:'rgb(50 50 93)'}}>{value.label}: <font className="font_pdpq">R$ {window.convertMonyes(value.value2 - value.discount,true)}</font> <font className="showInfosDescoto">(Valor R$ {window.convertMonyes(value.value2,true)})</font> <font className="showInfosDescoto">(Desconto R$ {window.convertMonyes(value.discount,true)})</font> </div>)

                                        })

                                    }
                                    
                                    <br/>
                                     
                                </div>
                                <div>
                                    <h3>Soma dos valores:</h3> 
                                    <div className="swinf_1">Desconto total: <font className="font_pdpq">R$ {window.convertMonyes(datas.desconto_total,true)}</font></div>
                                    <div className="swinf_1">Total com desconto: <font className="font_pdpq">R$ {window.convertMonyes(datas.total_com_desconto,true)}</font></div>
                                    <div className="swinf_1">Total sem desconto: <font className="font_pdpq">R$ {window.convertMonyes(datas.total_sem_desconto,true)}</font></div>

                                    <br/>
                                    <h3>Valores iugu:</h3>
                                    <div className="swinf_1">Total multa/juros: <font className="font_pdpq">R$ {window.convertMonyes(this.props.extraValues.TotalfineIugu,true)}</font></div>
                                    <div className="swinf_1">Total desconto: <font className="font_pdpq">R$ {window.convertMonyes(this.props.extraValues.TotaldiscountIugu,true)}</font></div>
                                    <div className="swinf_1">Total taxas: <font className="font_pdpq">R$ {window.convertMonyes(this.props.extraValues.Totaltaxes_paid_cents,true)}</font></div> 
                                    
                                    <br/>
                                    <h3>Repasses</h3>
                                    <div className="swinf_1">Pendente: <font className="font_pdpq">R$ {window.convertMonyes(this.props.repasses.pendente,true)}</font></div> 
                                    <div className="swinf_1">Pago: <font className="font_pdpq">R$ {window.convertMonyes(this.props.repasses.pago,true)}</font></div> 
                                    <div className="swinf_1">Cancelado: <font className="font_pdpq">R$ {window.convertMonyes(this.props.repasses.cancelado,true)}</font></div> 
                                    <div className="swinf_1">Reembolsado: <font className="font_pdpq">R$ {window.convertMonyes(this.props.repasses.reembolsado,true)}</font></div> 
                                    
                                    
                                    {
                                    /*
                                        TotalfineIugu
                                        TotaldiscountIugu
                                        Totaltaxes_paid_cents
                                    */
                                    }

                                </div>
                            </div>
                        </div>
                    </div> 
                </Draggable>
            </div>
        </React.Fragment>)

    }

}

export default ShowInfos;