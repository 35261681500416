import React from "react";
import api from "./../../Api.js";
import {Container,Row,Col,Card,CardHeader,Button,Table} from "reactstrap";
//import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx"; 
import $ from "jquery";
import ModalNewGroupField from "./modal/ModalNewGroupField.jsx";
import ModalNewGroupFieldField from "./modal/ModalNewGroupFieldField.jsx";

import Sortable from 'react-sortablejs';

class Myclassroom extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {errorGroup:"",errorField:"",groups:[],loadGroup:false,openModalGroup:false,openModalField:false};

        this.state.dialogGroupField = {name:""};
        this.state.dialogGroupFieldField = {name:"",type:"",items:[],mineDescription:"",obligatory:'0',classname:""};

        this.newGroup = this.newGroup.bind(this);
        this.newField = this.newField.bind(this);
        this.getGroups = this.getGroups.bind(this);

        this.editGroup = this.editGroup.bind(this);
        this.editField = this.editField.bind(this);  
        
        this.resetField = this.resetField.bind(this);
        this.resetGroup = this.resetGroup.bind(this);

		window.clientFields = this;

	}

    //Para casdastrar um novo grupo
    async newGroup(){
        
        this.setState({editGroup:"",openModalGroup:true,textDialog:"Cadastrar grupo",textDialogButton:"Cadastrar Grupo",errorGroup:""});
        this.resetGroup();

    }

    //Para cadastrar um novo campo
    async newField(group){
        
        this.setState({editGroup:group,editField:"",openModalField:true,textDialog:"Cadastrar Campo",textDialogButton:"Cadastrar Campo",errorField:""});
        this.resetField();

    }

    resetField(){ 
        
        this.setState({errorField:"",dialogGroupFieldField:{classname:"",name:"",type:"1",items:[],obligatory:'0',mineDescription:"",fieldID:""}});
    
    }
    
    resetGroup(){ 
        
        this.setState({errorGroup:"",dialogGroupField:{name:""}});
    
    }

    //Para editar um grupo
    async editGroup(id){

        var index = this.state.groups.map(function(x) {return x.id;}).indexOf(id);
        var data = this.state.groups[index];

        data = JSON.parse(JSON.stringify(data));

        this.setState({editGroup:id,openModalGroup:true,dialogGroupField:data,textDialog:"Alterar Grupo",textDialogButton:"Salvar Alterações"});
        
    }

    //Para editar um grupo
    async editField(value){

        await this.resetField();
        var data = JSON.parse(JSON.stringify(value));

        try {
            
            data.items = JSON.parse(data.items);

        }catch(error){
            
            data.items = '[]';
        
        }

        this.setState({editGroup:data.group,openModalField:true,dialogGroupFieldField:data,textDialog:"Alterar Campo",textDialogButton:"Salvar Alterações"});

    }

    async getGroups(){

        await this.setState({loadGroup:false,groupID:this.props.groupID});

        await api.getClientGroupFields(this.props.groupID).then(async (res) => {

            this.setState({groups:res.data,loadGroup:true});
    
        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  
            return false; 
    
        });

    }

    componentDidUpdate(){

        if(parseInt(this.props.groupID) !== parseInt(this.state.groupID)){

            this.getGroups(); 

        }

    }

    async changeOrderField(){

        var data = [];
        
        $(".group_field").each(function(){

            var c = 0;
            var grupoID = $(this).attr('idc');
            
            $(this).find('.fieldcamp').each(function(){
                
                var fieldID = $(this).attr('idc');
                var sequence = c;

                console.log('Grupo: '+grupoID+' Field:'+fieldID+' Sequence:'+c+' ');
                data.push({group:grupoID,fieldID:fieldID,sequence:c})

                c++;

            });

        });


        await api.updateSequenceGroupFields(data).then(async (res) => {

            console.log(res.data);
    
        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  
            return false; 
    
        });
 

    }

 	async componentDidMount(){

        this.getGroups();

 	}
 
    render(){

        console.log('deu render');
    
        return(<React.Fragment>

                <SimpleHeader name={this.props.name1} way={[{name:"Configurações",url:"/configuration"},{ name:this.props.name2, url: this.props.location.pathname }]} buttons={<React.Fragment />} />
                
                <Container className="mt--6 container-fluid" fluid>
                
                    <Card>
                    <CardHeader className="border-0">
                        <Row>
                            <Col xs="6">
                                <h3 className="mb-0">Lista de grupos de campos</h3>
                            </Col>
                            <React.Fragment>
                                <Col className="text-right" xs="6">
                                <Button className="btn-neutral btn-round btn-icon" size="sm" onClick={() => this.newGroup()} >
                                    <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-plus"></i>
                                    </span>
                                    <span className="btn-inner--text">
                                    Cadastrar Grupo
                                    </span>
                                </Button>
                                </Col>
                            </React.Fragment>
                        </Row>
                    </CardHeader>
                    
                    {

                        (this.state.loadGroup === true) &&

                    
                    <Sortable options={{handle: ".fa-arrows-alt",onEnd:function(){  this.changeOrderField() }.bind(this) }} > 
                    {

                        this.state.groups.map((value,index) => {

                            return(<React.Fragment key={index}>

                                <div className="group_field" idc={value.id} style={{padding:'50px'}}> 
                                
                                    <div className="client_field_group" style={{marginBottom:'12px'}}>
                                        <font><i class="fas fa-arrows-alt"></i> {value.name}</font>

                                        <Button style={{float:'right'}} className="btn-danger btn-round btn-icon" size="sm"><i class="fas fa-trash"></i></Button>

                                        <Button onClick={() => this.editGroup(value.id)} style={{float:'right'}} className="btn-default btn-round btn-icon" size="sm" >
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-pencil-alt"></i>
                                            </span>
                                            <span className="btn-inner--text">Editar grupo</span>
                                        </Button>
                                        
                                        <Button  onClick={() => this.newField(value.id)} style={{float:'right'}} className="btn-default btn-round btn-icon" size="sm" >
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            <span className="btn-inner--text">Novo campo</span>
                                        </Button>
                                                                                 
                                    </div>

                                    <div className="table_group_field">

                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th></th>
                                                    <th>Nome</th>
                                                    <th>Mini descrição</th>
                                                    <th><center>Tipo do campo</center></th>
                                                    <th><center>Obrigatório</center></th>
                                                    <th>fieldID</th>
                                                    <th>Classname</th>
                                                     
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <Sortable options={{handle: ".fa-arrows-alt",group:'shared',onEnd:function(){  this.changeOrderField() }.bind(this) }} tag="tbody">
                                                {
                                                    
                                                    value.fields.map((value2,index2) => {

                                                        var index = window.clientExtraField.map(function(x) {return x.id; }).indexOf(parseInt(value2.type));
                                                        var c = window.clientExtraField[index];

                                                        return(<tr key={'field_'+value2.id} idc={value2.id} className="fieldcamp tableList">

                                                                <td style={{textAlign:'center',width:'35px',paddingLeft:'10px',paddingRight:'10px'}}><i class="fas fa-arrows-alt"></i></td>
                                                                 
                                                                <td>{value2.name}</td>
                                                                <td>{value2.mineDescription}</td>
                                                                <td><center>{c.name}</center></td>
                                                                <td>
                                                                <center>
                                                                    {

                                                                        (parseInt(value2.obligatory) === 0) &&
                                                                        <strong style={{color:'red'}}>NÃO</strong>

                                                                    }
                                                                    {

                                                                        (parseInt(value2.obligatory) === 1) &&
                                                                        <strong style={{color:'green'}}>SIM</strong>

                                                                    }
                                                                </center>
                                                                </td>
                                                                <td>{value2.fieldID}</td>
                                                                <td>{value2.classname}</td>
                                                                <td>

                                                                    <font onClick={() => this.editField(value2)} className="table-actions fontButton"><i className="far fa-edit" /></font>
                                                                    <font className="table-actions fontButton"><i className="far fa-trash-alt" /></font>
                                                                
                                                                </td>

                                                        </tr>)

                                                    })
                                            
                                                }
                                            </Sortable>
                                        </Table>
                                         
                                        {

                                            (value.fields.length === 0) &&
                                            <React.Fragment>

                                                <center style={{marginTop:'15px'}}>Nenhum campo foi cadastrado.</center>

                                            </React.Fragment>

                                        }

                                    </div>

                                </div>

                            </React.Fragment>)

                        })

                    }
                    </Sortable>
                    }

                    {

                        (this.state.groups.length === 0) &&
                        <React.Fragment>

                            <center><h3 style={{margin:'30px 0px'}}>Nenhum grupo foi encontrado.</h3></center>

                        </React.Fragment>

                    }

                    </Card>
                </Container>

                <ModalNewGroupField groupID={this.props.groupID} getGroups={() => this.getGroups()} state={this.state} setState={ (data) => this.setState(data)} />
                <ModalNewGroupFieldField  getGroups={() => this.getGroups()} state={this.state} setState={ (data) => this.setState(data)} />
 
        </React.Fragment>)
        
    }

}

export default Myclassroom