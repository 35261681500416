function generateRoutes(permissions,translate){
 
    var routes = [];

    routes.push({path:"/",name:'Dashboard',icon:"fas fa-home text-primary",layout:"/admin"});

    routes.push({
        collapse: true,
        name: 'Cursos', 
        icon: "fas fa-graduation-cap",
        state: "dashboardsCollapse",
        views: [
            {
                path: "/course/newcourse",
                name: 'Novo curso',
                layout: "/admin"
            },
            {
                path: "/course/",
                name: 'Listar cursos',
                layout: "/admin"
            } 
        ]
    });

    routes.push({
        collapse: true,
        name: 'Relatórios', 
        icon: "fas fa-chart-pie",
        state: "dashboardsCollapseas",
        views: [
            {
                path: "/report/crm",
                name: 'Funil de vendas',
                layout: "/admin"
            },
            {
                path: "/report/comission",
                name: 'Repasses',
                layout: "/admin"
            }
        ]
    });

    routes.push({path:"/permission",name:'Permissões',icon:"fas fa-user-shield",layout:"/admin"});
    routes.push({path:"/license",name:'Licenças',icon:"fas fa-key",layout:"/admin"});
    routes.push({path:"/client_fields",name:"Campos dos clientes",layout:"/admin",icon:"fas fa-i-cursor"});
    routes.push({path:"/student_fields",name:"Campos dos estudantes",layout:"/admin",icon:"fas fa-i-cursor"});


    routes.push({path:"/contract_registration",name:"Contratos de mátricula",layout:"/admin",icon:"far fa-file-pdf"});
    routes.push({path:"/contract_license",name:"Contratos de licença",layout:"/admin",icon:"far fa-file-pdf"});
    routes.push({path:"/notice",name:"Comunicados",layout:"/admin",icon:"fas fa-bullhorn"});


    routes.push({path:"/invoice",name:"Faturas",layout:"/admin",icon:"fas fa-file-invoice-dollar"});
    
    
    routes.push({path:"/product",name:"Produtos ou serviços",layout:"/admin",icon:"fas fa-boxes"});
    routes.push({path:"/nature_operation",name:"Natureza de operação",layout:"/admin",icon:"fas fa-file-invoice-dollar"});
    
    routes.push({path:"/pricerange",name:'Faixas de preço',icon:"fas fa-cogs",layout:"/pricerange"});
    routes.push({path:"/accountplans",name:'Planos de conta',icon:"fas fa-user",layout:"/admin"});
    routes.push({path:"/plans",name:"Planos de pagamento",layout:"/admin",icon:"fas fa-money-bill-wave"});
    
    routes.push({path:"/users",name:'Usuários',icon:"fas fa-user",layout:"/admin"});
    
    routes.push({path:"/configuration",name:'Configurações',icon:"fas fa-cogs",layout:"/configuration"});

    routes.push({path:"/registration_cancel",name:'Motivos de cancelamentos',icon:"fas fa-cogs",layout:"/registration_cancel"});

    routes.push({path:"/api_lms",name:'Integração LMS',icon:"fas fa-cogs",layout:"/api_lms"});

    return routes;
  
  }
     
  export default generateRoutes;
  