import React from "react";
import {Alert,Modal,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Col,Row, NavItem,NavLink,Nav} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import NotificationAlert from "react-notification-alert";
import classnames from "classnames";

const Swal = require('sweetalert2');

class Product extends React.Component {

	constructor(props){

        super(props);
        
        this.state = {groupPlans:[],loadPlans:false,dialogGroup:{},dialogPlan:{},erroGroup:""};
        
		this.getPlansOfAccount = this.getPlansOfAccount.bind(this); 
        this.newPlan = this.newPlan.bind(this);
        this.editPlan = this.editPlan.bind(this);
        this.removePlan = this.removePlan.bind(this);
        this.savePlan = this.savePlan.bind(this);
        this.putPlan = this.putPlan.bind(this);
         
		window.plangroups = this;

    }

    newPlan(group){

        this.setState({editGroup:group,openModalPlan:true,editPlan:undefined,erroPlan:"",dialogPlan:{name:"",subplan:"",sequence:""}});

    }

    editPlan(id,dataPlan){
 
        var plan = JSON.parse(JSON.stringify(dataPlan));

        if(plan.subplan === null){ plan.subplan = ""; }
        this.setState({openModalPlan:true,editPlan:id,dialogPlan:plan,erroPlan:""});
        
    }

 

    async removePlan(id){
         Swal.fire({
            title: 'Tem certeza?',
            text: "Os dados não podem ser recuperados.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
            }).then((result) => {
        
            if(result.value){
                
                api.deletePlansOfAccount(id).then(async (res) => {
        
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O plano de conta foi removido.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);
                    
                    this.getPlansOfAccount();

                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);

                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);
        
                });
                    
        
            }
        
        });
     

    }
 
    /*async postGroup(){

        api.postGroupOfPlan(this.state.dialogGroup).then((res) => {

            this.setState({openDialogGroup:false});
            this.getGroupOfPlans();
            
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O grupo foi cadastrado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch((error) => {

            this.setState({erroGroup:error.response.data.msg});

        });

    }*/
  
    async putPlan(){

        api.putPlansOfAccount(this.state.editPlan,this.state.dialogPlan).then((res) => {

            this.setState({openModalPlan:false});
            this.getPlansOfAccount();
            
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O plano foi cadastrado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch((error) => {

            this.setState({erroPlan:error.response.data.msg});
            

        });

    }

    async postPlan(){

        var obj = JSON.parse(JSON.stringify(this.state.dialogPlan));
        if(obj.subplan === ""){ obj.subplan = null; }
        
        api.postPlansOfAccount(obj).then((res) => {

            this.setState({openModalPlan:false});
            this.getPlansOfAccount();
            
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O plano foi cadastrado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch((error) => {

            this.setState({erroPlan:error.response.data.msg});

        });

    }
 
    /*async putGroup(){

        api.putGroupOfPlan(this.state.editGroup,this.state.dialogGroup).then((res) => {

            this.setState({openDialogGroup:false});
            this.getPlansOfAccount();
            
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O grupo foi editado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch((error) => {

            this.setState({erroGroup:error.response.data.msg});

        });

    }*/

    /*saveGroup(e){

        e.preventDefault();
        if(this.state.editGroup === undefined){ this.postGroup(); }else{ this.putGroup(); }

    }*/


    savePlan(e){

        e.preventDefault();
        if(this.state.editPlan === undefined){ this.postPlan(); }else{ this.putPlan(); }
    
    }
/*
    editGroup(id){

        var index = this.state.groupPlans.findIndex(x => x.id === id);
        var group = this.state.groupPlans[index];
       
        group = JSON.parse(JSON.stringify(group));

        this.setState({editGroup:id,dialogGroup:group,openDialogGroup:true,erroGroup:""});

    }
 
    newGroup(){

        this.setState({openDialogGroup:true,editGroup:undefined,dialogGroup:{name:""},erroGroup:""});

    }*/
     
	//Para pegar as lessons
  	async getPlansOfAccount(){

		await api.getPlansOfAccount().then((res) => {
            
            this.setState({groupPlans:res.data});
   
  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

	async componentDidMount(){
        
        this.getPlansOfAccount();

    } 

    geraLista(array,sum,ops){

        console.log(sum);
        var trace = "";
        if(sum === undefined){ sum = ''; trace = ""; }else{ trace = "-"; }
        //console.log(array);

        return array.map((value,index) => {
    
            return(
            <div key={value} style={{marginLeft:'15px'}}>
                <div className="hover_ind" style={{display:'flex',paddingBottom:'10px',paddingTop:'7px'}}>
                    <div style={{paddingLeft:'50px'}}>{this.geraPonts(sum+trace+(index+1))} {value.name}</div>
                    <div style={{position:'absolute',right:'19px'}}>
                        <button onClick={() => this.editPlan(value.id,value) } class="btn-round btn-icon btn btn-secondary btn-sm"><i class="fas fa-pencil-alt"></i> Editar</button>
                        <button onClick={() => this.removePlan(value.id,value) } class="btn-round btn-icon btn btn-danger btn-sm"><i class="far fa-trash-alt"></i></button>
                    </div>
                </div>
                <div>
                    {this.geraLista(value.subs,(sum+trace+(index+1)),'SUBS')}
                </div>
            </div>)
            
        })

    }

    geraPonts(txt){

        console.log('Antes: '+txt);
        
        txt = txt.split('-');
        var txt2 = "";
        for(let index = 0; index < txt.length; index++) {
            
            txt2 = txt2+(index !== 0 ? ".":"")+(txt[index]);
            
        }

        console.log('Depois: '+txt);

        return txt2;

    }

    geraOps(array,sum,ops){

        var trace = "";
        if(sum === undefined){ sum = ''; trace = ""; }else{ trace = "-"; }

        return array.map((value,index) => {
 
            return(
            <React.Fragment key={value}>
                
                {

                    (value.id !== this.state.editPlan && value.subplan !== value.id) &&
                    <option value={value.id}>{this.geraPonts(sum+trace+(index+1))} {value.name}</option> 
                
                }
                
                {this.geraOps(value.subs,(sum+trace+(index+1)),'SUBS')}
            </React.Fragment>)
            
        })

    }

    



  
	render(){
        
        const handleChangePlan = name => event => {
            var dialogPlan = this.state.dialogPlan;

            if(name === "id"){

                event.target.value = event.target.value.toLocaleLowerCase().replace(/ /g,'').replace(/[^a-zA-Z_0-9]/g,'');

                
            }

            dialogPlan[name] = event.target.value;
            this.setState({dialogPlan:dialogPlan});
        }
        
    	return(
		<React.Fragment> 

            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
            <style>{' .hover_ind:hover{  background-color: #f1f1f1; box-shadow: 0px 3px 4px -5px #000; border-radius: 10px; } '}</style>
            
            <SimpleHeader name="Produtos" way={[{name:"Produtos",url:"/product"}]} buttons={<React.Fragment />} />

            <div class="mt--6 container-fluid">
                <div class="card">

                    <div class="border-0 card-header" style={{boxShadow:'0px 2px 4px -5px #000'}}>
                        <div class="row">
                            <div class="col-6">
                                <h3 class="mb-0">Planos de conta</h3>
                            </div>
                            <div class="text-right col-6">
                                <button onClick={ () => this.newPlan() } class="btn-round btn-icon btn btn-secondary btn-sm">
                                    <span class="btn-inner--icon mr-1"><i class="fas fa-plus"></i> Criar Plano</span> 
                                </button>
                            </div>
                        </div>
                    </div>

                    { this.geraLista(this.state.groupPlans) }
                    
                    {

                        (this.state.groupPlans.length === 0) &&
                        <div style={{textAlign:'center',marginTop: '25px',marginBottom: '20px'}}>Nenhum plano de contas foi encontrado.</div>

                    }
                </div>
            </div>

            <Modal className="modal-dialog-centered" isOpen={this.state.openModalPlan} >
                <div className="modal-header bg-secondary">
                    <h5 className="modal-title" id="exampleModalLabel">
                        <font>{this.state.editPlan === undefined ? 'Cadastrar plano':'Editar plano'}</font>
                    </h5>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModalPlan:false})} >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body bg-secondary">
                    <Form role="form" type="post" onSubmit={ (event) => this.savePlan(event) }>
                        
                        {
                            
                            (this.state.erroPlan !== "") &&
                            <div class="alert alert-danger fade show" role="alert"><strong>Ops!</strong> {this.state.erroPlan}</div>

                        }

                        <Col md={12}>
                            <h5>Identificador:</h5>
                            <FormGroup  >
                                <InputGroup className="input-group-merge input-group-alternative"> 
                                    <input disabled={(this.state.editPlan !== undefined ? true:false)} className="form-control" value={this.state.dialogPlan.id} onChange={handleChangePlan('id')} />
                                </InputGroup>
                            </FormGroup>
                        </Col>

                        <Col md={12}>
                            <h5>Nome do plano:</h5>
                            <FormGroup >
                                <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                    <Input type="text" onChange={handleChangePlan('name')} value={this.state.dialogPlan.name}  />
                                </InputGroup>
                            </FormGroup>
                        </Col>

                        <Col md={12}>
                            <h5>Sub-plano:</h5>
                            <FormGroup  >
                                <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                    <select className="form-control" value={this.state.dialogPlan.subplan} onChange={handleChangePlan('subplan')}>
                                        <option value=""></option>
                                        {this.geraOps(this.state.groupPlans)} 
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>

                        <Col md={12}>
                            <h5>Sequencia:</h5>
                            <FormGroup  >
                                <InputGroup className="input-group-merge input-group-alternative"> 
                                    <input className="form-control" value={this.state.dialogPlan.sequence} onChange={handleChangePlan('sequence')} />
                                </InputGroup>
                            </FormGroup>
                        </Col>

                        <Col md={12}>
                            <button type="submit" style={{width:"100%"}} className="btn-icon btn-3 btn btn-primary">{this.state.editPlan === undefined ? 'Cadastrar plano':'Editar plano'}</button>
                        </Col>

                    </Form>
                </div>
            </Modal>

		</React.Fragment>)
    }

}


export default Product;