import React from "react";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import moment from "moment";
import NotificationAlert from "react-notification-alert";

const Swal = require('sweetalert2');

class Contract_Lincese extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {contracts:[]};
		this.getContracts = this.getContracts.bind(this);
        this.deleteContract = this.deleteContract.bind(this);

		window.contracts = this;

	}

    deleteContract(id){

        Swal.fire({
            title: 'Tem certeza?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
          }).then((result) => {
      
            if(result.value){
                
                api.deleteContractLicense(id).then((res) => {
      
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O contrato removido.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);
                    
                    this.getContracts();

                    console.log('deletou');

                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });
                 
      
            }
      
        });

    }

  	async getContracts(){

		await api.getContractsLicense().then((res) => {
            
        	this.setState({contracts:res.data}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

	async componentDidMount(){
        
        this.getContracts();
 
	}
 

	render(){
 
    	return(
		<React.Fragment> 

            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

            <SimpleHeader name="Contratos de licença" way={[{name:"Contratos license",url:"/contract_license"}]} buttons={<React.Fragment />} />
            <div class="mt--6 container-fluid">
                <div class="card">
                <div class="border-0 card-header">
                    <div class="row">
                        <div class="col-6">
                            <h3 class="mb-0">Lista de contratos</h3>
                        </div>
                        <div class="text-right col-6">
                            <button onClick={ () => this.props.history.push('/contract_license/new') } class="btn-round btn-icon btn btn-default btn-sm">
                                <i class="fas fa-plus"></i> Criar contrato
                            </button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="align-items-center table-flush table">
                        <thead class="thead-light">
                            <tr>
                                <th>id</th>
                                <th>Nome</th>
                                <th>Version</th>
                                <th>Data crição</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                this.state.contracts.map((value,index) => {
                                  
                                    return(<tr>
                                                <td>{value.id}</td>
                                                <td style={{cursor:"pointer"}} onClick={ () => this.props.history.push('/contract_license/'+value.id)} >{value.name}</td>
                                                <td>{value.version}</td>
                                                <td>{moment(value.createDate,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</td>
                                                <td>
                                                    <font onClick={ () => this.props.history.push('/contract_license/'+value.id)} class="table-actions fontButton"><i class="far fa-edit"></i></font>
                                                    <font class="table-actions fontButton" onClick={() => this.deleteContract(value.id)}><i class="far fa-trash-alt"></i></font>
                                                </td>
                                            </tr>)

                                })

                            } 
                        </tbody>
                    </table>

                    {

                        (this.state.contracts.length === 0) &&
                        <React.Fragment>
                            <center><h4 style={{margin:"30px 0px"}}>Nenhum contrato foi encontrado.</h4></center>
                        </React.Fragment>

                    }


                </div>
            </div>
        </div>

		</React.Fragment>)
    }
}
export default Contract_Lincese;