import React from "react";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import ReactQuill from 'react-quill';
import NotificationAlert from "react-notification-alert";

class NewNotice extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {};
        this.state.dialog = {name:"",version:"",html:""};

        this.saveNotice = this.saveNotice.bind(this); 
        this.postNotice = this.postNotice.bind(this);
        this.putNotice = this.putNotice.bind(this);
        
		window.newnotice = this;

    }
 

    componentDidMount(){
  
        if(this.props.match.params !== "new"){

            this.getNotice(this.props.match.params.id);

        }

        setTimeout(function(){ 

            var ops = {
                toolbar: [
                  ['style', ['bold', 'italic', 'underline', 'clear']],
                  ['font', ['strikethrough', 'superscript', 'subscript','fontsize']],
                  ['color', ['color']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['height', ['height']],
                  ['Misc',['fullscreen','codeview']],
                  ['Insert',['picture','link']]
                ]
              };

            window.$('#summer').summernote(ops); 
        
        }, 300);

    }

    async getNotice(id){

        await api.getNotice(id).then(async (res) => {
            
            this.setState({dialog:res.data});
               
        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  
            return false; 
    
        });

    }

    async postNotice(){

        var obj = JSON.parse(JSON.stringify(this.state.dialog));
        obj.html = window.$('#summer').summernote('code');
  
        await api.postNotice(obj).then(async (response) => {
        
            this.props.history.push('/notice/'+response.data.notice.id);

            var dialog = this.state.dialog;
            dialog.id = response.data.notice.id;
            this.setState({dialog:dialog});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">O comunicado foi cadastrado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            return false; 
            
    
        });

    }


    async putNotice(){

        var obj = JSON.parse(JSON.stringify(this.state.dialog));
        obj.html = window.$('#summer').summernote('code');

        await api.putNotice(this.state.dialog.id,obj).then(async (res) => {
            
            console.log(res.data);

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
            return false; 
    
        });

    }

 
    saveNotice(event){

        event.preventDefault();

        if(this.state.dialog.id === undefined){

            this.postNotice();

        }else{

            this.putNotice();

        }

    }
 
 
	render(){
        
        const handleChange = name => event => {
            var dialog = this.state.dialog;
            dialog[name] = event.target.value;
            this.setState({dialog:dialog});
        }
        
        var t1,t2;
        if(this.state.dialog.id !== undefined){

            t1 = "Editar comunicado";
            t2 = "Salvar alterações";
            
        }else{

            t1 = "Cadastrar comunicado";
            t2 = "Cadastrar comunicado";

        }

    	return(
		<React.Fragment>
            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
            <SimpleHeader name="Comunicados" way={[{name:"Comunicados",url:"/notice"}]} buttons={<React.Fragment />} />
            <div class="mt--6 container-fluid">
                <div className="justify-content-center row">
                    <div className="card-wrapper col-lg-8">
                        <div className="card">
                            <div class="card-header">
                                <div class="row"><div class="col-6"><h3 class="mb-0">{t1}</h3></div></div>
                            </div>
                            <div className="card-body">
                                <form type="post" role="form" type="post" onSubmit={ (event) => this.saveNotice(event) }>
                                    <h6 class="heading-small text-muted mb-4">Informações gerais</h6>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-username">Título:</label>
                                                    <input onChange={ handleChange('name')} name="name" type="text" class="form-control" value={this.state.dialog.name} />
                                                </div>
                                            </div>
                                        </div>
                                        <textarea onChange={ () => console.log('mudou') } id="summer" value={this.state.dialog.html}></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-primary btn-lg btn-block">{t2}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</React.Fragment>)
    }
}
export default NewNotice;