import React from "react";
import { Button,Card,CardHeader,CardBody,CardImg,CardTitle,FormGroup,Form,Input,ListGroupItem,ListGroup,Progress,Container,Row,Col} from "reactstrap";
import {InputGroupText,Alert,Modal,InputGroupAddon,InputGroup} from "reactstrap";
import api from "./../../Api.js";
import $ from 'jquery';
import { SortablePane, Pane } from 'react-sortable-pane';
import classnames from "classnames";
import { RingLoader } from 'react-spinners';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import ApexChart from "./../chart/radar.js";
import { Link } from "react-router-dom";

class Course extends React.Component {

  constructor(props){

    super(props);

    this.getCourse = this.getCourse.bind(this);
    this.newUnit = this.newUnit.bind(this);
    this.editUnit = this.editUnit.bind(this);
    this.uploadImg = this.uploadImg.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.postUnit = this.postUnit.bind(this);
    this.saveUnit = this.saveUnit.bind(this);
    this.removeUnit = this.removeUnit.bind(this);
    this.removeUnitApi = this.removeUnitApi.bind(this);
    this.putUnit = this.putUnit.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.addUnitPane = this.addUnitPane.bind(this);
    this.removeUnitPane = this.removeUnitPane.bind(this);
    this.getLicensesCourse = this.getLicensesCourse.bind(this);

    this.state = {
      orderUnit:[],
      listUnit:[],
      openDialogRemoveUnit:false,
      removeUnit:"",
      dialogUnit:{name:"",icon:"",description:"",sequence:"",status:""},
      fileInput:[{erro:"",progress:""}],
      openDialogUnit:false,
      loadCourse:false,
      loadUnit:false,
      course:{},
      unit:[],
      loadVideos:false,
      loadQuizzes:false,
    };

    window.newCourse = this;

  }

  //Para pegar os dados do curso
  async getCourse(){

      await api.getCourse(this.props.match.params.course).then((res) => {
            
        this.setState({course:res.data,loadCourse:true});
        console.log(res.data);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        if(error.response.status === 404){ this.props.history.push('/'); }

      });

  }


  //Para pegar a unit
  async getUnitCourse(ignorePane = false){

      await api.getUnitCourse(this.props.match.params.course).then((res) => {
            
        this.setState({unit:res.data,loadUnit:true});

        if(ignorePane === false){ res.data.forEach(function(item,index,array){ this.addUnitPane(item) }.bind(this)); }

        console.log(res.data);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }

  //para cadastrar uma unit
  newUnit(){

    this.setState({erroUnit:"",dialogUnit:{name:"",icon:"",description:"",sequence:"",status:""},editUnit:"",openDialogUnit:true,titleDialogUnit:"Cadastrar unit",buttonDialogUnit:"Cadastrar"});
    setTimeout(function(){ $('.iconUnitDialog').fadeOut(0); }, 100);

  }

  //Para editar uma unit
  editUnit(id){

    this.setState({erroUnit:"",editUnit:id,openDialogUnit:true,titleDialogUnit:"Editar unit",buttonDialogUnit:"Salvar Alterações"});

    this.state.unit.forEach(function(item,index,array){ 

        if(parseInt(item.id) === parseInt(id)){ 
        
          var item2 = JSON.stringify(item);
          item2 = JSON.parse(item2);

          delete item2.createDate;
          delete item2.updateDate;
          delete item2.id; 
          delete item2.course; 
          var iconfile = item2.icon_file;
          delete item2.icon_file;

          this.setState({dialogUnit:item2});
          setTimeout(function(){ 

            $('input[name="customRadioInline1"][value="'+item2.status+'"]').prop('checked', true); 
            if(iconfile !== undefined){ 
              
              $('.iconUnitDialog').attr('src',sessionStorage.getItem('backend')+iconfile.url); 
              $('.iconUnitDialog').fadeIn(0); 
            
            }else{ 
              
              $('.iconUnitDialog').attr('src',''); 
              $('.iconUnitDialog').fadeOut(0); 
            
            }
          

          }, 10);

        } 

      }.bind(this));

  }

  //Para fazer o upload do arquivo no input file
  async uploadImg(input){

    let formData = new FormData();
    var fileInput = this.state.fileInput;
    var row = $('.section_file[name="'+input+'"]');
    input = row.find('input[type="file"]');
    var n = row.attr('n');
    var file = input[0].files;

    //seta o erro caso nenhum arquivo tenha sido informado
    if(file.length === 0){  
      fileInput[n].erro = "";
      this.setState({fileInput:fileInput});
      setTimeout(function(){ fileInput[n].erro = "Você deve escolher um arquivo."; this.setState({fileInput:fileInput}); }.bind(this), 1);
      return false;
    }

    //removo o erro caso tenha
    fileInput[n].erro = "";
    this.setState({fileInput:fileInput});

    formData.append('files', file[0]); 
    formData.append('name', file[0].name);
    this.setProgress(0,n);

    var obj = {'Content-Type': 'multipart/form-data',session:sessionStorage.getItem('session')};
    console.log(obj);
    
    axios.post(sessionStorage.getItem('backend')+'/upload',formData,{headers:obj,onUploadProgress:progressEvent => {

      this.setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total),n)

      }}).then(function(res){

        $('.iconUnitDialog').attr('src',sessionStorage.getItem('backend')+res.data.url);
        $('.iconUnitDialog').fadeIn(0);

        this.setProgress('',n);

        if(parseInt(n) === 0){ this.state.dialogUnit.icon = res.data.id; }

      }.bind(this)).catch(function(res){

        console.log('FAILURE!!');
        this.setProgress('',n);

      }.bind(this));

  }

  //Para setar o progresso da barra
  setProgress(progress,n){

    var fileInput = this.state.fileInput;
    fileInput[n].progress = progress;
    this.setState({fileInput:fileInput});
    console.log(progress);

  }

  //Quando mudar o arquivo no input, muda o texto no label
  changeFileInput(event,htmlFor){

    console.log(event);
    console.log(event.target.value);
    $("label[for='"+htmlFor+"']").html(event.target.value);
    console.log("label[for='"+htmlFor+"']");

  }

  async componentDidMount(){
  
    await this.getCourse();
    await this.getUnitCourse();
    await this.getVideos();
    await this.getCourseQuiz();
    await this.getLicensesCourse();

  }

  //Para cadastra uma nova unit
  async postUnit(){
 
    this.state.dialogUnit.course = this.props.match.params.course;
    await api.postUnit(this.state.dialogUnit).then((res) => {

      //Busca todas as units
      this.getUnitCourse(true);

      let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">A unit foi cadastrada.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
      this.refs.notificationAlert.notificationAlert(options);

      this.setState({openDialogUnit:false});
      console.log(res.data);
      this.addUnitPane(res.data.unit);
      return true;

    }).catch(error => {
          console.log(error);
          console.log(error.response);
          if(error.response.status === 400){
  
              this.setState({erroUnit:''});
              setTimeout(function(){ this.setState({erroUnit:error.response.data.msg}); }.bind(this), 1);

          }
          return false;
    });

  }

  async putUnit(){

    await api.putUnit(this.state.editUnit,this.state.dialogUnit).then((res) => {

      //Busca todas as units
      this.getUnitCourse(true);

      let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
      this.refs.notificationAlert.notificationAlert(options);
      this.setState({openDialogUnit:false});

      this.addUnitPane(res.data.unit,res.data.unit.id);
      return true;

    }).catch(error => {
          console.log(error);
          console.log(error.response);
          if(error.response.status === 400){
  
              this.setState({erroUnit:''});
              setTimeout(function(){ this.setState({erroUnit:error.response.data.msg}); }.bind(this), 1);

          }
          return false;
    });

  }

  //Ao clicar no botao do dialog da unit
  saveUnit(event){

    event.preventDefault();
    if(this.state.editUnit === ""){ this.postUnit(); }else{ this.putUnit(); }

  }

  //para abrir o dialog remover a unit
  removeUnit(id){ this.setState({openDialogRemoveUnit:true,removeUnit:id}); }

  //para remover a unit no banco de dados
  async removeUnitApi(){

      this.setState({openDialogRemoveUnit:false});

      await api.deleteUnit(this.state.removeUnit).then((res) => {
        
       //remove o pane
        this.removeUnitPane(""+this.state.removeUnit);

        this.getUnitCourse(true);

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">A unit foi removida.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);
 
      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }

  //Para atualizar a ordem das units
  updateOrder(order){

    console.log(order);
    this.setState({orderUnit:order});

    order.forEach(function(item,index,array){

        api.changeOrderUnit(item,index).then((res) => {

          console.log(res.data);

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
            return false;

        });

    });

  }

  //para pegar os vídeos cadastrados nesse curso
  async getVideos(){

    await api.getVideoCourse(this.props.match.params.course).then((res) => {

      this.setState({videos:res.data,loadVideos:true});
      console.log(res.data);

    }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);
      //if(error.response.status === 404){ this.props.history.push('/'); }

    });

  }

  //para pegar os quizzis cadastrados nesse curso
  async getCourseQuiz(){

    await api.getQuizCourse(this.props.match.params.course).then((res) => {

      this.setState({quizzes:res.data,loadQuizzes:true});
      console.log(res.data);

    }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);

    });

  }


  //Para pegar todos as licenças que usam esse curso
  async getLicensesCourse(){

    await api.getListslicenseCourse(this.props.match.params.course).then((res) => {

      this.setState({courseLicenses:res.data});

    }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);

    });

  }

 




  addUnitPane(value,update){

      if(value === undefined){ value = ""; }

        window.$('.__resizable_base__').remove();
        const orderUnit = this.state.orderUnit;
        var iconUnit;
        if(value.iconData !== undefined){ iconUnit = value.iconData.s3url; }else{ iconUnit = ''; }

        var pane = <Pane resizable={{x:false,y:false,xy:false}} className="paneUnitMove" key={value.id} defaultSize={{width:'100%',height:87}}  >
          <ListGroupItem className="px-0">
            <Row idc={value.id} className="align-items-center"><i style={{marginLeft:'20px',color:'#3a3a63'}} className="fas fa-arrows-alt movedor"></i>
              <Col className="col-auto">{this.state.a}
                <a href="#pablo" className="avatar avatarIconCourse" style={{pointerEvents:'none'}} onClick={e => e.preventDefault()} >
                  <img className="hvr-grow" alt="..." src={iconUnit} />
                </a>
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0"><font onClick={e => e.preventDefault()}>{value.name} <font style={{fontSize:"10px"}}></font> </font></h4>
                {

                  (parseInt(value.status) === 1  && false) &&
                  <React.Fragment>
                    <span className="text-success">●</span>
                    <small>Disponível</small>
                  </React.Fragment>

                }
                {

                  (parseInt(value.status) !== 1 && false) &&
                  <React.Fragment>
                    <span className="text-success">●</span>
                    <small>Não disponível</small>
                  </React.Fragment>

                }
                <small>{value.description}</small>
              </div>
              <Col className="col-auto">
                <Button onClick={ () => this.props.history.push('/course/'+this.props.match.params.course+'/unit/'+value.id+'/mountlesson') } color="secondary" size="sm" type="button"><i className="fas fa-pencil-alt"></i> Montar aulas</Button>
                <Button color="secondary" size="sm" type="button" onClick={ () => this.editUnit(value.id) }><i className="fas fa-pencil-alt"></i> Editar unit</Button>
                <Button color="danger" size="sm" type="button" onClick={ () => this.removeUnit(value.id) }><i className="far fa-trash-alt"></i></Button>
              </Col>
            </Row>
          </ListGroupItem>
        </Pane>;

        if(update !== undefined){

          //Encontro o painel
          var debug = 0;
          while(true){

            if(this.state.listUnit[debug] !== undefined){ 
              
              if(parseInt(update) === parseInt(this.state.listUnit[debug].key)){ 

                var listUnit = this.state.listUnit;
                listUnit[debug] = pane;
                this.setState({listUnit});
                console.log('achou'); 
                break; 

              }

            }

            console.log(debug);
            debug++; if(this.state.listUnit.length < debug){ break;}
          }

          return true;

        }

        this.state.listUnit.push(pane);

        orderUnit.push(""+value.id);
        this.setState({listAnswer:this.state.listAnswer, orderUnit:orderUnit });
        window.$('.__resizable_base__').remove();

    }

    removeUnitPane(id){

      window.$('.__resizable_base__').remove();
      const index = this.state.orderUnit.indexOf(id);
 
      const pane = this.state.listUnit.splice(index, 1);

      //const index2 = this.state.orderUnit.indexOf(id);
      const orderUnit = this.state.orderUnit.filter(o => o !== pane[0].key);

      console.log(orderUnit);

      this.setState({listUnit:this.state.listUnit,orderUnit});
      window.$('.__resizable_base__').remove();
 
 
    }




  render() {

    var calc = this.state.unit.length*87;
    if(calc === 0){ calc = 90; }
    var st = {height:calc+'px',padding:'0px'};

    if(this.state.loadCourse === true){
      
      if(this.state.course.iconData !== undefined){

        var icon = this.state.course.iconData.s3url;  
        
      }

      if(this.state.course.backgroundData !== undefined){
        
        var background = this.state.course.backgroundData.s3url;
        var styCapa = {background:'url('+background+')',backgroundRepeat:'no-repeat',backgroundSize:'cover'};
        
      }

    }

    const handleChange = name => event => {
        var dialogUnit = this.state.dialogUnit;
        dialogUnit[name] = event.target.value;
        this.setState({dialogUnit:dialogUnit});
    }

    return (
      <React.Fragment>
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <div className="header pb-5 d-flex align-items-center" style={{minHeight:"400px",backgroundImage:'url("' + background + '")',backgroundSize: "cover",backgroundPosition: "center top" }}>
          <span className="mask opacity-8" style={{backgroundColor:'#000000ab'}}/>
          <Container className="d-flex align-items-center" fluid>
            <Row style={{width:'inherit'}}>
              <Col lg="7" md="10">
              <Row>
                <Col lg="3"><img alt="..." style={{maxHeight:'180px'}} src={icon} /></Col>
                <Col lg="9">
                  <h1 className="display-2 text-white">{this.state.course.name}</h1>
                  <p className="text-white mt-0 mb-3">{this.state.course.description}</p>
                  <Link to={'/course/edit/'+this.state.course.id} className="btn btn-secondary" onClick={ () => this.props.history.push('/course/edit/'+this.state.course.id) }><i class="fas fa-pencil-alt"></i> Editar curso</Link>
                  <Button color="danger" type="button"><i class="far fa-trash-alt"></i></Button>
                </Col>
              </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-xl-2" xl="4">
              <Card className="card-profile" style={styCapa} hidden>
                <CardImg style={{opacity:'0',height:'167px'}} alt="..." src={''} top />
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image"><img alt="..." style={{border:'3px solid #fff0'}} src={icon} /></div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <Button className="mr-4" color="info" onClick={ () => this.props.history.push('/course/edit/'+this.props.match.params.course) } size="sm" ><i className="fas fa-pencil-alt"></i> Editar curso</Button>
                    <Button className="float-right" color="danger" onClick={e => e.preventDefault()} size="sm"><i className="far fa-trash-alt"></i> Remover curso</Button>
                  </div>
                </CardHeader>
                <CardBody className="pt-0" style={{backgroundColor:"#fff"}}>
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">22</span>
                          <span className="description">Textos</span>
                        </div>
                        <div>
                          <span className="heading">22</span>
                          <span className="description">Atividades</span>
                        </div>
                        <div>
                          <span className="heading">22</span>
                          <span className="description">Quizes</span>
                        </div>
                        <div>
                          <span className="heading">10</span>
                          <span className="description">Vídeos</span>
                        </div>
                        <div>
                          <span className="heading">89</span>
                          <span className="description">Perguntas</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center" hidden>
                    <h5 className="h3">
                      Jessica Jones
                      <span className="font-weight-light">, 27</span>
                    </h5>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Bucharest, Romania
                    </div>
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Solution Manager - Creative Tim Officer
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card hidden>
                <CardHeader>
                  <h5 className="h3 mb-0">Team members</h5>
                </CardHeader>
                <CardBody style={{padding:'0px',marginTop:"15px"}}>
                  <div id="chart"><ApexChart/></div> 
                </CardBody>
              </Card>
          
            </Col>
            <Col className="order-xl-1" xl="8">
              <Row>
                <Col lg="6" >
                  <Card className="border-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle className="text-uppercase text-muted mb-0" tag="h5" >Total de alunos no curso</CardTitle>
                          <span className="h2 font-weight-bold mb-0">350,897</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-white text-dark rounded-circle shadow bg-gradient-green text-white"><i class="fas fa-child"></i></div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className="border-0" tag="h5">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle className="text-uppercase text-muted mb-0">Instituções</CardTitle>
                          <span className="h2 font-weight-bold mb-0">

                          { 
                              
                              (this.state.courseLicenses === undefined) &&
                              <React.Fragment></React.Fragment>
                              
                            }

                            { 
                              
                              (this.state.courseLicenses !== undefined) &&
                              <React.Fragment>{this.state.courseLicenses.length}</React.Fragment>
                              
                            }

                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-white text-dark rounded-circle shadow text-white bg-gradient-green"><i class="fas fa-school"></i></div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal className="modal-dialog-centered" isOpen={this.state.openDialogUnit} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.state.titleDialogUnit}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openDialogUnit:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.saveUnit(event) }>
                  {
                    (this.state.erroUnit) &&
                    <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.state.erroUnit}</Alert>
                  }
                  <Row>
                      <Col md={12}>
                        <h5>Nome da unit:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialogUnit.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <h5>Descrição:</h5>
                        <Input className="form-control-alternative" onChange={handleChange('description')} value={ this.state.dialogUnit.description } placeholder="" rows="3" type="textarea" />
                      </Col>
                      <Col md={12}>
                        <h5 style={{marginTop:"10px"}}>Status:</h5>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input value="1" onChange={handleChange('status')} className="custom-control-input" id="statusUnitDialog1" name="customRadioInline1" type="radio"/>
                          <label className="custom-control-label" htmlFor="statusUnitDialog1">Disponível</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input value="0" onChange={handleChange('status')} className="custom-control-input" id="statusUnitDialog2" name="customRadioInline1" type="radio"/>
                          <label className="custom-control-label" htmlFor="statusUnitDialog2">Não disponível</label>
                        </div>
                      </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                    <h5 style={{marginTop:'10px'}}>Ícone:</h5>
                    </Col>
                    <Col md={12}>
                    <center><img src="" alt="..." className="iconUnitDialog" /></center>
                    </Col>
                    <Col md={12} className="section_file" name="capa" n="0" style={{marginTop:'10px'}}>
                      <div style={{display:"flex"}}>
                      <div className="custom-file form-control-sm">
                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_1'); this.uploadImg('capa'); } } className="custom-file-input" id="custom_file_2" type="file" />
                        <label className="custom-file-label " htmlFor="custom_file_1">Selecionar arquivo</label>
                      </div>
                      <Button onClick={ () => this.uploadImg('capa') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> Enviar</Button>
                      </div>
                      {
                        (this.state.fileInput[0].erro !== "") &&
                        <h6 className="erroInputBase animated bounceIn">{this.state.fileInput[0].erro}</h6>
                      }
                      {
                        (this.state.fileInput[0].progress !== "") &&
                        <div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
                          <div className="progress-info">
                            <div className="progress-label"><span>Enviando arquivo</span></div>
                            <div className="progress-percentage"><span>{this.state.fileInput[0].progress}%</span></div>
                          </div>
                          <Progress max="100" value={this.state.fileInput[0].progress} color="default" />
                        </div>
                      }
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openDialogUnit:false}) } >Cancelar</Button>
                    <Button className="my-4" color="primary" type="submit">{this.state.buttonDialogUnit}</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveUnit} >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
              <p>Se você prosseguir, todos os dados da unit serão removidos, inclusive as lessons, essa ação é irreversível.</p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeUnitApi() }>Ok, Remova</Button>
            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveUnit:false}) }>
              Cancelar e fechar
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Course;
