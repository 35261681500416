import React,{useState,useEffect} from 'react';
import io from 'socket.io-client';

import Crm from "./plugins/Crm.jsx";
var ok = false;

window.iooo = io;

function Socketio(props){
 
    const [ok,setOk] = useState(false);
    if(ok === false){

            if(window.location.origin === "http://localhost:3026"){

                window.socket = io.connect(sessionStorage.getItem('backend'),{query:'token='+sessionStorage.getItem('session')});
                window.socket.on('teste',((data) => console.log(data)));
                //window.socket = io(sessionStorage.getItem('backend'));

            }else{
                
                //console.log('Socket pego:'+sessionStorage.getItem('session'));
                window.socket = io('/api/socket.io',{query:'token='+sessionStorage.getItem('session')});
                window.socket.on('teste',((data) => console.log(data)));
             
            }
            
            window.socket.on('disconnect',function(){ console.log('socket deu dc'); });
            

            setOk(true);  

    }
    
    useEffect(() => {

        return(() => { console.log('force dc'); window.socket.disconnect();/* */ });

    },[]);

    

    return(<React.Fragment>

        {
            
            (ok === true) &&
            <React.Fragment><Crm /></React.Fragment>

        }
        

    </React.Fragment>);

}

export default Socketio;