import React from 'react';
import {InputG,Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,Col,Row} from "reactstrap";
import classnames from 'classnames';
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import CurrencyInput from 'react-currency-input';
import { object } from 'prop-types';

const Swal = require('sweetalert2');

class PriceRange extends React.Component {

  	constructor(props){

    	super(props);
        this.state = {pricerange:[],products:[],seguraProducts:[]};
        this.state.dialog = {name:""};

        this.getProducts = this.getProducts.bind(this);
        this.getPriceRange = this.getPriceRange.bind(this);
        this.newFaixa = this.newFaixa.bind(this);

        this.postFaixa = this.postFaixa.bind(this);
        this.putFaixa = this.putFaixa.bind(this);
        this.saveFaixa = this.saveFaixa.bind(this);
        this.editPrice = this.editPrice.bind(this);
        this.savePrices = this.savePrices.bind(this);
        this.saveValues = this.saveValues.bind(this);
        
		window.pricerange = this;

      }
    
    saveValues(){

        var obj = [];
        //console.log(this.state.products);
        for(let index = 0; index < this.state.products.length; index++){
            
            var pp = this.state.products[index];
            obj.push({product:pp.product,value:pp.value,comission:pp.comission});
            
        }
        
        console.log(this.state.products);
        console.log(obj);

        api.savePrice(this.state.editFaixa,obj).then(async(res) => {

            console.log(res.data);

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A faixa de preço foi removida.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.setState({openModalProducs:false});

            this.getProducts();
            this.getPriceRange();

        }).catch((error) => {});

    }
 
    editPrice(id){

        this.setState({openModalProducs:true,editFaixa:id,erroPrice:""});
        var state = this.state;

        //state.products = JSON.parse(JSON.stringify(this.state.seguraProducts));

        //var priceIndex = state.pricerange.map(function(x) {return x.id; }).indexOf(id);
       // var price = this.state.pricerange[priceIndex];

        var indexFaixa = this.state.pricerange.map(function(x){return x.id;}).indexOf(id);
        var faixa = this.state.pricerange[indexFaixa];

        var obj = [];
        for(let index = 0; index < this.state.seguraProducts.length; index++){
            
            var pp = this.state.seguraProducts[index];
            var value = pp.unitprice;
            var comission = 0;
            value = parseFloat(value);

            var indexPrice = faixa.prices.map(function(x){return x.product;}).indexOf(pp.id);
            if(indexPrice !== -1){

                value = faixa.prices[indexPrice].value;
                comission = faixa.prices[indexPrice].comission;

                value = parseFloat(value);
                comission = parseFloat(comission);

            }

            obj.push({name:pp.name,product:pp.id,value:value,comission:comission});
            
        }

        console.log(obj);

        this.setState({products:obj});

    }

    savePrices(event){

        event.preventDefault();
        api.getPriceRange().then(async(res) => { 

            this.setState({pricerange:res.data});

        }).catch((error) => {});

    }

    getProducts(){
 
        api.getProducts().then(async(res) => { 

            this.setState({seguraProducts:res.data});

        }).catch((error)=>{});

    }

    getPriceRange(){
        
        api.getPriceRange().then(async(res) => { 

            this.setState({pricerange:res.data});

        }).catch((error) => {});

    }
 
    newFaixa(){

        this.setState({openModalNewFaixa:true,dialog:{name:""},erroFaixa:'',editFaixa:undefined});

    }

    postFaixa(){

        api.postPriceRange(this.state.dialog).then((res) => {
            
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">A faixa de preço foi cadastrada.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.setState({erroFaixa:'',openModalNewFaixa:false});
            this.getPriceRange();

        }).catch(error => {

            if(error.response.status === 400){

                this.setState({erroFaixa:error.response.data.msg});

            }
        
        });

    }

    editFaixa(value){

        this.setState({dialog:JSON.parse(JSON.stringify(value)),editFaixa:value.id,openModalNewFaixa:true,erroFaixa:""});

    }

    async removeFaixa(id){

        Swal.fire({
            title: 'Tem certeza?',
            text: "Os dados não podem ser recuperados.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
          }).then((result) => {
      
            if(result.value){
                
                api.deletePriceRange(id).then(async (res) => {
      
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A faixa de preço foi removida.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);
					
					this.getPriceRange();

                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);

                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);
        
                });
                 
      
            }
      
        });

    }

    putFaixa(){

        api.putPriceRange(this.state.editFaixa,this.state.dialog).then((res) => {
            
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">A faixa de preço foi cadastrada.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.setState({erroFaixa:'',openModalNewFaixa:false});
            this.getPriceRange();

        }).catch(error => {

            if(error.response.status === 400){

                this.setState({erroFaixa:error.response.data.msg});

            }
        
        });

    }

    saveFaixa(event){
 
        event.preventDefault();
        if(this.state.editFaixa === undefined){ this.postFaixa(); }else{ this.putFaixa(); }

    }

    componentDidMount(){

        this.getProducts();
        this.getPriceRange();

    }
    
	render(){
        
        const handleChange = name => event => {
	        var dialog = this.state.dialog;
	        dialog[name] = event.target.value;
	        this.setState({dialog:dialog});
        }
        
  		return (<React.Fragment>
              
            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
            <SimpleHeader name="Faixas de preço" way={[{name:"Faixas de preço",url:"/product"}]} buttons={<React.Fragment />} />

            <div className="mt--6 container-fluid">
                <div className="card">
                    <div className="border-0 card-header" style={{boxShadow:'0px 2px 4px -5px #000'}}>
                        <div className="row">
                            <div className="col-6">
                                <h3 className="mb-0">Faixas de preço</h3>
                            </div>
                            <div className="text-right col-6">
                                <button onClick={ () => this.newFaixa() } className="btn-round btn-icon btn btn-secondary btn-sm">
                                    <span className="btn-inner--icon mr-1"><i className="fas fa-plus"></i> Criar faixa</span> 
                                </button>
                            </div>
                        </div>
                    </div>

                    <table className="align-items-center table-flush table">
                        <thead className="thead-light">
                            <tr>
                                <th>Nome</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                            <tbody>
                                {

                                    this.state.pricerange.map((value,index) => {
                                    
                                        return( <tr key={'field_'+value.id} idc={value.id} className="fieldcamp tableList" >
                                                    <td>{value.name}</td>
                                                    <td>

                                                        <font onClick={() => this.editFaixa(value) } className="table-actions fontButton"><i className="far fa-edit"></i></font>
                                                        <font onClick={() => this.editPrice(value.id) } className="table-actions fontButton"><i className="far fa-money-bill-alt"></i></font>
                                                        <font onClick={() => this.removeFaixa(value.id) } className="table-actions fontButton"><i className="far fa-trash-alt"></i></font>

                                                    </td>
                                                </tr>)

                                    })

                                }
                            </tbody>
                    </table>

                    {

                        (this.state.pricerange.length === 0) &&
                        <div style={{textAlign:'center',marginTop: '25px',marginBottom: '20px'}}>Nenhuma faixa de preço foi encontrada.</div>

                    }
                </div>
            </div> 

        <Modal className="modal-dialog-centered" isOpen={this.state.openModalNewFaixa} >
            <div className="modal-header bg-secondary">
                <h5 className="modal-title" id="exampleModalLabel">{(this.state.editFaixa !== undefined ? "Editar faixa de preço":"Cadastrar faixa de preço")}</h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModalNewFaixa:false})} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body bg-secondary">
                <Row>
                    <Col md={12}>
                        {

                            (this.state.erroFaixa !== "") &&
                            <div className="alert alert-warning fade show" role="alert"><strong>Ops!</strong> {this.state.erroFaixa}</div>

                        }
                        <Form role="form" type="post" onSubmit={ (event) => this.saveFaixa(event) }>
                            <h5>Nome do plano:</h5>
                            <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                                <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                <Input type="text" onChange={handleChange('name')} value={this.state.dialog.name} onFocus={() => this.setState({focusedName:true}) } onBlur={() => this.setState({focusedName:false}) } />
                                </InputGroup>
                            </FormGroup>
                            <button style={{width:"100%"}} type="submit" className="btn-icon btn-3 btn btn-primary">
                                {

                                    (this.state.editFaixa !== undefined) &&
                                    <React.Fragment><i className="far fa-save"></i> Salvar alterações</React.Fragment>

                                }
                                {

                                    (this.state.editFaixa === undefined) &&
                                    <React.Fragment><i className="fas fa-plus"></i> Cadastrar</React.Fragment>

                                }
                            </button>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Modal>

        <Modal className="modal-dialog-centered" isOpen={this.state.openModalProducs} style={{maxWidth:'1000px'}}>
            <div className="modal-header bg-secondary">
                            <h5 className="modal-title" id="exampleModalLabel">Valores da faixa:{
 
                                (this.state.openModalProducs === true) &&
                                    <React.Fragment>{this.state.pricerange[this.state.pricerange.map(function(x){return x.id;}).indexOf(this.state.editFaixa)].name}</React.Fragment>

                            }</h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModalProducs:false})} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body bg-secondary">
                <Row>
                    <Col md={12}>
                        {

                            (this.state.erroPrice !== "") &&
                            <div className="alert alert-warning fade show" role="alert"><strong>Ops!</strong> {this.state.erroFaixa}</div>

                        }
                        <Form role="form" type="post" onSubmit={ (event) => this.savePrices(event) }>
                            
                            <table class="align-items-center table-flush table">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Nome</th>
                                        <th>Valor</th>
                                        <th>Repasse % (bruto)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        this.state.products.map((value,index) =>{ 

                                            return(
                                            <tr key={'dd_'+index}>
                                                <td>{value.name}</td>
                                                <td class="td_custon_prod">
                                                    <CurrencyInput style={{height:'27px',width:'90px'}} decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={value.value.toFixed(2)} onChangeEvent={ (e) => { value.value = parseFloat(e.target.value); } } />
                                                </td>
                                                <td class="td_custon_prod">
                                                    <CurrencyInput style={{height:'27px',width:'90px'}} decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={value.comission.toFixed(2)} onChangeEvent={ (e) => { value.comission = parseFloat(e.target.value); } } />
                                                </td>
                                            </tr>)

                                        })

                                    }
                                    
                                </tbody>
                            </table>
                            <button onClick={() => this.saveValues() } style={{width:"100%"}} type="submit" className="btn-icon btn-3 btn btn-primary"><i className="far fa-save"></i> Salvar alterações</button>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Modal>
         

        </React.Fragment>);

	}

}

export default PriceRange;