import React from "react";

// react library for routing
import { Route, Switch } from "react-router-dom";

// core components
import AdminNavbar from "./../components/Navbars/AdminNavbar.jsx";
//import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "./../components/Sidebar/Sidebar.jsx";

import routes from "./../routes.js";
import NewCourse from "./../views/pages/NewCourse.jsx";
import Users from "./../views/pages/Users.jsx";
import Course from "./../views/pages/Course.jsx";
import ListCourse from "./../views/pages/ListCourse.jsx";
import NewLesson from "./../views/pages/NewLesson.jsx";
import ListLesson from "./../views/pages/ListLesson.jsx";
import License from "./../views/pages/License.jsx";
import Videos from "./../views/pages/course/Videos.js"; 
import routesNew from "./../routesNew.js";
//import MountQuiz from "./../views/pages/course/MountQuiz.jsx"; 
import CourseQuiz from "./../views/pages/course/CourseQuiz.jsx";
import Permission from "./../views/pages/Permission.jsx"; 
import ClientFields from "./../views/pages/ClientFields.jsx";
import ClientFields2 from "./../views/pages/ClientFields.jsx";
import NatureOperation from "./../views/pages/NatureOperation.jsx";
import NewNatureOperation from "./../views/pages/NewNatureOperation.jsx";
import Comission from "./../views/pages/Comission.jsx";

import Contract from "./../views/pages/Contract.jsx";
import NewContract from "./../views/pages/NewContract.jsx";

import Product from "./../views/pages/Product.jsx";
import Accountplans from "./../views/pages/Accountplans.jsx";
import Configuration from "./../views/pages/Configuration.jsx";
import Plans from "./../views/pages/Plans.jsx";
import EditPlan from "./../views/pages/EditPlan.jsx";
import PriceRange from "./../views/pages/PriceRange.jsx";
import CRMreport from "./../views/pages/CRMreport.jsx";
import Apilms from "./../views/pages/Apilms.jsx";
import Registration_cancel from "./../views/pages/Registration_cancel.jsx";

 
import Socket from "./../socket";
import GlobalIframe from "./../views/pages/components/GlobalIframe.jsx";
import ReactNotification from 'react-notifications-component';
import { store as store2 } from 'react-notifications-component'; 
import Dashboard from "./../views/pages/dashboards/Dashboard.jsx";
import Invoice from "./../views/pages/Invoice.jsx";
 

import Contract_Lincese from "./../views/pages/Contract_Lincese.jsx";
import NewContract_Lincese from "./../views/pages/NewContract_Lincese.jsx";


import NewNotice from "./../views/pages/NewNotice.jsx";
import Notice from "./../views/pages/Notice.jsx";


import 'react-notifications-component/dist/theme.css';



 

class Admin extends React.Component {

  state = {sidenavOpen: true};

  constructor(props) {
    
    super(props);
    
    window.toast3 = store2; //https://github.com/teodosii/react-notifications-component 

     
    
    window.Admin = this;

  }

   //Para mostrar as rotas do menu
   getRoutes(){
 
    var routes = routesNew(this.props.permission,this.props.translate);
    return routes;

  }

  componentDidUpdate(e){}

  

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({ sidenavOpen: !this.state.sidenavOpen });
  };

  getNavbarTheme = () => { return this.props.location.pathname.indexOf("admin/alternative-dashboard") === -1? "dark": "light";};

  render() {
    return (
      <React.Fragment>
        
        <Socket/>
        <GlobalIframe/>
        <ReactNotification />
        
        <Sidebar {...this.props} routes={this.getRoutes()} toggleSidenav={this.toggleSidenav} sidenavOpen={this.state.sidenavOpen} logo={{ innerLink: "/", imgSrc: 'ss', imgAlt: "..." }} />
        <div className="main-content" ref="mainContent" onClick={this.closeSidenav} >

          <AdminNavbar {...this.props} theme={this.getNavbarTheme()} toggleSidenav={this.toggleSidenav} sidenavOpen={this.state.sidenavOpen} brandText={this.getBrandText(this.props.location.pathname)} />
        
            <Switch>
            {

              /*
              <Route exact={true} path='/course/:course/quizzes/:quiz' component={MountQuiz}   />

              <Route exact={true} path='/course/:course/quizzes/:quiz' component={MountQuiz}   />
*/
            }
              <Route exact={true} path='/course/:course/quizzes' component={CourseQuiz} />
              <Route exact={true} path='/course/newcourse' component={NewCourse} />
              <Route exact={true} path='/course/edit/:id' component={NewCourse} />
              <Route exact={true} path="/course/:course/videos" component={Videos} />
              <Route exact={true} path="/course/:course/unit/:unit/lesson/:lesson" component={NewLesson} />

             
              <Route exact={true} path="/course/:course" component={Course} />
              <Route exact={true} path="/course" component={ListCourse} />
              <Route exact={true} path="/users" component={Users} />
              <Route exact={true} path="/license" component={License} />
              <Route exact={true} path="/permission" component={Permission} />

              <Route exact={true} path="/contract_registration" component={Contract} />
              <Route exact={true} path="/contract_registration/:id" component={NewContract} /> 

              <Route exact={true} path="/contract_license" component={Contract_Lincese} />
              <Route exact={true} path="/contract_license/:id" component={NewContract_Lincese} /> 
              
              <Route exact={true} path="/notice" component={Notice} />
              <Route exact={true} path="/notice/:id" component={NewNotice} />

              <Route exact={true} path="/plans" component={Plans} />
              <Route exact={true} path="/plans/:id" component={EditPlan} />


              <Route exact={true} path="/product" component={Product} />
              <Route exact={true} path="/nature_operation" component={NatureOperation} />
              <Route exact={true} path="/nature_operation/:id" component={NewNatureOperation} /> 

              <Route exact={true} path="/registration_cancel" component={Registration_cancel} /> 
               

              <Route exact={true} path="/accountplans" component={Accountplans} />
              <Route exact={true} path="/configuration" component={Configuration} />
              <Route exact={true} path="/pricerange" component={PriceRange} />

              <Route exact={true} path="/report/crm" component={CRMreport} />
              <Route exact={true} path="/report/comission" component={Comission} />

              <Route exact={true} path="/invoice" component={Invoice} />
              <Route exact={true} path="/api_lms" component={Apilms} />
 
              <Route exact={true} path="/client_fields" render={ (props) => <ClientFields {...props} name1="Campos de cliente" name2="Campos extras do cliente" groupID="0" /> } />
              <Route exact={true} path="/student_fields" render={ (props) => <ClientFields {...props} name1="Campos de estudante" name2="Campos extras do estudante" groupID="1" /> } />
              
              <Route exact={true} path="/" component={Dashboard} />
               
            </Switch>

        </div>

        {this.state.sidenavOpen ? ( <div className="backdrop d-xl-none" onClick={this.toggleSidenav} /> ) : null}

      </React.Fragment>
    );
  }
}

export default Admin;