import api from './../../Api.js';

function Preference_helper(props){
		
	this.props = props;

}

//Para pegar os cursos disponíveis em uma licença
Preference_helper.prototype.getCourseLicense = async function(id){
 
	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.getCourseLicense(id).then(async (res) => {
    	
    	return {status:200,data:res.data};
      
    }).catch(error => {
 
        if(error.response.status === 400){

            return {status:400,data:error.response};

        }

        return false;

    });

	return req;

}

//Para salvas os cursos disponíveis em uma licença
Preference_helper.prototype.putCourseLicense = async function(idLicense,courses){
 
	//for (var i = 0; i < this.props.units.length; i++) {//}
	var req = await api.putCourseLicense(idLicense,courses).then(async (res) => {
    	
    	return {status:200,data:res.data};
      
    }).catch(error => {
 
        if(error.response.status === 400){

            return {status:400,data:error.response};

        }

        return false;

    });

	return req;

}

 



export default Preference_helper;