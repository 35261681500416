
import React,{useState,useEffect} from "react";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx"; 
import ListLicenseSelect from "../../components/ListLicenseSelect.jsx";
import ComissionCard from "./components/report/Comission.jsx";
import ReactDatetime from "react-datetime";
import moment from "moment";
import api from "./../../Api.js";
import XLSX from 'xlsx';
import $ from "jquery";

export default function Comission(props){

    const [licenses,setLicenses] = useState([]);

    const [startDate,setStartDate] = useState(moment( moment().format('YYYY-MM')+'-01' ,'YYYY-MM-DD').format('YYYY-MM-DD'));
    var end = moment().format('YYYY')+'-'+moment().format('MM')+'-'+moment( ).endOf('month').format('DD');
 
    const [endDate,setEndDate] = useState(end);
    const [update,setUpdate] = useState('0');

    //const [data,setData] = useState({});
    if(window.dataComission === undefined){
        
        window.dataComission = {};

    }
    
    useEffect(() => { init(); },[licenses]);

    const init = ((all) => {

        if(all === true){

            console.log('all');
            console.log(all);

            window.dataComission = {};
//            setData({});

        }

        var warningTransfer = 0;
        
        if(document.getElementById("warningTransfer").checked === true){

            warningTransfer = 1;

        }

        var status = document.getElementById("statusComission").value;
        if(status !== ''){

            status = '&status='+status;

        }

        console.log('licenses.length: '+licenses.length);
        for(let index = 0; index < licenses.length; index++){
            
            if(window.dataComission[licenses[index]] === undefined){
                console.log('Buscando: '+licenses[index]);
                
                window.dataComission[licenses[index]] = {loading:true};
                setUpdate(moment().unix());

                api.getComissionsReport('?license='+licenses[index]+'&startDate='+startDate+'&endDate='+endDate+'&warningTransfer='+warningTransfer+status).then((response) => {

                    window.dataComission[licenses[index]] = {data:response.data};
                    setUpdate(moment().unix()+' teste '+licenses[index]);

                }).catch((error) => {

                    window.dataComission[licenses[index]] = {error:error.response.data.msg};
                    setUpdate(moment().unix()+' teste '+licenses[index]);

                }); 

            }
            
        }

    });

    return (
    <React.Fragment>
 
        <SimpleHeader name={'Relatório'} way={[{name:"Repasse",url:"/report/comission"}]} buttons={<React.Fragment></React.Fragment>} />
         
        <style>{' .inputmin{ padding: 5px; height: 25px; margin-bottom:5px;}'}</style>
        <style>{'.cbranco{ color: #fff; }'}</style>
        <div update={update} className="mt--6 container-fluid"> 
            <div className="row">
                <div className="card-wrapper col-md-4">
                    <div className="card bg-secondary card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <div>Data inicial:</div>
                                    <ReactDatetime inputProps={{"className":"inputmin form-control"}} dateFormat={'DD/MM/YYYY'} value={(moment(startDate,'YYYY-MM-DD',true).isValid() ? moment(startDate,'YYYY-MM-DD'):startDate)} onChange={(e) => { console.log(e.format('YYYY-MM-DD')); setStartDate(e.format('YYYY-MM-DD')); }} timeFormat={false} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <div>Data final:</div>
                                    <ReactDatetime inputProps={{"className":"inputmin form-control"}} dateFormat={'DD/MM/YYYY'} value={(moment(endDate,'YYYY-MM-DD',true).isValid() ? moment(endDate,'YYYY-MM-DD'):endDate)} onChange={(e) => { setEndDate(e.format('YYYY-MM-DD')); }} timeFormat={false} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <div>Login:</div>
                                    <input id="email_master" className="inputmin form-control"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <div>Senha:</div>
                                    <input id="senha_master" className="inputmin form-control" type="password"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div style={{marginBottom:'10px'}}>
                                    <div>Status do repasse</div>
                                    <select className="form-control inputmin " id={'statusComission'} style={{padding:'2px'}}>
                                        <option value=""></option>
                                        <option value="paid">Pago</option>
                                        <option value="pending">Pendente</option>
                                    </select>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="custom-control custom-checkbox" style={{marginBottom:'10px'}}>
                                    <input className="custom-control-input inputPermission" id={'warningTransfer'} value={"warningTransfer"} type="checkbox" />
                                    <label className="custom-control-label" htmlFor={'warningTransfer'}>Somente com warning transfer</label>
						        </div>
                            </div>
                        </div>
                        <div className="">
                            <button style={{width:"100%"}} className="btn btn-primary btn-sm" onClick={() => { init(true); }}>Pesquisar</button>
                        </div>
                    </div>
 
                     
                    <ListLicenseSelect licenses={JSON.parse(JSON.stringify(licenses))} onChange={(data) => { 
                        
                        console.log('Change data');
                        console.log(data);
                        setLicenses(JSON.parse(JSON.stringify(data))); 
                    
                    }} />
                </div>
                <div className="col-md-8">
                    {

                        licenses.map((value,index) => {

                            return <ComissionCard startDate={startDate} endDate={endDate} key={'license_'+value} data={window.dataComission} license={value} />;

                        })

                    }
                </div>    
            </div>
        </div>
    </React.Fragment>)

}