import React from "react";
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,CardHeader,Table,Container,Row,Col,UncontrolledTooltip} from "reactstrap";
import $ from "jquery";

class ModalSchoolDatas extends React.Component {

    constructor(props){

        super(props);

        this.setView = this.setView.bind(this);

    }

    setView(val){

        this.setState({view:val});
        $('.bt_change_view').removeClass('btn-secondary');
        $('.bt_change_view').removeClass('btn-primary');
        $('.bt_change_view').addClass('btn-secondary');
        $('#bt_'+val+'_s').removeClass('btn-secondary');
        $('#bt_'+val+'_s').addClass('btn-primary');
 
    }

    render(){

        return(<React.Fragment>

        <Modal className="modal-dialog-centered" isOpen={this.props.state.openDialogLicenseInfosModal} >
            <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default"> </h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.setState({openDialogLicenseInfosModal:false})}><span aria-hidden={true}>×</span> </button>
                </div>
                <CardBody className="px-lg-5">

                    <center style={{position:'absolute',left:'20px',top:'15px'}}>

                        <Button onClick={ () => this.setView('user') } className="bt_change_view" id="bt_user_s" color="primary" size="sm" type="button"><i class="fas fa-users"></i> Usuários</Button>
                        <Button onClick={ () => this.setView('clasroom') } className="bt_change_view" id="bt_classroom_s" color="secondary" size="sm" type="button"><i class="fas fa-graduation-cap"></i> Turmas</Button>
                        <Button onClick={ () => this.setView('company') } className="bt_change_view" id="bt_companys_s" color="secondary" size="sm" type="button"><i class="far fa-building"></i> Escolas</Button>

                    </center>

                </CardBody>
            </Card>
          </div>
        </Modal>

        </React.Fragment>);

    }

}

export default ModalSchoolDatas;