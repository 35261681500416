import React from "react";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal,FormGroup,InputGroup,InputGroupAddon,InputGroupText,Input} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import NotificationAlert from "react-notification-alert";

const Swal = require('sweetalert2');

class Registration_cancel extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {rcs:[],openmodal:false,id:undefined,dialog:{}};
		this.getRcs = this.getRcs.bind(this);
        this.deleteRc = this.deleteRc.bind(this);

        this.post = this.post.bind(this);
        this.put = this.put.bind(this);

		window.registration_cancel = this;

	}


    post(){

        api.postRc(this.state.dialog).then((res) => {
      
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
            
            this.getRcs();
            this.setState({openmodal:false});

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span>{error.response.data.msg}</div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

        });

    }

    put(){

        api.putRc(this.state.id,this.state.dialog).then((res) => {
      
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O motivo de cancelamento foi cadastrado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.getRcs();
            this.setState({openmodal:false});
        
        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span>{error.response.data.msg}</div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

        });

    }


    deleteRc(id){

        Swal.fire({
            title: 'Tem certeza?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
          }).then((result) => {
      
            if(result.value){
                
                api.deleteRc(id).then((res) => {
      
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O motivo de cancelamento foi removido.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);
                    
                    this.getRcs();

                    console.log('deletou');

                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });
                 
      
            }
      
        });

    }

  	async getRcs(){

		await api.getRcs().then((res) => {
            
        	this.setState({rcs:res.data}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);
 

  		});

	}

	async componentDidMount(){ this.getRcs(); }
 
    save(e){

        e.preventDefault();
        if(this.state.id === undefined){ this.post(); }else{ this.put(); }

    }

	render(){
 
    	return(
		<React.Fragment> 
            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
            <SimpleHeader name="Motivos de cancelamentos" way={[{name:"Motivos de cancelamentos",url:"/notice"}]} buttons={<React.Fragment />} />
            <div class="mt--6 container-fluid">
                <div class="card">
                <div class="border-0 card-header">
                    <div class="row">
                        <div class="col-6">
                            <h3 class="mb-0">Lista de motivos</h3>
                        </div>
                        <div class="text-right col-6">
                            <button onClick={ () => { this.setState({openmodal:true,id:undefined,dialog:{description:"",id:""}}) }} class="btn-round btn-icon btn btn-default btn-sm">
                                 <i class="fas fa-plus"></i> Criar motivo
                            </button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="align-items-center table-flush table">
                        <thead class="thead-light">
                            <tr>
                                <th>id</th>
                                <th>Descrição</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                this.state.rcs.map((value,index) => {
                                  
                                    return(<tr>
                                                <td>{value.id}</td>
                                                <td>{value.description}</td>
                                                <td>
                                                    <font onClick={ () => { this.setState({openmodal:true,id:value.id,dialog:JSON.parse(JSON.stringify(value))}); }} class="table-actions fontButton"><i class="far fa-edit"></i></font>
                                                    <font class="table-actions fontButton" onClick={() => this.deleteRc(value.id)}><i class="far fa-trash-alt"></i></font>
                                                </td>
                                            </tr>)

                                })

                            } 
                        </tbody>
                    </table>
                    {

                        (this.state.rcs.length === 0) &&
                        <React.Fragment>
                            <center><h4 style={{margin:"30px 0px"}}>Nenhum motivo de cancelamento foi cadastrado.</h4></center>
                        </React.Fragment>

                    }
                </div>
            </div>
        </div>

        <Modal className="modal-dialog-centered" isOpen={this.state.openmodal} >
            <div className="modal-header bg-secondary">
                <h5 className="modal-title" id="exampleModalLabel">{(this.state.id === undefined ? 'Cadastrar motivo':'Editar motivo')}</h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openmodal:false})} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body bg-secondary">
                <form onSubmit={(e) => { this.save(e) }}>
                    <h5>id interno:</h5> 
                    <FormGroup>
                        <InputGroup className="input-group-merge input-group-alternative">
                        <Input type="text" onChange={(e) => { 
                            
                            this.setState({a:''}); 
                            var v = e.target.value;
                            v = v.replaceAll(' ', '');
                            v = v.toLowerCase();
                            this.state.dialog.id = v;
                            
                        }} value={ this.state.dialog.id } />
                        </InputGroup>
                    </FormGroup>
                    <h5>Descrição:</h5> 
                    <FormGroup>
                        <InputGroup className="input-group-merge input-group-alternative">
                        <Input type="text" onChange={(e) => { this.setState({a:''}); this.state.dialog.description = e.target.value; }} value={ this.state.dialog.description } />
                        </InputGroup>
                    </FormGroup>
                    <button style={{width:"100%"}} type="submit" class="btn-icon btn-3 btn btn-primary">{(this.state.id === undefined ? 'Cadastrar':'Salvar alterações')}</button>
                </form> 
            </div>
        </Modal> 

		</React.Fragment>)
    }
}
export default Registration_cancel;