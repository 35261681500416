import React from "react";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import {BeatLoader} from "react-spinners";

class Apilms extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {products:[],availables:[],load:false,load2:false};
		this.getProducts = this.getProducts.bind(this);
        this.getProductsApiLms = this.getProductsApiLms.bind(this);
        this.putProductsApiLms = this.putProductsApiLms.bind(this);

		window.products = this;

	}

	//Para pegar as lessons
    async getProducts(){

		await api.getProducts().then((res) => {
            
        	this.setState({products:res.data,load:true}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

    async getProductsApiLms(){

		await api.getProductsApiLms().then((res) => {
            
        	this.setState({availables:res.data,load2:true}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

    async putProductsApiLms(){

		await api.putProductsApiLms(this.state.availables).then((res) => {
            
        	 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

     
 
	async componentDidMount(){
        
        this.getProducts();
        this.getProductsApiLms();
 
	}
 

	render(){
        

    	return(
		<React.Fragment> 

            <SimpleHeader name="Integração LMS" way={[{name:"Integração LMS",url:"/api_lms"}]} buttons={<React.Fragment />} />
            
            <Container className="mt--6 card-wrapper col-lg-8 container-fluid" fluid>
                <Card>
                    <CardHeader className="border-0">
                    <Row>
                        <Col xs="6"><h3 className="mb-0">Produtos disponíveis</h3></Col>
                        <Col xs="12"><div>Selecione os produtos que se estiverem pagos na fatura, irá liberar o acesso do estudante na turma.</div></Col>
                            
                        <React.Fragment>
                        <Col className="text-right" xs="6"><React.Fragment></React.Fragment></Col>
                        </React.Fragment>
                    </Row>
                    
                    <br/> 
                    {

                        (this.state.load === false || this.state.load2 === false) &&
                        <React.Fragment>
                            <div><center><BeatLoader color="#5e72e4" /></center></div>
                        </React.Fragment>

                    }
                    {

                            (this.state.load === true && this.state.load2 === true) &&
                            <div>
                            {

                                this.state.products.map((value,index) => {
 
                                     

                                    return(
                                    
                                    <div className="custom-control custom-checkbox">
                                        <input onChange={(e) => {

                                            var availables = this.state.availables;

                                            if(e.target.checked === true){

                                                if(availables.indexOf(value.id_name) === -1){

                                                    availables.push(value.id_name);

                                                }

                                            }else{

                                                if(availables.indexOf(value.id_name) !== -1){

                                                    availables.splice(availables.indexOf(value.id_name),1);

                                                }

                                            }

                                            this.setState({availables:availables});

                                        }} checked={(this.state.availables.indexOf(value.id_name) !== -1 ? true:false)} className="custom-control-input" id={'plan_cck_'+value.id} type="checkbox" />
                                        <label className="custom-control-label" htmlFor={'plan_cck_'+value.id}>{value.name}</label>
                                    </div>

                                    )

                                })

                            }

                             
                            <br/>
                        <button onClick={ () => this.putProductsApiLms() } className="btn btn-primary btn-lg btn-block"><i className="far fa-save"></i> Salvar alterações</button>
                    </div>
                    }
                    

                    </CardHeader>
                </Card>
 
            </Container>
		</React.Fragment>)
    }
}

export default Apilms;