import React from "react";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";

class ListLesson extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {contracts:[]};
		this.getContracts = this.getContracts.bind(this);

		window.contracts = this;

	}

	//Para pegar as lessons
  	async getContracts(){

		await api.getContracts().then((res) => {
            
        	this.setState({contracts:res.data}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

	async componentDidMount(){
        
        this.getContracts();

      
  
	}
 

	render(){
 
    	return(
		<React.Fragment> 

            <SimpleHeader name="Contratos" way={[{name:"Contratos",url:"/contract_registration"}]} buttons={<React.Fragment />} />
            <div class="mt--6 container-fluid">
                <div class="card">
                <div class="border-0 card-header">
                    <div class="row">
                        <div class="col-6">
                            <h3 class="mb-0">Lista de contratos</h3>
                        </div>
                        <div class="text-right col-6">
                            <button onClick={ () => this.props.history.push('/contract_registration/new') } class="btn-round btn-icon btn btn-default btn-sm">
                                <i class="fas fa-plus"></i> Criar contrato
                            </button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="align-items-center table-flush table">
                        <thead class="thead-light">
                            <tr>
                                <th>Nome</th>
                                <th>Descrição</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                this.state.contracts.map((value,index) => {
                                  
                                    return(<tr>
                                                <td style={{cursor:"pointer"}} onClick={ () => this.props.history.push('/contract_registration/'+value.id)} >{value.name}</td>
                                                <td>{value.description}</td>
                                                <td>

                                                    <font onClick={ () => this.props.history.push('/contract_registration/'+value.id)} class="table-actions fontButton"><i class="far fa-edit"></i></font>
                                                    <font class="table-actions fontButton"><i class="far fa-trash-alt"></i></font>

                                                </td>
                                            </tr>)

                                })

                            } 
                        </tbody>
                    </table>

                    {

                        (this.state.contracts.length === 0) &&
                        <React.Fragment>
                            <center><h4 style={{margin:"30px 0px"}}>Nenhum contrato foi encontrado.</h4></center>
                        </React.Fragment>

                    }


                </div>
            </div>
        </div>

		</React.Fragment>)
    }
}
export default ListLesson;