
import moment from "moment";
import React,{useState,useEffect} from "react"; 
import {Modal} from "reactstrap"; 
import {Button,Card,CardBody,Form} from "reactstrap";
import XLSX from 'xlsx';

export default function Comission(props){
 
    const [view,setView] = useState('');
    var urld = 'https://'+props.license+'.geekserp.com.br?tab=2&viewinvoice='+view.invoice+'&email='+document.getElementById('email_master').value+'&password='+document.getElementById('senha_master').value;

    const goUrl = 'https://'+props.license+'.geekserp.com.br?email='+document.getElementById('email_master').value+'&password='+document.getElementById('senha_master').value;


    const exportXlsx = (() => {

        var letras = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN'];
        
        var wb = JSON.parse('{"SheetNames":["Repasses"],"Sheets":{"Repasses":{} } }');
        
        var segura = 1;
        var segurasl = 0;
        var lines = window.$('.listItems_'+props.license).find('tr');
        var ultimoIndex = 0;

        for(let index = 0; index < lines.length; index++){
        
            var el = window.$(window.$('.listItems_'+props.license).find('tr')[index]);
            var sl = 0;
            segura++;
            var n = segura;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'#'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.comission_id').text()};
            sl++;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'Fatura'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.invoice_id').text()};
            sl++;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'Valor'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.value_comission').text()};
            sl++;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'Taxa de repasse'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.value_txrp').text()};
            sl++;

             
            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'Cm. Vencimento'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.comission_due_date').text()};
            sl++;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'Cm. Status'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.comission_status').text()};
            sl++;


            //invoice_status
            //invoice_paymentMethod
            //invoice_paymentMethod_install
            //invoice_paymentMethod_parcel_value
            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'Status Fatura'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.invoice_status').text()};
            sl++;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'Parcelamento'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.invoice_paymentMethod').text()};
            sl++;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'Total pago'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.invoice_paymentMethod_install').text()};
            sl++;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'Total de parcelas'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.invoice_paymentMethod_parcel_value').text()};
            sl++;


            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'check_v'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.check_v').text()};
            sl++;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'statusTransfer'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.statusTransfer').text()};
            sl++;

            wb.Sheets['Repasses'][''+letras[sl]+'1'] = {t:'s',v:'warningTransfer'};
            wb.Sheets['Repasses'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.warningTransfer').text()};
            sl++;


             
             
             

            if(sl > segurasl){ segurasl = sl; } 
            ultimoIndex = n;

        }

        wb.Sheets['Repasses']['!ref'] = 'A1:'+letras[segurasl]+''+(ultimoIndex+6);

        console.log(wb);
        var name = props.license+" - "+moment(props.startDate).format('DD-MM-YYY')+" - "+moment(props.endDate).format('DD-MM-YYYY');

        XLSX.writeFile(wb,name+'.xlsx');

    })

    var quantidade = 0;
    var totalPago = 0;
    var totalPendente = 0;
    var total = 0;

    return (
    <React.Fragment>

    <div className="card" style={{marginBottom:'0px'}}>
        <div class="border-0 card-header" style={{paddingTop:'5px',paddingBottom:'5px'}}>
            <div class="row">
                <div class="col-6">
                    <h4 class="mb-0">{props.license}</h4>
                </div>
                <div class="text-right col-6">
                    <a style={{marginRight:'10px'}} href={goUrl} target="_blank"><button type="button" class="btn btn-secondary btn-sm">Acessar licença</button></a>
                    <button type="button" class="btn btn-success btn-sm" onClick={() => { exportXlsx(); }}><i class="far fa-file-excel"></i> Exportar planilha</button>
                </div>
            </div>
        </div>
        <div className="table-responsive" style={{maxHeight:'350px'}}>
            <table className="align-items-center table-flush table">
                <thead class="thead-light">
                    <tr>
                        <th><center>id</center></th>
                        <th><center>fatura</center></th>
                        <th>valor</th>
                        <th>TXRP</th>
                        <th>Vencimento</th>
                        <th>Status</th>

                        <th>Status inv</th>
                        <th>Metodo inv</th>
                        <th>Tt.pg inv</th>
                        <th>V. parcel. sinv</th>

                        <th><center>check_v</center></th>
                        <th><center>statusTransfer</center></th>
                        <th><center>warningTransfer</center></th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody className={'listItems_'+props.license}>
                    {

                        (props.data[props.license] !== undefined) &&
                        <React.Fragment>
                            {

                                (props.data[props.license].data !== undefined) &&
                                <React.Fragment> 

                                {

                                    props.data[props.license].data.map((value,index) => {

                                        quantidade++;


                                        if(value.status === "paid"){ totalPago = totalPago + value.value; }
                                        if(value.status === "pending"){ totalPendente = totalPendente + value.value; }

                                        total = total + value.value;
                                        let txrp = '';

                                        if(value.invoiceItems !== undefined){

                                            //for (let index = 0; index < value.invoiceItems.length; index++) {
                                                
                                               //txrp = txrp + ''+value.invoiceItems[index].passThroughRate+'% ';
                                            
                                            //}

                                            if(value.invoiceItems[0] !== undefined){

                                                if(value.invoiceItems[0].passThroughRate !== null){

                                                    txrp = value.invoiceItems[0].passThroughRate;

                                                }else{

                                                    txrp = 0;

                                                }
                                                
                                            }

                                        }
                                        
                                        return (<tr>
                                        <td><center className="comission_id">{value.id}</center></td>
                                        <td> 
                                        
                                        {

                                            (value.invoice !== null && value.invoice !== "") &&
                                            <React.Fragment>
                                                <a href={goUrl+'&viewinvoice='+value.invoice} target="_blank">
                                                    <center className="invoice_id">{value.invoice}</center>
                                                </a>
                                            </React.Fragment>

                                        }
                                        
                                        
                                        
                                        </td>
                                        <td className="value_comission">R$ {window.convertMonyes(value.value,true)}</td>
                                        <td className="value_txrp" >{txrp}</td>
                                        <td className="comission_due_date">{moment(value.billingDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</td>
                                        <td className="comission_status">{value.status}</td>
                                        <td className="invoice_status">{value.invoice_status}</td>
                                        <td className="invoice_paymentMethod"> 
                                            { 

                                                (parseInt(value.invoice_paymentMethod) === 1) &&
                                                <React.Fragment>
                                                    <font><i className="fas fa-file-invoice"></i> <font className="a1">Boleto</font></font>
                                                </React.Fragment>

                                            }
                                            { 

                                                (parseInt(value.invoice_paymentMethod) === 2) &&
                                                <React.Fragment>
                                                    <font><i className="fas fa-credit-card"></i> <font className="a1">{value.invoice_installment}x no cartão</font> </font>
                                                </React.Fragment>

                                            }
                                            { 

                                                (parseInt(value.invoice_paymentMethod) === 3) &&
                                                <React.Fragment>
                                                    <font><i className="fas fa-qrcode"></i> <font className="a1">Pix</font> </font>
                                                </React.Fragment>

                                            }
                                            
                                            {

                                                (parseInt(value.invoice_paymentMethod) === 4) &&
                                                <React.Fragment>
                                                    <font><font className="a1">Não definido</font></font>
                                                </React.Fragment>

                                            }
                                        </td>
                                        <td className="invoice_paymentMethod_install">

                                        {

                                            (value.invoice_status === "paid") &&
                                            <React.Fragment> 
                                                R$ {window.convertMonyes(value.invoice_totalPaid,true)}
                                            </React.Fragment>

                                        }

                                        </td>
                                        <td className="invoice_paymentMethod_parcel_value">

                                        {

                                            (value.invoice_status === "paid") &&
                                            <React.Fragment>
                                                {

                                                    (parseInt(value.invoice_paymentMethod) === 2) &&
                                                    <React.Fragment>{value.invoice_installment}x de R$ {window.convertMonyes((value.invoice_totalPaid / value.invoice_installment),true)}  </React.Fragment>

                                                }
                                            </React.Fragment>

                                        }

                                        </td>

                                        <td><center className="check_v">{value.check_v}</center></td>
                                        <td><center className="statusTransfer">{value.statusTransfer}</center></td>
                                        <td><center className="warningTransfer">{value.warningTransfer}</center></td>
                                        <td>
                                            {

                                                (value.invoice !== null && value.invoice !== "") &&
                                                <React.Fragment>
                                                <a href={goUrl+'&viewinvoice='+value.invoice} target="_blank"><button style={{marginRight:'10px'}} className="btn btn-sm btn-primary">Ver externo</button></a>
                                                <button className="btn btn-sm btn-primary" onClick={() => { setView({invoice:value.invoice,comission:value.id}); }}>Ver interno</button>
                                                </React.Fragment>

                                            }
                                            
                                        </td>
                                        </tr>);

                                    })

                                }</React.Fragment>

                            }
                        </React.Fragment>
                        
                    }
                </tbody>
            </table>


            {

                (props.data[props.license] !== undefined) &&
                <React.Fragment>
                    {

                        (props.data[props.license].data !== undefined) &&
                        <React.Fragment> 
                            {

                                (props.data[props.license].data.length === 0) &&
                                <center style={{margin:'10px'}}>Nenhum repasse foi encontrado.</center>

                            }
                        </React.Fragment>

                    }
                </React.Fragment>

            }
 
            {

                (props.data[props.license] !== undefined) &&
                <React.Fragment>
                {

                    (props.data[props.license].loading === true) &&
                    <React.Fragment>
                        <center style={{margin:'10px'}}>Carregando...</center>
                    </React.Fragment>

                }
                {

                    (props.data[props.license] !== undefined) &&
                    <React.Fragment>
                        {

                            props.data[props.license].error !== undefined &&
                            <React.Fragment>
                                <center style={{margin:'10px',color:'red'}}>{props.data[props.license].error}</center>
                            </React.Fragment>

                        }
                    </React.Fragment>

                }    
                </React.Fragment>

            }
            
        </div>
    </div>

     
    <div id="ddbbs" class="d-flex  bg-dark text-white "  style={{marginBottom:'20px',padding:"10px 0px 15px 1px"}}>
        <div class="px-5"  >
            <div class="mb-2">Quantidade:</div><div class="h2 cbranco">{quantidade}</div>
        </div>
        <div class="px-5">
            <div class="mb-2">Pendente:</div>
            <div class="h2 cbranco" >{totalPendente.toFixed(2)}</div>
        </div>
        <div class="px-5">
            <div class="mb-2">Pago:</div>
            <div class="h2 cbranco" >{totalPago.toFixed(2)}</div>
        </div>
        <div class="px-5">
            <div class="mb-2">Total:</div>
            <div class="h2 cbranco" >{total.toFixed(2)}</div>
        </div>
    </div>

    {
        
        (view.invoice !== undefined) &&
        <React.Fragment>

            <Modal className="modal-dialog-centered" isOpen={true}  style={{maxWidth:'98%'}}>
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <div className="modal-header" style={{padding:'7px',paddingLeft:'20px',paddingRight:'20px'}}>
                            <h6 className="modal-title" id="modal-title-default">Fatura:{view.invoice} Comission:{view.comission} <a href={urld}><button className="btn btn-sm btn-primary">abrir externo</button></a></h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => { setView({}); }}><span aria-hidden={true}>×</span> </button>
                        </div>
                        <iframe title="teste" style={{height:(document.documentElement.clientHeight-100)+'px'}} frameBorder="none" src={urld}></iframe>
                    </Card>
                </div>
            </Modal>

        </React.Fragment>
        
    }

    </React.Fragment>)

}