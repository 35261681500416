import React from "react";
import api from "./../../../Api.js";
import {UncontrolledTooltip,InputGroupText,InputGroupAddon,InputGroup,Modal,Alert,Table,Button,Card,CardHeader,CardBody,FormGroup,Form,Input,Container,Row,Col,} from "reactstrap";
import SimpleHeader from "./../../../components/Headers/SimpleHeader.jsx";
import classnames from "classnames";
import NotificationAlert from "react-notification-alert";

import $ from 'jquery';

import moment from 'moment';

class CourseQuiz extends React.Component{

	constructor(props){

		super(props);

		this.state = {openDialogRemoveQuiz:false,opendialogQuiz:false,loadCourse:false,course:{},loadQuiz:false,quizzes:[],
		
			dialogQuiz:{
				name:"",
				description:"",
				showInstantFeedback:'',
				showDefaultResult:'',
				continueTillCorrect:''
			}

		};

		this.getCourse = this.getCourse.bind(this);
		this.newQuiz = this.newQuiz.bind(this);
		this.editQuiz = this.editQuiz.bind(this);
		this.putQuiz = this.putQuiz.bind(this);
		this.removeQuiz = this.removeQuiz.bind(this);
		this.removeQuizApi = this.removeQuizApi.bind(this); 
		this.getCourseQuiz = this.getCourseQuiz.bind(this);

		window.coursequiz = this;

	}

	//Para pegar os dados do curso
	async getCourse(){

	  var course = await api.getCourse(this.props.match.params.course).then((res) => {
	        
	    this.setState({course:res.data,loadCourse:true});
		return true;

	  }).catch(error => {

	    console.log(error.data);
	    console.log(error);
	    console.log(error.response);

	    if(error.response.status === 404){ this.props.history.push('/'); }
		return false;

	  });

	  return course;

	}


	//Para pegar todos os quizzes do curso
	async getCourseQuiz(){

		var course = await api.getQuizCourse(this.props.match.params.course).then((res) => {

			this.setState({quizzes:res.data});
			console.log(res.data);
			return true;

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);
			//if(error.response.status === 404){ this.props.history.push('/'); }
			return false;

		});

		return course;

	}

	//Para atualizar o quiz no banco de dados
	async putQuiz(){


		//pagar pegar o checkbox
		if($('#showInstantFeedback').is(':checked') === true){ this.state.dialogQuiz.showInstantFeedback = '1'; }else{ this.state.dialogQuiz.showInstantFeedback = '0'; }
		if($('#showDefaultResult').is(':checked') === true){ this.state.dialogQuiz.showDefaultResult = '1'; }else{ this.state.dialogQuiz.showDefaultResult = '0'; }
		if($('#continueTillCorrect').is(':checked') === true){ this.state.dialogQuiz.continueTillCorrect = '1'; }else{ this.state.dialogQuiz.continueTillCorrect = '0'; }

		await api.putQuiz(this.state.editQuiz,this.state.dialogQuiz).then((res) => {

			//Busca todas os 
			this.getCourseQuiz();

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
			this.setState({opendialogQuiz:false});

			this.getCourseQuiz();

			/*this.setState({opendialogQuiz:false});*/

			console.log(res.data);
			return true;

	    }).catch(error => {

			console.log(error);
			console.log(error.response);
			if(error.response.status === 400){

			  //this.setState({erroUnit:''});
			  //setTimeout(function(){ this.setState({erroUnit:error.response.data.msg}); }.bind(this), 1);
			  this.setState({erroQuiz:''});
              setTimeout(function(){ this.setState({erroQuiz:error.response.data.msg}); }.bind(this), 1);

			}
			return false;

	    });


	}

	//Para cadastrar o quiz no banco de dados
	async postQuiz(){

		if($('#showInstantFeedback').is(':checked') === true){ this.state.dialogQuiz.showInstantFeedback = '1'; }else{ this.state.dialogQuiz.showInstantFeedback = '0'; }
		if($('#showDefaultResult').is(':checked') === true){ this.state.dialogQuiz.showDefaultResult = '1'; }else{ this.state.dialogQuiz.showDefaultResult = '0'; }
		if($('#continueTillCorrect').is(':checked') === true){ this.state.dialogQuiz.continueTillCorrect = '1'; }else{ this.state.dialogQuiz.continueTillCorrect = '0'; }


		this.state.dialogQuiz.course = this.state.course.id;
		await api.postQuiz(this.state.dialogQuiz).then((res) => {

	 
			this.setState({opendialogQuiz:false});

			setTimeout(function(){ this.props.history.push('/course/'+this.state.course.id+'/quizzes/'+res.data.quiz.id); }.bind(this), 200);
			console.log(res.data);
			return true;

	    }).catch(error => {

			console.log(error);
			console.log(error.response);
			if(error.response.status === 400){

			  this.setState({erroQuiz:''});
              setTimeout(function(){ this.setState({erroQuiz:error.response.data.msg}); }.bind(this), 1);

			}
			return false;

	    });

	}

	async componentDidMount(){
  
    	await this.getCourse();
    	await this.getCourseQuiz();

	}

	newQuiz(){

		//titledialogQuiz opendialogQuiz buttondialogQuiz
		this.setState({dialogQuiz:{name:'',description:'',showInstantFeedback:'',
		showDefaultResult:'',
		continueTillCorrect:''}, titledialogQuiz:"Cadastrar quiz",opendialogQuiz:true,buttondialogQuiz:'Cadastrar',editQuiz:"",erroQuiz:""});

	}

	editQuiz(id){

		console.log('editando quiz');

		this.setState({titledialogQuiz:"Editar quiz",opendialogQuiz:true,buttondialogQuiz:'Salvar alterações',editQuiz:id,erroQuiz:""});

		this.state.quizzes.forEach(function(item,index,array){
 
			if(parseInt(item.id) === parseInt(id)){

				var nitem = JSON.parse(JSON.stringify(item));
				console.log(nitem);
				delete nitem.course;
				delete nitem.createDate;
				delete nitem.updateDate;
				delete nitem.sequence;
				delete nitem.id;
				
				this.setState({dialogQuiz:nitem});

				//$('input[name="customRadioInline1"][value='+nitem.randomAnswer+']').prop('checked', true);
				setTimeout(function(){ 

					window.$('#showInstantFeedback[value='+nitem.showInstantFeedback+']').prop('checked',true);
					window.$('#showDefaultResult[value='+nitem.showDefaultResult+']').prop('checked',true);
					window.$('#continueTillCorrect[value='+nitem.continueTillCorrect+']').prop('checked',true);

				},10);


				console.log('input[name="customRadioInline1"][value='+nitem.randomAnswer+']');

			}

		}.bind(this));

	}

	saveQuiz(event){
 
		event.preventDefault();
		if(this.state.editQuiz === ""){  this.postQuiz(); }else{ this.putQuiz();  }

	}

	removeQuiz(id){

		this.setState({openDialogRemoveQuiz:true,removeQuiz:id});

	}

	//Para remover o video no banco de dados
	async removeQuizApi(){

		this.setState({openDialogRemoveQuiz:false});

		var unit = await api.deleteQuiz(this.state.removeQuiz).then((res) => {

			this.getCourseQuiz();

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">O vídeo foi removido.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

			console.log(res.data);
			return true;


		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);
			return false; 
		});

		return unit;

	}

	render(){

  		const handleChange = name => event => {
	        var dialogQuiz = this.state.dialogQuiz;
	        dialogQuiz[name] = event.target.value;

	        console.log(event.target.value);

	        this.setState({dialogQuiz:dialogQuiz});

	    }

		return(<React.Fragment>
					<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
					{
						(this.state.loadCourse === true) &&
						<React.Fragment>

							<SimpleHeader name="" way={[{name:"Cursos",url:"/course"},{name:this.state.course.name,url:'/course/'+this.state.course.id},{name:"Quizzes",url:'/course/'+this.state.course.id+'/quizzes'}]} buttons={<React.Fragment></React.Fragment>} />

							<Container className="mt--6" fluid>
				          		<Row className="justify-content-center">
				            		<Col className="card-wrapper" lg="12">
										<Card>
											<CardHeader>
											  	<Row>
											  		<Col xs="6"><h3 className="mb-0">Quizzes</h3></Col>
											  		<Col className="text-right" xs="6">
									                  <Button onClick={ () => this.newQuiz() } className="btn-neutral btn-round btn-icon" color="default"  size="sm" >
									                    <span className="btn-inner--icon mr-1">
									                      <i className="fas fa-plus"></i>
									                    </span>
									                    <span className="btn-inner--text">Novo quiz</span>
									                  </Button>
									                </Col>
										  		</Row>
											</CardHeader>
											<Table className="align-items-center table-flush" hover responsive>
								              <thead className="thead-light">
								                <tr> 
								                  <th>Nome</th>
								                  <th>Descrição</th>
								                  <th><center>FeedBack instantâneo</center></th>
								                  <th><center>Mostrar resultado</center></th>
								                  <th><center>Continua até errar</center></th>
								                  <th><center>Data de criação</center></th>
								                  <th>Ações</th>
								                </tr>
								              </thead>
								              <tbody>
								              	{

									              	this.state.quizzes.map((value,index) => {

									              		//console.log(value.createDate);
									              		//console.log(moment(value.createDate, "YYYY-MM-DD H:mm:ss").format('YYYY/MM/DD'));

									              		var id_tol_tip1 = "trash_"+value.id;
														var id_tol_tip2 = "edit_"+value.id;
														var id_tol_tip3 = "editquestion_"+value.id;

														var t1,t2,t3;

									              		if(parseInt(value.showInstantFeedback) === 1){ t1 = <font style={{color:'green'}}>Sim</font>; }else{ t1 = <font style={{color:'red'}}>Não</font>; }
									              		if(parseInt(value.showDefaultResult) === 1){ t2 = <font style={{color:'green'}}>Sim</font>; }else{ t2 = <font style={{color:'red'}}>Não</font>; }
														if(parseInt(value.continueTillCorrect) === 1){ t3 = <font style={{color:'green'}}>Sim</font>; }else{ t3 = <font style={{color:'red'}}>Não</font>; }
 
			 
									              		return(<tr key={index} > 
										                  <td style={{cursor:'pointer'}} onClick={ () => this.props.history.push('/course/'+this.state.course.id+'/quizzes/'+value.id) } className="table-user"><b>{value.name}</b></td>
										                  <td><textarea className="form-control">{value.description}</textarea></td>
										                  <td><center><strong>{t1}</strong></center></td>
										                  <td><center><strong>{t2}</strong></center></td>
										                  <td><center><strong>{t3}</strong></center></td>
										                  <td><center>{moment(value.createDate, "YYYY-MM-DD H:mm:ss").format('YYYY/MM/DD')}</center></td>
										                  <td>
									                  			<a href="#marlon" onClick={ () => this.props.history.push('/course/'+this.state.course.id+'/quizzes/'+value.id) } style={{cursor:'pointer'}} className="table-action" id={id_tol_tip3} ><i class="far fa-check-square"></i></a>
											                    <UncontrolledTooltip delay={0} target={id_tol_tip3}>Montar questões</UncontrolledTooltip>
											                   
										                  		<a href="#marlon" style={{cursor:'pointer'}} className="table-action" id={id_tol_tip2} onClick={() => this.editQuiz(value.id) } ><i className="fas fa-pencil-alt"></i></a>
											                    <UncontrolledTooltip delay={0} target={id_tol_tip2}>Editar quiz</UncontrolledTooltip>
											                    
											                    <a href="#marlon" style={{cursor:'pointer'}} className="table-action table-action-delete" id={id_tol_tip1} onClick={() => this.removeQuiz(value.id) } ><i class="far fa-trash-alt"></i></a>
											                    <UncontrolledTooltip delay={0} target={id_tol_tip1}>Deletar quiz</UncontrolledTooltip>
										                  
										                  </td>
										                </tr>)})

								              	}
								              </tbody>
								            </Table>

								            {

								            	(this.state.quizzes.length === 0) &&
								            	<center><h3 style={{marginTop:'16px',marginBottom:'19px'}}>Nenhum quiz foi encontrado.</h3></center>

								            }

										</Card>
									</Col>
								</Row>
							</Container>

						</React.Fragment>

					}
					
			
			        <Modal className="modal-dialog-centered" isOpen={this.state.opendialogQuiz} >
						<div className="modal-body p-0">
						<Card className="bg-secondary border-0 mb-0">
						<div className="modal-header">
						<h6 className="modal-title" id="modal-title-default">{this.state.titledialogQuiz}</h6>
						<button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({opendialogQuiz:false})}><span aria-hidden={true}>×</span> </button>
						</div>
						  <CardBody className="px-lg-5">
						    <Form role="form" type="post" onSubmit={ (event) => this.saveQuiz(event) }>
						      {
						        (this.state.erroQuiz) &&
						        <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.state.erroQuiz}</Alert>
						      }
						      <Row>	 
									<Col md={12}>
									<h5>Nome do quiz:</h5>
										<FormGroup className={classnames("mb-3",{focused:this.state.focusedName})}>
									  	<InputGroup className="input-group-merge input-group-alternative">
									   	 <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
									   	 <Input type="text" onChange={handleChange('name')} value={ this.state.dialogQuiz.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
									  	</InputGroup>
										</FormGroup>
									</Col>
									<Col md={12}>
										<h5>Descrição:</h5>
										<Input className="form-control-alternative" onChange={handleChange('description')} value={ this.state.dialogQuiz.description } placeholder="" rows="3" type="textarea" />
									</Col>

									<Col md={12} style={{marginTop:'10px'}}></Col>
 

									<Col hidden md={12}>
										<div className="custom-control custom-checkbox">
								          <input className="custom-control-input" id="showInstantFeedback" value="1" type="checkbox"/>
								          <label className="custom-control-label" htmlFor="showInstantFeedback">Mostrar feedback ao escolher a resposta</label>
								        </div>
							        </Col>
							        <Col hidden md={12}>
										<div className="custom-control custom-checkbox">
								          <input className="custom-control-input" id="showDefaultResult" value="1" type="checkbox"/>
								          <label className="custom-control-label" htmlFor="showDefaultResult">Mostrar resultado ao finalizar</label>
								        </div>
							        </Col>
							        <Col hidden md={12}>
										<div className="custom-control custom-checkbox">
								          <input className="custom-control-input" id="continueTillCorrect" value="1" type="checkbox" />
								          <label className="custom-control-label" htmlFor="continueTillCorrect">Permite marcar até acertar.</label>
								        </div>
							        </Col>

						      </Row> 
						      <div className="text-center">
						        <Button className="my-4" color="default" type="button" onClick={ () => this.setState({opendialogQuiz:false}) } >Cancelar</Button>
						        <Button className="my-4" color="primary" type="submit">{this.state.buttondialogQuiz}</Button>
						      </div>
						    </Form>
						  </CardBody>
						</Card>
						</div>
					</Modal>
					<Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveQuiz} >
			          <div className="modal-header">
			            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
			            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
			              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
			            </button>
			          </div>
			          <div className="modal-body">
			            <div className="py-3 text-center">
			              <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
			              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
			              <p>Se você prosseguir, todos os dados do quiz serão removidos, se existir uma aula, que tenha esse quiz, ela ficará sem o quiz.</p>
			            </div>
			          </div>
			          <div className="modal-footer">
			            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeQuizApi() }>Ok, Remova</Button>
			            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveQuiz:false}) }>
			              Cancelar e fechar
			            </Button>
			          </div>
			        </Modal>


			</React.Fragment>)

	}

}

export default CourseQuiz;