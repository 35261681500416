import React from "react";
import api from "./../../Api.js";

import $ from 'jquery';
import {UncontrolledAlert,CardBody,FormGroup,Input,Button,Card,CardHeader,Progress,Container,Row,Col,Modal,Alert,InputGroup} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import axios from 'axios';
import { RingLoader } from 'react-spinners';

import Type from "./newcourse/Type.jsx";
import Category from "./newcourse/Category.jsx";
import Modality from "./newcourse/Modality.jsx";
import Level from "./newcourse/Level.jsx";

const Swal = require('sweetalert2');
class NewCourse extends React.Component {

  	constructor(props){

		super(props);
	
		this.state = {showCard:false,erroCourse:"",fileInput:[{erro:"",progress:""},{erro:"",progress:""},{erro:"",progress:""}],
		dialog:{curriculum:"",name:"",description:"",icon:"",cover:"",background:"",status:"",duration:"",video_url:"",

			requirements:[],
			benefits:[],
			projects:[],
			softwares:[],
			cousecategory:[],
			  
		},
		
		modalitys:[],	
		cousetypes:[],
		cousecategorys:[],
		couseLevels:[],
		
		openNewModality:false,

		dialogModality:{},
		dialogCourseType:{},
		dialogCategory:{},
		dialogLevel:{},

		};
		
		this.uploadImg = this.uploadImg.bind(this);
		this.setProgress = this.setProgress.bind(this);
		this.postCourse = this.postCourse.bind(this);
		this.getCourse = this.getCourse.bind(this);
		this.saveCourse = this.saveCourse.bind(this);
		this.putCourse = this.putCourse.bind(this);

		this.renderListItemSoftwares = this.renderListItemSoftwares.bind(this);
		this.addItemArray = this.addItemArray.bind(this);

		this.getModalitys = this.getModalitys.bind(this);
		this.getCategorys = this.getCategorys.bind(this);
		this.getCouseTypes = this.getCouseTypes.bind(this);
		this.getCouseLevels = this.getCouseLevels.bind(this);

		//this.saveModality = this.saveModality.bind(this);
		//this.saveCategory = this.saveCategory.bind(this);
		//this.postCategory = this.postCategory.bind(this);
		//this.putCategory = this.putCategory.bind(this);
		//this.postModality = this.postModality.bind(this);
		//this.putModality = this.putModality.bind(this);
		//this.deleteModality = this.deleteModality.bind(this);
		//this.postCourseType = this.postCourseType.bind(this);
		//this.putCourseType = this.putCourseType.bind(this);
		//this.deleteCourseType = this.deleteCourseType.bind(this);
		//this.deleteCourseCategory = this.deleteCourseCategory.bind(this);
		//this.addFunctionUpload = this.addFunctionUpload.bind(this);
		
		window.newCourse = this;

	}

	//addFunctionUpload(name){

		//window.$('.'+name).bind();
		//window.$('.'+name).on
 
	//}

	//Para fazer o upload do arquivo no input file
	async uploadImg(input,inputd,force){

		console.log('Acionou uploadImg');
		console.log('input',input);
		console.log('inputd',inputd);

		let formData = new FormData();
		var file = undefined;
		
		if(inputd === undefined){

			console.log('ps1 A1');
			var fileInput = this.state.fileInput;
		 
			var row = $('.section_file[name="'+input+'"]');
			console.log('ps1 A2');

			inputd = row.find('input[type="file"]')[0];
			console.log('ps1 A3');

			var n = row.attr('n');
			console.log('ps1 A4');
			file = inputd.files;

		}
		
		console.log('ps1');
	 

		console.log('ps1 A5');

		if(force === true){

			file = input.files;

		}

		console.log('inputd:',inputd);
		console.log('file:',file);

		

		//seta o erro caso nenhum arquivo tenha sido informado
 		if(file.length === 0){  
			
			console.log('ps2');

 			fileInput[n].erro = "";
 			this.setState({fileInput:fileInput});
 			setTimeout(function(){ fileInput[n].erro = "Você deve escolher um arquivo."; this.setState({fileInput:fileInput}); }.bind(this), 1);
 			
			console.log('saiu aqui');
			return false;

 		}

		 console.log('ps3');


		if(inputd === undefined){

			//removo o erro caso tenha
			fileInput[n].erro = "";
			this.setState({fileInput:fileInput});
			this.setProgress(0,n);
		
		}
 		

		console.log('ps4');

 		formData.append('files', file[0]); 
		formData.append('name', file[0].name);
		

		console.log('ps5');

		var obj = {'Content-Type': 'multipart/form-data',session:sessionStorage.getItem('session')};
		console.log(obj);
	
		var send = await axios.post(sessionStorage.getItem('backend')+'/upload',formData,{headers:obj,onUploadProgress:progressEvent => {

			this.setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total),n)

	    }}).then((res) => {

			if(inputd !== undefined){
 
				this.setProgress('',n);

				if(parseInt(n) === 0){ 
					
					this.state.dialog.icon = res.data.id;
					this.state.dialog.iconData = res.data;
				
				}

				if(parseInt(n) === 1){ 
					
					this.state.dialog.cover = res.data.id;
					this.state.dialog.coverData = res.data;
				
				}

				if(parseInt(n) === 2){ 
					
					this.state.dialog.background = res.data.id;
					this.state.dialog.backgroundData = res.data;
				
				}

				this.setState({a:""});
			
			}
	    	
			return res.data;

		}).catch((res) => {

	    	console.log('FAILURE!!');
	    	this.setProgress('',n);
			return false;

	  	});

		return send;

	}

	//Para setar o progresso da barra
	setProgress(progress,n){

		var fileInput = this.state.fileInput;
		if(fileInput !== undefined){

			if(fileInput[n] !== undefined){

				fileInput[n].progress = progress;
				this.setState({fileInput:fileInput});
				console.log(progress);

			}
			
		}
		
	}

	//Quando mudar o arquivo no input, muda o texto no label
	changeFileInput(event,htmlFor){

		console.log(event);
		console.log(event.target.value);
		$("label[for='"+htmlFor+"']").html(event.target.value);
		console.log("label[for='"+htmlFor+"']");

	}

	//Para postar o curso no banco de dados
	async postCourse(){

		await api.postCourse(this.state.dialog).then((res) => {

			this.props.history.push("/course/"+res.data.course.id);
			console.log(res.data);
			return true;

		}).catch(error => {
 
	        console.log(error);
	        console.log(error.response);

         	if(error.response.status === 400){

	          	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops, não foi possível cadastrar o curso.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          		this.refs.notificationAlert.notificationAlert(options);

          		this.setState({erroCourse:''});
          		setTimeout(function(){ this.setState({erroCourse:error.response.data.msg}); }.bind(this), 1);

	        }
	        return false;
		});

	}

	//Para salvar as alterações do curso no banco de dados
	async putCourse(){

		await api.putCourse(this.props.match.params.id,this.state.dialog).then((res) => {

	 		let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
      		this.refs.notificationAlert.notificationAlert(options);
			console.log(res.data);
		 

		}).catch(error => {
 
	        console.log(error);
	        console.log(error.response);

         	if(error.response.status === 400){

	          	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops, não foi possível cadastrar o curso.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          		this.refs.notificationAlert.notificationAlert(options);

          		this.setState({erroCourse:''});
          		setTimeout(function(){ this.setState({erroCourse:error.response.data.msg}); }.bind(this), 1);

	        }
	        return false;
		});

	}

	 

	//Para pegar o curso caso esteja editando
	async getCourse(){

		await api.getCourse(this.props.match.params.id).then((res) => {

			this.setState({showCard:true});

			var course = JSON.parse(JSON.stringify(res.data));
			delete course.id;
			delete course.background_file;
			delete course.icon_file;
			delete course.cover_file;
			delete course.updateDate;
			delete course.createDate;

			this.setState({dialog:course});
			
			setTimeout(function(){

				//if(res.data.background_file !== undefined){ $('.imgup_background').attr('src',sessionStorage.getItem('backend')+res.data.background_file.url) }
				//if(res.data.icon_file !== undefined){ $('.imgup_icon').attr('src',sessionStorage.getItem('backend')+res.data.icon_file.url) }
				//if(res.data.cover_file !== undefined){ $('.imgup_cover').attr('src',sessionStorage.getItem('backend')+res.data.cover_file.url) }
				$('input[name="status"][value="'+res.data.status+'"]').prop('checked', true);

			}, 5);

		}).catch(error => {

			console.log(error);
	        console.log(error.response);

         	if(error.response.status === 400){

	          	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops, obter o curso.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          		this.refs.notificationAlert.notificationAlert(options);

          		this.setState({erroCourse:''});
          		setTimeout(function(){ this.setState({erroCourse:error.response.data.msg}); }.bind(this), 1);

	        }

	        if(error.response.status === 404){ this.props.history.push('/'); }

		});

	}

	getModalitys(){

		api.getModalitys().then((res) => {

			this.setState({modalitys:res.data});

		}).catch(error => {
		});

	}


	getCategorys(){

		api.getCategorys().then((res) => {

			this.setState({cousecategorys:res.data});

		}).catch(error => {
		});

	}


	 
	getCouseTypes(){

		api.getCouseTypes().then((res) => {

			this.setState({cousetypes:res.data});

		}).catch(error => {
		});

	}

	getCouseLevels(){

		api.getCouseLevels().then((res) => {

			this.setState({couseLevels:res.data});

		}).catch(error => {
		});

	}


	saveCourse(event){

		event.preventDefault(); 
		if(this.props.match.params.id === undefined){ this.postCourse(); }else{ this.putCourse(); }

	}

	async componentDidMount(){

		//Caso esteja criando um novo curso
		if(this.props.match.params.id === undefined){ this.setState({showCard:true}); }else{ this.getCourse(); }
		console.log(this.props.match.params);

		this.getModalitys();
		this.getCouseTypes();
		this.getCategorys();
		this.getCouseLevels();

	}
 

	renderListItemSoftwares(array,unique){

		if(array.length === 0){ return <div style={{textAlign:'center',fontSize:'12px'}}>Nenhum item foi adicionado.</div>; }
		
		return array.map((value,index) => {
			
			console.log(typeof value);
			if(typeof value !== "object"){ value = {name:"",icon:""}; }
			

			return(<React.Fragment key={'d1_'+index}>
				 
				<div style={{display:'flex',height:'50px',alignItems:'center'}}>

					<div>
						{
							
							 
							<React.Fragment>

							 	{

									(value.dataIcon !== undefined) &&
									<div style={{cursor:'pointer'}} onClick={() => {

										var click = '.input_send_file_un_'+unique+'_'+index;
										window.$(click).click();

									}}><img style={{maxHeight:'30px',marginRight:'12px'}} src={value.dataIcon.s3url} alt=".." /></div>

								}

								{

									(value.dataIcon === undefined) &&
									<button type="button" className="btn btn-secondary btn-sm" onClick={() => { 

										var click = '.input_send_file_un_'+unique+'_'+index;
										window.$(click).click();
										//this.addFunctionUpload("input_send_file_un_"+unique+"_"+index);

									}} >Escolher ícone</button>

								}
								


								<input 

									onChange={async (event) => { 
										
										var upd = await this.uploadImg(window.$('.input_send_file_un_'+unique+'_'+index)[0],'',true);
										console.log(upd);

										if(upd !== false){

											value.icon = upd.id;
											value.dataIcon = upd;

										}

										this.setState({a:"a"});

									 }}
								
								hidden className={"input_send_file_un_"+unique+"_"+index} type="file" />

							</React.Fragment>
						
						}
					</div>

					<input onChange={ (e) =>{

						console.log(array[index]);
						array[index].name = e.target.value;
						this.setState({a:''});
						console.log('data');

					}} className={'form-control'} type="text" value={array[index].name} style={{height:'22px',marginBottom:'5px'}} />
					<button type="button" className="btn btn-sm" style={{marginLeft:'5px',position:'relative',top:'-3px'}} onClick={() => {

						array.splice(index,1);
						this.setState({a:''});

					}}><i class="fas fa-trash"></i></button>
				</div>

			</React.Fragment>)

		});

	}
	
	addItemArray(array){

		array.push({name:"",icon:""});
		this.setState({name:'',icon:''});

	}

	 

	 

	 

 

	 




	 
 


	 
  
	 


	 
	render(){

			var title,buttonFooter,simpleHED;

			const handleChange = name => event => {
		        var dialog = this.state.dialog;
		        dialog[name] = event.target.value;
		        this.setState({dialog:dialog});
		    }

		    if(this.props.match.params.id === undefined){

		    	 title = "Novo curso";
		    	 buttonFooter = <Button type="submit" block color="primary" size="lg" ><i class="fas fa-plus"></i> Cadastrar curso</Button>;
		    	 simpleHED = <SimpleHeader name={title} way={[{name:"Cursos",url:"/course"},{name:title,url:this.props.match.url}]} buttons={<React.Fragment></React.Fragment>} />;

		    }else{
				
				 title = "Editando curso";
				 buttonFooter = <Button type="submit" block color="primary" size="lg" ><i class="far fa-save"></i> Salvar alterações</Button>;
				 simpleHED = <SimpleHeader history={this.props.history} name={title} way={[{name:"Cursos",url:"/course"},{name:this.state.dialog.name,url:"/course/"+this.props.match.params.id},{name:title,url:this.props.match.url}]} buttons={<React.Fragment></React.Fragment>} />;
		    
		    }

			return(
		    <React.Fragment>
		        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
		        
		        {simpleHED}
          	        
          	        <Container className="mt--6" fluid>
		          		<Row className="justify-content-center">
		            		<Col className="card-wrapper" lg="8">
								<Card>
									<CardHeader>
									  <Row><Col xs="6"><h3 className="mb-0">{title}</h3></Col></Row>
									</CardHeader>

									{

					                    (this.state.showCard === false) &&
					                    <center style={{margin:'40px'}}><RingLoader sizeUnit={"px"} size={60} color={'#32325d'} loading={true} /></center>

				                  	}

									{ 

										(this.state.showCard === true) && 

									 

									<CardBody>
										
										
										<form type="post" onSubmit={ (event) => this.saveCourse(event) } >
										<h6 className="heading-small text-muted mb-4">Informações gerais</h6>
					                    <div className="pl-lg-4">
					                      <Row>
					                        <Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Nome do curso:</label>
					                            <Input defaultValue="" onChange={handleChange('name')} value={ this.state.dialog.name } d="input-username" type="text" />
					                          </FormGroup>
					                        </Col>
					                        <Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Descrição do curso:</label>
					                            <Input defaultValue="" onChange={handleChange('description')} value={ this.state.dialog.description } rows="4" type="textarea" />
					                          </FormGroup>
					                        </Col>
											<Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Currículo:</label>
					                            <Input defaultValue="" onChange={handleChange('curriculum')} value={ this.state.dialog.curriculum } rows="4" type="textarea" />
					                          </FormGroup>
					                        </Col>
											<Col lg="4">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Matrículas mínima para turma formada:</label>
					                            <input defaultValue="" onChange={handleChange('regmin')} value={ this.state.dialog.regmin } rows="4" className="form-control" />
					                          </FormGroup>
					                        </Col>
											<Col lg="3">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Idade mínima:</label>
					                            <input defaultValue="" onChange={handleChange('minimum_age')} value={ this.state.dialog.minimum_age } rows="4" className="form-control" />
					                          </FormGroup>
					                        </Col>
											<Col lg="2">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Duração:</label>
					                            <input defaultValue="" onChange={handleChange('duration')} value={ this.state.dialog.duration } rows="4" className="form-control" />
											  </FormGroup>
					                        </Col>
											<Col lg="3">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Idade máxima:</label>
					                            <input defaultValue="" onChange={handleChange('maximum_age')} value={ this.state.dialog.maximum_age } rows="4" className="form-control" />
											  </FormGroup>
					                        </Col>
											<Col lg="2">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">É destaque:</label>
					                            <select onChange={handleChange('spotlight')} value={ this.state.dialog.spotlight } className="form-control" >
													<option></option>
													<option value="0">Não</option>
													<option value="1">Sim</option>
												</select>
											  </FormGroup>
					                        </Col>


											<Col lg="2">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Em breve:</label>
					                            <select onChange={handleChange('launch')} value={ this.state.dialog.launch } className="form-control" >
													<option></option>
													<option value="0">Não</option>
													<option value="1">Sim</option>
												</select>
											  </FormGroup>
					                        </Col>

											<Col lg="2">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">É lançamento:</label>
					                            <select onChange={handleChange('comingsoon')} value={ this.state.dialog.comingsoon } className="form-control" >
													<option></option>
													<option value="0">Não</option>
													<option value="1">Sim</option>
												</select>
											  </FormGroup>
					                        </Col>
											<Col lg="3">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Modalidade:</label>
					                            <select defaultValue="" onChange={handleChange('modality')} value={ this.state.dialog.modality } rows="4" className="form-control">
													<option value=""></option>
													{

														this.state.modalitys.map((value,index) => {

															return(<option value={value.id}>{value.name}</option>)

														})

													}
												</select>
												<button type="button" style={{marginTop:'5px'}} className="btn btn-secondary btn-sm" onClick={() => { this.setState({openModalEditModality:true}); }}>Criar/editar</button>
											  </FormGroup>
					                        </Col>
											<Col lg="3">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Tipo do curso:</label>
					                            <select defaultValue="" onChange={handleChange('course_type')} value={ this.state.dialog.course_type } rows="4" className="form-control">
													<option value=""></option>
													{

														this.state.cousetypes.map((value,index) => {

															return(<option value={value.id}>{value.name}</option>)

														})

													}
												</select>
												<button type="button" style={{marginTop:'5px'}} className="btn btn-secondary btn-sm" onClick={() => { this.setState({openModalEditCourseType:true}); }}>Criar/editar</button>
											  </FormGroup>
					                        </Col>
											<Col lg="3">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Categoria:</label>
					                            <select defaultValue="" onChange={handleChange('category')} value={ this.state.dialog.category } rows="4" className="form-control">
													<option value=""></option>
													{

														this.state.cousecategorys.map((value,index) => {

															return(<option value={value.id}>{value.name}</option>)

														})

													}
												</select>
												<button type="button" style={{marginTop:'5px'}} className="btn btn-secondary btn-sm" onClick={() => { this.setState({openModalEditCourseCategory:true}); }}>Criar/editar</button>
											  </FormGroup>
					                        </Col>

											<Col lg="3">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Nível:</label>
					                            <select defaultValue="" onChange={handleChange('level')} value={ this.state.dialog.level } rows="4" className="form-control">
													<option value=""></option>
													{

														this.state.couseLevels.map((value,index) => {

															return(<option value={value.id}>{value.name}</option>)

														})

													}
												</select>
												<button type="button" style={{marginTop:'5px'}} className="btn btn-secondary btn-sm" onClick={() => { this.setState({openModalEditCourseLevel:true}); }}>Criar/editar</button>
											  </FormGroup>
					                        </Col>


											<Col lg="4">
												<FormGroup>
													<label className="form-control-label" htmlFor="input-username">ID CURSO LMS:</label>
													<input defaultValue="" onChange={handleChange('lms_course_id')} value={ this.state.dialog.lms_course_id } rows="4" className="form-control" />
												</FormGroup>
					                        </Col>

											<Col lg="6">
												<FormGroup>
													<label className="form-control-label" htmlFor="input-username">URL do vídeo:</label>
													<input defaultValue="" onChange={handleChange('video_url')} value={ this.state.dialog.video_url } rows="4" className="form-control" />
												</FormGroup>
					                        </Col>
 
											
											<Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Pré-requisitos:</label>
												<div>
													{
														this.renderListItemSoftwares(this.state.dialog.requirements,'requirements')	
													}
												</div>
												<button onClick={ () => this.addItemArray(this.state.dialog.requirements) } style={{marginTop:"5px"}} type="button" class="btn btn-secondary btn-sm"><i class="fas fa-plus"></i> Adicionar</button>
											  </FormGroup>
					                        </Col>
											<Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Benefícios:</label>
												<div>
													{
														this.renderListItemSoftwares(this.state.dialog.benefits,'benefits')	
													}
												</div>
												<button onClick={ () => this.addItemArray(this.state.dialog.benefits) } style={{marginTop:"5px"}} type="button" class="btn btn-secondary btn-sm"><i class="fas fa-plus"></i> Adicionar</button>
											  </FormGroup>
					                        </Col>
											<Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Projetos:</label>
												<div>
													{
														this.renderListItemSoftwares(this.state.dialog.projects,'projects')
													}
												</div>
												<button onClick={ () => this.addItemArray(this.state.dialog.projects) } style={{marginTop:"5px"}} type="button" class="btn btn-secondary btn-sm"><i class="fas fa-plus"></i> Adicionar</button>
											  </FormGroup>
					                        </Col>
											<Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Programas:</label>
												<div>
													{
														this.renderListItemSoftwares(this.state.dialog.softwares,'softwares')	
													}
												</div>
												<button onClick={ () => {
													
													this.addItemArray(this.state.dialog.softwares)

												} } style={{marginTop:"5px"}} type="button" class="btn btn-secondary btn-sm"><i class="fas fa-plus"></i> Adicionar</button>
											  </FormGroup>
					                        </Col>
					                        <Col lg="12">
   										    	<label className="form-control-label" style={{marginRight:'10px'}}>Status:</label> 
												<div className="custom-control custom-radio custom-control-inline">
												  <input onChange={handleChange('status')} className="custom-control-input" id="customRadio1" name="status" value="1" type="radio" />
												  <label className="custom-control-label" htmlFor="customRadio1">Disponível para criar turmas.</label>
												</div>
												<div className="custom-control custom-radio custom-control-inline">
												  <input onChange={handleChange('status')} className="custom-control-input" id="customRadio2" name="status" value="0" type="radio" />
												  <label className="custom-control-label" htmlFor="customRadio2">Disponível apenas para ediação.</label>
												</div>
											</Col>
					                      </Row>
					                    </div>
				                    	<div className="pl-lg-4">
				                    		<hr/>
				                    		<h6 className="heading-small text-muted mb-4">Ícone</h6>
				                      		<Row className="section_file" name="icone" n="0">
				                      			<Col lg="4">
													  {

														(this.state.dialog.iconData !== undefined) &&
														<center><img alt="..." className="imgup imgup_background" src={this.state.dialog.iconData.s3url} /></center>

													  }
												</Col>
				                      			<Col lg="8">
				                      				{
				                      					(this.state.fileInput[0].progress === "") &&
				                      					<div style={{display:"flex"}}>
						                      				<div className="custom-file form-control-sm">
										                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_0'); this.uploadImg('icone'); } } className="custom-file-input" id="custom_file_0" type="file" />
										                        <label className="custom-file-label " htmlFor="custom_file_0">Selecionar arquivo</label>
									                      	</div>
								                      		<Button onClick={ () => this.uploadImg('icone') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i class="ni ni-cloud-upload-96"></i> Enviar</Button>
						                          		</div>
				                      				}
					                          		{
					                          			(this.state.fileInput[0].erro !== "") &&
					                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[0].erro}</h6>
					                          		}
					                          		{
					                          			(this.state.fileInput[0].progress !== "") &&
					                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
												          <div className="progress-info">
												            <div className="progress-label"><span>Enviando arquivo</span></div>
												            <div className="progress-percentage"><span>{this.state.fileInput[0].progress}%</span></div>
												          </div>
												          <Progress max="100" value={this.state.fileInput[0].progress} color="default" />
												        </div>
					                          		}
					                          	</Col>
				                      		</Row>
				                      		<hr/>
				                      		<h6 className="heading-small text-muted mb-4">Capa</h6>
				                      		<Row className="section_file" name="capa" n="1">
				                      			<Col lg="4">
													{

														(this.state.dialog.coverData !== undefined) &&
														<center><img alt="..." className="imgup imgup_background" src={this.state.dialog.coverData.s3url} /></center>

													}
												</Col>
				                      			<Col lg="8">
				                      				<div style={{display:"flex"}}>
					                      				<div className="custom-file form-control-sm">
									                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_1'); this.uploadImg('capa'); }} className="custom-file-input" id="custom_file_1" type="file" />
									                        <label className="custom-file-label " htmlFor="custom_file_1">Selecionar arquivo</label>
								                      	</div>
							                      		<Button onClick={ () => this.uploadImg('capa') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i class="ni ni-cloud-upload-96"></i> Enviar</Button>
					                          		</div>
					                          		{
					                          			(this.state.fileInput[1].erro !== "") &&
					                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[1].erro}</h6>
					                          		}
					                          		{
					                          			(this.state.fileInput[1].progress !== "") &&
					                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
												          <div className="progress-info">
												            <div className="progress-label"><span>Enviando arquivo</span></div>
												            <div className="progress-percentage"><span>{this.state.fileInput[1].progress}%</span></div>
												          </div>
												          <Progress max="100" value={this.state.fileInput[1].progress} color="default" />
												        </div>
					                          		}
					                          	</Col>
				                      		</Row>
				                      		<hr/>
				                      		<h6 className="heading-small text-muted mb-4">Background</h6>
				                      		<Row className="section_file" name="background" n="2">
				                      			<Col lg="4">
													{

														(this.state.dialog.backgroundData !== undefined) &&
														<center><img alt="..." className="imgup imgup_background" src={this.state.dialog.backgroundData.s3url} /></center>

													}
												</Col>
				                      			<Col lg="8">
				                      				<div style={{display:"flex"}}>
					                      				<div className="custom-file form-control-sm">
									                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_2'); this.uploadImg('background'); } } className="custom-file-input" id="custom_file_2" type="file" />
									                        <label className="custom-file-label " htmlFor="custom_file_2">Selecionar arquivo</label>
								                      	</div>
							                      		<Button onClick={ () => this.uploadImg('background') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i class="ni ni-cloud-upload-96"></i> Enviar</Button>
					                          		</div>
					                          		{
					                          			(this.state.fileInput[2].erro !== "") &&
					                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[2].erro}</h6>
					                          		}
					                          		{
					                          			(this.state.fileInput[2].progress !== "") &&
					                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
												          <div className="progress-info">
												            <div className="progress-label"><span>Enviando arquivo</span></div>
												            <div className="progress-percentage"><span>{this.state.fileInput[2].progress}%</span></div>
												          </div>
												          <Progress max="100" value={this.state.fileInput[2].progress} color="default" />
												        </div>
					                          		}
					                          	</Col>
				                      		</Row>
				                      	</div>
				                      	<hr/>
				                      	{buttonFooter}
				                      	</form>
 									</CardBody>
 									}
								</Card>
							</Col>
						</Row>
					</Container>




			 

					 






					 







					 
















					 













					 




















			<Type getCouseTypes={() => { this.getCouseTypes(); }} setState={(data) => { this.setState(data); }} state={this.state} />
			<Category getCategorys={() => { this.getCategorys(); }} setState={(data) => { this.setState(data); }} state={this.state} />
			<Modality getModalitys={() => { this.getModalitys(); }} setState={(data) => { this.setState(data); }} state={this.state} />
			<Level getCouseLevels={() => { this.getCouseLevels(); }} setState={(data) => { this.setState(data); }} state={this.state} />
			  









		    </React.Fragment>);
	}
}
export default NewCourse;