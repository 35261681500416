
import React,{useState,useEffect} from "react"; 
import api from "../Api.js";

export default function ListLicenseSelect(props){

    const [load,setLoad] = useState(false);

    const [licenses,setLicenses] = useState([]);
    const [search,setSearch] = useState('');

    const getLicenses = ((e) => {

        api.getAllLicense().then((response) => {

            setLicenses(response.data);
            setLoad(true);

        });

    });

    useEffect(() => {

        getLicenses();

    },[])

    return (
    <React.Fragment>
        <div className="card bg-secondary card-body">
            <div><h3 class="mb-0">Pesquisar</h3></div>
            <div><input style={{padding:'0px',height:'20px',paddingLeft:'10px'}} placeholder="" type="text" onChange={(e) => { setSearch(e.target.value); }} class="form-control-alternative form-control" value={search} /></div>
            {

                (load === false) &&
                <React.Fragment>
                    <div className="" style={{textAlign:'center'}}>Carregando licenças</div>
                </React.Fragment>

            }
            {

                (load === true) &&
                <React.Fragment>
                {

                    licenses.map((value,index) => {

                        if(search !== ""){

                            var nm = value.name.toLowerCase().replaceAll(' ','');
                            var sm = search.toLowerCase().replaceAll(' ','');

                            if(nm.indexOf(sm) === -1){ return <React.Fragment></React.Fragment>; }

                        }

                        return (<div className="custom-control custom-checkbox">
						          <input onChange={(e) => {

                                    var index = props.licenses.indexOf(value.license)

                                    if(e.target.checked === true){

                                        props.licenses.push(value.license);

                                    }else{

                                        props.licenses.splice(index,1);

                                    }

                                    var data = JSON.parse(JSON.stringify(props.licenses));
                                    console.log(data);

                                    if(props.onChange !== undefined){

                                        props.onChange(data);

                                    }
                                    

                                  }} checked={props.licenses.indexOf(value.license) === -1 ? false:true} className="custom-control-input inputPermission" id={value.id} value={value.id} type="checkbox" />
						          <label className="custom-control-label" htmlFor={value.id}>{value.name}</label>
						        </div>)

                    })

                }    
                </React.Fragment>

            }
        </div>
    </React.Fragment>)

}