import React from "react";
import {Alert,Modal,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Col,Row, NavItem,NavLink,Nav} from "reactstrap";
import SimpleHeader from "./../../../components/Headers/SimpleHeader.jsx";
import api from "./../../../Api.js";
import NotificationAlert from "react-notification-alert";
import CurrencyInput from 'react-currency-input';
import classnames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
 
class EditLicenseValuesProduct extends React.Component {

	constructor(props){

        super(props);
        
        this.state = {load:false,licenses:[]};
        this.saveValues = this.saveValues.bind(this);
        this.getProductValues = this.getProductValues.bind(this);

        window.editLicenseValuesProduct = this;

    }

    async saveValues(event){

        event.preventDefault();

        var obj = {licenses:this.state.licenses};
        obj = JSON.parse(JSON.stringify(obj));

        for(let index = 0; index < obj.licenses.length; index++){
            
            delete obj.licenses[index].dataLicense;
            delete obj.licenses[index].name;
            
        }

        console.log(obj);
        await api.saveProductValues(this.props.product,obj).then((res) => {
            
            if(this.props.success !== undefined){

                this.props.success();

            }

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
 

            if(this.props.error !== undefined){

                this.props.error(error.response.data);

            }
            
        });

    }

    async getProductValues(){

        await api.getProductValues(this.props.product).then((res) => {
            
            this.setState({load:true,licenses:res.data});
            //this.setState({load:true});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);

           // this.setState({erroProduct:error.response.data.msg});
            
        });

    }

    componentDidMount(){

        this.getProductValues();

    }

    render(){

        var bt = <button style={{marginBottom:'10px'}} class="btn-round btn-icon btn btn-primary btn-sm"><span class="btn-inner--text">Salvar alterações</span></button>;

        return(
        <React.Fragment>
            <Form role="form" type="post" onSubmit={ (event) => this.saveValues(event) }>
                
            <center>{bt}</center>

            {

                (this.state.load === false) &&
                <center><ClipLoader size={50} color={"#9c9fa0"} loading={true} /></center>

            }

            {

                (this.state.load === true && this.state.licenses.length === 0) &&
                <center>Nenhuma licença foi encontrada.</center>

            }

              
                    <table class="align-items-center table-flush table">
                        <thead class="thead-light">
                            <tr>
                                <th>Nome</th>
                                <th>Valor</th>
                                <th>Repasse % (bruto)</th>
                            </tr>
                        </thead>
                        <tbody>
                        {

                            this.state.licenses.map((value,index) => {
                            
                            var disabled = false;
                            var txtExtra = "";
                            console.log(value);

                            if(value.dataLicense.dataPriceRange !== undefined){
                                
                                value.value = 0;
                                value.comission = 0;

                                var index2 = value.dataLicense.dataPriceRange.prices.map(function(x){return x.product; }).indexOf(this.props.product);
                                if(index2 !== -1){
                                    
                                    value.value = value.dataLicense.dataPriceRange.prices[index2].value;
                                    value.comission = value.dataLicense.dataPriceRange.prices[index2].comission;

                                }

                                txtExtra = <strong>({value.dataLicense.dataPriceRange.name})</strong>;
                                disabled = true;

                            }

                            var style = {};
                            var style2 = {};

                            if(value.value === 0){

                                style = {backgroundColor:'red',color:"#fff"};

                            }

                            if(value.comission === 0){

                                style2 = {backgroundColor:'red',color:"#fff"};

                            }


                            return(<tr key={'dd_'+index}>
                                        <td >{value.name} {txtExtra}</td>
                                        <td className="td_custon_prod"><CurrencyInput style={style} disabled={disabled} decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={value.value} onChangeEvent={ (e) => { value.value = e.target.value; this.setState({a:'1'}); }} /></td>
                                        <td className="td_custon_prod"><CurrencyInput style={style2} disabled={disabled} decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={value.comission} onChangeEvent={ (e) => { value.comission = e.target.value; this.setState({a:'1'}); }} /></td>
                                    </tr>)

                            })

                        }
                        </tbody>
                    </table>
                    
                    <center>
                
                    {bt}

            </center>

            </Form>

            <style>{' .td_custon_prod input {padding: 0px 0px 0px 4px; height: 27px; width: 90px;} '}</style>
        </React.Fragment>)

    }
    

}
        

                
export default EditLicenseValuesProduct;