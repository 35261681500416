import React from "react";
import {FormGroup,InputGroupAddon,InputGroupText,InputGroup,Col,Row,Modal,Card,CardBody,Form} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import Pagination from "./components/Pagination.jsx";
import {ClipLoader} from "react-spinners";
import moment from "moment";
import StatusInvoice from "./components/StatusInvoice.jsx";
import MethodPayment from "./components/MethodPayment.jsx";
import ScrollContainer from 'react-indiana-drag-scroll';
import ReactDatetime from "react-datetime";
import ShowInfo from './components/ShowInfo.jsx';
import XLSX from 'xlsx';
import SlidingPane from "react-sliding-pane";
import Cookies from 'universal-cookie';
import "react-sliding-pane/dist/react-sliding-pane.css";

const cookies = new Cookies();

class Invoice extends React.Component {

	constructor(props){

        super(props);
		this.state = {
            loading:false,
            licenses:[],
            invoices:[],
            status:null,
            order:'1',
            total:0,
            page:0,
            limit:10,
            search:"",
            license:58,
            //license:'',
            showItens:false,
            error:"",
            courses:[],
            showinfos:false,
            startDate:'',
            endDate:'',
            products:[],
            filterProducts:[]
        };
        
        this.state.startDate = moment( moment().format('YYYY-MM')+'-01' ,'YYYY-MM-DD');
        var end = moment().format('YYYY')+'-'+moment().format('MM')+'-'+moment( ).endOf('month').format('DD');
        this.state.endDate = moment(end,'YYYY-MM-DD');

        this.getAllLicense = this.getAllLicense.bind(this);
        this.searchInvoices = this.searchInvoices.bind(this);
        this.getCourses = this.getCourses.bind(this);
        this.update = this.update.bind(this);
        this.geraXlsx = this.geraXlsx.bind(this);
        this.saveCaolumCache = this.saveCaolumCache.bind(this);
        this.xlsxComission = this.xlsxComission.bind(this);

        this.state.colunas = [
            {id:"td_val_1",n:'1',name:"#"},
            {id:"td_val_2",n:'2',name:"ID"},
            {id:"td_val_3",n:'3',name:"Itens"},
            {id:"td_val_4",n:'4',name:"Descrição"},
            {id:"td_val_5",n:'5',name:"Observação"},
            {id:"td_val_6",n:'6',name:"Turma"},
            {id:"td_val_7",n:'7',name:"Cliente"},
            {id:"td_val_8",n:'8',name:"Aluno"},
            {id:"td_val_9",n:'9',name:"Curso"},
            {id:"td_val_10",n:'10',name:"Valor"},
            {id:"td_val_11",n:'11',name:"Desconto"},
            {id:"td_val_12",n:'12',name:"Total"},
            {id:"td_val_13",n:'13',name:"Valor iugu"},
            {id:"td_val_14",n:'14',name:"Desconto iugu"},
            {id:"td_val_15",n:'15',name:"Total iugu"},
            {id:"td_val_16",n:'16',name:"Total pago iugu"},
            {id:"td_val_17",n:'17',name:"Multa/Juros iugu"},
            {id:"td_val_18",n:'18',name:"Taxas iugu"},
            {id:"td_val_19",n:'19',name:"Data de criação"},
            {id:"td_val_20",n:'20',name:"Data de vencimento"},
            {id:"td_val_21",n:'21',name:"Data de pagamento"},
            {id:"td_val_22",n:'22',name:"Data de cancelamento"},
            {id:"td_val_23",n:'23',name:"Data de reembolso"},
            {id:"td_val_24",n:'24',name:"Data de geração"},
            {id:"td_val_25",n:'25',name:"Data de expiração"},
            {id:"td_val_26",n:'26',name:"Data de protesto"},
            {id:"td_val_27",n:'27',name:"Data de contestação"},
            {id:"td_val_28",n:'28',name:"Método"},
            {id:"td_val_29",n:'29',name:"Status"},
            {id:"td_val_30",n:'30',name:"Repasses"},
            {id:"td_val_31",n:'31',name:"Rep. Pagos"},
            {id:"td_val_32",n:'32',name:"Rep. Não pagos"},
            {id:"td_val_33",n:'33',name:"Url"}
        ];
        
        this.state.filterColunas = JSON.parse(JSON.stringify(this.state.colunas.map((value) => { return value.id; })));

        var invoice_showItens = cookies.get('invoice_showItens');
        if(invoice_showItens !== undefined){

            if(invoice_showItens === 'true'){ this.state.showItens = true; }
            if(invoice_showItens === 'false'){ this.state.showItens = false; }

        }

        if(cookies.get('invoice_license') !== undefined){

            this.state.license = cookies.get('invoice_license');

        }
        if(cookies.get('invoice_status') !== undefined){

            this.state.status = cookies.get('invoice_status'); 

        }
        
        if(cookies.get('invoice_start_date') !== undefined && cookies.get('invoice_end_date') !== undefined){

            if(moment(cookies.get('invoice_start_date'),'YYYY-MM-DD',true).isValid() === true && moment(cookies.get('invoice_end_date'),'YYYY-MM-DD',true).isValid() === true){

                this.state.startDate = moment(cookies.get('invoice_start_date'),'YYYY-MM-DD');
                this.state.endDate = moment(cookies.get('invoice_end_date'),'YYYY-MM-DD');

            }

        }
 
        if(cookies.get('gerencial_filterColunas') !== undefined){ 
            
            try{
                
                var colus = cookies.get('gerencial_filterColunas');
                colus = colus.split("aa").join("[");
                colus = colus.split("bb").join('"');
                colus = colus.split("cc").join(",");
                colus = colus.split("dd").join("]");
                this.state.filterColunas = JSON.parse(colus);

            }catch(error){}
             
        }

        if(cookies.get('filter_filterProducts') !== undefined){ 
            
            try{
                
                var colus = cookies.get('filter_filterProducts');
                colus = colus.split("aa").join("[");
                colus = colus.split("bb").join('"');
                colus = colus.split("cc").join(",");
                colus = colus.split("dd").join("]");
                this.state.filterProducts = JSON.parse(colus);

            }catch(error){}
             
        }

		window.invoice = this;

	}


    saveCaolumCache(){
         
        var dd2 = JSON.stringify(this.state.filterColunas);
        console.log(dd2);
        console.log(typeof dd2);

        if(typeof dd2 !== "string"){

            console.log('ERRO D1D2');
            return false;

        }

        dd2 = dd2.split("[").join("aa");
        dd2 = dd2.split('"').join("bb");
        dd2 = dd2.split(",").join("cc");
        dd2 = dd2.split("]").join("dd");

        console.log('salcou colunas no cache');
        console.log(dd2);

        cookies.set('gerencial_filterColunas',dd2,{path:'/'});
        
    }

    saveProductCache(){
        
        var dd2 = JSON.stringify(this.state.filterProducts);
        console.log(dd2);
        console.log(typeof dd2);

        if(typeof dd2 !== "string"){

            console.log('ERRO D1D2');
            return false;

        }

        dd2 = dd2.split("[").join("aa");
        dd2 = dd2.split('"').join("bb");
        dd2 = dd2.split(",").join("cc");
        dd2 = dd2.split("]").join("dd");

        console.log('salcou produtos no cache');
        console.log(dd2);

        cookies.set('filter_filterProducts',dd2,{path:'/'});
        
    }


    xlsxComission(){

        var letras = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL'];
  
        var dados = {};
        var wb = JSON.parse('{"SheetNames":["Faturas"],"Sheets":{"Faturas":{} } }');
  
        var segura = 1;
        var segurasl = 0;
        
        var invoices = window.$('.listItems').find('tr');
        
        wb.Sheets['Faturas']['A1'] = {t:'s',v:'#'};
        wb.Sheets['Faturas']['B1'] = {t:'s',v:'ID Repasse'};
        wb.Sheets['Faturas']['C1'] = {t:'s',v:'ID Fatura'};
        wb.Sheets['Faturas']['D1'] = {t:'s',v:'Valor'};
        wb.Sheets['Faturas']['E1'] = {t:'s',v:'Vencimento'};
        wb.Sheets['Faturas']['F1'] = {t:'s',v:'Data de pagamento'};
        wb.Sheets['Faturas']['G1'] = {t:'s',v:'Descrição'};
        wb.Sheets['Faturas']['H1'] = {t:'s',v:'Status'};
        wb.Sheets['Faturas']['I1'] = {t:'s',v:'Id repasse iugu'}; 
        wb.Sheets['Faturas']['J1'] = {t:'s',v:'Conta de origem'}; 
        wb.Sheets['Faturas']['K1'] = {t:'s',v:'Conta de destino'}; 
        wb.Sheets['Faturas']['L1'] = {t:'s',v:'Api json'};
        
        var trs = window.$('.list_comissions').find('tr');
        console.log('trs: '+trs.length);

        for(let index = 0; index < trs.length; index++){
            
            console.log('dd: '+index);
            var el = window.$(trs[index]);
            
            //var inv = {id:el.attr('idc')};

            var sl = 0;
            segura++;
            var n = segura;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_1').text()};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_2').text()};
            sl++;
            
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_invoice').text()};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_3').text()};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_4').text()};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_5').text()};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_6').text()};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_7').text()};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_x9').text()};
            sl++;
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_10').text()};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_11').text()};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_vax_8').text()};
            sl++;

            if(sl > segurasl){ segurasl = sl; }
            var ultimoIndex = n;
  
        }
        
        ultimoIndex++;
        ultimoIndex++;

        if(segurasl === 0){ return false; }
        console.log('Segura sl:'+segurasl);
        wb.Sheets['Faturas']['!ref'] = 'A1:'+letras[segurasl]+''+(ultimoIndex+6);
        
        var name = 'Repasse';

        XLSX.writeFile(wb,name+'.xlsx');
        var dd = moment().format('DD/MM/YYYY HH:mm:ss');


    }

     
    geraXlsx(){

        var letras = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL'];
  
        var dados = {};
        var wb = JSON.parse('{"SheetNames":["Faturas"],"Sheets":{"Faturas":{} } }');
  
        var segura = 1;
        var segurasl = 0;
        
        var invoices = window.$('.listItems').find('tr');

        for(let index = 0; index < invoices.length; index++){
        
            var el = window.$(window.$('.listItems').find('tr')[index]);

            console.log(el.find('.xlsx_nameClient').is(':visible'));

            //var inv = {id:el.attr('idc')};

            var sl = 0;

            segura++;
            var n = segura;

            var cc = 0;
            while(cc <= 50){

                if(el.find('.td_val_'+cc).is(':visible') === true){

                    wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:window.$(".td_"+cc).text()};
                    wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:el.find('.td_val_'+cc).text()};
                    sl++;

                }

                cc++;

            }

            if(sl > segurasl){ segurasl = sl; }
            var ultimoIndex = n;
  
        }
        
        ultimoIndex++;
        ultimoIndex++;

        //wb.Sheets['Faturas'][''+letras[0]+''+(ultimoIndex)+''] = {t:'s',v:'Total taxa cartão:'};
        //wb.Sheets['Faturas'][''+letras[0]+''+(ultimoIndex+1)+''] = {t:'s',v:'Total taxa boleto:'};
        //wb.Sheets['Faturas'][''+letras[0]+''+(ultimoIndex+2)+''] = {t:'s',v:'Total custo transação:'};
        //wb.Sheets['Faturas'][''+letras[0]+''+(ultimoIndex+3)+''] = {t:'s',v:'Total-bruto:'};
        //wb.Sheets['Faturas'][''+letras[0]+''+(ultimoIndex+4)+''] = {t:'s',v:'Desconto:'};
        //wb.Sheets['Faturas'][''+letras[0]+''+(ultimoIndex+5)+''] = {t:'s',v:'Total líquido:'};
        //wb.Sheets['Faturas'][''+letras[0]+''+(ultimoIndex+6)+''] = {t:'s',v:'Total líquido menos repasse:'};


        //wb.Sheets['Faturas'][''+letras[1]+''+(ultimoIndex)+''] = {t:'s',v:window.$(".fim_total_taxa_cartao").html()};
        //wb.Sheets['Faturas'][''+letras[1]+''+(ultimoIndex+1)+''] = {t:'s',v:window.$(".fim_total_taxa_boleto").html()};
        //wb.Sheets['Faturas'][''+letras[1]+''+(ultimoIndex+2)+''] = {t:'s',v:window.$(".fim_total_transacao").html()};
        //wb.Sheets['Faturas'][''+letras[1]+''+(ultimoIndex+3)+''] = {t:'s',v:window.$(".fim_sub_total").html()};
        //wb.Sheets['Faturas'][''+letras[1]+''+(ultimoIndex+4)+''] = {t:'s',v:window.$(".fim_total_desconto").html()};
        //wb.Sheets['Faturas'][''+letras[1]+''+(ultimoIndex+5)+''] = {t:'s',v:window.$(".fim_total_total").html()};
        //wb.Sheets['Faturas'][''+letras[1]+''+(ultimoIndex+6)+''] = {t:'s',v:window.$(".total_liquido_menos_repasse").html()};
         

         
        /*fim_total_total
        fim_total_desconto
        fim_sub_total
        fim_total_transacao
        fim_total_taxa_boleto
        fim_total_taxa_cartao*/ 
        
        if(segurasl === 0){ return false; }
        console.log('Segura sl:'+segurasl)
        wb.Sheets['Faturas']['!ref'] = 'A1:'+letras[segurasl]+''+(ultimoIndex+6);

        var name = "Faturas";

        XLSX.writeFile(wb,name+'.xlsx');
        var dd = moment().format('DD/MM/YYYY HH:mm:ss');
        
    }

    update(){

        if(this.state.loading === true){ return false; }
        this.searchInvoices();

    }

    //para buscar todos os usuarios via api
    async getAllLicense(){

        await api.getAllLicense().then((res) => {

            this.setState({licenses:res.data});

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);

        });
    
    }

     async getCourses(){

        await api.course().then((res) => {

            this.setState({courses:res.data});

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);

        });
    
    }

    //para buscar todos os usuarios via api
    async searchInvoices(){

        if(typeof this.state.startDate !== "object"){ console.log('er1'); return false;}
        if(typeof this.state.endDate !== "object"){ console.log('er2'); return false;}
    
        if(this.state.loading === true){ return false; }
        await this.setState({loading:true});

        var order = this.state.order;
        var status = this.state.status;
        //return false;

        cookies.set('invoice_license',this.state.license,{path:'/'});
        cookies.set('invoice_status',this.state.status,{path:'/'});
        cookies.set('invoice_start_date',this.state.startDate.format('YYYY-MM-DD'),{path:'/'});
        cookies.set('invoice_end_date',this.state.endDate.format('YYYY-MM-DD'),{path:'/'});

        await api.searchInvoices(this.state.license,this.state.order,this.state.status,this.state.search,this.state.limit,this.state.page,this.state.startDate.format('YYYY-MM-DD'),this.state.endDate.format('YYYY-MM-DD'),{items:this.state.filterProducts}).then((res) => {
    
            console.log(res.data);
            this.setState({invoices:res.data.invoices,total:res.data.total,error:'',loading:false});

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);

            if(error.response !== undefined){

                if(error.response.data !== undefined){

                    if(error.response.data.msg !== undefined){ this.setState({error:error.response.data.msg,loading:false}); }

                }

            }

        });
    
    }


    getProducts(){

        api.getProducts().then(async (res) => { 

            this.setState({products:res.data});

        }).catch((error) => {});

    }

	componentDidMount(){

        this.getCourses();
        this.getAllLicense();
        this.getProducts();

        if(this.state.license !== ""){

            this.searchInvoices();

        }

    }
 
	render(){
 
        var license = this.state.licenses.find(x => x.id === parseInt(this.state.license));

        console.log('license');
        console.log(license);

        var valor = 0;
        var desconto = 0;
        var contaLinha = 0;

        var TotaldiscountIugu = 0;
        var TotalfineIugu = 0;
        var TotaltotalCentsIugu = 0;
        var Totaltaxes_paid_cents = 0;
        var conta_repasse = 0;

        var comissions = [];

        var repasses = {pendente:0,pago:0,cancelado:0,reembolsado:0};
        for(let index = 0; index < this.state.invoices.length; index++){
            
            for(let index2 = 0; index2 < this.state.invoices[index].comissions.length; index2++){
                
                var cm = this.state.invoices[index].comissions[index2];
                if(cm.status === "pending"){ repasses.pendente += cm.value; }
                if(cm.status === "paid"){ repasses.pago += cm.value; }
                if(cm.status === "canceled"){ repasses.cancelado += cm.value; }
                if(cm.status === "refunded"){ repasses.reembolsado += cm.value; }
                comissions.push(cm);
                
            }
            
        }
        
        window.comissions = comissions;
        comissions.sort((a,b) => (a.datePaidUnix < b.datePaidUnix) ? 1 : ((b.datePaidUnix < a.datePaidUnix) ? -1 : 0));

        console.log(comissions); 
        /*this.state.invoices.map((value,index) => {
            value.*/

    	return(
		<React.Fragment>

            {

                this.state.colunas.map((value) => {

                    if(this.state.filterColunas.indexOf(value.id) === -1){

                        return(<React.Fragment><style>{' .td_'+value.n+'{ display:none; } .td_val_'+value.n+'{ display:none; } '}</style></React.Fragment>);

                    }else{

                        return(<React.Fragment></React.Fragment>);

                    }

                })

            }

            <style>{' .ReactModal__Overlay{ z-index: 9999; } '}</style>

            <SimpleHeader name="Faturas" way={[{name:"Faturas",url:"/invoice"}]} buttons={
            <React.Fragment>

                <button onClick={ () => {

                    if(this.state.showItens === false){ cookies.set('invoice_showItens','true',{path:'/'}); } 
                    if(this.state.showItens === true){ cookies.set('invoice_showItens','false',{path:'/'}); } 
                    
                    this.setState({showItens:!this.state.showItens}); 
                    
                }} type="button" className="btn btn-secondary btn-sm">{(this.state.showItens === true ? <React.Fragment><i class="far fa-check-square"></i> Esconder itens</React.Fragment>:<React.Fragment><i class="far fa-square"></i> Mostrar itens</React.Fragment>)}</button>

                <button onClick={ () => this.setState({showComissions:true}) } type="button" className="btn btn-secondary btn-sm"><i class="fas fa-hand-holding-usd"></i> Mostrar repasses</button>
                <button onClick={ () => this.setState({openFilterProduct:true}) } type="button" className="btn btn-secondary btn-sm"><i className="fas fa-boxes"></i> Filtrar produto</button>
                <button onClick={ () => this.setState({openFilterColuna:true}) } type="button" className="btn btn-secondary btn-sm"><i className="fas fa-columns"></i> Filtrar Coluna</button>
                <button className="btn btn-secondary btn-sm" onClick={() => this.setState({showinfos:!this.state.showinfos})}><i class="fas fa-chart-pie"></i> Graficos</button>
                <button onClick={() => this.geraXlsx()} type="button" className="btn btn-success btn-sm"><i className="far fa-file-excel"></i> Exportar planilha</button>  
            </React.Fragment>} />

            <style>{' .rdtPicker{ left: -170px !important; }   .input_dda input{ border: none !important; }  .input_dda input{ border: none !important; text-align: center; width: 100px; padding: 0px; height: 25px; } '}</style>
            <style>{' .select_dda{ padding: 0px; box-shadow: rgb(214 214 214) 0px 0px 6px -2px; height: 25px; border: none; } '}</style>

            <div className="mt--6 container-fluid">
                <div className="card">
                <div className="border-0 card-header">
                    <div className="row">
                        <div className="col-6" style={{display:'flex',position:'relative'}}>
                            <h3 className="mb-0">Lista de faturas</h3>
                        </div>
                        <div className="text-right col-6" style={{display:'flex',justifyContent:'flex-end'}}>
                            <select disabled={(this.state.loading)} value={this.state.license} style={{width:'200px',marginRight:'10px'}} onChange={(e) =>{ this.state.license = e.target.value; this.searchInvoices(); this.setState({license:e.target.value,order:'1',total:0,page:0,limit:10,search:"",invoices:[]}); }} className="form-control select_dda" >
                                <option value=""></option>
                                {

                                    this.state.licenses.map((value,index) => {

                                        if(value.status !== 1){ return(<React.Fragment></React.Fragment>) }
                                        return(<option value={value.id} >{value.name}</option>)

                                    })

                                }
                            </select>
                            <select disabled={(this.state.loading)} style={{width:'200px',marginRight:'10px'}} className="form-control select_dda" value={this.state.status} onChange={(e) => { if(e.target.value === "null"){ e.target.value = null; } console.log(e.target.value); this.update(); this.setState({status:e.target.value}); }}>
                                <option value="">Todos</option>
                                {

                                    window.statusInvoices().map((value,index) => {

                                        return(<option value={(value.id === null ? "null":value.id)}>{value.name}</option>)

                                    })

                                }
                            </select>
                            <div style={{display:'flex',justifyContent:'flex-end',boxShadow:'0px 0px 6px -2px #d6d6d6',borderRadius:'6px',alignItems:'center'}} className="input_dda">
                                <ReactDatetime dateFormat={'DD/MM/YYYY'} inputProps={{disabled:this.state.loading}} value={(moment(this.state.startDate,'YYYY-MM-DD',true).isValid() ? moment(this.state.startDate,'YYYY-MM-DD'):this.state.startDate)} onChange={(e) => {      this.state.startDate = e; this.setState({a:''}); console.log(e); this.setState({page:0}); this.update(); }} timeFormat={false} />
                                    <i className="far fa-calendar"></i>
                                <ReactDatetime dateFormat={'DD/MM/YYYY'} inputProps={{disabled:this.state.loading}} value={(moment(this.state.endDate,'YYYY-MM-DD',true).isValid() ? moment(this.state.endDate,'YYYY-MM-DD'):this.state.endDate)} onChange={(e) => {    this.state.endDate = e; this.setState({a:''}); console.log(e); this.setState({page:0}); this.update(); }} timeFormat={false} />
                            </div>
                        </div>
                    </div>
                </div>
                {

                    (this.state.loading === true && this.state.invoices.length !== 0) &&
                    <React.Fragment>
                        <style>{' tbody { opacity:0.5; } .rdtPicker{ display:none !important; } '}</style>
                        <div style={{position:'absolute',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <div><ClipLoader color={'#5e72e4'}/></div>
                        </div>
                    </React.Fragment>

                }
                <div className="table-responsive">
                <ScrollContainer ignoreElements=".text_select" className="scroll-container">
                    <table className="align-items-center table-flush table">
                        <thead className="thead-light">
                            <tr>    
                                <th className="td_1">#</th>
                                <th className="td_2">ID</th>
                                <th className="td_3">Itens</th>
                                <th className="td_4">Descrição</th>
                                <th className="td_5">Observação</th>
                                <th className="td_6">Turma</th>
                                <th className="td_7">Cliente</th>
                                <th className="td_8">Aluno</th>
                                <th className="td_9">Curso</th>
                                <th className="td_10">Valor</th>
                                <th className="td_11">Desconto</th>
                                <th className="td_12">Total</th>
                                <th className="td_13">Valor iugu</th>
                                <th className="td_14">Desconto iugu</th>
                                <th className="td_15">Total iugu</th>
                                <th className="td_16">Total pago iugu</th>
                                <th className="td_17">Multa/Juros iugu</th>
                                <th className="td_18">Taxas iugu</th>
                                <th className="td_19">Data de criação</th>
                                <th className="td_20">Data de vencimento</th>
                                <th className="td_21">Data de pagamento</th>
                                <th className="td_22">Data de cancelamento</th>
                                <th className="td_23">Data de reembolso</th>
                                <th className="td_24">Data de geração</th>
                                <th className="td_25">Data de expiração</th>
                                <th className="td_26">Data de protesto</th>
                                <th className="td_27">Data de contestação</th>
                                <th className="td_28">Método</th>
                                <th className="td_29">Status</th>
                                <th className="td_30">Repasses</th>
                                <th className="td_31">Rep. Pagos</th>
                                <th className="td_32">Rep. Não pagos</th>
                                <th className="td_33">Url</th>
                            </tr>
                        </thead>
                        <tbody className="listItems">
                            {

                                this.state.invoices.map((value,index) => {

                                    var courseName = "";

                                    var countRepasse = value.comissions.length;
                                    var repassePago = 0;
                                    var repasseNaoPago = 0;

                                    for(let index2 = 0; index2 < value.comissions.length; index2++){

                                        var c = value.comissions[index2];
                                        if(c.status === "paid"){

                                            repassePago += c.value;

                                        }else{
                        
                                            repasseNaoPago += c.value;

                                        }
                                        
                                    }

                                    if(value.dataClassRoom !== undefined){

                                        var dataCourse = this.state.courses.find(x => x.id === value.dataClassRoom.course);
                                        if(dataCourse !== undefined){

                                            courseName = dataCourse.name;

                                        }

                                    }
                                    
                                    var discountIugu = undefined;
                                    var fineIugu = undefined;
                                    var totalCentsIugu = undefined;
                                    var taxes_paid_cents = undefined;

                                    if(value.jsonParse !== undefined){

                                        //pega discount
                                        if(value.jsonParse.early_payment_discounts !== undefined){

                                            if(value.jsonParse.early_payment_discounts[0] !== undefined){

                                                discountIugu = value.jsonParse.early_payment_discounts[0].value_cents;
                                                TotaldiscountIugu += value.jsonParse.early_payment_discounts[0].value_cents / 100;

                                            }

                                        }

                                        //pega multa
                                        if(value.jsonParse.fines_on_occurrence_day_cents !== null && value.jsonParse.fines_on_occurrence_day_cents !== "" && typeof value.jsonParse.fines_on_occurrence_day_cents === 'number'){

                                            fineIugu = value.jsonParse.fines_on_occurrence_day_cents;
                                            TotalfineIugu += (fineIugu / 100);

                                        }

                                        //pega o total_cents
                                        if(value.jsonParse.total_cents !== undefined && typeof value.jsonParse.total_cents === "number"){

                                            totalCentsIugu = value.jsonParse.total_cents;
                                            TotaltotalCentsIugu += (totalCentsIugu / 100);

                                        }
                                        
                                        if(value.jsonParse.taxes_paid_cents !== undefined && typeof value.jsonParse.taxes_paid_cents === "number"){

                                            taxes_paid_cents = value.jsonParse.taxes_paid_cents;
                                            Totaltaxes_paid_cents += (taxes_paid_cents / 100);

                                        }

                                    }

                                    contaLinha++;
                                    //console.log('value.dataClient');
                                    //console.log(value.dataClient);
                                    
                                    return(<React.Fragment>
                                            <tr>
                                                <td className="td_val_1">{contaLinha}</td>
                                                <td className="td_val_2"><a href="">{value.id}</a></td>
                                                <td className="td_val_3">{value.itens}</td>
                                                <td className="td_val_4">{value.name}</td>
                                                <td className="td_val_5">{value.description}</td>
                                                <td className="td_val_6">
                                                    {

                                                        (value.dataClassRoom !== undefined && license !== undefined) &&
                                                        <React.Fragment><a href={license.url_front_school+'/client/'+value.dataClient.id+'?registration='+value.dataRegistration.id+'&invoice='+value.id+'&tab=2&listinvoices=1'} target="_blank">{value.dataClassRoom.name}</a></React.Fragment>

                                                    }
                                                </td>
                                                <td className="td_val_7">
                                                    {

                                                        (value.dataClient !== undefined && license !== undefined) &&
                                                        <React.Fragment><a href={license.url_front_school+'/client/'+value.dataClient.id} target="_blank">{value.dataClient.name} {value.dataClient.lastName}</a></React.Fragment>

                                                    }
                                                </td>
                                                <td className="td_val_8">
                                                    {

                                                        (value.dataStudent !== undefined && license !== undefined) &&
                                                        <React.Fragment><a href={license.url_front_school+'/student/'+value.dataStudent.id} target="_blank">{value.dataStudent.name} {value.dataStudent.lastName}</a></React.Fragment>

                                                    }
                                                </td>
                                                <td className="td_val_9" >{courseName}</td>
                                                <td className="td_val_10" dd="value">{window.convertMonyes(value.value,true)}</td>
                                                <td className="td_val_11" dd="discount">{window.convertMonyes(value.discount,true)}</td>
                                                <td className="td_val_12" dd="totalValue">{window.convertMonyes(value.totalValue,true)}</td>

                                                <td className="td_val_13" dd="total_cents">
                                                    {

                                                        (totalCentsIugu !== undefined) &&
                                                        <React.Fragment>{window.convertMonyes(totalCentsIugu / 100,true)}</React.Fragment>

                                                    }
                                                </td>
                                                <td className="td_val_14" dd="discountIugu">
                                                    {

                                                        (discountIugu !== undefined) &&
                                                        <React.Fragment>{window.convertMonyes(discountIugu / 100,true)}</React.Fragment>

                                                    }
                                                </td>
                                                <td className="td_val_15">
                                                    {

                                                        (totalCentsIugu !== undefined && discountIugu !== undefined) &&
                                                        <React.Fragment>{window.convertMonyes(((totalCentsIugu - discountIugu) / 100),true)}</React.Fragment>

                                                    }
                                                </td> 
                                                <td className="td_val_16" dd="totalPaid">
                                                {
                                                    (value.totalPaid !== null) &&
                                                    <React.Fragment>{window.convertMonyes(value.totalPaid,true)}</React.Fragment>
                                                }
                                                </td>
                                                <td className="td_val_17" dd="multa">
                                                {
                                                    (fineIugu !== undefined) &&
                                                    <React.Fragment>{window.convertMonyes(fineIugu / 100,true)}</React.Fragment>
                                                }
                                                </td>
                                                <td className="td_val_18" dd="taxes_paid_cents">
                                                {

                                                    (taxes_paid_cents !== undefined) &&
                                                    <React.Fragment>{window.convertMonyes(taxes_paid_cents / 100,true)}</React.Fragment>

                                                }
                                                </td> 
                                                <td className="td_val_19" v={value.createDate} style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select ">{moment(value.createDate).format('DD/MM/YYYY')}</div></td>
                                                <td className="td_val_20" v={value.due_date} style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select ">{moment(value.due_date).format('DD/MM/YYYY')}</div></td>

                                                <td className="td_val_21" v={value.date_paid} style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select ">{ ( value.date_paid !== null ? moment(value.date_paid).format('DD/MM/YYYY'):'')}</div></td>
                                                <td className="td_val_22" v={value.date_cancellation} style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select ">{ ( value.date_cancellation !== null ? moment(value.date_cancellation).format('DD/MM/YYYY'):'')}</div></td>
                                                <td className="td_val_23" v={value.date_refound} style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select ">{ ( value.date_refound !== null ? moment(value.date_refound).format('DD/MM/YYYY'):'')}</div></td>
                                                
                                                <td className="td_val_24" v={value.date_billing} style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select ">{ ( value.date_billing !== null ? moment(value.date_billing).format('DD/MM/YYYY'):'')}</div></td>
                                                <td className="td_val_25" v={value.date_expired} style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select ">{ ( value.date_expired !== null ? moment(value.date_expired).format('DD/MM/YYYY'):'')}</div></td>
                                                <td className="td_val_26" v={value.date_protested} style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select ">{ ( value.date_protested !== null ? moment(value.date_protested).format('DD/MM/YYYY'):'')}</div></td>
                                                <td className="td_val_27" v={value.date_chargeback} style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select ">{ ( value.date_chargeback !== null ? moment(value.date_chargeback).format('DD/MM/YYYY'):'')}</div></td>

                                                <td className="td_val_28" style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select "><MethodPayment method={value.paymentMethod} installment={value.installment}/></div></td>
                                                <td className="td_val_29" style={{position:'relative'}}><div style={{position:'inherit'}} className="text_select "><StatusInvoice value={value} status={value.status}/></div></td>
                                                
                                                <td className="td_val_30">{countRepasse}</td>
                                                <td className="td_val_31">{window.convertMonyes(repassePago,true)}</td>
                                                <td className="td_val_32">{window.convertMonyes(repasseNaoPago,true)}</td>

                                                <td className="td_val_33">
                                                    {

                                                        (value.jsonParse !== undefined) &&
                                                        <React.Fragment><a target="_blank" href={value.jsonParse.secure_url}>{value.jsonParse.secure_url}</a></React.Fragment>

                                                    }
                                                </td>

                                            </tr>
                                            {

                                                (value.jsonParse !== undefined && this.state.showItens === true) &&
                                                <React.Fragment>
                                                    {

                                                        (value.jsonParse.items.length > 1) &&
                                                        <React.Fragment>
                                                            {

                                                                value.jsonParse.items.map((value2,index2) => {

                                                                    contaLinha++;

                                                                    return(
                                                                        <tr>
                                                                            <td className="td_val_1">{contaLinha}</td>
                                                                            <td className="td_val_2"></td>
                                                                            <td className="td_val_3">{value2.description}</td>
                                                                            <td className="td_val_4"></td>
                                                                            <td className="td_val_5"></td>
                                                                            <td className="td_val_6"></td>
                                                                            <td className="td_val_7"></td>
                                                                            <td className="td_val_8"></td>
                                                                            <td className="td_val_9"></td>
                                                                            <td className="td_val_10">
                                                                                {

                                                                                window.convertMonyes((((value2.price_cents * value2.quantity ) / 100)),true)    

                                                                                }
                                                                            </td>
                                                                            <td className="td_val_11"></td>
                                                                            <td className="td_val_12"></td>
                                                                            <td className="td_val_13"></td>
                                                                            <td className="td_val_14"></td>
                                                                            <td className="td_val_15"></td>
                                                                            <td className="td_val_16"></td>
                                                                            <td className="td_val_17"></td>
                                                                            <td className="td_val_18"></td>
                                                                            <td className="td_val_19"></td>
                                                                            <td className="td_val_20"></td>
                                                                            <td className="td_val_21"></td>
                                                                            <td className="td_val_22"></td>
                                                                            <td className="td_val_23"></td>
                                                                            <td className="td_val_24"></td>
                                                                            <td className="td_val_25"></td>
                                                                            <td className="td_val_26"></td>
                                                                            <td className="td_val_27"></td>
                                                                            <td className="td_val_28"></td>
                                                                            <td className="td_val_29"></td>
                                                                            <td className="td_val_30"></td>
                                                                        </tr>)

                                                                })

                                                            }
                                                        </React.Fragment>

                                                    }

                                                </React.Fragment>

                                            }
                                        </React.Fragment>)

                                })

                            } 
                        </tbody>
                    </table>
                    </ScrollContainer>
                    {

                        (this.state.invoices.length !== 0) &&
                        <Pagination 

                            onChangePage={(data) => { 

                                console.log('onChangePage: '+data);

                                this.state.page = data;
                                this.searchInvoices();
                            
                            }}

                            onChangeLimit={(data) => { 

                                console.log('onChangeLimit: '+data);

                                this.state.page = 0;
                                this.state.limit = data;
                                this.searchInvoices();
                            
                            }}

                            page={this.state.page}
                            limit={this.state.limit}
                            total={this.state.total}

                        />

                    }
                    {

                        //(this.state.loading === true) &&
                        //<React.Fragment><center style={{marginTop:'30px',marginBottom:'20px'}}><BeatLoader color={'#5e72e4'}/></center></React.Fragment>

                    }
                    {

                        (this.state.invoices.length === 0 && this.state.loading === false) &&
                        <React.Fragment>
                            <div style={{display:"flex",justifyContent:'center',marginBottom:'20px',marginTop:'20px'}}>Nenhuma fatura foi encontrada.</div>
                        </React.Fragment>

                    }
                    {

                        (this.state.error !== "" && this.state.loading === false) &&
                        <React.Fragment>
                            <div style={{display:"flex",justifyContent:'center'}}>
                                <div className="alert alert-warning fade show" role="alert" style={{marginTop:'15px'}}><strong>Erro</strong> {this.state.error}</div>
                            </div>
                        </React.Fragment>

                    }
                    {

                        (this.state.license === "") &&
                        <React.Fragment>
                            <div style={{display:"flex",justifyContent:'center'}}>
                                <div className="alert alert-info fade show" role="alert" style={{marginTop:'15px'}}>Você precisa escolher uma licença.</div>
                            </div>
                        </React.Fragment>

                    }
                </div>
            </div>
        </div>

        {

            (this.state.showinfos === true) &&
            <ShowInfo repasses={repasses} extraValues={{TotaldiscountIugu:TotaldiscountIugu,TotalfineIugu:TotalfineIugu,TotaltotalCentsIugu:TotaltotalCentsIugu,Totaltaxes_paid_cents:Totaltaxes_paid_cents}} close={() => this.setState({showinfos:false})} invoices={this.state.invoices} />

        }

        <SlidingPane className="some-custom-class" overlayClassName="some-custom-overlay-class" isOpen={this.state.openFilterColuna} title="Filtrar colunas" onRequestClose={() => { this.setState({openFilterColuna:false}); }} width="400px" >
            
            <center style={{marginBottom:'15px'}}>
                <button onClick={async() => { await this.setState({filterColunas:JSON.parse(JSON.stringify(this.state.colunas.map((value) => { return value.id})))}); this.saveCaolumCache(); }} type="button" class="btn btn-secondary btn-sm">Marcar todos</button>
                <button onClick={async() => { await this.setState({filterColunas:[]}); this.saveCaolumCache(); }} type="button" class="btn btn-secondary btn-sm">Desmarcar todos</button>
            </center>

            {

                this.state.colunas.map((value,index) => {

                    
                    return(
                    <React.Fragment key={'idc_'+index}>
                        <div className="custom-control custom-checkbox">
                            <input onClick={async () => {
                                    
                                var index = this.state.filterColunas.indexOf(value.id);
                                var filterColunas = this.state.filterColunas;

                                if(index === -1){

                                    filterColunas.push(value.id);
                                    
                                }else{

                                    filterColunas.splice(index,1);

                                }
                                
                                await this.setState({filterColunas:filterColunas});
                                this.saveCaolumCache();

                            }} checked={(this.state.filterColunas.indexOf(value.id) !== -1 ? true:false)} className="custom-control-input" id={'custom_dcs33_'+index} type="checkbox" />
                            <label className="custom-control-label" htmlFor={'custom_dcs33_'+index}>{value.name}</label>
                        </div>
                    </React.Fragment>
                    )

                })

            }
        </SlidingPane>

        <SlidingPane className="some-custom-class" overlayClassName="some-custom-overlay-class" isOpen={this.state.openFilterProduct} title="Filtrar produtos" onRequestClose={() => { this.setState({openFilterProduct:false}); }} width="600px" >
            
            <center style={{marginBottom:'15px'}}>
                <button onClick={async() => { await this.setState({filterProducts:JSON.parse(JSON.stringify(this.state.products.map((value) => { return value.id_name})))}); this.saveCaolumCache(); this.update(); }} type="button" class="btn btn-secondary btn-sm">Marcar todos</button>
                <button onClick={async() => { await this.setState({filterProducts:[]}); this.saveProductCache(); this.update(); }} type="button" class="btn btn-secondary btn-sm">Desmarcar todos</button>
            </center>
            
            {

                this.state.products.map((value,index) => {

                    
                    return(
                    <React.Fragment key={'idc_'+index}>
                        <div className="custom-control custom-checkbox">
                            <input disabled={(this.state.loading)} onClick={async () => {
                                    
                                var index = this.state.filterProducts.indexOf(value.id_name);
                                var filterProducts = this.state.filterProducts;

                                if(index === -1){

                                    filterProducts.push(value.id_name);
                                    
                                }else{

                                    filterProducts.splice(index,1);

                                }
                                
                                await this.setState({filterProducts:filterProducts});
                                this.saveProductCache();
                                this.update();

                            }} checked={(this.state.filterProducts.indexOf(value.id_name) !== -1 ? true:false)} className="custom-control-input" id={'custom_dcs313_'+index} type="checkbox" />
                            <label className="custom-control-label" htmlFor={'custom_dcs313_'+index}>{value.name} - ({value.id_name}) </label>
                        </div>
                    </React.Fragment>
                    )

                })

            }
        </SlidingPane>

        {

            (this.state.showComissions === true) &&
            <React.Fragment>
                <Modal className="modal-dialog-centered" isOpen={true} style={{maxWidth:'calc(100% - 120px)'}}>
                    <div className="modal-body p-0" >
                        <Card className="bg-secondary border-0 mb-0">
                        <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">Lista de repasses</h6>
                        
                        <button onClick={() => this.xlsxComission()} type="button" class="btn btn-success btn-sm" style={{position:'absolute',top:'45px'}}><i class="far fa-file-excel"></i> Exportar planilha</button>

                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({showComissions:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                        <CardBody className="px-lg-1">
                            <Form role="form" type="post" onSubmit={ (event) => this.saveUser(event) }>
                            <ScrollContainer ignoreElements=".text_select" className="scroll-container">
                                <table className="align-items-center table-flush table">
                                    <thead className="thead-light">
                                        <tr>    
                                            <th className="">#</th>
                                            <th>Id repasse</th>
                                            <th>Id invoice</th>
                                            <th className="">Valor</th>
                                            <th className="">Vencimento</th>
                                            <th className="">Data de pagamento</th>
                                            <th className="">Descrição</th>
                                            <th className="">Status</th>
                                            <th className="">id repasse iugu</th>
                                            <th className="">Conta origem</th>
                                            <th className="">Conta destino</th>
                                            <th className="">api_json</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list_comissions">
                                        {

                                            <React.Fragment>
                                                {

                                                    comissions.map((value2,index2) => {
                                                        
                                                        conta_repasse++;

                                                        var idiugu = "";
                                                        var destino = "";
                                                        var origem = "";

                                                        try{
                                                            
                                                            var jsondd = JSON.parse(value2.api_json);
                                                            idiugu = jsondd.id;

                                                            console.log(jsondd.receiver);

                                                            destino = jsondd.receiver.name+' - '+jsondd.id;
                                                            origem = jsondd.sender.name+' - '+jsondd.id;

                                                        }catch(error){}
                                                        
                                                        return(
                                                        <tr>
                                                            <td className="td_vax_1">{conta_repasse}</td>
                                                            <td className="td_vax_2">{value2.id}</td>
                                                            <td className="td_vax_invoice">{value2.invoice}</td>
                                                            <td className="td_vax_3">{value2.value}</td>
                                                            <td className="td_vax_4">{moment(value2.billingDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</td>
                                                            <td className="td_vax_5">{(value2.datePaid !== null ? moment(value2.datePaid,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'):'')}</td>
                                                            <td className="td_vax_6">{value2.description}</td>
                                                            <td className="td_vax_7">{value2.status}</td>
                                                            <td className="td_vax_x9">{idiugu}</td>
                                                            <td className="td_vax_10">{origem}</td>
                                                            <td className="td_vax_11">{destino}</td>
                                                            <td className="">{ ((value2.api_json !== "" && value2.api_json !== null) ? <textarea className="form-control td_vax_8" >{value2.api_json}</textarea>:'') }</td>
                                                        </tr>)

                                                    })

                                                }
                                            </React.Fragment>

                                        }
                                    </tbody>
                                </table>
                            </ScrollContainer>
                            {

                                (conta_repasse === 0) &&
                                <React.Fragment>
                                    <div style={{textAlign:'center',marginTop:'25px'}}>Nenhum repasse foi encontrado.</div>
                                </React.Fragment>

                            }
                            </Form>
                        </CardBody>
                        </Card>
                    </div>
                </Modal>
            </React.Fragment>

        }
        
		</React.Fragment>)

    }

}

export default Invoice;