import React, {} from 'react';
import ReactPaginate from 'react-paginate';

export default class Pagination extends React.Component{

  constructor(props) {
 
    super(props);
    this.state = {enableChange:false};

    window.pagination = this;
  
  }
  
  /*

  limit
  page
  total



  */

  render(){

    var totalpages = 1;
    var tot = this.props.total;
    var limit = this.props.limit;

    var div = tot/limit;
    var divfix = div.toFixed(0);
    if(div > divfix){ divfix++; }
    
    console.log(div);
    console.log(divfix);
    totalpages = divfix;

    var mostrandoA = ((this.props.page + 1) * this.props.limit) + 1 - this.props.limit;
    var mostrandoB = this.props.limit * (this.props.page + 1);

    if(mostrandoB > this.props.total){ mostrandoB = this.props.total; }

    return(<React.Fragment>
        <div style={{display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',width:'50%'}}>
                <div class="dataTables_length" style={{display:'flex'}}>
                    <div className="txt_pagination">Mostrando</div>
                    <select value={this.props.limit} onChange={(e) => this.props.onChangeLimit(e.target.value)} class="select_acts_pgs form-control form-control-sm select_acts_pgs">
                        <option value="10">10</option>
                        <option value="30">30</option>
                        <option value="60">60</option>
                        <option value="90">90</option>
                        <option value="120">120</option>
                        <option value="120">250</option>
                        <option value="120">500</option>
                        <option value="120">1000</option>
                        <option value="999999">Todos</option>
                    </select> 
                    <div className="txt_pagination">entradas.</div>
                </div>
                <span class="react-bootstrap-table-pagination-total txt_pagination">&nbsp;Mostrando as linhas {mostrandoA} a {mostrandoB} de {this.props.total}</span>
            </div>
            <div style={{display:'flex',width:'50%',justifyContent:'flex-end',paddingRight:'1.5rem',alignItems:'center'}}>
                <ReactPaginate

                    breakClassName={"pga_breakClassName"}
                    breakLinkClassName={"pga_breakLinkClassName"}
                    disabledClassName={"pga_disabledClassName"}
                    nextLinkClassName={"pga_nextLinkClassName"}
                    previousLinkClassName={"pga_previousLinkClassName"}
                    nextClassName={"pga_nextClassName"}
                    previousClassName={"pga_previousClassName"}
                    activeLinkClassName={"pga_activeLinkClassName"}
                    activeClassName={"pga_activeClassName"}
                    pageLinkClassName={"pga_pageLinkClassName"}
                    pageClassName={"pga_pageClassName"}
                    containerClassName={"pga_containerClassName"}
                    
                    pageCount={totalpages} 
                    pageRangeDisplayed={5} 
                    marginPagesDisplayed={0}

                    onPageChange={(e) => { if(this.state.enableChange === false){ this.state.enableChange = true; return false; } console.log(e.selected); this.props.onChangePage(e.selected); /**/ }}

                    forcePage={this.props.page}
                    initialPage={this.props.page}

                    previousLabel={'<'}
                    nextLabel={'>'}

                />
            </div>
        </div>
    </React.Fragment>);

  }

}

 
 