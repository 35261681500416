import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";

class AdminNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-main navbar-dark navbar-transparent" expand="lg" id="navbar-main" >
          <Container>
        
            <button aria-controls="navbar-collapse" aria-expanded={false} aria-label="Toggle navigation" className="navbar-toggler" data-target="#navbar-collapse" data-toggle="collapse" id="navbar-collapse" type="button" >
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse className="navbar-custom-collapse" navbar toggler="#navbar-collapse" >
              <div className="navbar-collapse-header">
                <Row>
         
                  <Col className="collapse-close" xs="12">
                    <button style={{position:'absolute',borderBottom:'1px solid rgba(0, 0, 0, 0)',left:'calc(100% - 25px)',top:'-12px'}} aria-controls="navbar-collapse" aria-expanded={false} aria-label="Toggle navigation" className="navbar-toggler" data-target="#navbar-collapse" data-toggle="collapse" id="navbar-collapse" type="button" >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
            
              <hr className="d-lg-none" />
            
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;