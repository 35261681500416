import React from "react";
import ReactDOM from "react-dom";
// react library for routing

import App from './App.js';

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";

// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/quill/dist/quill.core.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v1.0.0";

import "./assets/css/animate.css";
import "./assets/css/pagination.css";
import "./assets/css/custom.css";
import "./assets/css/hover.css";

/*
<option value="0">Texto</option>
<option value="1">Vídeo</option>
<option value="2">Audio</option>
<option value="3">Quiz</option>
<option value="4">Gamepad</option>
*/

window.iconsLesson = ['ni ni-caps-small','ni ni-button-play','ni ni-note-03','ni ni-paper-diploma','ni ni-controller'];

//Campo dos usuários
window.clientExtraField = [
    
    {id:1,name:"Texto"},
    {id:2,name:"Inteiro"},
    {id:3,name:"Data"},
    {id:4,name:"Dinheiro"},
    {id:5,name:"Lista"},
    {id:6,name:"RG"},
    {id:7,name:"CPF"},
    {id:8,name:"Telefone ou Celular"},
    {id:9,name:"CEP"},
    {id:10,name:"Texto longo"},
    {id:11,name:"Arquivo"}

];

ReactDOM.render(<App/>,document.getElementById("root"));