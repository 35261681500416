import React from "react";
import {UncontrolledAlert,CardBody,FormGroup,Input,Button,Card,CardHeader,Progress,Container,Row,Col,Modal,Alert,InputGroup} from "reactstrap";
import api from "./../../../Api.js";

const Swal = require('sweetalert2');

class Type extends React.Component {

	constructor(props){

        super(props);

        this.saveCourseType = this.saveCourseType.bind(this);
        this.postCourseType = this.postCourseType.bind(this);
        this.putCourseType = this.putCourseType.bind(this);
        this.deleteCourseType = this.deleteCourseType.bind(this);
 
	}
  
    saveCourseType(){

		if(this.state.dialogCourseType.id === undefined){ this.postCourseType(); }else{ this.putCourseType(); }
		
	}

    postCourseType(){

		api.postCourseType(this.state.dialogCourseType).then(() => {

			this.setState({openNewCourseType:false});
			this.props.getCouseTypes();

		}).catch((error) => {

			this.setState({errorNewCourseType:error.response.data.msg});

		});

	}

	putCourseType(){

		api.putCourseType(this.state.dialogCourseType.id,this.state.dialogCourseType).then(() => {
			
			this.setState({openNewCourseType:false});
			this.props.getCouseTypes();

		}).catch((error) => {

			this.setState({errorNewCourseType:error.response.data.msg});

		});

	}

	deleteCourseType(id){

        Swal.fire({
            title: 'Tem certeza?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
          }).then((result) => {
      
            if(result.value){
                
                api.deleteCourseType(id).then((res) => {
       
                    this.props.getCouseTypes();

                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });
                 
      
            }
      
        });

    }

	render(){


        //12 de 290
        //12 de 341

        this.state = this.props.state;
        this.setState = this.props.setState;

    	return(
		<React.Fragment>
            <Modal className="modal-dialog-centered" contentClassName="bg-gradient-danger" isOpen={this.state.openNewCourseType} >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">{(this.state.dialogCourseType.id === undefined ? 'Cadastrar tipo de curso':'Editar tipo de curso')}</h6>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({dialogCourseType:{name:""},openNewCourseType:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <CardBody className="px-lg-4">
                        {

                            (this.state.errorNewCourseType !== undefined && this.state.errorNewCourseType !== "") &&
                            <Col md={12}>
                                <Alert color="danger">
                                    <strong>Ops!</strong> {this.state.errorNewCourseType}
                                </Alert>
                            </Col>

                        }
                        
                        <form onSubmit={(e) => { console.log('ds1'); e.preventDefault(); this.saveCourseType(); }}>
                            <Col md={12}>
                                <h5>Nome:</h5>
                                <FormGroup className={'mb-3'} >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <input className="form-control" type="text" onChange={(e) => { this.setState({dialogCourseType:{...this.state.dialogCourseType,name:e.target.value}}); }} value={ this.state.dialogCourseType.name } />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <button type="submit" className="btn btn-primary" style={{width:'100%'}}>{(this.state.dialogCourseType.id === undefined ? 'Cadastrar tipo':'Salvar alterações')}</button>
                            </Col>
                        </form>
                    </CardBody>
                    </Card>
                </div>	 
            </Modal>

            <Modal className="modal-dialog-centered" contentClassName="bg-gradient-danger" isOpen={this.state.openModalEditCourseType} style={{maxWidth:'1100px'}}>
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">Cagegorias de cursos</h6>
                        <button onClick={() => { this.setState({openNewCourseType:true,dialogModality:{name:"",errorNewCourseType:""}}); }} className="btn btn-sm btn-secondary" style={{position:'absolute',top:'45px'}}>Criar tipo de curso</button>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalEditCourseType:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <CardBody className="px-lg-5">
                                
                        <table className="align-items-center table-flush table">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Nome</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    this.state.cousetypes.map((value,index) => {

                                        return(
                                        <React.Fragment>
                                            <tr>
                                                <th>{index+1}</th>
                                                <th>{value.name}</th>
                                                <th>
                                                    <button onClick={() => { this.setState({openNewCourseType:true,dialogCourseType:value,errorNewCourseType:""}); }} className="btn-secondary btn-round btn-icon btn btn-sm"><i class="far fa-edit"></i> Editar</button>
                                                    <button onClick={() => { this.deleteCourseType(value.id); }} className="btn-danger btn-round btn-icon btn btn-sm"><i class="fas fa-trash"></i></button>
                                                </th>
                                            </tr>	
                                        </React.Fragment>)

                                    })

                                }
                            </tbody>
                        </table>
                        {

                            (this.state.cousetypes.length === 0) &&
                            <center style={{margin:'20px'}}>Nenhum tipo de curso foi encontrado.</center>

                        }
                    </CardBody>
                    </Card>
                </div>	 
            </Modal>

		</React.Fragment>)

    }
}

export default Type;