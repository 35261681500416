import React from "react";
import {} from "reactstrap";
import CardsHeader from "../../../components/Headers/CardsHeader.jsx";
import ListItens from "./ListItens.jsx";
import {FormGroup,InputGroupAddon,InputGroupText,InputGroup,Col,Row} from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";

class Dashboard extends React.Component {
  
  constructor(props){

    super(props);
    this.state = {
      startDate:moment( moment().format('YYYY-MM')+'-01' ,'YYYY-MM-DD'),
      endDate:moment( moment().format('YYYY')+'-'+moment().format('MM')+'-'+moment( ).endOf('month'),'YYYY-MM-DD'),
    };
  
 

  }

 

  componentWillMount(){}

  componentDidMount(){}

 

  render(){


    console.log('startDate:'+this.state.startDate);
    console.log('endDate:'+this.state.endDate)

    return(
      <React.Fragment>
        <style>{' .rdt input{     border: 0px solid #dee2e6 !important; } '}</style>
        <div className="row" style={{position:'absolute',zIndex:'9',width:'100%',display:'flex',justifyContent:'center',top:'90px',alignItems:'center'}}>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',background:'#fff',borderRadius:'10px',}}>
            <div>
              <ReactDatetime inputProps={{placeholder:""}} timeFormat={false} value={this.state.startDate} onChange={(e) => { if(typeof e === "object"){ this.setState({startDate:e}); } console.log(e); }}/>
            </div>
            <i class="far fa-calendar-alt" style={{backgroundColor:'white',padding:'6px'}}></i>
            <div>
              <ReactDatetime inputProps={{placeholder:""}} timeFormat={false} value={this.state.endDate} onChange={(e) => { if(typeof e === "object"){ this.setState({endDate:e}); } console.log(e); }}/>
            </div>
          </div>
        </div>
        <CardsHeader startDate={this.state.startDate.format('YYYY-MM-DD')} endDate={this.state.endDate.format('YYYY-MM-DD')} />
        <div className="mt--6 container-fluid">
          <div className="row">
            <div className="col-md-8">
              <ListItens startDate={this.state.startDate.format('YYYY-MM-DD')} endDate={this.state.endDate.format('YYYY-MM-DD')}/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

  }
}

export default Dashboard;
