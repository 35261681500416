import React from "react";
import {UncontrolledAlert,CardBody,FormGroup,Input,Button,Card,CardHeader,Progress,Container,Row,Col,Modal,Alert,InputGroup} from "reactstrap";
import api from "./../../../Api.js";

const Swal = require('sweetalert2');

class Category extends React.Component {

	constructor(props){

        super(props);
        
        this.saveCategory = this.saveCategory.bind(this);
        this.postCategory = this.postCategory.bind(this);
        this.putCategory = this.putCategory.bind(this);
        this.deleteCourseCategory = this.deleteCourseCategory.bind(this);
        
	}

    async saveCategory(){

		if(this.state.dialogCategory.id === undefined){ this.postCategory(); }else{ this.putCategory(); }

	}

    postCategory(){

		api.postCategory(this.state.dialogCategory).then(() => {

			this.setState({openNewCourseCategory:false});
			this.props.getCategorys();

		}).catch((error) => {

			this.setState({errorNewModality:error.response.data.msg});

		});

	}

	putCategory(){

		api.putCategory(this.state.dialogCategory.id,this.state.dialogCategory).then(() => {
			
			this.setState({openNewCourseCategory:false});
			this.props.getCategorys();

		}).catch((error) => {

			this.setState({errorNewModality:error.response.data.msg});

		});

	}
	 
    deleteCourseCategory(id){

        Swal.fire({
            title: 'Tem certeza?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
          }).then((result) => {
      
            if(result.value){
                
                api.deleteCategory(id).then((res) => {
       
                    this.props.getCategorys();

                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });
                 
      
            }
      
        });

    }

	render(){
        

        this.state = this.props.state;
        this.setState = this.props.setState;

    	return(
		<React.Fragment> 

            <Modal className="modal-dialog-centered" contentClassName="bg-gradient-danger" isOpen={this.state.openNewCourseCategory} >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">{(this.state.dialogCategory.id === undefined ? 'Cadastrar categoria':'Editar modalidade')}</h6>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({dialogCategory:{name:"",description:""},openNewCourseCategory:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <CardBody className="px-lg-4">
                        {

                            (this.state.errorNewCategory !== undefined && this.state.errorNewCategory !== "") &&
                            <Col md={12}>
                                <Alert color="danger">
                                    <strong>Ops!</strong> {this.state.errorNewCategory}
                                </Alert>
                            </Col>

                        }
                        
                        <form onSubmit={(e) => { console.log('ds1'); e.preventDefault(); this.saveCategory(); }}>
                            <Col md={12}>
                                <h5>Nome:</h5>
                                <FormGroup className={'mb-3'} >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <input className="form-control" type="text" onChange={(e) => { this.setState({dialogCategory:{...this.state.dialogCategory,name:e.target.value}}); }} value={ this.state.dialogCategory.name } />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <button type="submit" className="btn btn-primary" style={{width:'100%'}}>{(this.state.dialogCategory.id === undefined ? 'Cadastrar categoria':'Salvar alterações')}</button>
                            </Col>
                        </form>
                    </CardBody>
                    </Card>
                </div>	 
            </Modal>
            <Modal className="modal-dialog-centered" contentClassName="bg-gradient-danger" isOpen={this.state.openModalEditCourseCategory} style={{maxWidth:'1100px'}}>
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">Tipos de categoria</h6>
                        <button onClick={() => { this.setState({openNewCourseCategory:true,dialogCategory:{name:"",errorNewCourseType:""}}); }} className="btn btn-sm btn-secondary" style={{position:'absolute',top:'45px'}}>Criar categoria</button>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalEditCourseCategory:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <CardBody className="px-lg-5">
                                
                        <table className="align-items-center table-flush table">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Nome</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    this.state.cousecategorys.map((value,index) => {

                                        return(
                                        <React.Fragment>
                                            <tr>
                                                <th>{index+1}</th>
                                                <th>{value.name}</th>
                                                <th>
                                                    <button onClick={() => { this.setState({openNewCourseCategory:true,dialogCategory:value,errorNewCourseCategory:""}); }} className="btn-secondary btn-round btn-icon btn btn-sm"><i class="far fa-edit"></i> Editar</button>
                                                    <button onClick={() => { this.deleteCourseCategory(value.id); }} className="btn-danger btn-round btn-icon btn btn-sm"><i class="fas fa-trash"></i></button>
                                                </th>
                                            </tr>	
                                        </React.Fragment>)

                                    })

                                }
                            </tbody>
                        </table>
                        {

                            (this.state.cousecategorys.length === 0) &&
                            <center style={{margin:'20px'}}>Nenhuma categoria de curso foi encontrado.</center>

                        }
                    </CardBody>
                    </Card>
                </div>	 
            </Modal>

		</React.Fragment>)

    }
}

export default Category;