import React,{useState,useEffect} from 'react';
import { ToastProvider,useToasts } from 'react-toast-notifications';

const novaoportunidade = new Audio('/sounds/juntos-607.mp3'); novaoportunidade.loop = false;
const playnovaoportunidade = () => { novaoportunidade.time = 0; novaoportunidade.play(); }

//marcou como ganha
const oportunidadeganha = new Audio('/sounds/oringz-w427-371.mp3'); oportunidadeganha.loop = false;
const playoportunidadeganha= () => { oportunidadeganha.time = 0; oportunidadeganha.play(); }

//marcou como perdida
const oportunidadeperdida = new Audio('/sounds/time-is-now-585.mp3'); oportunidadeperdida.loop = false;
const playoportunidadeperdida = () => { oportunidadeperdida.time = 0; oportunidadeperdida.play(); }

//marcou como aberta OU nova oportunidade
const oportunidadeaberta = new Audio('/sounds/pristine-609.mp3'); oportunidadeaberta.loop = false;
const playoportunidadeaberta = () => { oportunidadeaberta.time = 0; oportunidadeaberta.play(); }
 

//moveu oportunidade
const moveuoportunidade = new Audio('/sounds/you-know-508.mp3'); moveuoportunidade.loop = false;
const playmoveuoportunidade = () => { moveuoportunidade.time = 0; moveuoportunidade.play(); }
 

function Crm(props){

    const { addToast } = useToasts()
    window.addToast = addToast;

    const processData = ((data) => {

        console.log(data);
        
        if(parseInt(data.type2) === 3){
 
            window.addToast(<div>

                <div><strong>CRM - {data.license.name}</strong></div>
                <div style={{marginTop:'10px'}}>Oportunidade: <strong>{data.opportunity.name} {data.opportunity.email}</strong></div>

                <br/>

                    <div>

                        {

                            Object.keys(data.opportunity.values).map((keyName,i) => (

                                <div><strong>{keyName}</strong>: {data.opportunity.values[keyName]}</div>

                            ))

                        }

                    </div>

                <br/>

                {

                    (data.user !== undefined) &&
                    <div style={{marginTop:'10px'}}>Cadastrado pelo usuário: {data.user.name} {data.user.lastName}</div>

                }
                {

                    (data.user === undefined) &&
                    <div style={{marginTop:'10px'}}>Veio pelo formulário externo.</div>

                }

                <div>Ação: nova oportunidade recebida.</div>
                <div>
                    <button onClick={() => {
                        
                        window.setGlobalIframe({open:true,src:data.license.url_front_school+'/board/'+data.funnel.id+'?hiddenbar=1&openop='+data.opportunity.id+'&sessionCenter='+sessionStorage.getItem("session")});

                    }} style={{marginTop:'5px'}} className="btn btn-sm btn-secondary"><i className="fas fa-search"></i> Ver oportunidade</button>
                </div>
                
            </div>,{appearance:'info',autoDismiss:true})
            playnovaoportunidade();

        }

        //alterou dados
        if(parseInt(data.type2) === 6){
 
            window.addToast(<div>

                <div><strong>CRM - {data.license.name}</strong></div>
                
                <div style={{marginTop:'10px'}}>Oportunidade: {data.opportunity.name} {data.opportunity.email}</div>
                {

                    (data.user !== undefined) &&
                    <div style={{marginTop:'10px'}}>Usuário: {data.user.name} {data.user.lastName}</div>

                }
                
                <div>Ação: alterou os dados da tarefa <strong>"{data.assignment.name}"</strong>.</div>
                <div>
                    <button onClick={() => {
                        
                        window.setGlobalIframe({open:true,src:data.license.url_front_school+'/board/'+data.funnel.id+'?hiddenbar=1&openop='+data.opportunity.id+'&sessionCenter='+sessionStorage.getItem("session")});

                    }} style={{marginTop:'5px'}} className="btn btn-sm btn-secondary"><i className="fas fa-search"></i> Ver oportunidade</button>
                </div>
                
            </div>,{appearance:'info',autoDismiss:true})
            playmoveuoportunidade();

        }
        
        //marcou como concluida
        if(parseInt(data.type2) === 8){

            window.addToast(<div>

                <div><strong>CRM - {data.license.name}</strong></div>
                
                <div style={{marginTop:'10px'}}>Oportunidade: {data.opportunity.name} {data.opportunity.email}</div>
                {

                    (data.user !== undefined) &&
                    <div style={{marginTop:'10px'}}>Usuário: {data.user.name} {data.user.lastName}</div>

                }
                
                <div>Ação: Marcou a tarefa <strong>"{data.assignment.name}"</strong> como <strong>concluída</strong>.</div>
                <div>
                    <button onClick={() => {
                        
                        window.setGlobalIframe({open:true,src:data.license.url_front_school+'/board/'+data.funnel.id+'?hiddenbar=1&openop='+data.opportunity.id+'&sessionCenter='+sessionStorage.getItem("session")});

                    }} style={{marginTop:'5px'}} className="btn btn-sm btn-secondary"><i className="fas fa-search"></i> Ver oportunidade</button>
                </div>
                
            </div>,{appearance:'info',autoDismiss:true})
            playmoveuoportunidade();

        }
        
        //marcou como não concluida
        if(parseInt(data.type2) === 9){

            window.addToast(<div>

                <div><strong>CRM - {data.license.name}</strong></div>
                
                <div style={{marginTop:'10px'}}>Oportunidade: {data.opportunity.name} {data.opportunity.email}</div>
                {

                    (data.user !== undefined) &&
                    <div style={{marginTop:'10px'}}>Usuário: {data.user.name} {data.user.lastName}</div>

                }
                
                <div>Ação: Marcou a tarefa <strong>"{data.assignment.name}"</strong> como <strong>não concluída</strong>.</div>
                <div>
                    <button onClick={() => {
                        
                        window.setGlobalIframe({open:true,src:data.license.url_front_school+'/board/'+data.funnel.id+'?hiddenbar=1&openop='+data.opportunity.id+'&sessionCenter='+sessionStorage.getItem("session")});

                    }} style={{marginTop:'5px'}} className="btn btn-sm btn-secondary"><i className="fas fa-search"></i> Ver oportunidade</button>
                </div>
                
            </div>,{appearance:'info',autoDismiss:true})
            playmoveuoportunidade();

        }


 











        //alterou dados
        if(parseInt(data.type2) === 4){
 
            window.addToast(<div>

                <div><strong>CRM - {data.license.name}</strong></div>
                
                <div style={{marginTop:'10px'}}>Oportunidade: {data.opportunity.name} {data.opportunity.email}</div>
                {

                    (data.user !== undefined) &&
                    <div style={{marginTop:'10px'}}>Usuário: {data.user.name} {data.user.lastName}</div>

                }
                
                <div>Ação: Fez alterações nos dados.</div>
                <div>
                    <button onClick={() => {
                        
                        window.setGlobalIframe({open:true,src:data.license.url_front_school+'/board/'+data.funnel.id+'?hiddenbar=1&openop='+data.opportunity.id+'&sessionCenter='+sessionStorage.getItem("session")});

                    }} style={{marginTop:'5px'}} className="btn btn-sm btn-secondary"><i className="fas fa-search"></i> Ver oportunidade</button>
                </div>
                
            </div>,{appearance:'info',autoDismiss:true})
            playmoveuoportunidade();

        }

        //marcou como ganha
        if(parseInt(data.type2) === 10){

            window.addToast(<div>

                <div><strong>CRM - {data.license.name}</strong></div>
                
                <div style={{marginTop:'10px'}}>Oportunidade: {data.opportunity.name} {data.opportunity.email}</div>

                <div style={{marginTop:'10px'}}>Usuário: {data.user.name} {data.user.lastName}</div>
                <div>Ação: Marcou como <strong>ganha</strong></div>
                <div>
                    <button onClick={() => {
                        
                        window.setGlobalIframe({open:true,src:data.license.url_front_school+'/board/'+data.funnel.id+'?hiddenbar=1&openop='+data.opportunity.id+'&sessionCenter='+sessionStorage.getItem("session")});

                    }} style={{marginTop:'5px'}} className="btn btn-sm btn-success"><i className="fas fa-search"></i> Ver oportunidade</button>
                </div>
                
            </div>,{appearance:'success',autoDismiss:true})
            playoportunidadeganha();

        }

        //marcou como perdida
        if(parseInt(data.type2) === 11){

            window.addToast(<div>

                <div><strong>CRM - {data.license.name}</strong></div>
                
                <div style={{marginTop:'10px'}}>Oportunidade: {data.opportunity.name} {data.opportunity.email}</div>

                <div style={{marginTop:'10px'}}>Usuário: {data.user.name} {data.user.lastName}</div>
                <div>Ação: Marcou como <strong>perdida</strong></div>
                <div>
                    <button onClick={() => {
                        
                        window.setGlobalIframe({open:true,src:data.license.url_front_school+'/board/'+data.funnel.id+'?hiddenbar=1&openop='+data.opportunity.id+'&sessionCenter='+sessionStorage.getItem("session")});

                    }} style={{marginTop:'5px'}} className="btn btn-sm btn-secondary"><i className="fas fa-search"></i> Ver oportunidade</button>
                </div>
                
            </div>,{appearance:'error',autoDismiss:true})
            playoportunidadeperdida();

        }

        //marcou como aberta
        if(parseInt(data.type2) === 12){

            window.addToast(<div>

                <div><strong>CRM - {data.license.name}</strong></div>
                
                <div style={{marginTop:'10px'}}>Oportunidade: {data.opportunity.name} {data.opportunity.email}</div>
                {

                    (data.user !== undefined) &&
                    <div style={{marginTop:'10px'}}>Usuário: {data.user.name} {data.user.lastName}</div>

                }
                
                <div>Ação: Marcou como <strong>aberta</strong></div>
                <div>
                    <button onClick={() => {
                        
                        window.setGlobalIframe({open:true,src:data.license.url_front_school+'/board/'+data.funnel.id+'?hiddenbar=1&openop='+data.opportunity.id+'&sessionCenter='+sessionStorage.getItem("session")});

                    }} style={{marginTop:'5px'}} className="btn btn-sm btn-secondary"><i className="fas fa-search"></i> Ver oportunidade</button>
                </div>
                
            </div>,{appearance:'info',autoDismiss:true})
            playoportunidadeaberta();

        }

        //moveu oportunidade
        if(parseInt(data.type2) === 1){
            
            window.addToast(<div>

                <div><strong>CRM - {data.license.name}</strong></div>
                
                <div style={{marginTop:'10px'}}>Oportunidade: {data.opportunity.name} {data.opportunity.email}</div>

                <div style={{marginTop:'10px'}}>Usuário: {data.user.name} {data.user.lastName}</div>
                <div>Ação: Moveu de <strong>{data.cm2.name}</strong> para <strong>{data.cm1.name}</strong> </div>
                <div>
                    <button onClick={() => {
                        
                        window.setGlobalIframe({open:true,src:data.license.url_front_school+'/board/'+data.funnel.id+'?hiddenbar=1&openop='+data.opportunity.id+'&sessionCenter='+sessionStorage.getItem("session")});

                    }} style={{marginTop:'5px'}} className="btn btn-sm btn-primary"><i className="fas fa-search"></i> Ver oportunidade</button>
                </div>
                
            </div>,{appearance:'info',autoDismiss:true})
            playmoveuoportunidade();

        }


    });

    useEffect(() => { 
  
        window.socket.on('crm',processData);
 
        return(() => { window.socket.off('crm',processData); });

    },[]);

    return(<React.Fragment>
        
   
    </React.Fragment>);

}

export default Crm;