import React from "react";
import api from "./../../Api.js";
import moment from "moment";
import classnames from "classnames";
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,CardHeader,Table,Container,Row,Col,UncontrolledTooltip} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import CourseLicenseAvailable from "./modal/CourseLicenseAvailable.jsx";
import ModalSchoolDatas from "./modal/ModalSchoolDatas.jsx";
import ActionServer from "./modal/ActionServer.jsx";  

const fieldLicense = [
  
  //{nameField:"Razão social",name:"reason",classname:"col-md-2",type:"text",icon:""},
  //{nameField:"cnpj",name:"cnpj",classname:"col-md-2",type:"text",icon:"fas fa-building"},
  {nameField:"Logradouro",name:"publiPclace",classname:"col-md-2",type:"text",icon:"fas fa-map-marker-alt"},
  {nameField:"Número",name:"number",classname:"col-md-2",type:"text",icon:"fas fa-map-marker-alt"},
  {nameField:"Complemento",name:"complement",classname:"col-md-2",type:"text",icon:"fas fa-map-marker-alt"},
  {nameField:"Bairro",name:"neighborhood",classname:"col-md-2",type:"text",icon:"fas fa-map-marker-alt"},
  {nameField:"Cidade",name:"city",classname:"col-md-2",type:"text",icon:"fas fa-map-marker-alt"},
  {nameField:"Estado",name:"state",classname:"col-md-2",type:"text",icon:"fas fa-map-marker-alt"},
  {nameField:"CEP",name:"zipCode",classname:"col-md-2",type:"text",icon:"fas fa-map-marker-alt"},
  {nameField:"E-mail",name:"email",classname:"col-md-2",type:"text",icon:"fas fa-at"},
  {nameField:"Nome do operador",name:"operatorName",classname:"col-md-2",type:"text",icon:"fas fa-user-tie"},
  {nameField:"E-mail do operador",name:"operatorEmail",classname:"col-md-2",type:"text",icon:"fas fa-at"},

  {nameField:"Telefone",name:"phone",classname:"col-md-2",type:"text",icon:"fas fa-phone-volume"},
  {nameField:"Whatsapp",name:"whatsapp",classname:"col-md-2",type:"text",icon:"fas fa-phone-volume"},

  //{nameField:"IUGU API",name:"iugu_api",classname:"col-md-2",type:"text",icon:"fab fa-keycdn"},
  
  {nameField:"Banco",name:"bank",classname:"col-md-2",type:"text",icon:"fas fa-piggy-bank"},
  {nameField:"Agencia",name:"bank_ag",classname:"col-md-2",type:"text",icon:"fas fa-piggy-bank"},
  {nameField:"Tipo de conta",name:"account_type",classname:"col-md-2",type:"text",icon:"fas fa-piggy-bank"},
  {nameField:"Número da conta",name:"bank_cc",classname:"col-md-2",type:"text",icon:"fas fa-piggy-bank"},
  {nameField:"Hostname",name:"hostname",classname:"col-md-2",type:"text",icon:"fas fa-piggy-bank"},
  
];

class License extends React.Component {

  constructor(props){

      super(props);
      
      this.state = {loadComplete:false,openDialogLicenseCreate:false,license:[],openDialogLicenseInfosModal:false,

        dialog:{name:"",license:""},
        pricerange:[]

      };

      this.removerLicense = this.removerLicense.bind(this);
      this.saveLicense = this.saveLicense.bind(this);
      this.editLicense = this.editLicense.bind(this);
      this.listURL = this.listURL.bind(this);
      this.putLicense = this.putLicense.bind(this);
      this.newLicense = this.newLicense.bind(this);
      this.postLicense = this.postLicense.bind(this);
      this.removeLicenseApi = this.removeLicenseApi.bind(this);
      this.editCourseLicense = this.editCourseLicense.bind(this);
      this.getPriceRange = this.getPriceRange.bind(this);

      window.license = this;

  }

  getPriceRange(){

    api.getPriceRange().then(async(res) => { 

      this.setState({pricerange:res.data});

    }).catch((error) => {});

  }


  //para buscar todos os usuarios via api
  async getAllLicense(){

      await api.getAllLicense().then((res) => {

        this.setState({license:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });
    
  }

  //par apegar todos os levels de acesso
  async getAllLevelAcess(){

      await api.levelAcess().then((res) => {
        
        this.setState({levelAcess:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }
 

  //para atualizar os dados do usuario no sistema.
  async putLicense(){

      var data = JSON.parse(JSON.stringify(this.state.dialog));
      data.expirationDate = moment(data.expirationDate,'DD/MM/YYYY').format('YYYY-MM-DD')

      console.log(data);

      await api.putLicense(this.state.editLicense,data).then((res) => {
          
        this.setState({openDialogLicenseModal:false});
        
        this.setState({});
        
        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">A licença foi atualizada com sucesso.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);
  
        var state = this.state.license;

        var index  = state.map(function(x) {return x.id; }).indexOf(this.state.editLicense);
        state[index] = res.data.license;
        this.setState({license:state});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

          if(error.response.status === 400){
          this.setState({userError:""});
          this.setState({userError:error.response.data.msg});

        }

      });

  }

  //para cadastrar o usuario no banco de dados
  async postLicense(){

      var data = JSON.parse(JSON.stringify(this.state.dialog));
      data.expirationDate = moment(data.expirationDate,'DD/MM/YYYY').format('YYYY-MM-DD');

      await api.postLicense(data).then((res) => {

        this.setState({openDialogLicenseModal:false,loading:false});
        this.getAllLicense();

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">A licença foi cadastrada.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

         if(error.response.status === 400){

          this.setState({userError:""});
          this.setState({userError:error.response.data.msg});

        }

      });

  }

  //para editar os dados do usuario
  editLicense(id){
      
      this.setState({editLicense:id,openDialogLicenseModal:true,textDialogButton:"Salvar alterações",textDialog:"Editar licença",userError:""});
      var schools = this.state.license;

      for(let i = 0; i < schools.length; i++){
    
        if(parseInt(schools[i].id) === parseInt(id)){

          var license = JSON.parse(JSON.stringify(schools[i]));

          license.expirationDate  = moment(license.expirationDate,'YYYY-MM-DD').format('DD/MM/YYYY');
          this.setState({dialog:license});
         
        }

      } 
       

  }

  //Para cadastrar um usuário novo
  newLicense(){

    this.setState({editLicense:"",openDialogLicenseModal:true,textDialogButton:"Nova licença",textDialog:"Cadastrar"});
    this.setState({dialog:{

      url_front_school:"",
      url_back_school:"",
      url_front_engine:"",
      url_back_engine:"",
      
      license:"",serverIP:"",expirationDate:"",name:"",status:"1",url:"",userLimit:"",currencyType:"BRL",
    
    
      fantasyName:"",
      stateRegistration:"",
      municipalRegistration:"",
      focusnfe_regime:"",
      focusnfe_hom:"",
      focusnfe_prod:"",
      municipalCod:"",

      metaBase_use:""

    }});

  }


  //quando clicar no botão
  saveLicense(e){

      e.preventDefault();
  
      if(this.state.editLicense === ""){ this.postLicense(); }else{ this.putLicense(); }

  }
 

  //para remover um usuario do sistema
  removerLicense(id){

    this.setState({openDialogRemoveLicense:true,licenseDeletion:id});

  }

  //Para abrir o modal e listar a URLS dessa escola
  listURL(id){

    this.setState({openModalListUrl:true});
    var schools = this.state.license;

    for(let i = 0; i < schools.length; i++){
    
      if(parseInt(schools[i].id) === parseInt(id)){

        var modalListUrl = {};
        modalListUrl.url_front_school = schools[i].url_front_school;
        modalListUrl.url_back_school = schools[i].url_back_school;
        modalListUrl.url_front_engine = schools[i].url_front_engine;
        modalListUrl.url_back_engine = schools[i].url_back_engine;

        this.setState({modalListUrl:modalListUrl});

      }

    }

  }

  //para enviar os dados do usuario para atualização
  async removeLicenseApi(){

      this.setState({openDialogRemoveUser:false});
      await api.deleteUser(this.state.userDeletion).then((res) => {
          
        this.getAllLicense();
        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O usuário foi removido.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

        if(error.response.status === 400){

          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);

        }

      });

  }


  editCourseLicense(id){

    console.log({openModaCourseLicense:true,courseLicense:id});
    this.setState({openModaCourseLicense:true,courseLicense:id});

  }

  //para criar um novo usuario no sistem,
  async componentDidMount(){
    
    this.getPriceRange();
    await this.getAllLicense();

  }

  selectLanguage(id){

    var dialog = this.state.dialog;
    dialog.language = id;
    this.setState({openLanguageDialog:false,dialog:dialog});

  }
 
  render(){

    const handleChange = name => event => {
        var dialog = this.state.dialog;
        dialog[name] = event.target.value;
        this.setState({dialog:dialog});
    }

    
    var buttons = <React.Fragment></React.Fragment>;
 
    return(
      <React.Fragment>
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <SimpleHeader name="Licenças" way={[{name:"Licenças",url:"/license"}]} buttons={buttons} />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6"><h3 className="mb-0">Lista de licença</h3></Col>
                <Col className="text-right" xs="6">
                  <Button className="btn-neutral btn-round btn-icon" color="default" href="#pablo" id="tooltip969372949" onClick={() => this.newLicense()} size="sm">
                    <span className="btn-inner--icon mr-1"><i class="fas fa-key"></i></span>
                    <span className="btn-inner--text">Nova Licença</span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Nome</th>
                  <th>Licença</th>
                  <th>Principal</th>
                  <th><center>Status</center></th>
                  <th>Server IP</th>
                  <th><center>Data de castro</center></th>
                  <th><center>Validade</center></th> 
                  <th><center>Usuários</center></th>
                  <th><center>Valor do contrato</center></th>
                  <th><center>Ações</center></th>
                </tr>
              </thead>
              <tbody>
              {

                this.state.license.map((value,index) => {

                  var key = "user_key_"+value.id;
                  var tooltip = "tooltip_user_key_"+value.id;
                  var tooltip2 = "tooltip2_user_key_"+value.id;
                  var simbol,status;
                  
                  if(parseInt(value.status) === 0){ status = <strong>Suspensa</strong>; }
                  if(parseInt(value.status) === 1){ status = <strong style={{color:"green"}}>Ativa</strong>; }

                  if(value.currencyType === "BRL"){ simbol = "R$"; }
                  if(value.currencyType === "USD"){ simbol = "$"; }

                  //console.log(value);

                  return(<tr key={key}>
                      <td className="table-user" onClick={() => { this.editLicense(value.id); }} style={{cursor:'pointer',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',width:'100px'}} ><b>{value.name}</b></td>
                      <td><input style={{border:"none"}} value={value.license} readOnly /> </td>
                      <td>

                      {


                        (value.main === 1) &&
                        <React.Fragment><strong style={{color:"green"}}>Sim</strong></React.Fragment>

                      }

                      {

                        (value.main === 0 || value.main === null) &&
                        <React.Fragment><strong style={{}}>Não</strong></React.Fragment>

                      }

                      </td>
                      <td><center>{status}</center></td>
                      <td>{value.serverIP}</td>
                      <td><center>{moment(value.createDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</center></td>
                      <td><center>{moment(value.expirationDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</center></td>
        
                      <td><center>{value.userLimit}</center></td>
                      <td><center>{simbol} {value.contractValue.toFixed(2)}</center></td>
                      <td className="table-actions">
                        
                        <center>

                          <font style={{cursor:'pointer'}} onClick={ () => this.setState({openDialogLicenseInfosModal:true})} className="table-action" id={tooltip+"tedd"} ><i class="fas fa-info"></i></font>
                          <UncontrolledTooltip delay={0} target={tooltip+"tedd"}></UncontrolledTooltip>

                          <font style={{cursor:'pointer'}} onClick={ () => this.editLicense(value.id)} className="table-action" id={tooltip} ><i className="fas fa-pencil-alt"/></font>
                          <UncontrolledTooltip delay={0} target={tooltip}>Editar licença</UncontrolledTooltip>

                          <font style={{cursor:'pointer'}} onClick={ () => this.editCourseLicense(value.id)} className="table-action" id={tooltip+"a"} ><i className="fas fa-graduation-cap"></i></font>
                          <UncontrolledTooltip delay={0} target={tooltip+"a"}>Cursos disponíveis</UncontrolledTooltip>
                          
                          <font style={{cursor:'pointer'}} onClick={ () => this.removerLicense(value.id)} className="table-action table-action-delete" id={tooltip2} ><i className="fas fa-trash" /></font>
                          <UncontrolledTooltip delay={0} target={tooltip2}>Deletar licença</UncontrolledTooltip>
                          
                          <font style={{cursor:'pointer'}} onClick={ () => { this.setState({openActionServer:true,editLicense:value.id}) }} className="table-action table-action-delete" id={'aassa_'+value.id} ><i className="fas fa-server"></i></font>
                          <UncontrolledTooltip delay={0} target={'aassa_'+value.id}>Ações no servidor</UncontrolledTooltip>

                        </center>

                      </td>
                    </tr>)

                  })}

              </tbody>
            </Table>
          </Card>
        </Container>

        <Modal className="modal-dialog-centered" style={{maxWidth:"calc(100% - 40px)"}} isOpen={this.state.openDialogLicenseModal} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.state.textDialog}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openDialogLicenseModal:false})}><span aria-hidden={true}>×</span> </button>
           </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.saveLicense(event) }>
                  {
                    (this.state.userError) &&
                    <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.state.userError}</Alert>
                  }

                  <Row>

                      <Col md={3}>
                        <h5>Nome:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>Licença:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedLicense})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="fas fa-key" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('license')} value={ this.state.dialog.license } onFocus={() => this.setState({ focusedLicense: true }) } onBlur={() => this.setState({ focusedLicense: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>Server IP:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedIP})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-world-2" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('serverIP')} value={ this.state.dialog.serverIP } onFocus={() => this.setState({ focusedIP: true }) } onBlur={() => this.setState({ focusedIP: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>URL front school:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedurl_front_school})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_front_school')} value={ this.state.dialog.url_front_school } onFocus={() => this.setState({ focusedurl_front_school: true }) } onBlur={() => this.setState({ focusedurl_front_school: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>URL back school:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.url_back_school})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_back_school')} value={ this.state.dialog.url_back_school } onFocus={() => this.setState({ focusedurl_back_school: true }) } onBlur={() => this.setState({ focusedurl_back_school: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
 

                      <Col md={2}>
                        <h5>Status:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedStatus})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText>
                            {
                              (parseInt(this.state.dialog.status) === 0) &&
                              <i class="fas fa-toggle-off"></i>
                            }
                            {
                              (parseInt(this.state.dialog.status) === 1) &&
                              <i class="fas fa-toggle-on"></i>
                            }
                            </InputGroupText></InputGroupAddon>
                            <Input type="select" onChange={handleChange('status')} value={ this.state.dialog.status } onFocus={() => this.setState({ focusedStatus: true }) } onBlur={() => this.setState({ focusedStatus: false }) }>

                              <option value=""></option>
                              <option value="1">Ativa</option>
                              <option value="0">Suspensa</option>

                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>


                      <Col md={2}>
                        <h5>Subir serverless:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.serverless})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText>
                            {
                              (parseInt(this.state.dialog.serverless) === 0) &&
                              <i class="fas fa-toggle-off"></i>
                            }
                            {
                              (parseInt(this.state.dialog.serverless) === 1) &&
                              <i class="fas fa-toggle-on"></i>
                            }
                            </InputGroupText></InputGroupAddon>
                            <Input type="select" onChange={handleChange('serverless')} value={ this.state.dialog.serverless } onFocus={() => this.setState({ focusedStatus: true }) } onBlur={() => this.setState({ focusedStatus: false }) }>

                              <option value=""></option>
                              <option value="1">Sim</option>
                              <option value="0">Não</option>

                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>


                      <Col md={2}>
                        <h5>Subir serverless:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedLMT})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-users"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('serverless_page')} value={ this.state.dialog.serverless_page } onFocus={() => this.setState({ focusedLMT: true }) } onBlur={() => this.setState({ focusedLMT: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={2}>
                        <h5>Usar outro LMS (FORÇAR):</h5>
                        <FormGroup className={classnames("mb-3")} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-users"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('forcelms')} value={this.state.dialog.forcelms} />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={2}>
                        <h5>LMT. Usuários:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedLMT})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-users"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('userLimit')} value={ this.state.dialog.userLimit } onFocus={() => this.setState({ focusedLMT: true }) } onBlur={() => this.setState({ focusedLMT: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={2}>
                        <h5>Validade:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedValidade})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputMask onChange={handleChange('expirationDate')} value={ this.state.dialog.expirationDate } className="form-control" mask="99/99/9999" maskChar=" " onFocus={() => this.setState({ focusedValidade: true }) } onBlur={() => this.setState({ focusedValidade: false }) }/>
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={2}>
                        <h5>Valor do contrato:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedValidade})} >
                          <InputGroup className="input-group-merge input-group-alternative"> 
                            { (this.state.dialog.currencyType === "USD") &&
                              <CurrencyInput decimalSeparator="," thousandSeparator="" className="form-control" prefix="$ " value={this.state.dialog.contractValue} onChangeEvent={handleChange('contractValue')}/>
                            }
                            { (this.state.dialog.currencyType === "BRL") &&
                              <CurrencyInput decimalSeparator="," thousandSeparator="" className="form-control" prefix="R$ " value={this.state.dialog.contractValue} onChangeEvent={handleChange('contractValue')}/>
                            }
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={2}>
                        <h5>Moeda:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedCurrencyType})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input type="select" onChange={handleChange('currencyType')} value={ this.state.dialog.currencyType } className="form-control" onFocus={() => this.setState({ focusedCurrencyType: true }) } onBlur={() => this.setState({ focusedCurrencyType: false }) }>
                              <option value="BRL">BRL</option>
                              <option value="USD">USD</option>
                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      {

                        fieldLicense.map((value,index) => { 

                          if(value.type === "text"){

                           return(
                              <div className={value.classname}>
                                <h5>{value.nameField}:</h5>
                                <FormGroup className={classnames("mb-3",{focused:value[value.name]})} >
                                  <InputGroup className="input-group-merge input-group-alternative">
                                    <InputGroupAddon addonType="prepend"><InputGroupText><i class={value.icon}></i></InputGroupText></InputGroupAddon>
                                    <Input type="text" onChange={handleChange(value.name)} value={ this.state.dialog[value.name] }   />
                                  </InputGroup>
                                </FormGroup>
                              </div>)

                          }
                          return(<React.Fragment key={'input_'+index}></React.Fragment>)

                        })

                      }

                      <Col md={2}>
                        <h5>Faixa de preço:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedLMT})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-users"></i></InputGroupText></InputGroupAddon>
                            <select className="form-control" name="priceRange" value={this.state.dialog.priceRange} onChange={handleChange('priceRange')} >
                              <option value=""></option>
                              {

                                this.state.pricerange.map((value,index) => {

                                  return(<option value={value.id} key={value}>{value.name}</option>)

                                })

                              }
                            </select>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <h5>Licença principal:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <select className="form-control" name="main" value={this.state.dialog.main} onChange={handleChange('main')} >
                              <option value="0">Não</option> 
                              <option value="1">Sim</option> 
                            </select>
                          </InputGroup>
                        </FormGroup>
                      </Col>



                      <Col md={2}>
                        <h5>Latitude:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend"><InputGroupText><i className="fas fa-map-marker-alt" /></InputGroupText></InputGroupAddon>
                              <Input type="text" onChange={handleChange('latitude')} value={ this.state.dialog.latitude } />
                            </InputGroup>
                          </InputGroup>
                        </FormGroup>
                      </Col>


                      <Col md={2}>
                        <h5>Longitude:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend"><InputGroupText><i className="fas fa-map-marker-alt" /></InputGroupText></InputGroupAddon>
                              <Input type="text" onChange={handleChange('longitude')} value={ this.state.dialog.longitude } />
                            </InputGroup>
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={2}>
                        <h5>Mostrar na lista pública:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <select className="form-control" name="showpublic" value={this.state.dialog.showpublic} onChange={handleChange('showpublic')} >
                              <option value=""></option> 
                              <option value="0">Não</option> 
                              <option value="1">Sim</option> 
                            </select>
                          </InputGroup>
                        </FormGroup>
                      </Col>
 
                      <Col md={3}>
                        <h5>Razão social:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('reason')} value={ this.state.dialog.reason } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>Pixelid:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative"> 
                            <Input type="text" onChange={handleChange('pixelid')} value={ this.state.dialog.pixelid } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>googletagmanager:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative"> 
                            <Input type="text" onChange={handleChange('googletagmanager')} value={ this.state.dialog.googletagmanager } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>license_sprinthub:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative"> 
                            <Input type="text" onChange={handleChange('license_sprinthub')} value={ this.state.dialog.license_sprinthub } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>id_formulario_aula_demonstrativa:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative"> 
                            <Input type="text" onChange={handleChange('id_formulario_aula_demonstrativa')} value={ this.state.dialog.id_formulario_aula_demonstrativa } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
 
                      <Col md={12}><h4>Dados para emissão de nota fiscal:</h4></Col>
                      <Col md={3}>
                        <h5>Nome Fantasia:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('fantasyName')} value={ this.state.dialog.fantasyName } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h5>Razão social:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('reason')} value={ this.state.dialog.reason } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h5>CNPJ:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input type="text" onChange={handleChange('cnpj')} value={ this.state.dialog.cnpj } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h5>Inscrição Estadual:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative"> 
                            <Input type="text" onChange={handleChange('stateRegistration')} value={ this.state.dialog.stateRegistration } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <h5>Inscrição Municipal:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input type="text" onChange={handleChange('municipalRegistration')} value={ this.state.dialog.municipalRegistration } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <h5>Código municipal:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input type="text" onChange={handleChange('municipalCod')} value={ this.state.dialog.municipalCod } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      
                      <Col md={2}>
                        <h5>Regime Tributário:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <select className="form-control" onChange={handleChange('focusnfe_regime')} value={ this.state.dialog.focusnfe_regime }>
                              <option value=""></option>
                              <option value="1">Simples nacional</option>
                              <option value="2">Simples Nacional - excesso de sublimite de receita bruta</option>
                              <option value="3">Regime Normal</option>
                            </select>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <h5>Regime especial tributação:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <select className="form-control" onChange={handleChange('regime_especial')} value={ this.state.dialog.regime_especial }>
                            <option value=""></option>
                            <option value="1">Microempresa municipal</option>
                            <option value="2">Estimativa</option>
                            <option value="3">Sociedade de profissionais</option>
                            <option value="4">Cooperativa</option>
                            <option value="5">MEI - Simples Nacional</option>
                            <option value="6">ME EPP- Simples Nacional</option>
                            </select>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h5>Token de homologação:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input type="text" onChange={handleChange('focusnfe_hom')} value={ this.state.dialog.focusnfe_hom } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h5>Token de produção:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input type="text" onChange={handleChange('focusnfe_prod')} value={ this.state.dialog.focusnfe_prod } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      
                      




                      <Col md={12}><h4>Integração do meta base:</h4></Col>
                      <Col md={3}>
                        <h5>ID da dashboard:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <input className="form-control" type="text" onChange={handleChange('metaBase_use')} value={ this.state.dialog.metaBase_use } />
                          </InputGroup>
                        </FormGroup>
                      </Col>





                      <Col md={12}><h4>Integração do autentique:</h4></Col>
                      <Col md={3}>
                        <h5>API key:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <input className="form-control" type="text" onChange={handleChange('autentiqueapikey')} value={ this.state.dialog.autentiqueapikey } />
                          </InputGroup>
                        </FormGroup>
                      </Col>



                      <Col md={12}><h4>Links e redes sociais:</h4></Col>
                      <Col md={3}>
                        <h5>Facebook:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <input className="form-control" type="text" onChange={handleChange('facebook')} value={ this.state.dialog.facebook } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>Youtube:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <input className="form-control" type="text" onChange={handleChange('youtube')} value={ this.state.dialog.youtube } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>Linkedin:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <input className="form-control" type="text" onChange={handleChange('linkedin')} value={ this.state.dialog.linkedin } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
 
                      <Col md={3}>
                        <h5>Instagram:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <input className="form-control" type="text" onChange={handleChange('instagram')} value={ this.state.dialog.instagram } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h5>Trabalhe conosco:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <input className="form-control" type="text" onChange={handleChange('trabalhe_conosco')} value={ this.state.dialog.trabalhe_conosco } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h5>Link aula gratis:</h5>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <input className="form-control" type="text" onChange={handleChange('lpaulagratis')} value={ this.state.dialog.lpaulagratis } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                  </Row>
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openDialogLicenseModal:false}) } >Cancelar</Button>
                    <Button className="my-4" color="primary" type="submit">{this.state.textDialogButton}</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        
        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveUser} >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i class="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
              <p>Se você prosseguir, todos os dados do usuário serão removidos, essa ação é irreversível. É recomendavel <strong style={{fontWeight:'700'}}>bloquear</strong> o usuário ao invés de remove-lo. </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeUserApi() }>Ok, Remova</Button>
            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveUser:false}) }>
              Cancelar e fechar
            </Button>
          </div>
        </Modal>

        <ActionServer state={this.state} setState={ (d) => this.setState(d) } open={this.state.openActionServer} licenseID={this.state.editLicense} />

        <ModalSchoolDatas state={this.state} setState={ (data) => this.setState(data) } />
        <CourseLicenseAvailable setState={ (data) => this.setState(data) } state={this.state} />

      </React.Fragment>

    );
  }
}
export default License;