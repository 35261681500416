import React from "react";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; 
import NotificationAlert from "react-notification-alert";
import Select2 from "react-select2-wrapper";

const cfop = require('./cfop.json');

var ops = cfop.map((value) => {

    return {id:value.id,text:value.id+' - '+value.name};

});

class NewNatureOperation_Lincese extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {NatureOperations:[],products:[],tab:1};
        this.state.dialog = {
       
            icms_cfop:"",
            icms_aliquota:"",
            name:"",
            finality:"",
            obs:"",
            cfop:"",
            aliquot:"",
            icms_situacao_tributaria:"",
            icms_origem:"",
            pis_situacao_tributaria:"",
            cofins_situacao_tributaria:"",
            icms_obs:"",
            ipi_obs:"",
            ipi_situacao_tributaria:"",
            issqn_aliquota:"",
            issqn_base:"",
            issqn_descontar:"",
            issqn_obs:"",
            pis_base:"",
            pis_obs:"",
            pis_aliquota:"",
            cofins_situacao_tributaria:"",
            cofins_aliquota:"",
            cofins_base:"",
            cofins_obs:""

        };

        this.saveNatureOperation = this.saveNatureOperation.bind(this); 
        this.postNatureOperation = this.postNatureOperation.bind(this);
        this.putNatureOperation = this.putNatureOperation.bind(this);
        
		window.newNatureOperation = this;

    }
 

    componentDidMount(){
  
        if(this.props.match.params !== "new"){

            this.getNatureOperation(this.props.match.params.id);

        }
        
    }

    async getNatureOperation(id){

        await api.getNatureOperation(id).then(async (res) => {
            
            this.setState({dialog:res.data});
               
        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  
            return false; 
    
        });

    }

    async postNatureOperation(){

        var obj = JSON.parse(JSON.stringify(this.state.dialog));
  
        await api.postNatureOperation(obj).then(async (response) => {
        
            this.props.history.push('/nature_operation/'+response.data.natureoperation.id);

            var dialog = this.state.dialog;
            dialog.id = response.data.natureoperation.id;
            this.setState({dialog:dialog});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">A natureza foi cadastrado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            return false; 
            
    
        });

    }


    async putNatureOperation(){

        var obj = JSON.parse(JSON.stringify(this.state.dialog));
        obj.html = window.$('#summer').summernote('code');

        await api.putNatureOperation(this.state.dialog.id,obj).then(async (res) => {
            
            console.log(res.data);

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response);  

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
            return false; 
    
        });

    }

 
    saveNatureOperation(event){

        console.log('saveNatureOperation');

        event.preventDefault();
        if(this.state.dialog.id === undefined){

            console.log('postNatureOperation');
            this.postNatureOperation();

        }else{

            console.log('putNatureOperation');
            this.putNatureOperation();

        }

    }
 
 
	render(){
        
        const handleChange = name => event => {
            var dialog = this.state.dialog;
            dialog[name] = event.target.value;
            this.setState({dialog:dialog});
        }
        
        var t1,t2;
        if(this.state.dialog.id !== undefined){

            t1 = "Editar natureza";
            t2 = "Salvar alterações";
            
        }else{

            t1 = "Cadastrar natureza de operação";
            t2 = "Cadastrar natureza de operação";

        }

    	return(
		<React.Fragment>
            
            <style>{' .select2-selection__rendered{ overflow: hidden !important; text-overflow: ellipsis !important; white-space: nowrap !important; } '}</style>

            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
            <SimpleHeader name="Natureza de operação" way={[{name:"Natureza de operação",url:"/nature_operation"}]} buttons={<React.Fragment />} />
            <div class="mt--6 container-fluid">
                <div className="justify-content-center row">
                    <div className="card-wrapper col-lg-8">
                        <div className="card">
                            <div class="card-header">
                                <div class="row"><div class="col-6"><h3 class="mb-0">{t1}</h3></div></div>
                            </div>
                            <div className="card-body">
                                <form type="post" role="form" type="post" onSubmit={ (event) => this.saveNatureOperation(event) }>
                                    <h6 class="heading-small text-muted mb-4">Informações gerais</h6>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-username">Nome:</label>
                                                    <input onChange={ handleChange('name')} type="text" class="form-control" value={this.state.dialog.name} />
                                                </div>
                                            </div>
                                            {/*
                                            <div class="col-lg-4">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-username">Finalidade:</label>
                                                    <select onChange={handleChange('finality')} type="text" class="form-control" value={this.state.dialog.finality}>
                                                        <option value=""></option>
                                                        <option value="1">Normal</option>
                                                        <option value="2">Complementar</option>
                                                        <option value="3">Nota de ajuste</option>
                                                        <option value="4">Devolução</option>
                                                    </select>
                                                </div>
                                            </div>*/}

                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-username">Tipo de documento:</label>
                                                    <select class="form-control" onChange={handleChange('tipo_documento')} value={this.state.dialog.tipo_documento}>
                                                        <option value=""></option>
                                                        <option value="0">Nota Fiscal de Entrada</option>
                                                        <option value="1">Nota Fiscal de Saída</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-username">Finalidade de emissão:</label>
                                                    <select class="form-control" onChange={handleChange('finalidade_emissao')} value={this.state.dialog.finalidade_emissao}>
                                                        <option value=""></option>
                                                        <option value="1">Normal</option>
                                                        <option value="2">Complementar</option>
                                                        <option value="3">Nota de ajuste</option>
                                                        <option value="4">Devolução</option>
                                                    </select>
                                                </div>
                                            </div>

                                            
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-username">Observações padrões para notas desta operação:</label>
                                                    <textarea className="form-control" onChange={handleChange('obs')} value={this.state.dialog.obs}></textarea>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <h6 class="heading-small text-muted mb-4">Impostos</h6>
                                    <div class="pl-lg-4">
                                        <ul class="nav nav-tabs">
                                            <li onClick={() => { this.setState({tab:1}); }} class="nav-item" style={{cursor:'pointer'}}>
                                                <a class={"nav-link "+(this.state.tab === 1 ? 'active':'')}>ICMS</a>
                                            </li>
                                            <li onClick={() => { this.setState({tab:2}); }} class="nav-item" style={{cursor:'pointer'}}>
                                                <a class={"nav-link "+(this.state.tab === 2 ? 'active':'')}>IPI</a>
                                            </li>
                                            <li onClick={() => { this.setState({tab:3}); }} class="nav-item" style={{cursor:'pointer'}}>
                                                <a class={"nav-link "+(this.state.tab === 3 ? 'active':'')}>ISSQN</a>
                                            </li>
                                            <li onClick={() => { this.setState({tab:4}); }} class="nav-item" style={{cursor:'pointer'}}>
                                                <a class={"nav-link "+(this.state.tab === 4 ? 'active':'')}>PIS</a>
                                            </li>
                                            <li onClick={() => { this.setState({tab:5}); }} class="nav-item" style={{cursor:'pointer'}}>
                                                <a class={"nav-link "+(this.state.tab === 5 ? 'active':'')}>COFINS</a>
                                            </li>
                                        </ul>

                                        <br/>

                                        {

                                            (this.state.tab === 1) &&
                                            <React.Fragment>
                                                <div className="">
                                                <div className="row">
                                                    <div class="col-lg-5">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Situação tributária (CST):</label>
                                                            <select onChange={ handleChange('icms_situacao_tributaria')} type="text" class="form-control" value={this.state.dialog.icms_situacao_tributaria} >
                                                                <option value=""></option>
                                                                <option value="00">00 - Tributada integralmente</option>
                                                                <option value="10">10 - Tributada e com cobrança do ICMS por substituição tributária</option>
                                                                <option value="20">20 - Com redução de base de cálculo</option>
                                                                <option value="30">30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária</option>
                                                                <option value="40">40 - Isenta</option>
                                                                <option value="41">41 - Não tributada</option>
                                                                <option value="50">50 - Suspensão</option>
                                                                <option value="51">51 - Diferimento</option>
                                                                <option value="60">60 - ICMS cobrado anteriormente por substituição tributária</option>
                                                                <option value="70">70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária</option>
                                                                <option value="90">90 - Outras</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">CFOP:</label>
                                                             <Select2
                                                                                
                                                                className="form-control"
                                                                defaultValue={this.state.dialog.icms_cfop}
                                                                options={{placeholder:"Selecione um cfop"}}
                                                                data={ops}
                                                                onChange={(e) => { this.state.dialog.icms_cfop = e.target.value; console.log(e.target.value); }}

                                                            />

                                                            <font>Código fiscal de operação</font>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Alíquota:</label>
                                                            <input onChange={ handleChange('icms_aliquota')} type="text" class="form-control" value={this.state.dialog.icms_aliquota} />
                                                            <font>Interestadual (próprio)</font>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Observações do ICMS:</label>
                                                            <input onChange={ handleChange('icms_obs')} type="text" class="form-control" value={this.state.dialog.icms_obs} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row"></div>
                                            </div>
                                            </React.Fragment>

                                        }
                                        {

                                            (this.state.tab === 2) &&
                                            <React.Fragment>
                                                <div className="">
                                                    <div className="row">
                                                        <div class="col-lg-5">
                                                            <div class="form-group">
                                                                <label class="form-control-label" for="input-username">Situação tributária (CST):</label>
                                                                <select onChange={ handleChange('ipi_situacao_tributaria')} name="ipi_situacao_tributaria" type="text" class="form-control" value={this.state.dialog.ipi_situacao_tributaria} >
                                                                    <option value=""></option>
                                                                    <option value="50">50 - Saída tributada</option>
                                                                    <option value="51">51 - Saída tributada com alíquota zero</option>
                                                                    <option value="52">52 - Saída isenta</option>
                                                                    <option value="53">53 - Saída não-tributada</option>
                                                                    <option value="54">54 - Saída imune</option>
                                                                    <option value="55">55 - Saída com suspensão</option>
                                                                    <option value="99">99 - Outras saídas</option>
                                                                    <option value="ZZ">Não destacar IPI</option>		
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label class="form-control-label" for="input-username">Observações do IPI:</label>
                                                                <input onChange={ handleChange('ipi_obs')} name="name" type="text" class="form-control" value={this.state.dialog.ipi_obs} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                        }
    
                                        {

                                            (this.state.tab === 3) &&
                                            <React.Fragment>
                                                <div className="">
                                                    <div className="row">
                                                        <div class="col-lg-5">
                                                            <div class="form-group">
                                                                <label class="form-control-label" for="input-username">Situação tributária (CST):</label>
                                                                <select onChange={ handleChange('ipi_situacao_tributaria')} name="ipi_situacao_tributaria" type="text" class="form-control" value={this.state.dialog.ipi_situacao_tributaria} >
                                                                    <option value=""></option>
                                                                    <option value="00">tributado</option>
                                                                    <option value="01">isento</option>
                                                                    <option value="02">outra situação</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div class="col-lg-4">
                                                            <div class="form-group">
                                                                <label class="form-control-label" for="input-username">Alíquota:</label>
                                                                <input onChange={ handleChange('issqn_aliquota')} name="name" type="text" class="form-control" value={this.state.dialog.issqn_aliquota} />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="form-group">
                                                                <label class="form-control-label" for="input-username">Base:</label>
                                                                <input onChange={ handleChange('issqn_base')} name="name" type="text" class="form-control" value={this.state.dialog.issqn_base} />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="form-group">
                                                                <label class="form-control-label" for="input-username">Descontar ISS:</label>
                                                                <input onChange={ handleChange('issqn_descontar')} name="name" type="text" class="form-control" value={this.state.dialog.issqn_descontar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label class="form-control-label" for="input-username">Observações do ISSQN:</label>
                                                                <input onChange={ handleChange('issqn_obs')} name="name" type="text" class="form-control" value={this.state.dialog.issqn_obs} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                        }

                                    {

                                        (this.state.tab === 4) &&
                                        <React.Fragment>
                                            <div className="">
                                                <div className="row">
                                                    <div class="col-lg-5">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Situação tributária (CST):</label>
                                                            <select onChange={ handleChange('pis_situacao_tributaria')} name="pis_situacao_tributaria" type="text" class="form-control" value={this.state.dialog.pis_situacao_tributaria} >
                                                                <option value=""></option>
                                                                <option value="01">01 - Operação tributável (alíquota normal, cumulativo ou não)</option>
                                                                <option value="02">02 - Operação tributável (alíquota diferenciada)</option>
                                                                <option value="03">03 - Operação tributável (alíquota por unidade de produto)</option>
                                                                <option value="04">04 - Operação tributável (tributação monofásica, alíquota zero)</option>
                                                                <option value="05">05 - Operação Tributável (Substituição Tributária)</option>
                                                                <option value="06">06 - Operação tributável (alíquota zero)</option>
                                                                <option value="07">07 - Operação isenta da contribuição</option>
                                                                <option value="08">08 - Operação sem incidência da contribuição</option>
                                                                <option value="09">09 - Operação com suspensão da contribuição</option>
                                                                <option value="49">49 - Outras Operações de Saída</option>
                                                                <option value="50">50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno</option>
                                                                <option value="51">51 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno</option>
                                                                <option value="52">52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação</option>
                                                                <option value="53">53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno</option>
                                                                <option value="54">54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação</option>
                                                                <option value="55">55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação</option>
                                                                <option value="56">56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação</option>
                                                                <option value="60">60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno</option>
                                                                <option value="61">61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno</option>
                                                                <option value="62">62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação</option>
                                                                <option value="63">63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno</option>
                                                                <option value="64">64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação</option>
                                                                <option value="65">65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação</option>
                                                                <option value="66">66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação</option>
                                                                <option value="67">67 - Crédito Presumido - Outras Operações</option>
                                                                <option value="70">70 - Operação de Aquisição sem Direito a Crédito</option>
                                                                <option value="71">71 - Operação de Aquisição com Isenção</option>
                                                                <option value="72">72 - Operação de Aquisição com Suspensão</option>
                                                                <option value="73">73 - Operação de Aquisição a Alíquota Zero</option>
                                                                <option value="74">74 - Operação de Aquisição sem Incidência da Contribuição</option>
                                                                <option value="75">75 - Operação de Aquisição por Substituição Tributária</option>
                                                                <option value="98">98 - Outras Operações de Entrada</option>
                                                                <option value="99">99 - Outras operações</option>	
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Alíquota:</label>
                                                            <input onChange={ handleChange('pis_aliquota')} type="text" class="form-control" value={this.state.dialog.pis_aliquota} />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Base:</label>
                                                            <input onChange={ handleChange('pis_base')} type="text" class="form-control" value={this.state.dialog.pis_base} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Observações do PIS:</label>
                                                            <input onChange={ handleChange('pis_obs')} type="text" class="form-control" value={this.state.dialog.pis_obs} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </React.Fragment>

                                    }
                                    {

                                        (this.state.tab === 5) &&
                                        <React.Fragment>
                                            <div className="">
                                                <div className="row">
                                                    <div class="col-lg-5">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Situação tributária (CST):</label>
                                                            <select onChange={ handleChange('cofins_situacao_tributaria')} type="text" class="form-control" value={this.state.dialog.cofins_situacao_tributaria} >
                                                                <option value="Selecione">Selecione</option>
                                                                <option value="01">01 - Operação tributável (alíquota normal, cumulativo ou não)</option>
                                                                <option value="02">02 - Operação tributável (alíquota diferenciada)</option>
                                                                <option value="03">03 - Operação tributável (alíquota por unidade de produto)</option>
                                                                <option value="04">04 - Operação tributável (tributação monofásica, alíquota zero)</option>
                                                                <option value="05">05 - Operação Tributável (Substituição Tributária)</option>
                                                                <option value="06">06 - Operação tributável (alíquota zero)</option>
                                                                <option value="07">07 - Operação isenta da contribuição</option>
                                                                <option value="08">08 - Operação sem incidência da contribuição</option>
                                                                <option value="09">09 - Operação com suspensão da contribuição</option>
                                                                <option value="49">49 - Outras Operações de Saída</option>
                                                                <option value="50">50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno</option>
                                                                <option value="51">51 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno</option>
                                                                <option value="52">52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação</option>
                                                                <option value="53">53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno</option>
                                                                <option value="54">54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação</option>
                                                                <option value="55">55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação</option>
                                                                <option value="56">56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação</option>
                                                                <option value="60">60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno</option>
                                                                <option value="61">61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno</option>
                                                                <option value="62">62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação</option>
                                                                <option value="63">63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno</option>
                                                                <option value="64">64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação</option>
                                                                <option value="65">65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação</option>
                                                                <option value="66">66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação</option>
                                                                <option value="67">67 - Crédito Presumido - Outras Operações</option>
                                                                <option value="70">70 - Operação de Aquisição sem Direito a Crédito</option>
                                                                <option value="71">71 - Operação de Aquisição com Isenção</option>
                                                                <option value="72">72 - Operação de Aquisição com Suspensão</option>
                                                                <option value="73">73 - Operação de Aquisição a Alíquota Zero</option>
                                                                <option value="74">74 - Operação de Aquisição sem Incidência da Contribuição</option>
                                                                <option value="75">75 - Operação de Aquisição por Substituição Tributária</option>
                                                                <option value="98">98 - Outras Operações de Entrada</option>
                                                                <option value="99">99 - Outras operações</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Alíquota:</label>
                                                            <input onChange={ handleChange('cofins_aliquota')} type="text" class="form-control" value={this.state.dialog.cofins_aliquota} />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Base:</label>
                                                            <input onChange={ handleChange('cofins_base')} type="text" class="form-control" value={this.state.dialog.cofins_base} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label" for="input-username">Observações do COFINS:</label>
                                                            <input onChange={ handleChange('cofins_obs')} type="text" class="form-control" value={this.state.dialog.cofins_obs} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </React.Fragment>

                                    }
                                    </div>
                                    <button type="submit" class="btn btn-primary btn-lg btn-block">{t2}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</React.Fragment>)
    }
}
export default NewNatureOperation_Lincese;