import React from "react";
import api from "./../../../Api.js";
import { ResponsiveBar } from '@nivo/bar';
import ClipLoader from "react-spinners/ClipLoader";

import "./report.css";

class Report extends React.Component {

    constructor(props) {
    
        super(props);
        this.state = {loading:true,data:[],data2:{},ok:false};
        this.crmsocket = this.crmsocket.bind(this);
        
    }

    
    getData(){

        this.state.startDate = this.props.startDate;
        this.state.endDate = this.props.endDate;
        
        api.crm_report(this.props.license,this.props.crmname,'&startDate='+this.props.startDate+'&endDate='+this.props.endDate).then(async (res) => {
            
        
           var dados = [];
           for(let index = 0; index < res.data.columns.length; index++){

               var col = res.data.columns[index];
               dados.push({
                "country": col.name,
                "Ganha": col.totalWin,
                "GanhaColor": "hsl(0°,100,50.2)",
                "Perdida": col.totalLose,
                "PerdidaColor": "hsl(45,91,49)",
                "Aberta": col.totalOpen,
                "AbertaColor": "hsl(0°, 100, 50.2)",
              });
              
           }

           console.log(dados);
           this.setState({data2:res.data,data:dados,ok:true,loading:false})

        }).catch((error) => {

            console.log(error.response);
            this.setState({loading:false});

            if(error !== undefined){

                if(error.response !== undefined){

                    if(error.response.data !== undefined){

                        if(error.response.data.msg !== undefined){

                            this.setState({errorMsg:error.response.data.msg});

                        }

                    }

                }

            }

        });

    }

    componentDidUpdate(){

        if(this.state.startDate !== this.props.startDate || this.state.endDate !== this.props.endDate){
            
            this.getData();

        }

    }

    componentDidMount(){
        
        this.getData();
        window.socket.on('crm',this.crmsocket);

    }

    crmsocket(data){

        if(data.funnel !== undefined){

            if(data.funnel.name === this.props.crmname){

                this.getData();

            }

        }

    }

    componentWillUnmount(){

        window.socket.off('crm',this.crmsocket);

    }




    render(){

        if(this.props.ocultERROR === true && this.state.errorMsg !== undefined){ return(<React.Fragment></React.Fragment>)}

        return (<React.Fragment>
            <div className={(this.props.size !== undefined ? 'col-md-'+this.props.size:'col-md-4')+" report_crm"}>
                <div className="internal_report_crm" style={{position:'relative'}}>

                    <button onClick={() => { window.open(this.props.data.url_front_school+"/board/"+this.state.data2.id+"?sessionCenter="+sessionStorage.getItem('session'), '_blank') }} className="btn btn-sm btn-secondary" style={{zIndex:8,position:'absolute',right:'18px',top:'125px'}}><i class="fas fa-external-link-alt"></i> Nova guia</button>
                    <button onClick={() => { window.setGlobalIframe({open:true,src:this.props.data.url_front_school+"/board/"+this.state.data2.id+"?hiddenbar=1&sessionCenter="+sessionStorage.getItem('session')}) }} className="btn btn-sm btn-secondary" style={{zIndex:8,position:'absolute',right:'0',top:'165px'}}><i class="fas fa-search-dollar"></i> Inspencionar</button>

                    <div className="title_crm">{this.props.name}</div>
                    {

                        (this.state.ok === false) &&
                        <div class="chart_crm chart_crm_dd">
                            {

                                (this.state.errorMsg !== undefined) &&
                                <React.Fragment>
                                    <div><img style={{height:'40px',marginBottom:'15px'}} src="/images/error-flat.png" /></div>
                                    <div className="msgError">{this.state.errorMsg}</div>
                                </React.Fragment>

                            }
                            {

                                (this.state.loading === true) &&
                                <React.Fragment>
                                    <ClipLoader size={40} color={"#6d7893"} />
                                    <div className="msgError">Buscando dados...</div>
                                </React.Fragment>

                            }
                        </div>

                    }
                    {

                        (this.state.ok === true) &&
                        <div className="chart_crm">
                        <ResponsiveBar
                            data={this.state.data}
                            keys={['Aberta','Ganha','Perdida']}
                            indexBy="country"
                            margin={{top:0,right:130,bottom:80,left:60}}
                            padding={0.3}
                            valueScale={{type:'linear'}}
                            indexScale={{type:'band',round: true }}
                            colors={['#f2cb52 ','#1bd44d','#f23030 ']}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#eed312',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[{match:{id:'fries'},id:'dots'},{match: {id: 'sandwich'},id:'lines'}]}
                            borderColor={{from:'color',modifiers:[['darker',1.6]]}}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 20,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Oportunidades',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [{on:'hover',style:{itemOpacity:1}}]
                                }
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                    </div>
                    }
                </div>
            </div>
        </React.Fragment>)
    
    }

}

export default Report;