import axios from 'axios';

//Não verifica o certificado ssl
var api = axios.create({secure: false });

var apis = {
	
	login: (data) => api.post(sessionStorage.getItem('backend')+'/auth/password',data),
	users: () => api.get(sessionStorage.getItem('backend')+'/user',{headers: {session: sessionStorage.getItem('session')}}),
	levelAcess: () => api.get(sessionStorage.getItem('backend')+'/accesslevel',{headers: {session: sessionStorage.getItem('session')}}),

	listLanguages: () => api.get('/language/index.json'),
	languageText: (file) => api.get('/language/'+file),

	//para atualizar os dados de um usuario
	updateUser: (id,data) => api.put(sessionStorage.getItem('backend')+'/user/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Parar criar um novo usuario
	postUser: (data) => api.post(sessionStorage.getItem('backend')+'/user/',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover o usuario
	deleteUser: (id) => api.delete(sessionStorage.getItem('backend')+'/user/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para enviar arquivo
	uploadFile: (data) => api.delete(sessionStorage.getItem('backend')+'/upload/',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para criar um novo curso
	postCourse: (data) => api.post(sessionStorage.getItem('backend')+'/course/',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para listar todos os cursos
	course: (data) => api.get(sessionStorage.getItem('backend')+'/course/',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os dados de um curso
	getCourse: (id) => api.get(sessionStorage.getItem('backend')+'/course/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para atualizar os dados de um curso
	putCourse: (id,data) => api.put(sessionStorage.getItem('backend')+'/course/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os dados de uma unit
 	getUnit: (id) => api.get(sessionStorage.getItem('backend')+'/unit/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as units de um curso
	getUnitCourse: (id) => api.get(sessionStorage.getItem('backend')+'/unit/course/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as lessons de uma unit
	getLessonUnit: (id) => api.get(sessionStorage.getItem('backend')+'/lesson/unit/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar uma lesson especifica
	getLesson: (id) => api.get(sessionStorage.getItem('backend')+'/lesson/'+id,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para cadastrar uma unit
 	postUnit: (data) => api.post(sessionStorage.getItem('backend')+'/unit/',data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para editar uma unit
 	putUnit: (id,data) => api.put(sessionStorage.getItem('backend')+'/unit/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para remover uma unit
 	deleteUnit: (id) => api.delete(sessionStorage.getItem('backend')+'/unit/'+id,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para alterar a ordem da unit
 	postLesson: (data) => api.post(sessionStorage.getItem('backend')+'/lesson/',data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para cadastrar uma lesson
 	putLesson: (id,data) => api.put(sessionStorage.getItem('backend')+'/lesson/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para editar uma lesson
 	deleteLesson: (id) => api.delete(sessionStorage.getItem('backend')+'/lesson/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para remover uma lesson
 	changeOrderUnit: (item,index) => api.put(sessionStorage.getItem('backend')+'/unit/updateorder/'+item,{index:index},{headers: {session: sessionStorage.getItem('session')}}),

 	//Para remover uma lesson
 	changeOrderLesson: (item,index) => api.put(sessionStorage.getItem('backend')+'/lesson/updateorder/'+item,{index:index},{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar todos os vídeos de um curso
 	getVideoCourse: (id) => api.get(sessionStorage.getItem('backend')+'/video/course/'+id,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para cadastrar um novo víode
 	postVideo: (data) => api.post(sessionStorage.getItem('backend')+'/video/',data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para atualizar um video
 	putVideo: (id,data) => api.put(sessionStorage.getItem('backend')+'/video/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para remover um vídeo
 	deleteVideo: (id) => api.delete(sessionStorage.getItem('backend')+'/video/'+id,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar os dados de um vídeo
 	dataVideo: (id) => api.get(sessionStorage.getItem('backend')+'/video/'+id,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para verificar o acesso do usuario no sisstema
 	verifyAcess: (id) => api.get(sessionStorage.getItem('backend')+'/auth/verify',{headers: {session: sessionStorage.getItem('session')}}),

 	//Para cadastrar um novo quiz no curso
 	postQuiz: (item) => api.post(sessionStorage.getItem('backend')+'/quiz/',item,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar todos os quiz de um curso
 	getQuizCourse: (course) => api.get(sessionStorage.getItem('backend')+'/quiz/course/'+course,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para atualizar o quiz
 	putQuiz: (id,data) => api.put(sessionStorage.getItem('backend')+'/quiz/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para deletar um quiz
 	deleteQuiz: (id) => api.delete(sessionStorage.getItem('backend')+'/quiz/'+id,{headers: {session: sessionStorage.getItem('session')}}),
 	
 	//Para pegar as questões de um quiz
 	getQuizQuestions: (id) => api.get(sessionStorage.getItem('backend')+'/quizquestion/quiz/'+id,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar os dados de um quiz especifico
 	getQuiz: (id) => api.get(sessionStorage.getItem('backend')+'/quiz/'+id,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para cadastrar uma nova questão
 	postQuestion: (data) => api.post(sessionStorage.getItem('backend')+'/quizquestion/',data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para Atualizar uma questão
 	deleteQuestion: (id) => api.delete(sessionStorage.getItem('backend')+'/quizquestion/'+id,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para atualizar uma questão
 	putQuestion: (id,data) => api.put(sessionStorage.getItem('backend')+'/quizquestion/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar todas as license
 	getAllLicense: (id) => api.get(sessionStorage.getItem('backend')+'/license/',{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar todas as license
 	postLicense: (data) => api.post(sessionStorage.getItem('backend')+'/license/',data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar todas as license
 	putLicense: (id,data) => api.put(sessionStorage.getItem('backend')+'/license/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar os niveis de acesso de um grupo
 	getAccessLevelGroup: (group) => api.get(sessionStorage.getItem('backend')+'/accesslevel/group/'+group,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pergar todas as permissões
 	getAllPermission: () => api.get(sessionStorage.getItem('backend')+'/accesslevel/permission/',{headers: {session: sessionStorage.getItem('session')}}),

 	//Para cadastrar um grupo de permissão
 	postGroupPermission: (obj) => api.post(sessionStorage.getItem('backend')+'/accesslevel/group',obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para editar um grupo de permissão
 	putGroupPermission: (id,data) => api.put(sessionStorage.getItem('backend')+'/accesslevel/group/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para salvar as permissões do grupo
 	savePermissionGroup: (grupo,data)  => api.put(sessionStorage.getItem('backend')+'/accesslevel/save/permission/'+grupo,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para cadastrar o grupo de aula
	postLessonGroup: (data)  => api.post(sessionStorage.getItem('backend')+'/lessongroup',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para salvar o grupo de aula
	putLessonGroup: (id,data)  => api.put(sessionStorage.getItem('backend')+'/lessongroup/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os cursos disponíveis em um licença
	getCourseLicense: (id)  => api.get(sessionStorage.getItem('backend')+'/license/listcourses/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para atualizar a licença do curso
	putCourseLicense: (idLicense,courses)  => api.put(sessionStorage.getItem('backend')+'/license/savecourses/'+idLicense,{courses:courses},{headers: {session: sessionStorage.getItem('session')}}),

	//Para listar quais licenças usam o curso
	getListslicenseCourse: (course)  => api.get(sessionStorage.getItem('backend')+'/course/listslicense/'+course,{headers: {session: sessionStorage.getItem('session')}}),

	getClientGroupFields: (groupID) => api.get(sessionStorage.getItem('backend')+'/clientfield/group/'+groupID,{headers:{session:sessionStorage.getItem('session')}}),

	postClientGroupFields: (data) => api.post(sessionStorage.getItem('backend')+'/clientfield/group/',data,{headers:{session:sessionStorage.getItem('session')}}),

	putClientGroupFields: (id,data) => api.put(sessionStorage.getItem('backend')+'/clientfield/group/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	deleteClientGroupFields: (id) => api.delete(sessionStorage.getItem('backend')+'/clientfield/group/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postClientGroupFieldsField: (data) => api.post(sessionStorage.getItem('backend')+'/clientfield/field/',data,{headers:{session:sessionStorage.getItem('session')}}),

	putClientGroupFieldsField: (id,data) => api.put(sessionStorage.getItem('backend')+'/clientfield/field/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	updateSequenceGroupFields: (data) => api.put(sessionStorage.getItem('backend')+'/clientfield/updatesequence/',data,{headers:{session:sessionStorage.getItem('session')}}),


	postContract: (data) => api.post(sessionStorage.getItem('backend')+'/contract/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putContract: (id,data) => api.put(sessionStorage.getItem('backend')+'/contract/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	getContracts: () => api.get(sessionStorage.getItem('backend')+'/contract/',{headers:{session:sessionStorage.getItem('session')}}),
	getContract: (id) => api.get(sessionStorage.getItem('backend')+'/contract/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	deleteContract: (id) => api.delete(sessionStorage.getItem('backend')+'/contract/'+id,{headers:{session:sessionStorage.getItem('session')}}),



	postContractLicense: (data) => api.post(sessionStorage.getItem('backend')+'/contract_license/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putContractLicense: (id,data) => api.put(sessionStorage.getItem('backend')+'/contract_license/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	getContractsLicense: () => api.get(sessionStorage.getItem('backend')+'/contract_license/',{headers:{session:sessionStorage.getItem('session')}}),
	getContractLicense: (id) => api.get(sessionStorage.getItem('backend')+'/contract_license/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	deleteContractLicense: (id) => api.delete(sessionStorage.getItem('backend')+'/contract_license/'+id,{headers:{session:sessionStorage.getItem('session')}}),


	postNotice: (data) => api.post(sessionStorage.getItem('backend')+'/notice/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putNotice: (id,data) => api.put(sessionStorage.getItem('backend')+'/notice/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	getNotices: () => api.get(sessionStorage.getItem('backend')+'/notice/',{headers:{session:sessionStorage.getItem('session')}}),
	getNotice: (id) => api.get(sessionStorage.getItem('backend')+'/notice/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	deleteNotice: (id) => api.delete(sessionStorage.getItem('backend')+'/notice/'+id,{headers:{session:sessionStorage.getItem('session')}}),




	getPlans: (id) => api.get(sessionStorage.getItem('backend')+'/paymentplan/',{headers:{session:sessionStorage.getItem('session')}}),
	
	getPlan: (id) => api.get(sessionStorage.getItem('backend')+'/paymentplan/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postPlan: (data) => api.post(sessionStorage.getItem('backend')+'/paymentplan/',data,{headers:{session:sessionStorage.getItem('session')}}),
	 
	putPlan: (id,data) => api.put(sessionStorage.getItem('backend')+'/paymentplan/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os items do plano
	getItems: (id,data) => api.get(sessionStorage.getItem('backend')+'/paymentplan/getitems/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getCousesPlan: (id) => api.get(sessionStorage.getItem('backend')+'/paymentplan/getcourses/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	getLicensesPlan: (id) => api.get(sessionStorage.getItem('backend')+'/paymentplan/getlicenses/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getProducts: (id) => api.get(sessionStorage.getItem('backend')+'/product/' ,{headers:{session:sessionStorage.getItem('session')}}),

	getProductsApiLms: () => api.get(sessionStorage.getItem('backend')+'/api_lms/' ,{headers:{session:sessionStorage.getItem('session')}}),
	putProductsApiLms: (data) => api.put(sessionStorage.getItem('backend')+'/api_lms/',{array:data},{headers:{session:sessionStorage.getItem('session')}}),
	


	postProduct: (data) => api.post(sessionStorage.getItem('backend')+'/product/',data,{headers:{session:sessionStorage.getItem('session')}}),

	deleteProduct: (id) => api.delete(sessionStorage.getItem('backend')+'/product/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	putProduct: (id,data) => api.put(sessionStorage.getItem('backend')+'/product/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	getProduct: (id) => api.get(sessionStorage.getItem('backend')+'/product/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	 
	getPlanOfplansofaccount: (id) => api.get(sessionStorage.getItem('backend')+'/plansofaccount/',{headers:{session:sessionStorage.getItem('session')}}),
	
	getProductValues: (id) => api.get(sessionStorage.getItem('backend')+'/product/values/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	saveProductValues: (id,data) => api.put(sessionStorage.getItem('backend')+'/product/savevalues/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
 
	//Para mudar a sequencia
	changeSequencePlan: (data) => api.put(sessionStorage.getItem('backend')+'/paymentplan/changesequence/',data,{headers:{session:sessionStorage.getItem('session')}}),
	 
	//geradores de arquivo de configuração
	generateNginx: (license,data) => api.post(sessionStorage.getItem('backend')+'/license/generator/'+license+'/nginx/',data,{headers:{session:sessionStorage.getItem('session')}}),
	generatePM2: (license,data) => api.post(sessionStorage.getItem('backend')+'/license/generator/'+license+'/config/',data,{headers:{session:sessionStorage.getItem('session')}}),
	generateFront: (license,data) => api.post(sessionStorage.getItem('backend')+'/license/generator/'+license+'/front/',data,{headers:{session:sessionStorage.getItem('session')}}),
	installDatabase: (license,data) => api.post(sessionStorage.getItem('backend')+'/license/generator/'+license+'/mysql/',data,{headers:{session:sessionStorage.getItem('session')}}),
	
	//generateMysql: (license,data) => api.post(sessionStorage.getItem('backend')+'/license/generator/'+license+'/nginx/',data,{headers:{session:sessionStorage.getItem('session')}}),
	//changeSequencePlan: (data) => (license,data) => api.post(sessionStorage.getItem('backend')+'/license/generator/'+license+'/nginx/',data,{headers:{session:sessionStorage.getItem('session')}}),
	 
	deleteAccesslevel: (id) => api.delete(sessionStorage.getItem('backend')+'/accesslevel/group/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	configuration: (id) => api.get(sessionStorage.getItem('backend')+'/configuration/'),
	
	getConfigurationReal: () => api.get(sessionStorage.getItem('backend')+'/configurationreal/',{headers:{session: sessionStorage.getItem('session')}}),

	saveConfiguration: (obj) => api.put(sessionStorage.getItem('backend')+'/configuration/',obj,{headers: {session: sessionStorage.getItem('session')}}),

	postGroupOfPlan: (obj) => api.post(sessionStorage.getItem('backend')+'/plansofaccount/group/',obj,{headers: {session: sessionStorage.getItem('session')}}),
	putGroupOfPlan: (id,data) => api.put(sessionStorage.getItem('backend')+'/plansofaccount/group/'+id,data,{headers:{session: sessionStorage.getItem('session')}}),
	getGroupOfPlans: () => api.get(sessionStorage.getItem('backend')+'/plansofaccount/group/',{headers:{session: sessionStorage.getItem('session')}}),
	deleteGroupOfPlans: (id) => api.delete(sessionStorage.getItem('backend')+'/plansofaccount/group/'+id,{headers:{session: sessionStorage.getItem('session')}}),

	getPlansOfAccount: () => api.get(sessionStorage.getItem('backend')+'/plansofaccount/',{headers:{session: sessionStorage.getItem('session')}}),
	postPlansOfAccount: (data) => api.post(sessionStorage.getItem('backend')+'/plansofaccount/',data,{headers:{session: sessionStorage.getItem('session')}}),
	putPlansOfAccount: (id,data) => api.put(sessionStorage.getItem('backend')+'/plansofaccount/'+id,data,{headers:{session: sessionStorage.getItem('session')}}),
	deletePlansOfAccount: (id,data) => api.delete(sessionStorage.getItem('backend')+'/plansofaccount/'+id,{headers:{session: sessionStorage.getItem('session')}}),

	getPriceRange: () => api.get(sessionStorage.getItem('backend')+'/pricerange/',{headers:{session: sessionStorage.getItem('session')}}),

	postPriceRange: (data) => api.post(sessionStorage.getItem('backend')+'/pricerange/',data,{headers:{session: sessionStorage.getItem('session')}}),
	putPriceRange: (id,data) => api.put(sessionStorage.getItem('backend')+'/pricerange/'+id,data,{headers:{session: sessionStorage.getItem('session')}}),
	deletePriceRange: (id,data) => api.delete(sessionStorage.getItem('backend')+'/pricerange/'+id,{headers:{session: sessionStorage.getItem('session')}}),

	savePrice: (id,data) => api.put(sessionStorage.getItem('backend')+'/pricerange/save/'+id,data,{headers:{session: sessionStorage.getItem('session')}}),

	crm_report: (license,crmname,extra) => api.get(sessionStorage.getItem('backend')+'/crm_report/'+license+'?funnelname='+crmname+extra,{headers:{session: sessionStorage.getItem('session')}}),

	 
	getReportRegistration: (startDate,endDate) => api.get(sessionStorage.getItem('backend')+'/report/registration?startDate='+startDate+'&endDate='+endDate,{headers:{session: sessionStorage.getItem('session')}}),	
	getReportClientregister: (startDate,endDate) => api.get(sessionStorage.getItem('backend')+'/report/clientregister?startDate='+startDate+'&endDate='+endDate,{headers:{session: sessionStorage.getItem('session')}}),
	getReportInvoicesPaid: (startDate,endDate) => api.get(sessionStorage.getItem('backend')+'/report/invoicesPaid?startDate='+startDate+'&endDate='+endDate,{headers:{session: sessionStorage.getItem('session')}}),
	getReportWinoportunity: (startDate,endDate) => api.get(sessionStorage.getItem('backend')+'/report/winoportunity?startDate='+startDate+'&endDate='+endDate,{headers:{session: sessionStorage.getItem('session')}}),
	getReportProductvend: (startDate,endDate) => api.get(sessionStorage.getItem('backend')+'/report/productvend?startDate='+startDate+'&endDate='+endDate,{headers:{session: sessionStorage.getItem('session')}}),
 
	searchInvoices: (license,order,status,search,limit,page,startDate,endDate,data) => api.post(sessionStorage.getItem('backend')+'/invoice/search?license='+license+'&limit='+limit+'&page='+page+'&search='+search+'&status='+status+'&order='+order+'&startDate='+startDate+'&endDate='+endDate,data,{headers:{session:sessionStorage.getItem('session')}}),


	getNatureOperations: (id) => api.get(sessionStorage.getItem('backend')+'/natureoperations',{headers:{session:sessionStorage.getItem('session')}}),
	getNatureOperation: (id) => api.get(sessionStorage.getItem('backend')+'/natureoperation/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	postNatureOperation: (data) => api.post(sessionStorage.getItem('backend')+'/natureoperation',data,{headers:{session:sessionStorage.getItem('session')}}),
	putNatureOperation: (id,data) => api.put(sessionStorage.getItem('backend')+'/natureoperation/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteNatureOperation: (id) => api.delete(sessionStorage.getItem('backend')+'/natureoperation/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	testMetabase: () => api.get(sessionStorage.getItem('backend')+'/metabase/test',{headers:{session:sessionStorage.getItem('session')}}),
	syncMetabase: () => api.get(sessionStorage.getItem('backend')+'/metabase/sync',{headers:{session:sessionStorage.getItem('session')}}),
	
	 
	deleteRc: (id) => api.delete(sessionStorage.getItem('backend')+'/registration_cancel/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getRcs: () => api.get(sessionStorage.getItem('backend')+'/registration_cancel',{headers:{session:sessionStorage.getItem('session')}}),
	postRc: (data) => api.post(sessionStorage.getItem('backend')+'/registration_cancel',data,{headers:{session:sessionStorage.getItem('session')}}),
	putRc: (id,data) => api.put(sessionStorage.getItem('backend')+'/registration_cancel/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	getModalitys: () => api.get(sessionStorage.getItem('backend')+'/course_modality',{headers:{session:sessionStorage.getItem('session')}}),
	getCategorys: () => api.get(sessionStorage.getItem('backend')+'/course_category',{headers:{session:sessionStorage.getItem('session')}}),

	 
	getCouseTypes: () => api.get(sessionStorage.getItem('backend')+'/course_type',{headers:{session:sessionStorage.getItem('session')}}),
	getCouseLevels: () => api.get(sessionStorage.getItem('backend')+'/course_level',{headers:{session:sessionStorage.getItem('session')}}),

	 

	postModality: (data) => api.post(sessionStorage.getItem('backend')+'/course_modality',data,{headers:{session:sessionStorage.getItem('session')}}),
	putModality: (id,data) => api.put(sessionStorage.getItem('backend')+'/course_modality/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteModality: (id) => api.delete(sessionStorage.getItem('backend')+'/course_modality/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	

	postCategory: (data) => api.post(sessionStorage.getItem('backend')+'/course_category',data,{headers:{session:sessionStorage.getItem('session')}}),
	putCategory: (id,data) => api.put(sessionStorage.getItem('backend')+'/course_category/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteCategory: (id) => api.delete(sessionStorage.getItem('backend')+'/course_category/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	

	 


	postCourseType: (data) => api.post(sessionStorage.getItem('backend')+'/course_type',data,{headers:{session:sessionStorage.getItem('session')}}),
	putCourseType: (id,data) => api.put(sessionStorage.getItem('backend')+'/course_type/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteCourseType: (id) => api.delete(sessionStorage.getItem('backend')+'/course_type/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postCourseLevel: (data) => api.post(sessionStorage.getItem('backend')+'/course_level',data,{headers:{session:sessionStorage.getItem('session')}}),
	putCourseLevel: (id,data) => api.put(sessionStorage.getItem('backend')+'/course_level/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteCourseLevel: (id) => api.delete(sessionStorage.getItem('backend')+'/course_level/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getComissionsReport: (extra) => api.get(sessionStorage.getItem('backend')+'/report/comission'+extra,{headers:{session:sessionStorage.getItem('session')}}),

	 

}

export default apis;