import React from "react";
import api from "./../../Api.js";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import Report from "./crm/Report.jsx";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";

class Admin extends React.Component {

    constructor(props) {
    
        super(props);

        this.state = {namecrm:"",okcrm:false,licenses:[],tamanho:'4',ocultERROR:true};

        this.state.startDate = moment( moment().format('YYYY-MM')+'-01' ,'YYYY-MM-DD');
        var end = moment().format('YYYY')+'-'+moment().format('MM')+'-'+moment( ).endOf('month').format('DD');
        this.state.endDate = moment(end,'YYYY-MM-DD');
        this.getLicenses = this.getLicenses.bind(this);

        this.getClassNameReactDatetimeDays = this.getClassNameReactDatetimeDays.bind(this);
        this.handleReactDatetimeChange = this.handleReactDatetimeChange.bind(this);

        window.crm = this;

    }

    getClassNameReactDatetimeDays = date => { if (this.state.startDate && this.state.endDate) { } if ( this.state.startDate && this.state.endDate && this.state.startDate._d + "" !== this.state.endDate._d + "" ) { if ( new Date(this.state.endDate._d + "") > new Date(date._d + "") && new Date(this.state.startDate._d + "") < new Date(date._d + "") ) { return " middle-date"; } if (this.state.endDate._d + "" === date._d + "") { return " end-date"; } if (this.state.startDate._d + "" === date._d + "") { return " start-date"; } } return "";};
  
    handleReactDatetimeChange = async (who, date) => { 
        
        if(typeof date !== 'object'){ return false; }

        if ( this.state.startDate && who === "endDate" && new Date(this.state.startDate._d + "") > new Date(date._d + "") ) { 

            await this.setState({startDate: date,endDate: date}); 
        
        } else if ( this.state.endDate && who === "startDate" && new Date(this.state.endDate._d + "") < new Date(date._d + "") ) { await this.setState({ startDate: date,endDate: date});}else {this.setState({[who]: date});}
         
    };

    getLicenses(){

        api.getAllLicense().then(async (res) => { 

            this.setState({licenses:res.data});

        }).catch((error) => {});

    }

    componentDidMount(){

        this.getLicenses(); 

    }
 
    componentWillUnmount(){

  

    }
    
    render(){

        return (<React.Fragment>

            <SimpleHeader history={this.props.history} name={'Relatório'} way={[{name:"Relatório",url:"/report/crm"},{name:"CRM",url:"/report/crm"}]} buttons={<React.Fragment></React.Fragment>} />     
            <div class="mt--6 container-fluid">
                <div class="justify-content-center row">
                    <div class="card-wrapper col-lg-8">
                        <div class="card bg-secondary">
                            <div class="card-body">
                                <div><h3 class="mb-0">Informe o nome do crm.</h3></div>
                                <div style={{marginTop:'5px',marginBottom:'10px'}}><Input disabled={this.state.okcrm} value={this.state.namecrm} onChange={(e) => this.setState({namecrm:e.target.value})} className="form-control-alternative" placeholder="" type="text" /></div>
                                <div style={{display:'flex'}}>
                                    <div style={{display:'flex',marginRight:'10px'}}>
                                        <div style={{paddingTop:'13px',paddingRight:'10px'}}><h5>Tamanho:</h5></div>
                                        <div>
                                            <select className="form-control" value={this.state.tamanho} onChange={(e) => { this.setState({tamanho:e.target.value}); }}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </div>
                                    { /* Time Picker */}
                                    <div className="reactdate" style={{marginRight:'10px'}}>
                                        <div><ReactDatetime disabled={true} locale="pt-br" style={{height:'31px'}} inputProps={{placeholder:"",disabled: this.state.lockDates}} value={this.state.startDate} timeFormat={false} onChange={async e => { await this.handleReactDatetimeChange("startDate", e); }}renderDay={(props, currentDate, selectedDate) => {let classes = props.className;classes += this.getClassNameReactDatetimeDays(currentDate);return (<td {...props} className={classes}>{currentDate.date()}</td>);}}/></div>
                                        <i class="far fa-calendar-alt" style={{backgroundColor:'white',padding:'6px'}}></i>
                                        <div><ReactDatetime locale="pt-br" inputProps={{placeholder:"",disabled: this.state.lockDates}} value={this.state.endDate} timeFormat={false} onChange={async e => { await this.handleReactDatetimeChange("endDate", e); }}renderDay={(props, currentDate, selectedDate) => {let classes = props.className;classes += this.getClassNameReactDatetimeDays(currentDate);return (<td {...props} className={classes}>{currentDate.date()}</td>);}}/></div> 
                                    </div>
 
                                    <div style={{paddingTop:'10px',marginRight:'10px'}} className="custom-control custom-checkbox">
                                        <input onChange={(e) => this.setState({ocultERROR:e.target.checked})} checked={this.state.ocultERROR} className="custom-control-input" id="customCheck1" type="checkbox" />
                                        <label className="custom-control-label" htmlFor="customCheck1">Ocultar erros</label>
                                    </div>
                                    
                                    <button hidden={this.state.okcrm} onClick={() => { if(this.state.okcrm === false && this.state.namecrm !== ""){ this.setState({okcrm:true}); } }} type="button" class="btn btn-primary">Gerar relatório</button>
                                    <button hidden={!this.state.okcrm}  onClick={() => { this.setState({okcrm:false}); }} type="button" class="btn btn-danger">Resetar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {

                //(this.state.okcrm === true) &&
                <React.Fragment>
                    <div className="row" style={{marginLeft:'25px',marginRight:'25px'}}>
                        {
                            (this.state.okcrm === true) &&
                            this.state.licenses.map((value,index) => {

                                if(value.status === 1){ return(<Report ocultERROR={this.state.ocultERROR} startDate={this.state.startDate.format('YYYY-MM-DD')} endDate={this.state.endDate.format('YYYY-MM-DD')} size={this.state.tamanho} key={'license_'+value.id} name={value.name} data={value} license={value.license} crmname={this.state.namecrm} />); }
                                if(value.status !== 1){ return(<React.Fragment key={'license_'+value.id}></React.Fragment>); }

                            })

                        }
                    </div>
                </React.Fragment>

            }
        </React.Fragment>)
    
    }

}

export default Admin;