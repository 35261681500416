import React from "react"; 
import api from "./../../../Api.js";
import {BarLoader} from "react-spinners";

class ListItens extends React.Component {
  
    constructor(props){

        super(props);
        this.state = {
            loadProductvend:false,
            startDate:undefined,
            endDate:undefined,
            data:[],
            products:[],
        };

        this.getProducts = this.getProducts.bind(this);
    
      }

    async componentDidUpdate(){

        var update = false;
        if(this.state.startDate !== this.props.startDate){ update = true; this.state.startDate = this.props.startDate; }
        if(this.state.endDate !== this.props.endDate){ update = true; this.state.endDate = this.props.endDate; }
    
        if(update === true){
    
            await this.getReportProductvend(); 
    
        }
    
    }

    async getReportProductvend(){

        await this.setState({loadProductvend:false});
        await api.getReportProductvend(this.props.startDate,this.props.endDate).then(async (res) => { 
            
            res.data.sort(function (a, b) { if (a.totalValue > b.totalValue) { return -1; } if (a.totalValue < b.totalValue) { return 1; } return 0; });
            this.setState({loadProductvend:true,data:res.data});
      
        }).catch((error) => {});
    
    }
    
    async getProducts(){

		await api.getProducts().then((res) => {
            
            this.setState({products:res.data});
   
  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

    componentDidMount(){ 
        
        this.setState({a:'1'});
        this.getProducts();

    }

    render(){
 
    console.log(this.state.data);

    return(
        <React.Fragment>
            <div class="card">
                <div class="border-0 card-header">
                    <div class="align-items-center row">
                        <div class="col"><h3 class="mb-0">Items vendidos</h3></div>
                    </div>
                </div>
                {

                    (this.state.loadProductvend === true && this.state.data.length !== 0) &&
                    <React.Fragment>
                        <style>{' .table_ddsa{ overflow-y:auto; height:350px; } '}</style>
                    </React.Fragment>

                }
                <div class="table-responsive table_ddsa" >
                    <table class="align-items-center table-flush table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">id_center</th>
                                <th scope="col">Valor total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                this.state.data.map((value,index) => {

                                    var name = "";
                                    var pr = this.state.products.find(x => x.id_name === value.id_center);
                                    if(pr !== undefined){
                                        
                                        name = pr.name;

                                    }

                                    return( <tr>
                                                <td>{name}</td>
                                                <td>{value.id_center}</td>
                                                <td>{value.totalValue}</td>
                                            </tr>)

                                })

                            }
                            
                        </tbody>
                    </table>
                    {

                        (this.state.loadProductvend === true && this.state.data.length === 0) &&
                        <React.Fragment>
                            <div style={{textAlign:'center',marginTop:'20px',marginBottom:'20px'}}>Nenhum produto foi encontrado.</div>
                        </React.Fragment>

                    }
                    {

                        (this.state.loadProductvend === false) &&
                        <React.Fragment>
                            <div style={{display:'flex',justifyContent:'center',textAlign:'center',marginTop:'20px',marginBottom:'20px'}}><BarLoader color="#b7b7b7"/></div>
                        </React.Fragment>

                    } 
                </div>
            </div>
        </React.Fragment>
    );

  }
}

export default ListItens;
