import React from "react";
import {Card,CardHeader,ListGroupItem,ListGroup,Container,Row,Col} from "reactstrap";
import {} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import { Link } from "react-router-dom";

class ListCourse extends React.Component {

	constructor(props){

		super(props);
		
		this.getUnitCourse = this.getUnitCourse.bind(this);
		this.state = {courses:[],loadCourse:false};

		window.course = this;

	}

	//Para pegar a unit
  	async getUnitCourse(){

		var unit = await api.course().then((res) => {
		    
			this.setState({courses:res.data,loadCourse:true});
			return true;

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);
			return false; 
		});

		return unit;
  	}

	async componentDidMount(){
		
		await this.getUnitCourse();

	}

	render(){

		return(
			<React.Fragment> 
				<SimpleHeader name="Cursos" way={[{name:"Cursos",url:this.props.history.location.pathname}]} buttons={<React.Fragment></React.Fragment>} />
			    <Container className="mt--6" fluid>
				    <Card>
						<CardHeader>
							<Row><Col xs="6"><h3 className="mb-0">Cursos</h3></Col></Row>
						</CardHeader>
						<ListGroup flush>
					    	{
					    		this.state.courses.map((value,index) => { 
									
									var img_1,url;

					    			if(value.iconData !== undefined){ img_1 = <img style={{backgroundColor:'#adb5bd00'}} alt="..." className="avatar " src={value.iconData.s3url} />; }else{ img_1 = <img style={{backgroundColor:'#adb5bd00'}} alt="..." className="avatar " src={''} />; }
					    			//if(value.background_file !== undefined){ img_2 = <img style={{backgroundColor:'#adb5bd00'}} alt="..." className="avatar " src={''} />; }else{ img_2 = <img style={{backgroundColor:'#adb5bd00'}} alt="..." className="avatar " src={''} />; }

									return(<Link to={'/course/'+value.id}>
									          	<li style={{cursor:"pointer"}}  className="list-group-item-action list-group-item">
													<Row className="align-items-center">
														<Col className="col-auto">{img_1}</Col>
														<div className="col ml--2">
															<div className="d-flex justify-content-between align-items-center">
																<div>
																	<h4 className="mb-0 text-sm">{value.name}</h4>
																</div>
																<div className="text-right text-muted" hidden>
																	<small>2 hrs ago</small>
																</div>
															</div>
															<p className="text-sm mb-0">{value.description}</p>
														</div>
													</Row>
									          	</li>
											</Link>)

								})
					    	}
				        </ListGroup>
				    </Card>
				</Container>
			</React.Fragment>
		)
	}
}
export default ListCourse;