import React from "react";    

class StatusInvoice extends React.Component {

   // constructor(props){

        //super(props);

    //}

    render(){

        var s = {height:'8px',width:'8px'};

        return(
            <React.Fragment>
                {

                    (this.props.status === "pending" && this.props.value.financialOK === true) &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i style={s} class="bg-warning"></i><span class="status">A receber</span></span>
                    </React.Fragment>

                }
                {

                    (this.props.status === "pending" && this.props.value.financialOK === false) &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i style={s} class="bg-danger"></i><span class="status">Vencida</span></span>
                    </React.Fragment>

                }
                {

                    (this.props.status === "canceled") &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i style={s} class="bg-danger"></i><span class="status">Cancelada</span></span>
                    </React.Fragment>

                }
                {

                    (this.props.status === "paid") &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i style={s} class="bg-success"></i><span class="status">Paga</span></span>
                    </React.Fragment>

                }
                {

                    (this.props.status === null) &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i style={s} class="bg-warning"></i><span class="status">Não faturado</span></span>
                    </React.Fragment>

                }
                {

                    (this.props.status === "in_analysis") &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i style={s} style={{backgroundColor:"#000"}}></i><span class="status">Em análise</span></span>
                    </React.Fragment>

                }
                {

                    (this.props.status === "refunded") &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i class="bg-info"></i><span class="status">Reembolsada</span></span>
                    </React.Fragment>

                }
                {

                    (this.props.status === "chargeback") &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i class="bg-info"></i><span class="status">Contestada</span></span>
                    </React.Fragment>

                }
                {

                    (this.props.status === "partially_paid") &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i class="bg-info"></i><span class="status">P. pago</span></span>
                    </React.Fragment>

                }
                {

                    (this.props.status === "partially_refunded") &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i class="bg-info"></i><span class="status">P. reembolsada</span></span>
                    </React.Fragment>

                }
                {

                   (this.props.value.reissued !== null) &&
                   <React.Fragment><font style={{position:'absolute',marginLeft:'-108px',marginTop:'22px'}}>Reemitida (ID:{this.props.value.reissued})</font></React.Fragment> 

                }
                {

                    (this.props.status === "expired") &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i style={s} class="bg-warning"></i><span class="status">Expirado</span></span>
                    </React.Fragment>

                }

                {

                    (this.props.status === "in_protest") &&
                    <React.Fragment>
                        <span class="ddbad_cus badge-dot mr-4 badge badge-"><i style={s} class="bg-danger"></i><span class="status">Em protesto</span></span>
                    </React.Fragment>

                }
            </React.Fragment>
        );

    }
}

export default StatusInvoice;