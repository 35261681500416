import React from "react";
import api from "./../../Api.js";

import $ from 'jquery';
import {UncontrolledAlert,CardBody,FormGroup,Input,Button,Card,CardHeader,Progress,Container,Row,Col,Modal,Alert,InputGroup,InputGroupAddon,InputGroupText} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import axios from 'axios';
import { RingLoader } from 'react-spinners';

class NewCourse extends React.Component {

  	constructor(props){

		super(props);
        this.state = {showCard:true,erroCourse:"",selectPlaClone:"",
            
            dialog:{onlineOnly:"0",name:"",alias:"",paymentMethod:"1",totalFaturas:"",installment:"",allowRefund:"",status:"",benefits:[],skipOnlineRegistration:"0",standardContract:"",checkCourse:[]},
            items:[],
            courses:[],
            licenses:[], 
            checkLicense:[],
            plans:[],
            contracts:[],
            products:[],
            openModalSubscription:false
            
        };
        
        this.putPlan = this.putPlan.bind(this);
        this.getPlans = this.getPlans.bind(this);
        this.newItem = this.newItem.bind(this);
        this.changeItem = this.changeItem.bind(this);
        this.getItems = this.getItems.bind(this);
        this.newItemCustom = this.newItemCustom.bind(this);
        this.changePlanCustom = this.changePlanCustom.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.removeCustomItem = this.removeCustomItem.bind(this);
        this.getProducts = this.getProducts.bind(this);

        this.getLicense = this.getLicense.bind(this);
        this.getCourses = this.getCourses.bind(this);
 
        this.setCheckLicense = this.setCheckLicense.bind(this);

        this.getCousesPlan = this.getCousesPlan.bind(this);
        this.getLicensesPlan = this.getLicensesPlan.bind(this);
        this.openModalClone = this.openModalClone.bind(this);
        this.getClonePlans = this.getClonePlans.bind(this);
        this.getPlansList = this.getPlansList.bind(this);

        this.renderListItem = this.renderListItem.bind(this);
        this.addItemArray = this.addItemArray.bind(this);
        this.getContracts = this.getContracts.bind(this);

        window.newCourse = this;

    }

    getProducts(){

        api.getProducts().then((res) => {
            
        	this.setState({products:res.data}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

          });
          

    }

    async getContracts(){

        await api.getContracts().then((res) => {
            
        	this.setState({contracts:res.data}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

          });
          
    }


    renderListItem(array){

		if(array.length === 0){ return <div style={{textAlign:'center',fontSize:'12px'}}>Nenhum item foi adicionado.</div>; }
		
		return array.map((value,index) => {

			return(<React.Fragment key={'d1_'+index}>

				<div style={{display:'flex'}}>
                    
                <i onClick={ () => {

                    array.splice(index,1);
                    this.setState({a:'1'});

                }} class="fas fa-trash-alt" style={{marginRight:'8px',fontSize:'14px',marginTop:'4px',cursor:'pointer'}}></i>
                
                <input onChange={ (e) =>{

					array[index] = e.target.value;
					this.setState({a:''});
					console.log('data');

				}} className={'form-control'} type="text" value={value} style={{height:'22px',marginBottom:'5px'}} /></div>

			</React.Fragment>)

		})

	}
	
	addItemArray(array){

		array.push('');
		this.setState({a:''});

	}

    async getClonePlans(){
        
        if(this.state.selectPlaClone === ""){ return false; }

        await this.getPlans(this.state.selectPlaClone); 
        await this.getItems(this.state.selectPlaClone); 
        await this.getCourses(this.state.selectPlaClone);
        await this.getCousesPlan(this.state.selectPlaClone);
        await this.getLicensesPlan(this.state.selectPlaClone);
        this.setState({openModalClone:false});

    }

    //Para alterar os dados de um items 
    newItem(){

        var items = this.state.items;
        items.push({product:"",description:"",quantity:"0",value:"0",discountMax:"0",allowSeparate:"0",forceCustom:"0",allowIgnore:"0",skipOnlineRegistration:"0",customSeparated:[],comission:"",allowChangeQuantity:"0"});
        this.setState({items});

    }

    //Para alterar os dados de um items 
    newItemCustom(index){

        var items = this.state.items;
        items[index]['customSeparated'].push({name:"",info:"",paymentMethod:"1",totalFaturas:"0",installment:"0",allowRefund:"0"});
        this.setState({items});

    }

    async putPlan(){

        var obj = JSON.parse(JSON.stringify(this.state.dialog));
        obj.items = this.state.items;
        
        obj.checkLicense = this.state.checkLicense; 
        
        console.log(obj);

        await api.putPlan(this.props.match.params.id,obj).then((res) => {
            
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As alterações foram salvas.</span></div>),type:'success',icon:"ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
           
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível cadastrar a lesson.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        });

    }

    async getPlans(id){

        if(id === undefined){ id = this.props.match.params.id; }else{ var seguraname = true; }

        await api.getPlan(id).then((res) => {
            
            if(seguraname === true){ res.data.name = this.state.dialog.name; }
            this.setState({dialog:res.data});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
            
            alert(error.response.data.msg);

        });

    }


    async getCousesPlan(id){

        if(id === undefined){ id = this.props.match.params.id; }

        await api.getCousesPlan(id).then((res) => {
            
            this.setState({checkCourse:res.data});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
            
            alert(error.response.data.msg);

        });

    }

    async getLicensesPlan(id){

        if(id === undefined){ id = this.props.match.params.id; }

        await api.getLicensesPlan(id).then((res) => {
            
            console.log(res.data);
            this.setState({checkLicense:res.data});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
            
            alert(error.response.data.msg);

        });

    }

    async getPlansList(){ 

		await api.getPlans().then((res) => {
            
        	this.setState({plans:res.data}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

    }
    
    async getItems(id){

        if(id === undefined){ id = this.props.match.params.id; }

        await api.getItems(id).then((res) => {
            
            this.setState({items:res.data});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
            
            alert(error.response.data.msg);

        });

    }

    async getLicense(){

        //licenses
        await api.getAllLicense().then((res) => {
            
            this.setState({licenses:res.data});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
            
            alert(error.response.data.msg);

        });

    }

    async getCourses(){

        //courses
        await api.course().then((res) => {
            
            this.setState({courses:res.data});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
            
            alert(error.response.data.msg);

        });

    }

	async componentDidMount(){

        await this.getContracts();
        await this.getPlansList(); 
        await this.getPlans(); 
        await this.getItems();
        await this.getLicense();
        await this.getCourses();
        await this.getCousesPlan();
        await this.getLicensesPlan();
        await this.getProducts();
        
    }

    changeItem(itemIndex,name,value){

        var items = this.state.items;
        items[itemIndex][name] = value;
        this.setState({items});

    }

    changePlanCustom(itemIndex,planIndex,name,value){

        var items = this.state.items;
        
        console.log(itemIndex);
        console.log(planIndex);

        items[itemIndex].customSeparated[planIndex][name] = value;
        console.log(items[itemIndex].customSeparated[planIndex]);

        this.setState({items});

    }

    //para remover um item
    removeItem(index){

        var items = this.state.items;
        items.splice(index,1);
        this.setState({items});

    }

    //para remover um item
    removeCustomItem(index,index2){

        var items = this.state.items;
        items[index].customSeparated.splice(index2,1);
        this.setState({items});

    }

    /*async setCheckCourse(id,status){

        var checkCourse = this.state.checkCourse;
        if(status === true){ checkCourse.push(id); }
        if(status === false){ var index = checkCourse.indexOf(id); checkCourse.splice(index,1); }
        await this.setState({checkCourse}); 

    }*/

    async setCheckLicense(id,status){

        var checkLicense = this.state.checkLicense;
        if(status === true){ checkLicense.push(id); }
        if(status === false){ var index = checkLicense.indexOf(id); checkLicense.splice(index,1); }
        await this.setState({checkLicense}); 

    }

    openModalClone(){

        this.setState({openModalClone:true});

    }
     

	render(){

			var title,buttonFooter,simpleHED;

			const handleChange = name => event => {
		        var dialog = this.state.dialog;
		        dialog[name] = event.target.value;
		        this.setState({dialog:dialog});
		    }
  
			return(
		    <React.Fragment>
		        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
	
                    <SimpleHeader history={this.props.history} name={'Editando plano'} way={[{name:"Planos",url:"/plans"}]} buttons={<React.Fragment></React.Fragment>} />
          	        
          	        <Container className="mt--6" fluid>
		          		<Row className="justify-content-center">
		            		<Col className="card-wrapper" lg="12">
								<Card>
									<CardHeader>
									    <Row>
                                            <Col xs="6"><h3 className="mb-0">Editar plano</h3></Col>
                                            <div class="text-right col-6">
                                                <button onClick={ () => this.openModalClone() } type="button" class="btn-neutral btn-round btn-icon btn btn-secondary btn-sm"><span class="btn-inner--icon mr-1"><i class="fas fa-plus"></i></span><span class="btn-inner--text">Sobrescrever tudo a partir de outro plano</span></button>
                                            </div>
                                        </Row>
									</CardHeader>

									{

					                    (this.state.showCard === false) &&
					                    <center style={{margin:'40px'}}><RingLoader sizeUnit={"px"} size={60} color={'#32325d'} loading={true} /></center>

				                  	}

									{ 

                                    (this.state.showCard === true) && 
 
									<CardBody>
										{
											(this.state.erroCourse !== "") &&
											<UncontrolledAlert className="animated bounceIn" color="danger" fade={false}>
									          <span className="alert-inner--icon"><i className="ni ni-like-2" /></span>
									          <span className="alert-inner--text"><strong>Ops!</strong> {this.state.erroCourse}</span>
									        </UncontrolledAlert>
										}
										<form type="post" onSubmit={ (event) => this.saveCourse(event) } >
										<h6 className="heading-small text-muted mb-4">Informações gerais</h6>
					                    <div className="pl-lg-4">
					                      <Row>
                                          <Col lg="6">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Nome do plano:</label>
					                            <Input defaultValue="" onChange={handleChange('name')} value={ this.state.dialog.name } d="input-username" type="text" />
					                          </FormGroup>
					                        </Col>

                                            <Col lg="6">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Alias:</label>
					                            <Input defaultValue="" onChange={handleChange('alias')} value={ this.state.dialog.alias } d="input-username" type="text" />
					                          </FormGroup>
					                        </Col>

					                        <Col lg="2">
                                                <FormGroup>
                                                    <label className="form-control-label">Status:</label>
                                                    <select className="form-control" onChange={handleChange('status')} value={ this.state.dialog.status } type="text">
                                                        <option value=""></option>
                                                        <option value="1">Disponível</option>
                                                        <option value="0">Não disponível</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                             
                                            <Col lg="2">
                                                <FormGroup>
                                                    <label className="form-control-label" >Método de pagamento:</label>
                                                    <select className="form-control" onChange={handleChange('paymentMethod')} value={ this.state.dialog.paymentMethod } type="text">
                                                        <option value=""></option>
                                                        <option value="1">Boleto</option>
                                                        <option value="2">Cartão</option>
                                                        <option value="3">Pix</option>
                                                        <option value="4">Todos</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>

                                            <Col hidden lg="2">
                                                <FormGroup>
                                                    <label className="form-control-label" >Exibição:</label>
                                                    <select className="form-control" onChange={handleChange('onlineOnly')} value={ this.state.dialog.onlineOnly } type="text">
                                                        <option value="0">Somente interno</option>
                                                        <option value="2">Interno e online</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            
                                            <Col lg="2">
                                                <FormGroup>
                                                    <label className="form-control-label" >Número de faturas:</label>
                                                    <input className="form-control" onChange={handleChange('totalFaturas')} value={ this.state.dialog.totalFaturas } type="text" />
                                                </FormGroup>
                                            </Col>

 
                                            {

                                                (parseInt(this.state.dialog.paymentMethod) === 2 || parseInt(this.state.dialog.paymentMethod) === 4) &&
                                                <React.Fragment>

                                                    <Col lg="2">
                                                        <FormGroup>
                                                            <label className="form-control-label" >Número de parcelas:</label>
                                                            <input className="form-control" onChange={handleChange('installment')} value={ this.state.dialog.installment } type="text" />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col lg="2">
                                                        <FormGroup>
                                                            <label className="form-control-label" >Permite reembolso:</label>
                                                            <select className="form-control" onChange={handleChange('allowRefund')} value={ this.state.dialog.allowRefund } type="text">
                                                                <option value="1">Sim</option>
                                                                <option value="0">Não</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>

                                                </React.Fragment>

                                            }

                                            <Col lg="2">
                                                <FormGroup>
                                                    <label className="form-control-label" >Tipo de plano:</label>
                                                    <select className="form-control" onChange={handleChange('typeOfPlan')} value={ this.state.dialog.typeOfPlan } type="text">
                                                        <option value=""></option>
                                                        <option value="3">Assinatura</option>
                                                        <option value="2">Matrícula presencial (Area do cliente)</option>
                                                        <option value="1">Matrícula live (Area do cliente)</option>
                                                        <option value="0">Matrícula na unidade</option>
                                                    </select>
                                                    {

                                                        (parseInt(this.state.dialog.typeOfPlan) === 3) &&
                                                        <button type="button" class="btn btn-secondary btn-sm" style={{marginTop:'10px'}} onClick={() => { this.setState({openModalSubscription:true}); }}><i class="fas fa-plus"></i> Acesso curso</button>

                                                    }
                                                </FormGroup>
                                            </Col>

                                            {

                                                (this.state.dialog.typeOfPlan == "3") &&
                                                <React.Fragment>
                                                <Col lg="2">
                                                    <FormGroup>
                                                        <label className="form-control-label" >Intervalo:</label>
                                                        <select className="form-control" onChange={handleChange('interval_m')} value={ this.state.dialog.interval_m } type="text">
                                                            <option value=""></option>
                                                            <option value="monthly">Mensal</option>
                                                            <option value="quarterly">Trimestral</option>
                                                            <option value="semester">Semestral</option>
                                                            <option value="yearly">Anual</option>
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                </React.Fragment>

                                            }

                                            <Col lg="2">
                                                <FormGroup>
                                                    <label className="form-control-label" >Contrato padrão:</label>
                                                    <select className="form-control" onChange={handleChange('standardContract')} value={ this.state.dialog.standardContract } type="text">
                                                        <option value=""></option>

                                                        {

                                                            this.state.contracts.map((value,index) => {

                                                                return(<option value={value.id} key={'dd_index_'+index}>{value.name}</option>)

                                                            })

                                                        }

                                                    </select>
                                                </FormGroup>
                                            </Col>

                                            <Col lg="2">
                                                <FormGroup>
                                                    <label className="form-control-label" >É recorrente:</label>
                                                    <select className="form-control" onChange={handleChange('recurrence')} value={ this.state.dialog.recurrence } type="text">
                                                        <option value=""></option>

                                                        <option value="0">Não</option>
                                                        <option value="1">Sim</option>

                                                    </select>
                                                </FormGroup>
                                            </Col>


                                             

                                            <Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Beneficios:</label>
												<div>
													{
														this.renderListItem(this.state.dialog.benefits)	
													}
												</div>

												<button onClick={ () => this.addItemArray(this.state.dialog.benefits) } style={{marginTop:"5px"}} type="button" class="btn btn-secondary btn-sm"><i class="fas fa-plus"></i> Adicionar</button>
											  
                                              </FormGroup>
					                        </Col>

					                      </Row>
					                    </div>

                                        <h6 className="heading-small text-muted mb-4">Items</h6>

                                        <div className="pl-lg-4">
                                            
                                            

                                            {

                                                this.state.items.map((value,index) => {

                                                    return(<React.Fragment key={'aa_'+index}>
                                                        
                                                        <div className style={{position:'relative',boxShadow:'rgb(0, 0, 0) 0px 1px 5px -2px',borderRadius: '10px',paddingLeft: '30px',paddingRight: '30px',paddingTop: '31px'}}>

                                                        <i onClick={ () => this.removeItem(index)} class="far fa-trash-alt" style={{position:'absolute',top:'15px',left:'calc(100% - 30px)',cursor:'pointer'}}></i>

                                                            <div className="row">
                                                                
                                                                <Col lg="3">
                                                                    <FormGroup>
                                                                        <label className="form-control-label">Produto:</label>
                                                                        <select className="form-control" onChange={(event) => this.changeItem(index,'product',event.target.value)} value={ this.state.items[index]['product'] } type="text">
                                                                            <option value=""></option>
                                                                            {

                                                                                this.state.products.map((value,index) => { 

                                                                                    return(<option value={value.id} key={index}>{value.name}</option>)

                                                                                })

                                                                            }
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                
                                                                {

                                                                    /*
                                                                    
                                                                    <Col lg="3" hidden>
                                                                        <FormGroup>
                                                                            <label className="form-control-label">Descrição:</label>
                                                                            <input className="form-control" onChange={(event) => this.changeItem(index,'description',event.target.value)} value={ this.state.items[index]['description'] } type="text" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    */

                                                                }
                                                                


                                                                <Col lg="1">
                                                                    <FormGroup>
                                                                        <label className="form-control-label">Quantidade:</label>
                                                                        <input className="form-control" onChange={(event) => this.changeItem(index,'quantity',event.target.value)} value={ this.state.items[index]['quantity'] } type="text" />
                                                                    </FormGroup>
                                                                </Col>
                                                                {/*
                                                                <Col lg="2">
                                                                    <FormGroup>
                                                                        <label className="form-control-label">Valor:</label>
                                                                        <input className="form-control" onChange={(event) => this.changeItem(index,'value',event.target.value)} value={ this.state.items[index]['value'] } type="text" />
                                                                    </FormGroup>
                                                                </Col>
                                                                */}
                                                                <Col lg="2">
                                                                    <FormGroup>
                                                                        <label className="form-control-label">Desconto max:</label> 
                                                                        <input className="form-control" onChange={(event) => this.changeItem(index,'discountMax',event.target.value)} value={ this.state.items[index]['discountMax'] } type="text" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="2">
                                                                    <FormGroup>
                                                                        <label className="form-control-label">Permite cobrar separado:</label>
                                                                        <select className="form-control" onChange={(event) => this.changeItem(index,'allowSeparate',event.target.value)} value={ this.state.items[index]['allowSeparate'] } type="text">
                                                                            <option value="1">Sim</option>
                                                                            <option value="0">Não</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
  
                                                                <Col lg="2">
                                                                    <FormGroup>
                                                                        <label className="form-control-label">Permite alterar quantidade:</label>
                                                                        <select className="form-control" onChange={(event) => this.changeItem(index,'allowChangeQuantity',event.target.value)} value={ this.state.items[index]['allowChangeQuantity'] } type="text">
                                                                            <option value=""></option>
                                                                            <option value="0">Não</option>
                                                                            <option value="1">Sim</option> 
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg="2">
                                                                    <FormGroup>
                                                                        <label className="form-control-label">Força plano separado:</label>
                                                                        <select className="form-control" onChange={(event) => this.changeItem(index,'forceCustom',event.target.value)} value={ this.state.items[index]['forceCustom'] } type="text">
                                                                            <option value="1">Sim</option>
                                                                            <option value="0">Não</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg="2">
                                                                    <FormGroup>
                                                                        <label className="form-control-label">Permite ignorar:</label>
                                                                        <select className="form-control" onChange={(event) => this.changeItem(index,'allowIgnore',event.target.value)} value={ this.state.items[index]['allowIgnore'] } type="text">
                                                                            <option value="1">Sim</option>
                                                                            <option value="0">Não</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                 
                                                                <Col lg="2">
                                                                    <FormGroup>
                                                                        <label className="form-control-label">Ignorar na matrícula online:</label>
                                                                        <select className="form-control" onChange={(event) => this.changeItem(index,'skipOnlineRegistration',event.target.value)} value={ this.state.items[index]['skipOnlineRegistration'] } type="text">
                                                                            <option value="1">Sim</option>
                                                                            <option value="0">Não</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                
                                                            </div>
                                                            
                                                            {

                                                                (value.customSeparated.length !== 0) &&
                                                                <div className="row">
                                                                
                                                                <h6 className="heading-small text-muted mb-4">Planos customizado para este item</h6>
                                                                    {

                                                                        value.customSeparated.map((value2,index2) =>{


                                                                            return(<React.Fragment key={'adfdf_'+index2}>
                                                                                <div className="col-lg-12" style={{position:"relative",marginTop:'20px'}}>

                                                                                <i onClick={ () => this.removeCustomItem(index,index2)} class="far fa-trash-alt" style={{position:'absolute',top:'-18px',left:'calc(100% - 30px)',cursor:'pointer'}}></i>

                                                                                    <div className="row">
                                                                                        <Col lg="2">
                                                                                            <FormGroup>
                                                                                                <label className="form-control-label">Nome:</label>
                                                                                                <input className="form-control" onChange={(event) => this.changePlanCustom(index,index2,'name',event.target.value)} value={ this.state.items[index].customSeparated[index2]['name'] } type="text" />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col lg="1">
                                                                                            <FormGroup>
                                                                                                <label className="form-control-label">Info:</label>
                                                                                                <input className="form-control" onChange={(event) => this.changePlanCustom(index,index2,'info',event.target.value)} value={ this.state.items[index].customSeparated[index2]['info'] } type="text" />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col lg="1">
                                                                                            <FormGroup>
                                                                                                <label className="form-control-label" >É recorrente:</label>
                                                                                                <select className="form-control" onChange={(event) => this.changePlanCustom(index,index2,'recurrence',event.target.value)} value={ this.state.items[index].customSeparated[index2]['recurrence'] } type="text">
                                                                                                    <option value=""></option>

                                                                                                    <option value="0">Não</option>
                                                                                                    <option value="1">Sim</option>

                                                                                                </select>
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col lg="2">
                                                                                            <FormGroup>
                                                                                                <label className="form-control-label">Método de pagamento:</label>
                                                                                                <select className="form-control" onChange={(event) => this.changePlanCustom(index,index2,'paymentMethod',event.target.value)} value={ this.state.items[index].customSeparated[index2]['paymentMethod'] } type="text">
                                                                                                    <option value=""></option>
                                                                                                    <option value="1">Boleto</option>
                                                                                                    <option value="2">Cartão</option>
                                                                                                    <option value="3">Pix</option>
                                                                                                    <option value="4">Todos</option>
                                                                                                </select>
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col lg="2">
                                                                                            <FormGroup>
                                                                                                <label className="form-control-label">Total de faturas:</label>
                                                                                                <input className="form-control" onChange={(event) => this.changePlanCustom(index,index2,'totalFaturas',event.target.value)} value={ this.state.items[index].customSeparated[index2]['totalFaturas'] } type="text" />
                                                                                            </FormGroup>
                                                                                        </Col>

                                                                                        {

                                                                                            (parseInt(this.state.items[index].customSeparated[index2].paymentMethod) === 2 || parseInt(this.state.items[index].customSeparated[index2].paymentMethod) === 4) &&
                                                                                            <React.Fragment>
                                                                                                <Col lg="2">
                                                                                                    <FormGroup>
                                                                                                        <label className="form-control-label">Parcelamento:</label>
                                                                                                        <input className="form-control" onChange={(event) => this.changePlanCustom(index,index2,'installment',event.target.value)} value={ this.state.items[index].customSeparated[index2]['installment'] } type="text" />
                                                                                                    </FormGroup>
                                                                                                </Col>
                                                                                                <Col lg="2">
                                                                                                    <FormGroup>
                                                                                                        <label className="form-control-label">Permite reembolso:</label>
                                                                                                        <select className="form-control" onChange={(event) => this.changePlanCustom(index,index2,'allowRefund',event.target.value)} value={ this.state.items[index].customSeparated[index2]['allowRefund'] } type="text">
                                                                                                            <option value=""></option>
                                                                                                            <option value="1">Sim</option>
                                                                                                            <option value="0">Não</option>
                                                                                                        </select>
                                                                                                    </FormGroup>
                                                                                                </Col>
                                                                                            </React.Fragment>

                                                                                        }
                                                                                         
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </React.Fragment>)

                                                                        })

                                                                    }
 
                                                                </div>

                                                            }
                                                            

                                                            <div className="row">
                                                                <div style={{marginBottom:'30px'}} className="col-lg-12">
                                                                    <button onClick={ () => this.newItemCustom(index) } style={{float:'right'}} type="button" class="btn-icon btn-3 btn btn-sm btn-secondary"><span class="btn-inner--icon"><i class="fas fa-plus"></i></span><span class="btn-inner--text"> Novo plano customizado</span></button>
                                                                    <br/>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                        <hr/>
                                                    </React.Fragment>)

                                                })

                                            }

                                            <center>
                                                <button onClick={ () => this.newItem() } type="button" class="btn-icon btn-3 btn btn-sm btn-secondary"><span class="btn-inner--icon"><i class="fas fa-plus"></i></span><span class="btn-inner--text"> Novo item</span></button>
                                            </center>
                                            <br/>

                                        </div>

                                     {

/*    <div hidden className="row" style={{marginBottom:'30px'}}>

                                            <div className="col-md-12" style={{display:'flex'}}> 
                                                <div className="col-md-6">
                                                    <h3>Cursos:</h3>
                                                    {

                                                        this.state.courses.map((value,index) =>{
                                                            
                                                            var checked;
                                                            if(this.state.dialog.checkCourse.indexOf(value.id) === -1){ checked = false; }else{ checked = true; }

                                                            //checkCourse
                                                            return(
                                                                <React.Fragment key={'index_aa_'+index}>
                                                                    <div>
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input checked={checked} onChange={ (event) => this.setCheckCourse(value.id,event.target.checked) } className="custom-control-input" id={'customCheck1_'+value.id} type="checkbox" />
                                                                            <label className="custom-control-label" htmlFor={'customCheck1_'+value.id}>
                                                                            {value.name}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )

                                                        })

                                                    }
                                                </div>
                                                <div className="col-md-6">
                                                    <h3>Licenças:</h3>
                                                    {

                                                        this.state.licenses.map((value,index) =>{
                                                            
                                                            var checked;
                                                            if(this.state.checkLicense.indexOf(value.id) === -1){ checked = false; }else{ checked = true; }
                                                              
                                                            return(

                                                                <React.Fragment key={'index_aa2_'+index}>
                                                                    <div>
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input checked={checked} onChange={ (event) => this.setCheckLicense(value.id,event.target.checked) } className="custom-control-input" id={'customCheck12_'+value.id} type="checkbox" />
                                                                            <label className="custom-control-label" htmlFor={'customCheck12_'+value.id}>
                                                                            {value.name}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>

                                                            )

                                                        })

                                                    }
                                                </div>
                                            </div>
                                            
                                            <p>
                                            <br/>
                                            <br/></p>

                                        </div> */

                                     }

					                    <div className="pl-lg-4"></div>

                                            <button onClick={ () => this.putPlan() } style={{width:"100%"}} type="button" class="btn-icon btn-3 btn btn-primary"><span class="btn-inner--icon"><i class="fas fa-plus"></i></span><span class="btn-inner--text"> Salvar alterações</span></button>
                                            <button onClick={ () => this.putPlan() } style={{width:'144px',position:'fixed',top:'241px',left:'calc(100% - 200px)'}} type="button" class="btn-icon btn-3 btn btn-primary btn-sm"><span class="btn-inner--icon"><i class="fas fa-plus"></i></span><span class="btn-inner--text"> Salvar alterações</span></button>
				                      	
                                          </form>
 									</CardBody>
 									}
								</Card>
							</Col>
						</Row>
					</Container>
                    <Modal className="modal-dialog-centered" isOpen={this.state.openModalClone} >
                        <div className="modal-body p-0">
                            <Card className="bg-secondary border-0 mb-0">
                            <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">Sobrescrever items.</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalClone:false})}><span aria-hidden={true}>×</span> </button>
                        </div>
                            <CardBody className="px-lg-5">
                             
                                <Alert color="info" className=" "><strong>Atenção!</strong> Essa ação irá puxar os atributos do plano escolhido, o plano só será alterado apos você clicar em "salvar".</Alert>

                                <Row>
                                    <Col md={12}>
                                        <h5>Escolha um plano:</h5>
                                        <div class="input-group-merge input-group-alternative input-group">
                                            <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-piggy-bank"></i></span></div>
                                            <select type="text" class="form-control" onChange={ (event) => {this.setState({selectPlaClone:event.target.value})} } value={this.state.selectPlaClone}>
                                                <option value=""></option>
                                                {

                                                    this.state.plans.map((value,index) => {

                                                        if(value.id !== this.props.match.params.id){

                                                            return(<option key={'dd_a'+index} value={value.id}>{value.name}</option>)

                                                        }else{

                                                            return(<React.Fragment key={'dd_a'+index}></React.Fragment>)
                                                            
                                                        }

                                                    })

                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center"> 
                                    <Button onClick={ () => this.getClonePlans() } style={{width:'100%'}} className="my-4" color="primary" type="submit">Puxar items,cursos e licenças.</Button>
                                </div> 
                            </CardBody>
                            </Card>
                        </div>
                    </Modal>
                    <Modal className="modal-dialog-centered" isOpen={this.state.openModalSubscription} >
                        <div className="modal-body p-0">
                            <Card className="bg-secondary border-0 mb-0">
                            <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">Cursos da assinatura</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalSubscription:false})}><span aria-hidden={true}>×</span> </button>
                        </div>
                            <CardBody className="px-lg-5">        
                                {

                                    this.state.courses.map((value,index) => {
                                        
                                        return(<React.Fragment key={value.id}>
                                            
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                
                                                onChange={(e) => {

                                                    var inx = this.state.dialog.checkCourse.indexOf(value.id);

                                                    if(e.target.checked === true){

                                                        if(inx === -1){

                                                            this.state.dialog.checkCourse.push(value.id);

                                                        }

                                                    }
                                                    if(e.target.checked === false){

                                                        if(inx !== -1){

                                                            this.state.dialog.checkCourse.splice(inx,1);

                                                        }

                                                    }

                                                    this.setState({a:"1"});

                                                }}

                                                checked={this.state.dialog.checkCourse.indexOf(value.id) === -1 ? false:true} className="custom-control-input inputPermission" id={value.id} value={value.id} type="checkbox" />
                                                <label className="custom-control-label" htmlFor={value.id}>{value.name}</label>
                                            </div>

                                        </React.Fragment>)

                                    })

                                }
                            </CardBody>
                            </Card>
                        </div>
                    </Modal>

		    </React.Fragment>);
	}
}
export default NewCourse;
