import React from "react";
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,Col,Row} from "reactstrap";

import classnames from "classnames";
 
import api from "./../../../Api.js";
import NotificationAlert from "react-notification-alert";

class EditUser extends React.Component {

	constructor(props){

    	super(props);
        
        this.setState = this.props.setState;

        this.postField = this.postField.bind(this);
        
        this.putField = this.putField.bind(this);
        this.newItem = this.newItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.changeItem = this.changeItem.bind(this);

        this.save = this.save.bind(this);

        this.state = {};

    }
      
    async postField(){

        var data = this.props.state.dialogGroupFieldField;
        data = JSON.parse(JSON.stringify(data));
        data.groupID = this.props.state.editGroup;
        try{
          data.items = JSON.stringify(data.items);
        }catch(error){
          data.items = '[]';
        }

        await api.postClientGroupFieldsField(data).then(async (res) => {

            this.props.getGroups();
            this.props.setState({openModalField:false});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O campo foi cadastrado.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
    
        }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response); 
            this.props.setState({errorField:error.response.data.msg});
            return false; 
    
        });

    }

    async putField(){

      var data = this.props.state.dialogGroupFieldField;
      data = JSON.parse(JSON.stringify(data));
      try{
        data.items = JSON.stringify(data.items);
      }catch(error){
        data.items = '[]';
      }

      delete data.groupID;

      data.type = data.type+"";
      data.sequence = data.sequence+"";
      data.obligatory = data.obligatory+""; 

      await api.putClientGroupFieldsField(data.id,data).then(async (res) => {

            this.props.getGroups();
            this.props.setState({openModalField:false});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
    
      }).catch(error => { 
    
            console.log(error.data);
            console.log(error);
            console.log(error.response); 
            this.props.setState({errorField:error.response.data.msg});
            return false; 
    
      });

    }

    save(event){

        event.preventDefault();
        if(this.props.state.editField === ""){ this.postField(); }else{ this.putField(); }

    }

    newItem(){

      var dialogGroupFieldField = this.props.state.dialogGroupFieldField;
      dialogGroupFieldField.items.push('');
      this.props.setState({dialogGroupFieldField:dialogGroupFieldField});

    }

    removeItem(index){

      var dialogGroupFieldField = this.props.state.dialogGroupFieldField;
      dialogGroupFieldField.items.splice(index,1);
      this.props.setState({dialogGroupFieldField:dialogGroupFieldField});

    }

    changeItem(index,value){

      var dialogGroupFieldField = this.props.state.dialogGroupFieldField;
      dialogGroupFieldField.items[index] = value;
      this.props.setState({dialogGroupFieldField:dialogGroupFieldField});

    }

  	render(){

      const handleChange = name => event => {
	        var dialogGroupFieldField = this.props.state.dialogGroupFieldField;
	        dialogGroupFieldField[name] = event.target.value;
	        this.setState({dialogGroupFieldField:dialogGroupFieldField});
	    }
     
  		return(<React.Fragment>
              
            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        
            <Modal className="modal-dialog-centered" isOpen={this.props.state.openModalField} >
            
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">{this.props.state.textDialog}</h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModalField:false})}><span aria-hidden={true}>×</span> </button>
                </div>
                <CardBody className="px-lg-5">

                    {
                        (this.props.state.errorField !== "") &&
                        <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.props.state.errorField}</Alert>
                    }

                    <Form role="form" type="post" onSubmit={ (event) => this.save(event) }>
                     <Row>

                        <Col md={12}>
                            <h5>Nome:</h5>
                            <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                <Input type="text" onChange={handleChange('name')} value={ this.props.state.dialogGroupFieldField.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                            </InputGroup>
                            </FormGroup>
                        </Col>


                        <Col md={12}>
                            <h5>fieldID:</h5>
                            <FormGroup className={classnames("mb-3",{focused:this.state.fieldID})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                <Input type="text" onChange={handleChange('fieldID')} value={ this.props.state.dialogGroupFieldField.fieldID } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                            </InputGroup>
                            </FormGroup>
                        </Col>
                        
                        <Col md={12}>
                            <h5>Classname:</h5>
                            <FormGroup className={classnames("mb-3",{focused:this.state.classname})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                <Input type="text" onChange={handleChange('classname')} value={ this.props.state.dialogGroupFieldField.classname } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                            </InputGroup>
                            </FormGroup>
                        </Col>

                         
                         

                        <Col md={12}>
                            <h5>Tipo:</h5>
                            <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                <Input type="select" className="" value={this.props.state.dialogGroupFieldField.type} onChange={handleChange('type')}>
                                {

                                  window.clientExtraField.map((value,index) => {
                                    
                                    return(<option key={index} value={value.id}>{value.name}</option>)
                                  
                                  })

                                }
                              </Input>
                            </InputGroup>
                            </FormGroup>
                        </Col>
                        {

                          (parseInt(this.props.state.dialogGroupFieldField.type) === 5) &&
                          <React.Fragment>

                              <Col md={12}>

                                {

                                  this.props.state.dialogGroupFieldField.items.map((value,index) => {

                                    return(<React.Fragment key={index}>

                                      <div className="row" style={{marginBottom:'7px'}}>

                                        {index+1} 
                                        <input onChange={ (event) => this.changeItem(index,event.target.value) } className="inputClientListField" value={value} />  
                                        <i onClick={ () => this.removeItem(index) } style={{marginLeft:'10px',marginTop:'5px',cursor:'pointer'}} class="far fa-trash-alt"></i>
                                        
                                      </div>
                                      

                                    </React.Fragment>)
                                    
                                  })

                                }
                                {

                                  (this.props.state.dialogGroupFieldField.items.length === 0) &&
                                  <React.Fragment><center style={{marginBottom:'10px'}}>Nenhum item foi adicionado.</center></React.Fragment>

                                }

                                <center>

                                  <Button style={{marginBottom:'10px'}} onClick={() => this.newItem() } className="btn-neutral btn-round btn-icon" size="sm" >
                                      <span className="btn-inner--icon mr-1">
                                      <i className="fas fa-plus"></i>
                                      </span>
                                      <span className="btn-inner--text">Novo item</span>
                                  </Button>

                                </center>

                              </Col>
                          </React.Fragment>

                        }


                        
                              <Col md={12}>
                                  <h5>Pesquena descrição (caso precise):</h5>
                                  <FormGroup className={classnames("mb-3",{focused:this.state.mineDescription})} >
                                  <InputGroup className="input-group-merge input-group-alternative">

                                      <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                      <Input value={this.props.state.dialogGroupFieldField.mineDescription} onChange={handleChange('mineDescription')} className="form-control-alternative" rows="3" type="textarea" />
                
                                  </InputGroup>
                                  </FormGroup>
                              </Col>


                              <Col md={12}>
                                  <h5>Esse campo é obrigatório?</h5>
                                  <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                                  <InputGroup className="input-group-merge input-group-alternative">
                                      <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                      <Input type="select" className="" value={this.props.state.dialogGroupFieldField.obligatory} onChange={handleChange('obligatory')}>
                                     
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>

                                      </Input>
                                  </InputGroup>
                                  </FormGroup>
                              </Col>


                               

                    </Row>
                    <div className="text-center">
                        <Button className="my-4" color="default" type="button" onClick={ () => this.props.setState({openModalField:false}) } >Cancelar</Button>
                        <Button className="my-4" color="primary" type="submit">{this.props.state.textDialogButton}</Button>
                    </div>
                    </Form>
                </CardBody>
                </Card>
            </div>
            </Modal>
        
        </React.Fragment>);

  	}

}

export default EditUser;