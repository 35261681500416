import React from "react";
import {InputG,Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,Col,Row} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import {Link} from "react-router-dom";
import classnames from "classnames";
import Sortable from 'react-sortablejs';
import $ from 'jquery';

class Plans extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {plans:[],openModalNewPlan:false,dialog:{name:""},load:false};
		this.getPlans = this.getPlans.bind(this);
        this.postPlan = this.postPlan.bind(this);

		window.plans = this;

	}

	//Para pegar as lessons
  	async getPlans(){

		await api.getPlans().then((res) => {
            
        	this.setState({plans:res.data,load:true}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

	async componentDidMount(){
        
        this.getPlans();

    }
    
    async postPlan(){

        await api.postPlan(this.state.dialog).then((res) => {
            
            console.log(res.data);
            this.props.history.push('/plans/'+res.data.paymentplan.id);

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
            
            alert(error.response.data.msg);

        });
          
    }
 
    async changeOrderField(){

        var sequence = [];

        $(".fieldcamp").each(function(){

            sequence.push( $(this).attr('idc') );

        });
 
        await api.changeSequencePlan(sequence).then((res) => {
            
            console.log(res.data); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
            
           // alert(error.response.data.msg);

        });

         

    }

    
	render(){
        
        const handleChange = name => event => {
	        var dialog = this.state.dialog;
	        dialog[name] = event.target.value;
	        this.setState({dialog:dialog});
        }
        
    	return(
		<React.Fragment> 

            <SimpleHeader name="Contratos" way={[{name:"Contratos",url:"/contract"}]} buttons={<React.Fragment />} />
            <div class="mt--6 container-fluid">
                <div class="card">
                <div class="border-0 card-header">
                    <div class="row">
                        <div class="col-6">
                            <h3 class="mb-0">Planos de pagamentos</h3>
                        </div>
                        <div class="text-right col-6">
                            <button onClick={ () => this.setState({openModalNewPlan:true}) } class="btn-neutral btn-round btn-icon btn btn-default btn-sm">
                                <span class="btn-inner--icon mr-1"><i class="fas fa-plus"></i></span>
                                <span class="btn-inner--text">Criar plano</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="align-items-center table-flush table">
                        <thead class="thead-light">
                            <tr><th>Nome</th><th>Ações</th></tr>
                        </thead>
                        {

                            (this.state.load === true) &&
                            <Sortable options={{setList:function(e){},handle: ".fa-arrows-alt",group:'shared',onEnd:function(){  this.changeOrderField() }.bind(this) }} tag="tbody" > 
                            
                        
                                {

                                    this.state.plans.map((value,index) => {
                                    
                                        return( <tr key={'field_'+value.id} idc={value.id} className="fieldcamp tableList" >
                                                    <td><Link style={{cursor:"pointer",color:"#000"}} to={'/plans/'+value.id}><i class="fas fa-arrows-alt"></i> {value.name}</Link></td>
                                                    <td>

                                                        <Link style={{cursor:"pointer",color:"#000"}} to={'/plans/'+value.id}><font onClick={ () => this.props.history.push('/plans/'+value.id)} class="table-actions fontButton"><i class="far fa-edit"></i></font></Link>
                                                        <font class="table-actions fontButton"><i class="far fa-trash-alt"></i></font>

                                                    </td>
                                                </tr>)

                                    })

                                } 
                            
                            </Sortable> 

                        }
                        
                    </table>
                    {

                        (this.state.plans.length === 0) &&
                        <React.Fragment>
                            <center><h4 style={{margin:"30px 0px"}}>Nenhum plano de pagamento foi encontrado.</h4></center>
                        </React.Fragment>

                    }
                </div>
            </div>
        </div>

        <Modal className="modal-dialog-centered" isOpen={this.state.openModalNewPlan} >
            <div className="modal-header bg-secondary">
                <h5 className="modal-title" id="exampleModalLabel">Novo plano de pagamento</h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModalNewPlan:false})} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body bg-secondary">
                <Row>
                    <Col md={12}>
                        <h5>Nome do plano:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                            </InputGroup>
                        </FormGroup>
                        <button onClick={ () => this.postPlan() } style={{width:"100%"}} type="button" class="btn-icon btn-3 btn btn-primary"><span class="btn-inner--icon"><i class="fas fa-plus"></i></span><span class="btn-inner--text"> Cadastrar</span></button>
                    </Col>
                </Row>
            </div>
        </Modal>

		</React.Fragment>)
    }
}
export default Plans;