import React from "react";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import NotificationAlert from "react-notification-alert";
const Swal = require('sweetalert2');

class Notice extends React.Component {

	constructor(props){

        super(props);
        
		this.state = {natures:[]};
		this.getNatureOperations = this.getNatureOperations.bind(this);
        this.deleteNatureOperation = this.deleteNatureOperation.bind(this);

		window.notice = this;

	}

    deleteNatureOperation(id){

        Swal.fire({
            title: 'Tem certeza?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
          }).then((result) => {
      
            if(result.value){
                
                api.deleteNatureOperation(id).then((res) => {
      
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A natureza foi removida.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);
                    
                    this.getNatureOperations();

                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });
                 
      
            }
      
        });

    }

  	async getNatureOperations(){

		await api.getNatureOperations().then((res) => {
            
        	this.setState({natures:res.data}); 

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

	async componentDidMount(){
        
        this.getNatureOperations();
 
	}

	render(){
 
    	return(
		<React.Fragment> 

            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

            <SimpleHeader name="Natureza de operação" way={[{name:"Natureza de operação",url:"/nature_operation"}]} buttons={<React.Fragment />} />
            <div class="mt--6 container-fluid">
                <div class="card">
                <div class="border-0 card-header">
                    <div class="row">
                        <div class="col-6">
                            <h3 class="mb-0">Natureza de operação</h3>
                        </div>
                        <div class="text-right col-6">
                            <button onClick={ () => this.props.history.push('/nature_operation/new') } class="btn-round btn-icon btn btn-default btn-sm">
                                <i class="fas fa-plus"></i> Criar natureza
                            </button>
                        </div>
                    </div>
                </div>
                
                <div class="table-responsive">
                    <table class="align-items-center table-flush table">
                        <thead class="thead-light">
                            <tr>
                                <th>id</th>
                                <th>Título</th>
                                <th>Descrição</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                this.state.natures.map((value,index) => {
                                  
                                    return(<tr>
                                                <td onClick={ () => this.props.history.push('/nature_operation/'+value.id)} style={{cursor:"pointer"}}>{index+1}</td>
                                                <td onClick={ () => this.props.history.push('/nature_operation/'+value.id)} style={{cursor:"pointer"}}>{value.name}</td>
                                                <td>{value.obs}</td>
                                                <td>
                                                    <font class="table-actions fontButton" onClick={ () => this.props.history.push('/nature_operation/'+value.id)}><i class="far fa-edit"></i></font>
                                                    <font class="table-actions fontButton" onClick={() =>{ this.deleteNatureOperation(value.id); }}><i class="far fa-trash-alt"></i></font>
                                                </td>
                                            </tr>)

                                })

                            } 
                        </tbody>
                    </table>

                    {

                        (this.state.natures.length === 0) &&
                        <React.Fragment>
                            <center><h4 style={{margin:"30px 0px"}}>Nenhuma natureza foi encontrada.</h4></center>
                        </React.Fragment>

                    }


                </div>
            </div>
        </div>

		</React.Fragment>)
    }
}
export default Notice;