import React from "react";

class MethodPayment extends React.Component {

    constructor(props){

        super(props);

        window.methodpayment = this;
        
    }

    render(){

        return(
            <React.Fragment>
                { 

                    (parseInt(this.props.method) === 1) &&
                    <React.Fragment>
                        <font><i class="fas fa-file-invoice"></i> <font className="a1">Boleto</font></font>
                    </React.Fragment>

                }
                { 

                    (parseInt(this.props.method) === 2) &&
                    <React.Fragment>
                        <font><i class="fas fa-credit-card"></i> <font className="a1">{this.props.installment}x no cartão</font> </font>
                    </React.Fragment>

                }
                { 

                    (parseInt(this.props.method) === 3) &&
                    <React.Fragment>
                        <font><i class="fas fa-qrcode"></i> <font className="a1">Pix</font> </font>
                    </React.Fragment>

                }
                { 

                    (parseInt(this.props.method) === 4) &&
                    <React.Fragment>
                        <font><font className="a1">Não definido</font></font>
                    </React.Fragment>

                }
                { 

                    (parseInt(this.props.method) === 5) &&
                    <React.Fragment>
                        <font><img style={{height:'17px',position:'relative',top:'-2px'}} src="/images/icons/pagseguro.png"/> <font className="a1">PagSeguro</font></font>
                    </React.Fragment>

                }
            </React.Fragment>
        );

    }
}

export default MethodPayment;