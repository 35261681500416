import api from './../../Api.js';

function Course_helper(props){
		
	this.props = props; 

}

 

Course_helper.prototype.listCourses = async function(){
 
	var req = await api.course().then(async (res) => {
    	
    	return {status:200,data:res.data};
       
    }).catch(error => {

      if(error.response.status === 400){

      	return {status:400,data:error.response};

      }

      return false;

    });

	return req;

}



export default Course_helper;