import React,{useState,useEffect} from 'react';
import {Button,Card,CardBody,Form} from "reactstrap";
import {Modal} from "reactstrap"; 

function GlobalIframe(props){

    const [iframe,setGlobalIframe] = useState({open:false,src:'http://localhost:3020/'}); 
    window.setGlobalIframe = setGlobalIframe; 

    return(<React.Fragment>
        
        <style>{' .diviframe .modal-content{ height: 100%; position: absolute;} '}</style>
        <style>{' .iframe_dda{ height: 100%; width: 100%; border: none; position: absolute; border-radius: 5px;} '}</style>

        <Modal className="modal-dialog-centered diviframe" isOpen={iframe.open} style={{minWidth:'98%'}}>
            {
                (iframe.open === true) &&
                <div className="modal-body p-0">
                    
                    <button onClick={() => window.open(iframe.src.replace("hiddenbar=1", "")) } className="btn btn-sm btn-secondary" style={{width:'135px',position:'absolute',zIndex:'9',right:'95px',top:'10px'}}>Abrir em nova guia</button>
                    <button onClick={() => setGlobalIframe({open:false,src:''}) } className="btn btn-sm btn-secondary" style={{width: '65px',position:'absolute',zIndex:'9',right:'20px',top:'10px'}}>Fechar</button>


                    <iframe src={iframe.src} className="iframe_dda" />
                </div> 
            }
        </Modal>
        
    </React.Fragment>);

}

export default GlobalIframe;