import React,{useState,useEffect} from 'react';
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import {Col,Button,Row,Progress} from "reactstrap";
import $ from 'jquery';
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import api from "./../../Api.js";
import {TwitterPicker} from 'react-color'; 

class Configuration extends React.Component {
    
    constructor(props){
    
        super(props);
        
        this.state = {fileInput:[{progress:""},{progress:""},{progress:""},{progress:""}],configuration:{}};
    	this.uploadImg = this.uploadImg.bind(this);
    	this.setProgress = this.setProgress.bind(this);
        this.changeFileInput = this.changeFileInput.bind(this);
        this.getConfigurationRealdd = this.getConfigurationRealdd.bind(this);
        this.changeConf = this.changeConf.bind(this);

        window.conf = this;

    }


    async testMetabase(sinc){

        if(this.state.loadmeta === true){ return false; }
        this.state.loadmeta = true;

        var d = await api.testMetabase().then(async (res) => {
            
            if(sinc === true){

                await api.syncMetabase().then((res) => {

                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">Os dados estão sendo sincronizados, isso pode demorar minutos.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);
                    
                }).catch(error => {
 
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);
                    

                });

            }else{

                let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A integração com o metabase esta funcionando.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                this.refs.notificationAlert.notificationAlert(options);

            }
     
            this.state.loadmeta = false;
        	return true

		}).catch(error => {

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">Não foi possível testar o metabase, verifique os dados.</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
            
            this.state.loadmeta = false;
            return false;

		});

        return d;

    }

  
    async getConfigurationRealdd(){

        await api.configuration().then((res) => {

            window.app.setState({confs:res.data});

        });

        await api.getConfigurationReal().then((res) => {
        	
        	this.setState({configuration:res.data});  

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

		});

    }

    componentDidMount(){

        this.getConfigurationRealdd();
      
    }

    

    //Para fazer o upload do arquivo no input file
	async uploadImg(inputName){

		let formData = new FormData();
		var fileInput = this.state.fileInput;
		var row = $('.section_file[name="'+inputName+'"]');
		var input = row.find('input[type="file"]');
		var n = row.attr('n');
		var file = input[0].files;

		//seta o erro caso nenhum arquivo tenha sido informado
 		if(file.length === 0){  
 			fileInput[n].erro = "";
 			this.setState({fileInput:fileInput});
 			setTimeout(function(){ fileInput[n].erro = this.props.translate['voce_deve_escolher_um_arquivo']; this.setState({fileInput:fileInput}); }.bind(this), 1);
 			return false;
 		}

 		//removo o erro caso tenha
 		fileInput[n].erro = "";
 		this.setState({fileInput:fileInput});

 		formData.append('files', file[0]); 
		formData.append('name', file[0].name);
		formData.append('type',inputName);

		this.setProgress(0,n);

		axios.post(sessionStorage.getItem('backend')+'/upload/system',formData,{headers:{'Content-Type': 'multipart/form-data',session: sessionStorage.getItem('session')},onUploadProgress:progressEvent => {

			this.setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total),n)

	    }}).then(function(res){

            console.log(res.data);
	    	this.setProgress('',n);
	    	//var state = window.app.state.confs;
	    	//state[inputName] = res.data.url;
	    	//window.app.setState({confs:state});

            this.getConfigurationRealdd();
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O arquivo foi enviado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        	this.refs.notificationAlert.notificationAlert(options);
            

	  	}.bind(this)).catch(function(error){

	    	//console.log('FAILURE!!');
	    	//console.log(res.data);
	    	//console.log(res.response.data);

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

	    	this.setProgress('',n);

	  	}.bind(this));

	}

	//Para setar o progresso da barra
	setProgress(progress,n){

		var fileInput = this.state.fileInput;
		fileInput[n].progress = progress;
		this.setState({fileInput:fileInput});
		//console.log(progress);

	}

	//Quando mudar o arquivo no input, muda o texto no label
	changeFileInput(event,htmlFor){

		//console.log(event);
		//console.log(event.target.value);
		$("label[for='"+htmlFor+"']").html(event.target.value);
		//console.log("label[for='"+htmlFor+"']");

	}

	async saveConfiguration(event){

		event.preventDefault();
        var confs = this.state.configuration;
        
        confs.headerDegrade1 = window.app.state.confs.headerDegrade1;
        confs.headerDegrade2 = window.app.state.confs.headerDegrade2;

		await api.saveConfiguration(confs).then((res) => {
        	
        	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As configurações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        	this.refs.notificationAlert.notificationAlert(options);

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

		});


	}

	changeColor(event,name){

		//console.log(event);
		var state = window.app.state.confs;
		state[name] = event.hex;
		window.app.setState({confs:state});

    }
    
    changeConf(name,e){

        var configuration = this.state.configuration;
        configuration[name] = e.target.value;
        this.setState({configuration});

    }
 
    render(){
        
     

        return(<React.Fragment>
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

        <SimpleHeader name={'aparencia'} way={[{name:'Configuração',url:"/configuration"}]} buttons={<React.Fragment></React.Fragment>} />

        <div className="mt--6 container-fluid">
            <div className="justify-content-center row">
                <div className="card-wrapper col-xs-12 col-sm-12 col-md-12">
                    <form type="post" onSubmit={ (event) => this.saveConfiguration(event) } >
                        <div className="card">
                            <div className="card-header">
                                <h3 className="mb-0">Sistema</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6" style={{marginTop:'10px'}}>
                                        <div className="security" style={{borderbottom:'1px solid #fff'}}>
                                            <div className="col-md-12 ">
                                                <h4>Nome da empresa:</h4> 
                                                <input onChange={(e) => this.changeConf('systemName',e) } type="text" className="form-control" value={this.state.configuration.systemName} />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="security" style={{borderbottom:'1px solid #fff'}}>
                                            <div className="col-md-12 ">
                                                <h4>Slogan:</h4> 
                                                <input onChange={(e) => this.changeConf('slogan',e) } type="text" className="form-control" value={this.state.configuration.slogan} />
                                            </div>
                                        </div>
                                        <br/>
                                        <div hidden className="security" style={{borderbottom:'1px solid #fff'}}>
                                            <div className="col-md-12 ">
                                                <h4>Custom CSS:</h4> 
                                                <textarea onChange={(e) => this.changeConf('customcss',e) } type="text" className="form-control" >{window.app.state.confs.customcss}</textarea>
                                            </div>
                                        </div>
                                       {/*
                                        
                                        <div className="security" style={{borderbottom:'1px solid #fff'}}>
                                            <div className="col-md-12 ">
                                                <h4>ID da conta iugu:</h4> 
                                                <input onChange={(e) => this.changeConf('iugu_id',e) } type="text" className="form-control" value={this.state.configuration.iugu_id} />
                                            </div>
                                        </div>
                                        
                                        <br/>
                                        
                                        <div className="security" style={{borderbottom:'1px solid #fff'}}>
                                            <div className="col-md-12 ">
                                                <h4>API da conta iugu:</h4> 
                                                <input onChange={(e) => this.changeConf('iugu_api',e) } type="text" className="form-control" value={this.state.configuration.iugu_api} />
                                            </div>
                                        </div>
                                        
                                        */}
                                       
                                    </div>
                                    <div className="col-md-6" style={{marginTop:'10px'}}>
                                        <div className="col-md-12">
                                            <h4 style={{marginBottom:'20px'}}>Cor degrade 1:</h4> 
                                            <TwitterPicker color={window.app.state.confs.headerDegrade1} onChange={ (event) => this.changeColor(event,'headerDegrade1') }/>		                		
                                        </div><br/>
                                        <div className="col-md-12">
                                            <h4 style={{marginBottom:'20px'}}>Cor degrade 2:</h4> 
                                            <TwitterPicker color={window.app.state.confs.headerDegrade2} onChange={ (event) => this.changeColor(event,'headerDegrade2') }/>						                		
                                        </div>
                                    </div>
                                    <Col md={12}>
                                        <center>
                                            <Button color="secondary" type="submit" style={{marginTop:'20px'}}>Salvar alterações</Button>
                                        </center>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>		
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="mb-0">Logos e background</h3>
                        </div>
                        <div className="card-body">
                        <div class="alert alert-info fade show" role="alert"><strong>Informação!</strong> Recomendamos imagens do tipo PNG com fundo transparente. Não use imagem to tipo SVG.</div>
{/*                                <div hidden className="col-md-12" style={{marginTop:'10px'}}>
                                      <div style={{padding:'0px 15px 0px 15px'}}><h6 className="heading-small text-muted mb-4">{this.props.translate['background_da_tela_de_login']}</h6>
                                          <Row className="section_file" name="background" n="0">
                                              <Col md={12} style={{padding:'34px'}}>
                                                  <Row>
                                                      <Col md="4"><center><img alt="..." className="imgup img-responsive" src={sessionStorage.getItem('backend')+window.app.state.confs.background} /></center></Col>
                                                      <Col md="8">
                                                          {
                                                              (this.state.fileInput[0].progress === "") &&
                                                              <div style={{display:"flex"}}>
                                                                  <div className="custom-file form-control-sm">
                                                                    <input onChange={ (event) => { this.changeFileInput(event,'custom_file_0');this.uploadImg('background'); } } className="custom-file-input" id="custom_file_0" type="file" />
                                                                    <label className="custom-file-label " htmlFor="custom_file_0">{this.props.translate['selecionar_arquivo']}</label>
                                                                  </div>
                                                                  <Button onClick={ () => this.uploadImg('background') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> Enviar</Button>
                                                              </div>
                                                          }
                                                          {
                                                              (this.state.fileInput[0].erro !== "") &&
                                                              <h6 className="erroInputBase animated bounceIn">{this.state.fileInput[0].erro}</h6>
                                                          }
                                                          {
                                                              (this.state.fileInput[0].progress !== "") &&
                                                              <div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
                                                              <div className="progress-info">
                                                                <div className="progress-label"><span>{this.props.translate['enviando_arquivo']}</span></div>
                                                                <div className="progress-percentage"><span>{this.state.fileInput[0].progress}%</span></div>
                                                              </div>
                                                              <Progress max="100" value={this.state.fileInput[0].progress} color="default" />
                                                            </div>
                                                          }
                                                      </Col>
                                                  </Row>
                                              </Col>
                                          </Row>
                                      </div>
                                </div>*/}
                                <div className="col-md-12">
                                      <div style={{padding:'0px 15px 0px 15px'}}><h6 className="heading-small text-muted mb-4">Logo externa</h6>
                                          <Row className="section_file" name="logo" n="1">
                                              <Col md={12} style={{padding:'34px'}}>
                                                  <Row>
                                                      <Col md="4" style={{padding:'0px'}} >
                                                          
                                                     
                                                          <center><img alt="..."  style={{maxHeight:'60px',position:"relative"}} className="imgup img-responsive" src={window.app.state.confs.logo}/></center>
                                                
                                                      </Col>
                                                      <Col md="8">
                                                          {
                                                              (this.state.fileInput[1].progress === "") &&
                                                              <div style={{display:"flex"}}>
                                                                  <div className="custom-file form-control-sm">
                                                                    <input onChange={ (event) => { this.changeFileInput(event,'custom_file_1');this.uploadImg('logo'); } } className="custom-file-input" id="custom_file_1" type="file" />
                                                                    <label className="custom-file-label " htmlFor="custom_file_1">Selecionar arquivo</label>
                                                                  </div>
                                                                  <Button onClick={ () => this.uploadImg('logo') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> Enviar</Button>
                                                              </div>
                                                          }
                                                          {
                                                              (this.state.fileInput[1].erro !== "") &&
                                                              <h6 className="erroInputBase animated bounceIn">{this.state.fileInput[1].erro}</h6>
                                                          }
                                                          {
                                                              (this.state.fileInput[1].progress !== "") &&
                                                              <div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
                                                              <div className="progress-info">
                                                                <div className="progress-label"><span>Enviando arquivo</span></div>
                                                                <div className="progress-percentage"><span>{this.state.fileInput[1].progress}%</span></div>
                                                              </div>
                                                              <Progress max="100" value={this.state.fileInput[1].progress} color="default" />
                                                            </div>
                                                          }
                                                      </Col>
                                                  </Row>
                                              </Col>
                                          </Row>
                                      </div>
                                </div>
                                <div className="col-md-12">
                                      <div style={{padding:'0px 15px 0px 15px'}}><h6 className="heading-small text-muted mb-4">Logo interna</h6>
                                          <Row className="section_file" name="internal_logo" n="2">
                                              <Col md={12} style={{padding:'34px'}}>
                                                  <Row>
                                                      <Col md="4">
                                                          <center><img alt="..."  style={{maxHeight:'60px',position:"relative"}} className="imgup img-responsive" src={window.app.state.confs.internal_logo}/></center>
                                                      </Col>
                                                      <Col md="8">
                                                          {
                                                              (this.state.fileInput[2].progress === "") &&
                                                              <div style={{display:"flex"}}>
                                                                  <div className="custom-file form-control-sm">
                                                                    <input onChange={ (event) => { this.changeFileInput(event,'custom_file_2');this.uploadImg('internal_logo'); } } className="custom-file-input" id="custom_file_1" type="file" />
                                                                    <label className="custom-file-label " htmlFor="custom_file_2">Selecionar arquivo</label>
                                                                  </div>
                                                                  <Button onClick={ () => this.uploadImg('internal_logo') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> Enviar</Button>
                                                              </div>
                                                          }
                                                          {
                                                              (this.state.fileInput[2].erro !== "") &&
                                                              <h6 className="erroInputBase animated bounceIn">{this.state.fileInput[2].erro}</h6>
                                                          }
                                                          {
                                                              (this.state.fileInput[2].progress !== "") &&
                                                              <div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
                                                              <div className="progress-info">
                                                                <div className="progress-label"><span>Enviando arquivo</span></div>
                                                                <div className="progress-percentage"><span>{this.state.fileInput[2].progress}%</span></div>
                                                              </div>
                                                              <Progress max="100" value={this.state.fileInput[2].progress} color="default" />
                                                            </div>
                                                          }
                                                      </Col>
                                                  </Row>
                                              </Col>
                                          </Row>
                                      </div>
                                </div>
                                <div className="col-md-12">
                                      <div style={{padding:'0px 15px 0px 15px'}}><h6 className="heading-small text-muted mb-4">Favicon</h6>
                                          <Row className="section_file" name="favicon" n="3">
                                              <Col md={12} style={{padding:'34px'}}>
                                                  <Row>
                                                      <Col md="4">
                                                          <center><img alt="..." style={{maxHeight:'60px',position:"relative"}} className="imgup img-responsive" src={window.app.state.confs.favicon}/></center>
                                                      </Col>
                                                      <Col md="8">
                                                          {
                                                              (this.state.fileInput[3].progress === "") &&
                                                              <div style={{display:"flex"}}>
                                                                  <div className="custom-file form-control-sm">
                                                                    <input onChange={ (event) => { this.changeFileInput(event,'custom_file_3');this.uploadImg('favicon'); } } className="custom-file-input" id="custom_file_1" type="file" />
                                                                    <label className="custom-file-label " htmlFor="custom_file_3">Selecionar arquivo</label>
                                                                  </div>
                                                                  <Button onClick={ () => this.uploadImg('favicon') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> Enviar</Button>
                                                              </div>
                                                          }
                                                          {
                                                              (this.state.fileInput[3].erro !== "") &&
                                                              <h6 className="erroInputBase animated bounceIn">{this.state.fileInput[3].erro}</h6>
                                                          }
                                                          {
                                                              (this.state.fileInput[3].progress !== "") &&
                                                              <div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
                                                              <div className="progress-info">
                                                                <div className="progress-label"><span>Enviando arquivo</span></div>
                                                                <div className="progress-percentage"><span>{this.state.fileInput[3].progress}%</span></div>
                                                              </div>
                                                              <Progress max="100" value={this.state.fileInput[3].progress} color="default" />
                                                            </div>
                                                          }
                                                      </Col>
                                                  </Row>
                                              </Col>
                                          </Row>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <form type="post" onSubmit={ (event) => this.saveConfiguration(event) } >
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="mb-0">Configuração do Metabase</h3>
                                </div>
                                <div className="card-body">
                                    <div class="">
                                        <div class="col-md-12">
                                            <h4>Usar Metabase:</h4>
                                            <select onChange={(e) => this.changeConf('metabase_status',e) } type="text" class="form-control" value={this.state.configuration.metabase_status}>
                                            <option value="0">Não</option>
                                            <option value="1">Sim</option>
                                            </select>
                                        </div>
                                        <br/>
                                        <div class="col-md-12">
                                            <h4>URL:</h4>
                                            <input onChange={(e) => this.changeConf('metabase_url',e) } type="text" class="form-control" value={this.state.configuration.metabase_url} />
                                        </div>
                                        <br/>
                                        <div class="col-md-12">
                                            <h4>Usuário:</h4>
                                            <input onChange={(e) => this.changeConf('metabase_username',e) } type="text" class="form-control" value={this.state.configuration.metabase_username} />
                                        </div>
                                        <br/>
                                        <div class="col-md-12">
                                            <h4>Senha:</h4>
                                            <input onChange={(e) => this.changeConf('metabase_password',e) } type="password" class="form-control" value={this.state.configuration.metabase_password}/>
                                        </div>

                                        <br/>
                                        <div class="col-md-12">
                                            <h4>Secret key:</h4>
                                            <input onChange={(e) => this.changeConf('metabase_secretkey',e) } type="password" class="form-control" value={this.state.configuration.metabase_secretkey}/>
                                        </div>

                                        <br/>
                                        <div class="col-md-12">
                                            <h4>ID da dashboard modelo:</h4>
                                            <input onChange={(e) => this.changeConf('metabase_dash',e) } type="text" class="form-control" value={this.state.configuration.metabase_dash}/>
                                        </div>
                                        <div>
                                            <center>   
                                                <button type="button" class="btn btn-secondary" style={{marginTop:"20px"}} onClick={() => { this.testMetabase(); }}><i class="fas fa-play"></i> Testar integração</button>
                                                <button type="submit" class="btn btn-secondary" style={{marginTop:"20px"}}>Salvar alterações</button>
                                                <button type="button" class="btn btn-secondary" style={{marginTop:"20px"}} onClick={() => { this.testMetabase(true); }}><i class="fas fa-sync"></i> Sincronizar</button>
                                            </center>
                                        </div>
                                        <div style={{marginTop:"10px"}}><center><font style={{marginTop:'17px',fontWeight:'300'}}>Salve os dados antes de testar.</font></center></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>


                    <div className="col-md-6">
                        <form type="post" onSubmit={ (event) => this.saveConfiguration(event) } >
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="mb-0">Configuração do SMTP</h3>
                                </div>
                                <div className="card-body">
                                    <div class="" style={{display:"flex"}}>
                                        <div class="col-md-8">
                                            <h4>Endereço do servidor (host):</h4>
                                            <input onChange={(e) => this.changeConf('smtp_host',e) } type="text" class="form-control" value={this.state.configuration.smtp_host} />
                                        </div>
                                        <div class="col-md-4">
                                            <h4>Porta:</h4>
                                            <input onChange={(e) => this.changeConf('smtp_port',e) } type="text" class="form-control" value={this.state.configuration.smtp_port} />
                                        </div>
                                    </div>
                                    <br/>
                                    <div class="">
                                        <div class="col-md-12">
                                            <h4>Secure:</h4>
                                            <select onChange={(e) => this.changeConf('smtp_secure',e) } type="text" class="form-control" value={this.state.configuration.smtp_secure}>
                                                <option value="1">Sim</option>
                                                <option value="0">Não</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br/>
                                    <div class="" style={{display:'flex'}}>
                                        <div class="col-md-6">
                                            <h4>Usuário:</h4>
                                            <input onChange={(e) => this.changeConf('smtp_username',e) } type="text" class="form-control" value={this.state.configuration.smtp_username}/>
                                        </div>
                                        <div class="col-md-6">
                                            <h4>Senha:</h4>
                                            <input onChange={(e) => this.changeConf('smtp_password',e) } type="text" class="form-control" value={this.state.configuration.smtp_password}/>
                                        </div>
                                    </div> 
                                    <br/>
                                    <div class="" style={{display:'flex'}}>
                                        <div class="col-md-12">
                                            <h4>E-mail de saida:</h4>
                                            <input onChange={(e) => this.changeConf('smtp_emailexit',e) } type="email" class="form-control" value={this.state.configuration.smtp_emailexit} />
                                        </div>
                                    </div>
                                    <br/>
                                    <div class="" style={{display:'flex'}}>
                                        <div class="col-md-12">
                                            <h4>E-mail para recebimento de cópia:</h4>
                                            <input onChange={(e) => this.changeConf('smtp_emailcc',e) } type="email" class="form-control" value={this.state.configuration.smtp_emailcc} />
                                        </div>
                                    </div>
                                    <br/>
                                    <div class="" style={{display:'flex'}}>
                                        <div class="col-md-12">
                                            <h4>Tipo de cópia:</h4>
                                            <select onChange={(e) => this.changeConf('smtp_typecopy',e) } type="email" class="form-control" value={this.state.configuration.smtp_typecopy} >
                                                <option value=""></option>
                                                <option value="1">Normal</option>
                                                <option value="2">Oculta</option>
                                            </select>
                                        </div>
                                    </div>
                                    <center><button type="submit" class="btn btn-secondary" style={{marginTop:"20px"}}>Salvar alterações</button></center>
                                </div>
                            </div>
                        </form>
                    </div>



                    <div className="col-md-6">
                        <form type="post" onSubmit={ (event) => this.saveConfiguration(event) } >
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="mb-0">Configuração de segurança (reCAPTCHA v3)</h3>
                                </div>
                                <div className="card-body">
                                    <div class="">
                                        <div class="col-md-12">
                                            <h4>Usar reCAPTCHA:</h4>
                                            <select onChange={(e) => this.changeConf('recaptcha_status',e) } type="text" class="form-control" value={this.state.configuration.recaptcha_status}>
                                            <option value="0">Não</option>
                                            <option value="1">Sim</option>
                                            </select>
                                        </div>
                                        <br/>
                                        <div class="col-md-12">
                                            <h4>Chave do cliente:</h4>
                                            <input onChange={(e) => this.changeConf('recaptcha_clientkey',e) } type="text" class="form-control" value={this.state.configuration.recaptcha_clientkey} />
                                        </div>
                                        <br/>
                                        <div class="col-md-12">
                                            <h4>Chave secreta:</h4>
                                            <input onChange={(e) => this.changeConf('recaptcha_backendkey',e) } type="text" class="form-control" value={this.state.configuration.recaptcha_backendkey}/>
                                        </div>
                                        <center><button type="submit" class="btn btn-secondary" style={{marginTop:"20px"}}>Salvar alterações</button></center>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>


                    <div className="col-md-6">
                        <form type="post" onSubmit={ (event) => this.saveConfiguration(event) } >
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="mb-0">Bloqueio de edição de fatura</h3>
                                </div>
                                <div className="card-body">
                                    <div class="">
                                        <div class="col-md-12">
                                            <h4>Quais os nomes do itens a ser monitorado?</h4>
                                            <input onChange={(e) => this.changeConf('block_edit_item_name',e) } type="text" class="form-control" value={this.state.configuration.block_edit_item_name} />
                                            <div>Divida os nomes por vigula, se o nome existem em um dos itens da fatura, a edição da fatura será bloqueada.</div>
                                        </div>
                                        <center><button type="submit" class="btn btn-secondary" style={{marginTop:"20px"}}>Salvar alterações</button></center>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    



            </div>
        </div>
    </React.Fragment>);

}

}
export default Configuration;