import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {Button,Card,CardBody,CardTitle,Container,Row,Col} from "reactstrap";
import {BarLoader} from "react-spinners";
import api from "./../../Api.js";

class CardsHeader extends React.Component {

  constructor(props){

    super(props);
    this.state = {

      loadRegistration:false,
      loadClientregister:false,
      loadInvoicesPaid:false,
      loadWinoportunity:false,

      registration:0,
      clientregister:0,
      invoicesPaid:0,
      winoportunity:0,

      startDate:undefined,
      endDate:undefined,

    };

  }

  componentDidMount(){

    this.setState({a:'1'});

  }

  async componentDidUpdate(){

    var update = false;
    if(this.state.startDate !== this.props.startDate){ update = true; this.state.startDate = this.props.startDate; }
    if(this.state.endDate !== this.props.endDate){ update = true; this.state.endDate = this.props.endDate; }

    if(update === true){

      await this.getReportRegistration();
      await this.getReportClientregister();
      await this.getReportInvoicesPaid();
      await this.getReportWinoportunity();

    }

  }

  async getReportRegistration(){
    
    await this.setState({loadRegistration:false});
    await api.getReportRegistration(this.props.startDate,this.props.endDate).then(async (res) => { 
      
      this.setState({loadRegistration:true,registration:res.data.total});
  
    }).catch((error) => {});

  }

  async getReportClientregister(){

    await this.setState({loadClientregister:false});
    await api.getReportClientregister(this.props.startDate,this.props.endDate).then(async (res) => { 
      
      this.setState({loadClientregister:true,clientregister:res.data.total});
  
    }).catch((error) => {});

  }

  async getReportInvoicesPaid(){

    await this.setState({loadInvoicesPaid:false});
    await api.getReportInvoicesPaid(this.props.startDate,this.props.endDate).then(async (res) => { 
      
      this.setState({loadInvoicesPaid:true,invoicesPaid:res.data.total});
  
    }).catch((error) => {});

  }

  async getReportWinoportunity(){

    await this.setState({loadWinoportunity:false});
    await api.getReportWinoportunity(this.props.startDate,this.props.endDate).then(async (res) => { 
      
      this.setState({loadWinoportunity:true,winoportunity:res.data.total});
  
    }).catch((error) => {});

  }


  render() {
    return (
      <>
        <div className="header pb-6" style={{background:"linear-gradient(-45deg,"+window.app.state.confs.headerDegrade1+","+window.app.state.confs.headerDegrade2+")"}}>
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
 
                <Col className="text-right" lg="6" xs="5">
                  <Button
                    className="btn-neutral"
                    color="default"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    size="sm"
                  >
                    New
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    size="sm"
                  >
                    Filters
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">Matrículas faturadas</CardTitle>
                          <span className="h2 font-weight-bold mb-0">{(this.state.loadRegistration === true ? this.state.registration:<BarLoader color="#b7b7b7"/>)}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                            <i className="ni ni-active-40" />
                          </div>
                        </Col>
                      </Row>
                      <p hidden className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2"><i className="fa fa-arrow-up" /> 3.48%</span>
                        <span className="text-nowrap">Since last month</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">Clientes cadastrados</CardTitle>
                          <span className="h2 font-weight-bold mb-0">{(this.state.loadClientregister === true ? this.state.clientregister:<BarLoader color="#b7b7b7"/>)}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            <i className="ni ni-chart-pie-35" />
                          </div>
                        </Col>
                      </Row>
                      <p hidden className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2"><i className="fa fa-arrow-up" /> 3.48% </span>
                        <span className="text-nowrap">Since last month</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">Faturas pagas</CardTitle>
                          <span className="h2 font-weight-bold mb-0">{(this.state.loadInvoicesPaid === true ? this.state.invoicesPaid:<BarLoader color="#b7b7b7"/>)}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="ni ni-money-coins" />
                          </div>
                        </Col>
                      </Row>
                      <p hidden className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2"><i className="fa fa-arrow-up" />3.48%</span>
                        <span className="text-nowrap">Since last month</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5"className="text-uppercase text-muted mb-0">Oportunidades ganhas</CardTitle>
                          <span className="h2 font-weight-bold mb-0">{(this.state.loadWinoportunity === true ? this.state.winoportunity:<BarLoader color="#b7b7b7"/>)}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="ni ni-chart-bar-32" />
                          </div>
                        </Col>
                      </Row>
                      <p hidden className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2"><i className="fa fa-arrow-up" /> 3.48%</span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default CardsHeader;
