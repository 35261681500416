import React from "react";
import {Alert,Modal,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Col,Row, NavItem,NavLink,Nav,DropdownItem,DropdownMenu,
    Dropdown,
    DropdownToggle
} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import NotificationAlert from "react-notification-alert";
import CurrencyInput from 'react-currency-input';
import classnames from "classnames";
import EditLicenseValuesProduct from "./components/EditLicenseValuesProduct";

class Product extends React.Component {

	constructor(props){

        super(props);
        
        this.state = {products:[],dialog:{name:"",courses:[],licenses:[],nfse:[]},plan_of_accounts:[],openDialogRemoveProduct:false,erroProduct:"",navPills:1,dropdownOpen:false};
        
        this.state.courses = [];
        this.state.licenses = [];

		this.getProducts = this.getProducts.bind(this);
        this.postProduct = this.postProduct.bind(this);
        this.newProduct = this.newProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.editProduct = this.editProduct.bind(this);
        this.getPlanOfplansofaccount = this.getPlanOfplansofaccount.bind(this);
        this.editPriceLicense = this.editPriceLicense.bind(this);

        this.courses = this.courses.bind(this);
        this.licenses = this.licenses.bind(this);
 
        //setTimeout(() => {

            //this.editProduct(6);
            //this.setState({navPills:8});

        //}, 1000);
        

        /*icms_situacao_tributaria
        icms_origem
        pis_situacao_tributaria
        cofins_situacao_tributaria*/

        //OK
        this.state.icms_situacao_tributaria = [
            {value:"00",name:"Tributada integralmente"},
            {value:"10",name:"Tributada e com cobrança do ICMS por substituição tributária"},
            {value:"20",name:"Com redução de base de cálculo"},
            {value:"30",name:"Isenta ou não tributada e com cobrança do ICMS por substituição tributária"},
            {value:"40",name:"Isenta"},
            {value:"41",name:"Não tributada"},
            {value:"50",name:"Suspensão"},
            {value:"51",name:"Diferimento"},
            {value:"60",name:"ICMS cobrado anteriormente por substituição tributária"},
            {value:"70",name:"Com redução de base de cálculo e cobrança do ICMS por substituição tributária"},
            {value:"90",name:"Outras"}
        ];

        //OK
        this.state.icms_origem = [
            {value:"0",name:"Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8"},
            {value:"1",name:"Estrangeira – Importação direta, exceto a indicada no código 6"},
            {value:"2",name:"Estrangeira – Adquirida no mercado interno, exceto a indicada no código 7"},
            {value:"3",name:"Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% (quarenta por cento) e inferior ou igual a 70% (setenta por cento)"},
            {value:"4",name:"Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/67, e as Leis nºs 8.248/91, 8.387/91, 10.176/01 e 11.484/07"},
            {value:"5",name:"Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40% (quarenta por cento)"},
            {value:"6",name:"Estrangeira – Importação direta, sem similar nacional, constante em lista de Resolução Camex e gás natural"},
            {value:"7",name:"Estrangeira – Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução Camex e gás natural"},
            {value:"8",name:"Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70% (setenta   por cento)"}
        ];

        this.state.pis_situacao_tributaria = [
            {value:"1",name:"Operação Tributável com Alíquota Básica"},
            {value:"2",name:"Operação Tributável com Alíquota Diferenciada"},
            {value:"3",name:"Operação Tributável com Alíquota por Unidade de Medida de Produto"},
            {value:"4",name:"name:Operação Tributável Monofásica - Revenda a Alíquota Zero"},
            {value:"5",name:"Operação Tributável por Substituição Tributária"},
            {value:"6",name:"Operação Tributável a Alíquota Zero"},
            {value:"7",name:"Operação Isenta da Contribuição"},
            {value:"8",name:"Operação sem Incidência da Contribuição"},
            {value:"9",name:"Operação com Suspensão da Contribuição"},
            {value:"49",name:"Outras Operações de Saída"},
            {value:"50",name:"Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno"},
            {value:"51",name:"Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno"},
            {value:"52",name:"Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação"},
            {value:"53",name:"Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"},
            {value:"54",name:"Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"},
            {value:"55",name:"Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"},
            {value:"56",name:"Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação"},
            {value:"60",name:"Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno"},
            {value:"61",name:"Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno"},
            {value:"62",name:"Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação"},
            {value:"63",name:"Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"},
            {value:"64",name:"Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"},
            {value:"65",name:"Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"},
            {value:"66",name:"Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação"},
            {value:"67",name:"Crédito Presumido - Outras Operações"},
            {value:"70",name:"Operação de Aquisição sem Direito a Crédito"},
            {value:"71",name:"Operação de Aquisição com Isenção"},
            {value:"72",name:"Operação de Aquisição com Suspensão"},
            {value:"73",name:"Operação de Aquisição a Alíquota Zero"},
            {value:"74",name:"Operação de Aquisição sem Incidência da Contribuição"},
            {value:"75",name:"Operação de Aquisição por Substituição Tributária"},
            {value:"98",name:"Outras Operações de Entrada"},
            {value:"99",name:"Outras Operações"}
        ];
        
        this.state.cofins_situacao_tributaria = [
            {value:"01",name:"Operação Tributável com Alíquota Básica"},
            {value:"02",name:"Operação Tributável com Alíquota Diferenciada"},
            {value:"03",name:"Operação Tributável com Alíquota por Unidade de Medida de Produto"},
            {value:"04",name:"Operação Tributável Monofásica - Revenda a Alíquota Zero"},
            {value:"05",name:"Operação Tributável por Substituição Tributária"},
            {value:"06",name:"Operação Tributável a Alíquota Zero"},
            {value:"07",name:"Operação Isenta da Contribuição"},
            {value:"08",name:"Operação sem Incidência da Contribuição"},
            {value:"09",name:"Operação com Suspensão da Contribuição"},
            {value:"49",name:"Outras Operações de Saída"},
            {value:"50",name:"Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno"},
            {value:"51",name:"Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno"},
            {value:"52",name:"Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação"},
            {value:"53",name:"Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"},
            {value:"54",name:"Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"},
            {value:"55",name:"Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação"},
            {value:"56",name:"Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação"},
            {value:"60",name:"Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno"},
            {value:"61",name:"Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno"},
            {value:"62",name:"Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação"},
            {value:"63",name:"Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"},
            {value:"64",name:"Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"},
            {value:"65",name:"Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"},
            {value:"66",name:"Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação"},
            {value:"67",name:"Crédito Presumido - Outras Operações"},
            {value:"70",name:"Operação de Aquisição sem Direito a Crédito"},
            {value:"71",name:"Operação de Aquisição com Isenção"},
            {value:"72",name:"Operação de Aquisição com Suspensão"},
            {value:"73",name:"Operação de Aquisição a Alíquota Zero"},
            {value:"74",name:"Operação de Aquisição sem Incidência da Contribuição"},
            {value:"75",name:"Operação de Aquisição por Substituição Tributária"},
            {value:"98",name:"Outras Operações de Entrada"},
            {value:"99",name:"Outras Operações"}
        ];

		window.product = this;

    }
    
    courses(){

        api.course(this.state.removeProduct).then((res) => {

            this.setState({courses:res.data});
            console.log(res.data);

        }).catch(error => {});

    }

    licenses(){

        api.getAllLicense(this.state.removeProduct).then((res) => {

            this.setState({licenses:res.data});
            console.log(res.data);

        }).catch(error => {});

    }


    toggleNavs = (e, state, index) => {

        e.preventDefault();
        this.setState({[state]: index });

    };

    editPriceLicense(id){ this.setState({openModalEditPrice:true,editPrice:id}); }

    editProduct(id){

        var index = this.state.products.map(function(x){ return x.id; }).indexOf(id);
        this.setState({navPills:1,erroProduct:"",editProduct:id,openModalNewProduct:true,dialog:JSON.parse(JSON.stringify(this.state.products[index]))});

    }
    
    removeProduct(id){ this.setState({erroProduct:"",removeProduct:id,openDialogRemoveProduct:true}); }

    removeProductAPI(){
        
        api.deleteProduct(this.state.removeProduct).then((res) => {
            
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span>O produto foi removido sucesso.</div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
            this.getProducts();
            this.setState({openDialogRemoveProduct:false});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

          });
          
    }

    getPlanOfplansofaccount(){
        
        api.getPlanOfplansofaccount().then((res) => {

            console.log(res.data);
            this.setState({plan_of_accounts:res.data});

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);

        });
          
    }
 
	//Para pegar as lessons
  	async getProducts(){

		await api.getProducts().then((res) => {
            
            this.setState({products:res.data});
   
  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

	async componentDidMount(){
        
        this.getProducts();
        this.getPlanOfplansofaccount();
        this.courses();
        this.licenses();

    }
    
    async postProduct(){

        await api.postProduct(this.state.dialog).then((res) => {
            
            this.setState({openModalNewProduct:false});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">O produto foi cadastrado sucesso.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
            this.getProducts();

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);
             
            this.setState({erroProduct:error.response.data.msg});

        });
          
    }
 

    async putProdut(){

        await api.putProduct(this.state.editProduct,this.state.dialog).then((res) => {

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span>O produto foi editado.</div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

            this.getProducts();

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
            console.log(error.response);

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span>{error.response.data.msg}</div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
            
            //this.setState({erroProduct:error.response.data.msg});
            
        });

    }

    newProduct(){

        this.setState({navPills:1,openModalNewProduct:true,editProduct:undefined,dialog:{

            icms_situacao_tributaria:"",
            icms_origem:"",
            pis_situacao_tributaria:"",
            cofins_situacao_tributaria:"",
            cod_product:"",
            business_unit:"",
            nfse:[],
            licenses:[],courses:[],name:"",sku:"",ncm:"",gtin:"",unitprice:'0',costprice:'0',icon:null,plan_of_accounts:'',description:""}});

    }

    saveProduct(event){

        event.preventDefault();
        if(this.state.editProduct === undefined){

            this.postProduct();
            
        }else{

            this.putProdut();
            
        }
    }

    geraOps(array,sum,ops){

        if(sum === undefined){ sum = ''; }

        return array.map((value,index) => {
 
            return(
            <React.Fragment key={value}>
                {

                    (value.id !== this.state.editPlan && value.subplan !== value.id) &&
                    <option value={value.id}>&nbsp;{this.geraPonts(sum+''+(index+1))} {value.name}</option> 
                
                }
                {this.geraOps(value.subs,(sum+(index+1)),'SUBS')}
            </React.Fragment>)
            
        })

    }

    geraPonts(txt){

        //console.log(txt);
        
        txt = txt.split('');
        var txt2 = "";
        for(let index = 0; index < txt.length; index++) {
            
            txt2 = txt2+(index !== 0 ? ".":"")+(txt[index]);
            
        }

        return txt2;

    }

	render(){
        
        const handleChange = name => event => {
	        var dialog = this.state.dialog;
            dialog[name] = event.target.value;
            
            if(name === ""){

                event.target.value = event.target.value.toLocaleLowerCase().replace(/ /g,'').replace(/[^a-zA-Z_0-9]/g,'');

            }

	        this.setState({dialog:dialog});
        }
        
    	return(
		<React.Fragment> 
            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
            <SimpleHeader name="Produtos ou serviços" way={[{name:"Produtos ou serviços",url:"/product"}]} buttons={<React.Fragment />} />
            <div className="mt--6 container-fluid">
                <div className="card">
                <div className="border-0 card-header">
                    <div className="row">
                        <div className="col-6">
                            <h3 className="mb-0">Lista de produtos ou serviços</h3>
                        </div>
                        <div className="text-right col-6">
                            <button onClick={ () => this.newProduct() } className="btn-neutral btn-round btn-icon btn btn-default btn-sm">
                                <span className="btn-inner--icon mr-1"><i className="fas fa-plus"></i></span>
                                <span className="btn-inner--text">Criar produto</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="align-items-center table-flush table">
                        <thead className="thead-light">
                            <tr>
                                <th>Nome</th>
                                <th>Tipo</th>
                                <th>Identificador</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                this.state.products.map((value,index) => {
                                  
                                    return(<tr>
                                                <td>{value.name}</td>
                                                <td>{(value.type === 0 ? "Produto":"Serviço")}</td> 
                                                <td>{value.id_name}</td>
                                                <td>
                                                    <font onClick={ () => this.editProduct(value.id) } className="table-actions fontButton"><i className="far fa-edit"></i></font>
                                                    <font onClick={ () => this.editPriceLicense(value.id) } className="table-actions fontButton"><i className="fas fa-money-bill-wave"></i></font>
                                                    <font onClick={ () => this.removeProduct(value.id) } className="table-actions fontButton"><i className="far fa-trash-alt"></i></font> 
                                                </td>
                                            </tr>)

                                })

                            } 
                        </tbody>
                    </table>
                    {

                        (this.state.products.length === 0) &&
                        <React.Fragment>
                            <center><h4 style={{margin:"30px 0px"}}>Nenhum produto foi encontrado.</h4></center>
                        </React.Fragment>

                    }
                </div>
            </div>
        </div>

        <Modal className="modal-dialog-centered" isOpen={this.state.openModalNewProduct} >
            <div className="modal-header bg-secondary">
                <h5 className="modal-title" id="exampleModalLabel">
                    {
                        (this.state.editProduct === undefined) &&
                        <font>Cadastrar</font>
                    }
                    {
                        (this.state.editProduct !== undefined) &&
                        <font>Editar</font>
                    }
                </h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModalNewProduct:false})} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body bg-secondary">
            <Form role="form" type="post" onSubmit={ (event) => this.saveProduct(event) }>

                {
                    
                    (this.state.erroProduct !== "") &&
                    <Alert color="danger">
                        <strong>Ops!</strong> {this.state.erroProduct}
                    </Alert>

                }
                <Row>
              
                       
                    {/* 
                        <Nav style={{marginBottom:'17px'}} className="nav-fill flex-column flex-sm-row" id="tabs-text" pills role="tablist">
                            <NavItem><NavLink aria-selected={this.state.navPills === 1} className={classnames("mb-sm-3 mb-md-0",{active: this.state.navPills === 1})} onClick={e => this.toggleNavs(e, "navPills", 1)} href="#pablo" role="tab" >Informações gerais</NavLink></NavItem>
                            <NavItem><NavLink aria-selected={this.state.navPills === 2} className={classnames("mb-sm-3 mb-md-0",{active: this.state.navPills === 2})} onClick={e => this.toggleNavs(e, "navPills", 2)} href="#pablo" role="tab" >Dimenssões e peso</NavLink></NavItem>
                            <NavItem><NavLink aria-selected={this.state.navPills === 3} className={classnames("mb-sm-3 mb-md-0", {active: this.state.navPills === 3})} onClick={e => this.toggleNavs(e, "navPills", 3)} href="#pablo" role="tab">Outros</NavLink></NavItem>
                        </Nav>
                        
                    */}
                    <Col md={12} style={{marginBottom:'15px'}}>
                        <div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink style={{cursor:'pointer'}} active={(this.state.navPills === 1 ? true:false)} onClick={() => { this.setState({navPills:1}); }}>Informações gerais</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink style={{cursor:'pointer'}} active={(this.state.navPills === 2 ? true:false)} onClick={() => { this.setState({navPills:2}); }}>Dimenssões e peso</NavLink>
                                </NavItem> 
                                <Dropdown nav isOpen={(this.state.dropdownOpen === true ? true:false)} toggle={() => { this.setState({dropdownOpen:!this.state.dropdownOpen}) }}>
                                <DropdownToggle nav caret>
                                Outros
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header hidden>Header</DropdownItem>
                                    <DropdownItem style={{cursor:'pointer'}} onClick={() => { this.setState({navPills:4,dropdownOpen:false}); }}>Licenças</DropdownItem>
                                    <DropdownItem style={{cursor:'pointer'}} onClick={() => { this.setState({navPills:5,dropdownOpen:false}); }}>Cursos</DropdownItem>
                                    <DropdownItem style={{cursor:'pointer'}} onClick={() => { this.setState({navPills:6,dropdownOpen:false}); }}>Faturação</DropdownItem>
                                    {

                                        (this.state.dialog.type === 1 || this.state.dialog.type == "1") &&
                                        <DropdownItem style={{cursor:'pointer'}} onClick={() => { this.setState({navPills:7,dropdownOpen:false}); }}>Nfse</DropdownItem>

                                    }
                                    {

                                        (this.state.dialog.type == 0 || this.state.dialog.type == "0") &&
                                        <DropdownItem style={{cursor:'pointer'}} onClick={() => { this.setState({navPills:8,dropdownOpen:false}); }}>Nfe</DropdownItem>

                                    }
                                    <DropdownItem divider />
                                    <DropdownItem style={{cursor:'pointer'}} onClick={() => { this.setState({navPills:3,dropdownOpen:false}); }}>Outros campos</DropdownItem>
                                </DropdownMenu>
                                </Dropdown>
                            </Nav>
                        </div>
                    </Col>

                    {

                        (this.state.navPills === 8 && this.state.openModalNewProduct === true) &&
                        <React.Fragment>
                            <Col md={4}>
                                <h5>NCM:</h5>
                                <FormGroup className={classnames("mb-3",{focused:this.state.focusedName3})} >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <Input type="text" onChange={handleChange('ncm')} value={ this.state.dialog.ncm } onFocus={() => this.setState({ focusedName3: true }) } onBlur={() => this.setState({ focusedName3: false }) } />
                                    </InputGroup>
                                    <font style={{fontSize:'12px'}}>Nomeclatura comum do Mercosul</font>
                                </FormGroup>
                            </Col>
                            <Col md={4}> 
                                <h5>Código do produto / EAN:</h5>
                                <FormGroup>
                                    <InputGroup className="input-group-merge input-group-alternative"> 
                                        <Input type="text" onChange={handleChange('cod_product')} value={this.state.dialog.cod_product} />
                                    </InputGroup> 
                                </FormGroup>
                            </Col>
                            <Col md={4}> 
                                <h5>Unidade Comercial:</h5>
                                <FormGroup>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <Input type="text" onChange={handleChange('business_unit')} value={this.state.dialog.business_unit} />
                                    </InputGroup> 
                                </FormGroup>
                            </Col>
                            <Col hidden md={12}> 
                                <h5>icms_situacao_tributaria:</h5>
                                <FormGroup>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <select onChange={handleChange('icms_situacao_tributaria')} value={this.state.dialog.icms_situacao_tributaria} className="form-control">
                                            <option value=""></option>
                                            {

                                                this.state.icms_situacao_tributaria.map((value,index) => {

                                                    return(<option valiue={value.id}>{value.value} - {value.name}</option>)

                                                })

                                            }
                                        </select>
                                    </InputGroup> 
                                </FormGroup>
                            </Col>
                            <Col md={12}> 
                                <h5>icms_origem:</h5>
                                <FormGroup>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <select onChange={handleChange('icms_origem')} value={this.state.dialog.icms_origem} className="form-control">
                                            <option value=""></option>
                                            {

                                                this.state.icms_origem.map((value,index) => {

                                                    return(<option value={value.value}>{value.value} - {value.name}</option>)

                                                })

                                            }
                                        </select>
                                    </InputGroup> 
                                </FormGroup>
                            </Col>
                            <Col hidden md={12}> 
                                <h5>pis_situacao_tributaria:</h5>
                                <FormGroup>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <select onChange={handleChange('pis_situacao_tributaria')} value={this.state.dialog.pis_situacao_tributaria} className="form-control">
                                            <option value=""></option>
                                            {

                                                this.state.pis_situacao_tributaria.map((value,index) => {

                                                    return(<option valiue={value.id}>{value.value} -{value.name}</option>)

                                                })

                                            }
                                        </select>
                                    </InputGroup> 
                                </FormGroup>
                            </Col>
                            <Col hidden md={12}> 
                                <h5>cofins_situacao_tributaria:</h5>
                                <FormGroup>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <select onChange={handleChange('cofins_situacao_tributaria')} value={this.state.dialog.cofins_situacao_tributaria} className="form-control">
                                            <option value=""></option>
                                            {

                                                this.state.cofins_situacao_tributaria.map((value,index) => {

                                                    return(<option valiue={value.id}>{value.value} -{value.name}</option>)

                                                })

                                            }
                                        </select>
                                    </InputGroup> 
                                </FormGroup>
                            </Col>
                        </React.Fragment>

                    }

                    {

                        (this.state.navPills === 7 && this.state.openModalNewProduct === true) &&
                        <React.Fragment>
                            <Col md={12}>
                                <style>{' .ddinput{ padding: 0px 5px; height: 19px; } .modal-dialog{ max-width: 1300px; }'}</style>
                                <table class="align-items-center table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">Lincença</th>
                                            <th scope="col">Aliquota</th>
                                            <th scope="col">ISS retido</th>
                                            <th scope="col">Item na lista de serviço</th>
                                            <th scope="col">Código Tributario</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            this.state.licenses.map((value,index) => {

                                                var nfsnew = {license:value.id,aliquota:"",iss:"",item_lista_servico:"",codigo_tributario_municipio:""};
                                                var nsf = this.state.dialog.nfse.find(x => x.license === value.id);
                                                if(nsf === undefined){

                                                    this.state.dialog.nfse.push(nfsnew);
                                                    nsf = this.state.dialog.nfse.find(x => x.license === value.id);

                                                }
                                                 

                                                return(
                                                    <tr key={index}>
                                                        <td>{value.name}</td>
                                                        <td><input onChange={(e) => { nsf.aliquota = e.target.value; this.setState({a:''}); }} type="text" class="form-control ddinput" value={nsf.aliquota} /></td>
                                                        <td>
                                                            <select onChange={(e) => { nsf.iss = e.target.value; this.setState({a:''}); }} type="text" class="form-control ddinput" value={nsf.iss} style={{width:'90px'}}>
                                                                <option value=""></option>
                                                                <option value="1">Sim</option>
                                                                <option value="0">Não</option>
                                                            </select>
                                                        </td>
                                                        <td><input onChange={(e) => { nsf.item_lista_servico = e.target.value; this.setState({a:''}); }} type="text" class="form-control ddinput" value={nsf.item_lista_servico} /></td>
                                                        <td><input onChange={(e) => { nsf.codigo_tributario_municipio = e.target.value; this.setState({a:''}); }} type="text" class="form-control ddinput" value={nsf.codigo_tributario_municipio} /></td>
                                                    </tr>)

                                            })

                                        }
                                        
                                    </tbody>
                                </table>
                            </Col>
                        </React.Fragment>

                    }
                    

                    {/**/}
                    <Col hidden={!(this.state.navPills === 5)} md={12}>

                    <div style={{marginBottom:"10px"}}><center>
                                <button onClick={() =>{ 

                                    var dialog = this.state.dialog; 
                                    dialog.courses = [];

                                    for(let index = 0; index < this.state.courses.length; index++){
                                        dialog.courses.push(this.state.courses[index].id);
                                    }

                                    this.setState({dialog});

                                 }} type="button" class="btn-round btn-icon btn btn-secondary btn-sm"><i class="far fa-check-square"></i> Marcar todos</button>
                                <button onClick={() =>{ var dialog = this.state.dialog; dialog.courses = []; this.setState({dialog}); }} type="button" class="btn-round btn-icon btn btn-secondary btn-sm"><i class="far fa-square"></i> Desmarcar todos</button>
                            </center>
                        </div>

                        {

                            this.state.courses.map((value,index) => {

                                return(<div className="custom-control custom-checkbox" key={value}>
                                            <input 
                                            key={value}
                                            onChange={(e) => { 
                                            
                                                var dialog = this.state.dialog;
                                                
                                                if(e.target.checked === true){

                                                    dialog.courses.push(value.id);

                                                }else{
    
                                                    dialog.courses.splice(dialog.courses.indexOf(value.id),1);

                                                }

                                                this.setState({dialog:dialog});

                                            }}
                                
                                            checked={(this.state.dialog.courses.indexOf(value.id) !== -1 ? true:false)}
                                        
                                            className="custom-control-input" id={'dd_3_'+value.id} type="checkbox" />
                                            <label className="custom-control-label" htmlFor={'dd_3_'+value.id}>{value.name}</label>
                                        </div>)

                            })

                        }
                        <br/>
                    </Col>
                    
                   
                    <Col hidden={!(this.state.navPills === 4)} md={12}> 

                        <div style={{marginBottom:"10px"}}><center>
                                <button onClick={() =>{ 

                                    var dialog = this.state.dialog; 
                                    dialog.licenses = [];

                                    for(let index = 0; index < this.state.licenses.length; index++){
                                        dialog.licenses.push(this.state.licenses[index].id);
                                    }

                                    this.setState({dialog});

                                 }} type="button" class="btn-round btn-icon btn btn-secondary btn-sm"><i class="far fa-check-square"></i> Marcar todos</button>
                                <button onClick={() =>{ var dialog = this.state.dialog; dialog.licenses = []; this.setState({dialog}); }} type="button" class="btn-round btn-icon btn btn-secondary btn-sm"><i class="far fa-square"></i> Desmarcar todos</button>
                            </center>
                        </div>
                        
                        {

                            this.state.licenses.map((value,index) => {

                                console.log(this.state.dialog.licenses.indexOf(value.id));
                                
                                var che = false;
                                
                                if(this.state.dialog.licenses.indexOf(value.id) !== -1){

                                    che = true;
 
                                }

                                return(<div key={value} className="custom-control custom-checkbox" >
                                        <input onChange={(e) => { 
                                            
                                            var dialog = this.state.dialog;
                                            
                                            if(e.target.checked === true){

                                               dialog.licenses.push(value.id);

                                            }else{
 
                                               dialog.licenses.splice(dialog.licenses.indexOf(value.id),1);

                                            }

                                            this.setState({dialog:dialog});

                                        }} checked={che} className="custom-control-input" id={'dd_2_'+value.id} type="checkbox" />
                                        <label className="custom-control-label" htmlFor={'dd_2_'+value.id}>{value.name}</label>
                                    </div>)

                            })

                        }
                        <br/>
                    </Col>

                    <Col hidden={!(this.state.navPills === 6)} md={12}>
                        <h5>Caso este produto seja gerado separadamente, em qual licença o fatura deve ser gerada?</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <select className="form-control" value={this.state.dialog.licenseFaturation} onChange={handleChange('licenseFaturation')}>
                                <option value=""></option>
                                {
                                
                                    this.state.licenses.map((value,index) => {

                                        return(<option value={value.id} key={index}>{value.name}</option>)

                                    })
                                
                                }
                            </select>
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 1)} md={12}>
                        <h5>Tipo:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <select className="form-control" value={this.state.dialog.type} onChange={handleChange('type')}>
                                <option value=""></option>
                                <option value="0">Produto</option>
                                <option value="1">Serviço</option>
                            </select>
                            </InputGroup>
                        </FormGroup>
                    </Col>


                    <Col hidden={!(this.state.navPills === 1)} md={12}>
                        <h5>Nome:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 1)} md={12}>
                        <h5>Identificador:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName332})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('id_name')} value={ this.state.dialog.id_name } onFocus={() => this.setState({ focusedName332: true }) } onBlur={() => this.setState({ focusedName332: false }) } />
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 1)} md={12}>
                        <h5>Identificador no contrato:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName332})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('id_name_contract')} value={ this.state.dialog.id_name_contract } onFocus={() => this.setState({ focusedName332a: true }) } onBlur={() => this.setState({ focusedName332a: false }) } />
                            </InputGroup>
                            <font style={{fontSize:'12px',fontWeight:'200'}}>Para gerar a varivel no contrato</font>
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 3)} md={6}>
                        <h5>SKU:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName2})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('sku')} value={ this.state.dialog.sku } onFocus={() => this.setState({ focusedName2: true }) } onBlur={() => this.setState({ focusedName2: false }) } />
                            </InputGroup>
                            <font style={{fontSize:'12px'}}>Código (SKU) ou referência (opcional)</font>
                        </FormGroup>
                    </Col>

                     

                    <Col hidden={!(this.state.navPills === 3)} md={6}>
                        <h5>GTIN/EAN:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName333})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('gtin')} value={ this.state.dialog.gtin } onFocus={() => this.setState({ focusedName333: true }) } onBlur={() => this.setState({ focusedName333: false }) } />
                            </InputGroup>
                            <font style={{fontSize:'12px'}}>Global Trade Item Number</font>
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 3)} md={6}>
                        <h5>Código CEST:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName333})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('cest')} value={ this.state.dialog.cest } onFocus={() => this.setState({ focusedName333: true }) } onBlur={() => this.setState({ focusedName333: false }) } />
                            </InputGroup>
                            <font style={{fontSize:'12px'}}>Código Especificador da Substituição Tributária</font>
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 2)} md={4}>
                        <h5>Altura:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName333})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <CurrencyInput decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={this.state.dialog.height} onChangeEvent={handleChange('height')} onFocus={() => this.setState({focusedName5:true}) } onBlur={() => this.setState({focusedName5:false}) } />
                            </InputGroup> 
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 2)} md={4}>
                        <h5>Largura:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName333})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon> 
                            <CurrencyInput decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={this.state.dialog.width} onChangeEvent={handleChange('width')} onFocus={() => this.setState({focusedName5:true}) } onBlur={() => this.setState({focusedName5:false}) } />
                            </InputGroup> 
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 2)} md={4}>
                        <h5>Comprimento:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName333})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon> 
                            <CurrencyInput decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={this.state.dialog.length} onChangeEvent={handleChange('length')} onFocus={() => this.setState({focusedName5:true}) } onBlur={() => this.setState({focusedName5:false}) } />
                            </InputGroup> 
                        </FormGroup>
                    </Col>


                    <Col hidden={!(this.state.navPills === 2)} md={6}>
                        <h5>Peso liquido:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName333})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon> 
                            <CurrencyInput decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={this.state.dialog.netWeight} onChangeEvent={handleChange('netWeight')} onFocus={() => this.setState({focusedName5:true}) } onBlur={() => this.setState({focusedName5:false}) } />
                            </InputGroup> 
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 2)} md={6}>
                        <h5>Peso bruto:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName333})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon> 
                            <CurrencyInput decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={this.state.dialog.grossWeight} onChangeEvent={handleChange('grossWeight')} onFocus={() => this.setState({focusedName5:true}) } onBlur={() => this.setState({focusedName5:false}) } />
                            </InputGroup> 
                        </FormGroup>
                    </Col>
                     

                    <Col hidden={!(this.state.navPills === 1)} md={6}>
                        <h5>Preço unitário:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName4})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <CurrencyInput decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={this.state.dialog.unitprice} onChangeEvent={handleChange('unitprice')} onFocus={() => this.setState({focusedName4:true}) } onBlur={() => this.setState({focusedName4:false}) } />
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 1)} md={6}>
                        <h5>Preço de custo:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName5})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <CurrencyInput decimalSeparator="." thousandSeparator="" className="form-control" prefix="" value={this.state.dialog.costprice} onChangeEvent={handleChange('costprice')} onFocus={() => this.setState({focusedName5:true}) } onBlur={() => this.setState({focusedName5:false}) } />
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col hidden={!(this.state.navPills === 1)} md={12}>
                        <h5>Plano de contas:</h5>
                        <FormGroup  >
                            <InputGroup className="input-group-merge input-group-alternative">
                                <select onChange={handleChange('plan_of_accounts')}  className="form-control" value={this.state.dialog.plan_of_accounts}>
                                    <option value=""></option>
                                    {
                                        
                                        this.geraOps(this.state.plan_of_accounts)
                                        /*.map((value,index) => {
                                            
                                            return(<option value={value.id} key={'planv_'+value.id}>{value.name}</option>)

                                        })*/

                                    }
                                </select>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                     
                    <Col hidden={!(this.state.navPills === 1)} md={4}>
                        <h5>Responsável pelas taxas do gateway:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName5})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                                <select onChange={handleChange('responsibleRate')}  className="form-control" value={this.state.dialog.responsibleRate}>
                                    <option value=""></option>
                                    <option value="0">Franqueado</option>
                                    <option value="1">Franqueadora</option>
                                </select>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    
                    <Col hidden={!(this.state.navPills === 1)} md={12}>
                        <h5>Descrição:</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName67})} >
                            <InputGroup className="input-group-merge input-group-alternative">
                             <textarea value={this.state.dialog.description} onChange={handleChange('description')} className="form-control" onFocus={() => this.setState({focusedName67:true}) } onBlur={() => this.setState({focusedName67:false}) }></textarea>
                            </InputGroup>
                        </FormGroup>
                    </Col>

                </Row>
                <Row>
                <Col md={12}>
                    <button type="submit" style={{width:"100%"}} className="btn-icon btn-3 btn btn-primary"><span className="btn-inner--icon"> </span><span className="btn-inner--text"> 
                                    
                    {
                        (this.state.editProduct === undefined) &&
                        <font>Cadastrar</font>
                    }
                    {
                        (this.state.editProduct !== undefined) &&
                        <font>Editar</font>
                    }
                    
                    </span></button>
                </Col>
                </Row>
                </Form>
            </div>
        </Modal>

        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveProduct} >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
              <p>Por segurança, somente produtos que não estão relacionados a nenhum item nos planos de pagamentos, podem ser removidos.</p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeProductAPI() }>Ok, Remova</Button>
            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveProduct:false}) }>
              Cancelar e fechar
            </Button>
          </div>
        </Modal>

        <Modal className="modal-dialog-centered" isOpen={this.state.openModalEditPrice} style={{maxWidth:'800px'}}>
            <div className="modal-header bg-secondary">
                <h5 className="modal-title" id="exampleModalLabel">
                    <font>Editar valores e comissões</font>
                </h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModalEditPrice:false})} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body bg-secondary">
                {
                    (this.state.openModalEditPrice === true) &&
                    <EditLicenseValuesProduct success={ (data) => {

                        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span>As alterações foram salvas.</div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			            this.refs.notificationAlert.notificationAlert(options);
                        this.setState({openModalEditPrice:false});

                    }}
                    
                    error={(data) => {

                        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível salvar.</span><span data-notify="message">{data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
		  		        this.refs.notificationAlert.notificationAlert(options);

                    }}
                    
                    product={this.state.editPrice} />
                }
            </div>
        </Modal>
     
		</React.Fragment>)
    }

}


export default Product;