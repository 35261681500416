import React from "react";
import {Button,Card,CardBody,Form} from "reactstrap";
import {Modal} from "reactstrap"; 
import NotificationAlert from "react-notification-alert";
import api from "./../../../Api.js";

class ActionServer extends React.Component {

	constructor(props){

		super(props);
       
        this.state = {
			loadgenerateNginx:false,
			loadgeneratePM2:false,
			loadgenerateFront:false,
			loadinstallDatabase:false
		};

		this.generateNginx = this.generateNginx.bind(this);
		this.generatePM2 = this.generatePM2.bind(this);
		this.generateFront = this.generateFront.bind(this);
		this.installDatabase = this.installDatabase.bind(this);
		
		window.actionserver = this;

	}



	 

	async installDatabase(){

		if(this.state.loadinstallDatabase === true){ return false; }
		await this.setState({loadinstallDatabase:true});

		var obj = {};
		if(this.state.installDatabase === true){

			obj.force = true;

		}

		await api.installDatabase(this.props.licenseID,obj).then((res) => {

			console.log(res.data);
			this.setState({loadinstallDatabase:false});

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O arquivo foi gerado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
				
		}).catch(error => {

			this.setState({loadinstallDatabase:false});

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			let options = {place: "tr",message:(<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type:'danger',icon:"fas fa-exclamation",autoDismiss:7};
			this.refs.notificationAlert.notificationAlert(options);

		});

	}

	async generateNginx(){

		if(this.state.loadgenerateNginx === true){ return false; }
		await this.setState({loadgenerateNginx:true});

		var obj = {};
		if(this.state.generateNginx === true){

			obj.force = true;

		}

		await api.generateNginx(this.props.licenseID,obj).then((res) => {

			console.log(res.data);
			this.setState({loadgenerateNginx:false});

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O arquivo foi gerado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
				
		}).catch(error => {

			this.setState({loadgenerateNginx:false});

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			let options = {place: "tr",message:(<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type:'danger',icon:"fas fa-exclamation",autoDismiss:7};
			this.refs.notificationAlert.notificationAlert(options);

		});

	}
	
	async generatePM2(){

		if(this.state.loadgeneratePM2 === true){ return false; }
		await this.setState({loadgeneratePM2:true});

		var obj = {};
		if(this.state.generatePM2 === true){

			obj.force = true;

		}

		await api.generatePM2(this.props.licenseID,obj).then((res) => {
			
			console.log(res.data);
			this.setState({loadgeneratePM2:false});

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O arquivo foi gerado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
		 
		}).catch(error => {

			this.setState({loadgeneratePM2:false});

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			let options = {place: "tr",message:(<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type:'danger',icon:"fas fa-exclamation",autoDismiss:7};
			this.refs.notificationAlert.notificationAlert(options);

		});

	}

	async generateFront(){

		if(this.state.loadgenerateFront === true){ return false; }
		await this.setState({loadgenerateFront:true});

		var obj = {};
		if(this.state.generateFront === true){

			obj.force = true;

		}

		await api.generateFront(this.props.licenseID,obj).then((res) => {

			console.log(res.data);
			this.setState({loadgenerateFront:false});
			
			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O arquivo foi gerado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
			
      	}).catch(error => {

			this.setState({loadgenerateFront:false});

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			let options = {place: "tr",message:(<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type:'danger',icon:"fas fa-exclamation",autoDismiss:7};
			this.refs.notificationAlert.notificationAlert(options);

      	});

	}

   

	render(){

		if(this.props.open === true){

			var license = this.props.state.license.map(function(x){return x.id;}).indexOf(this.props.licenseID);
			license = this.props.state.license[license];
			var txt = 'pm2 start server.js --name '+license.license+' --node-args="--expose-gc" -- configfile=/home/ubuntu/slave/server_list/'+license.hostname+'.json';

		}
 
		return(
		<React.Fragment>

            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

	        <Modal className="modal-dialog-centered" isOpen={this.props.open} >
	          <div className="modal-body p-0">
	            <Card className="bg-secondary border-0 mb-0">
	            <div className="modal-header">
	            <h6 className="modal-title" id="modal-title-default">Ações disponíves</h6>
	            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.setState({openActionServer:false})}><span aria-hidden={true}>×</span> </button>
	          </div>
                    <CardBody className="px-lg-5">
                        <Form role="form" type="post" onSubmit={ (event) => this.saveCourses(event) }>
 
                            <div> 
			
								<div style={{display:'flex',marginBottom:'10px'}}>
									<div>
										<button onClick={() => this.generateNginx() } type="button" className="btn btn-secondary btn-sm">Gerar nginx</button>
									</div>
									<div style={{marginLeft:'10px'}}>
										<div className="custom-control custom-checkbox">
											<input className="custom-control-input" id="customCheck1D" type="checkbox" checked={this.state.generateNginx} onChange={ (e) => this.setState({generateNginx:e.target.checked}) } />
											<label className="custom-control-label" htmlFor="customCheck1D">Forçar geração.</label>
										</div>
									</div>
								</div>

								<div style={{display:'flex',marginBottom:'10px'}}>
									<div>
										<button onClick={() => this.generateFront() } type="button" className="btn btn-secondary btn-sm">Gerar serverlist front</button>
									</div>
									<div style={{marginLeft:'10px'}}>
										<div className="custom-control custom-checkbox">
											<input className="custom-control-input" id="customCheck1C" type="checkbox" checked={this.state.generateFront} onChange={ (e) => this.setState({generateFront:e.target.checked}) } />
											<label className="custom-control-label" htmlFor="customCheck1C">Forçar geração.</label>
										</div>
									</div>
								</div>

								<div style={{display:'flex',marginBottom:'10px'}}>
									<div>
										<button onClick={() => this.generatePM2() } type="button" className="btn btn-secondary btn-sm">Gerar config pm2</button>
									</div>
									<div style={{marginLeft:'10px'}}>
										<div className="custom-control custom-checkbox">
											<input className="custom-control-input" id="customCheck1B" type="checkbox" checked={this.state.generatePM2} onChange={ (e) => this.setState({generatePM2:e.target.checked}) } />
											<label className="custom-control-label" htmlFor="customCheck1B">Forçar geração.</label>
										</div>
									</div>
								</div>


								<div style={{display:'flex',marginBottom:'10px'}}>
									<div>
										<button onClick={() => this.installDatabase() } type="button" className="btn btn-secondary btn-sm">Instalar banco de dados</button>
									</div>
									<div hidden style={{marginLeft:'10px'}}>
										<div className="custom-control custom-checkbox">
											<input className="custom-control-input" id="customCheck1B" type="checkbox" checked={this.state.generatePM2} onChange={ (e) => this.setState({generatePM2:e.target.checked}) } />
											<label className="custom-control-label" htmlFor="customCheck1B">Forçar geração.</label>
										</div>
									</div>
								</div>

								<br/>

								{
/**/
									(this.props.open === true) &&
									<React.Fragment>
										Comando para iniciar o pm2:
										<textarea style={{height:'142px'}} className="form-control" value={txt}></textarea>
									</React.Fragment>

								}
								
								{

									/*
										
										<div style={{display:'flex',marginBottom:'10px'}}>
											<div>
												<button type="button" className="btn btn-secondary btn-sm">Instalar banco</button>
											</div>
											<div style={{marginLeft:'10px'}}>
												<div className="custom-control custom-checkbox">
													<input className="custom-control-input" id="customCheck1A" type="checkbox" checked={} onChange={ (e) => this.setState({:e.target.checked}) } />
													<label className="custom-control-label" htmlFor="customCheck1A">Forçar geração</label>
												</div>
											</div>
										</div>
										
									*/

								}
								
                            
                             
							 

                             
                            

                            </div>

                        </Form>
                    </CardBody>
	            </Card>
	          </div>
	        </Modal>
	    
        </React.Fragment>

		)

	}

}

export default ActionServer;